import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  resumeTheDeviceLogsAfterRefresh,
  performDeviceInteraction,
  viewNotifications,
  changeBuildFileName,
  hideModalBuildPush,
  showLogModal,
  startDeviceLogs,
  stopDeviceLogs,
  recentAppTray,
  onOrientationChange,
  showInputTextModal,
  showPushBuildModal,
  showAppManagementBuildModal,
  showOrientationModal,
  changeOrientation,
  hideOrientationModal,
  restartDevice,
  changePageSourceFetchingInterval,
  visibleRecordingSettingsModal,
  openPageSourceInNewTab,
} from "../../../../actions/Recording";

import { Col, Row, Modal, Radio, Progress, Tooltip, Input } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faUser,
  faHome,
  faRetweet,
  faArrowLeft,
  faLock,
  faUnlock,
  faVolumeUp,
  faVolumeDown,
  faVolumeMute,
  faCloudUploadAlt,
  faBell,
  faArchive,
  faUndo,
  faBars,
  faFileArchive,
  faKeyboard,
  faCloudDownloadAlt,
  faStopCircle,
} from "@fortawesome/free-solid-svg-icons";
import {
  DEVICE_INTERACTION,
  DEVICE_STATUS,
  NOTIFICATION_SCROLL_DIRECTION,
  PORTRAIT,
  LANDSCAPE,
  TOOLTIP_BG_COLOR,
  ANDROID,
} from "../../../../Constants";
import InputTextModal from "../InputTextModal";
import { showDeviceAction } from "../../../../Util";
import DevicePushBuildModalV2 from "../../../DeviceSessionV2/DevicePushBuildModalV2/DevicePushBuildModalV2";
import SvgLoader from "../../../../Util/SvgLoader";
import {
  ANDROID_LOGO,
  ANDROID_LOGO_IMAGE,
  DEVICE_ORIENTATION_ICON,
  PRESS_KEYBOARD_ICON,
  IOS_LOGO,
  PRESS_BACK_ICON,
  PRESS_CLOUD_UPLOAD,
  PRESS_HOME_ICON,
  PRESS_LOCK_ICON,
  PRESS_LOCK_OPEN_ICON,
  PRESS_MENU_OPEN,
  PRESS_NOTIFICATION_ICON,
  PRESS_VOLUME_DOWN_ICON,
  PRESS_VOLUME_UP_ICON,
  SETTING_ICON,
  ICON_SEARCH,
} from "../../../../Constants/SvgConstants";
import styles from "./DeviceInteractActions.module.scss";
import RecordingSettings from "../RecordingSettings/RecordingSettings";
import CommonConfirmationModalV2 from "../../../CommonComponents/CommonConfirmationModalV2/CommonConfirmationModalV2";

library.add(faUser);
library.add(faHome);
library.add(faRetweet);
library.add(faArrowLeft);
library.add(faLock);
library.add(faUnlock);
library.add(faVolumeUp);
library.add(faVolumeDown);
library.add(faVolumeMute);
library.add(faCloudUploadAlt);
library.add(faCloudDownloadAlt);
library.add(faBell);
library.add(faArchive);
library.add(faUndo);
library.add(faBars);
library.add(faFileArchive);
library.add(faKeyboard);
library.add(faStopCircle);

const DeviceInteractActions = (props) => {
  const [allValues, setState] = useState({
    fileInput: null,
    color: props.color,
    deviceSessionId: null,
    showLogModal: false,
    logType: "VERBOSE",
    showOrientationModal: false,
    orientaionValue: "",
    homeButton: "",
    backButton: "",
    lockButton: "",
    unlockButton: "",
    bellButton: "",
    pushBuildButton: "",
    orientationButton: "",
    recentButton: "",
    restartButton: "",
  });
  const [openRestartDeviceModal, setOpenRestartDeviceModal] = useState(false);

  const dispatch = useDispatch();

  //WillMount
  useEffect(() => {
    setState({ ...allValues, fileInput: null });
    setState({ ...allValues, logType: null });
    if (logStarted) {
      dispatch(resumeTheDeviceLogsAfterRefresh(props.serverId, props.targetUniqueId));
    }
  }, []);

  //DidUpdate
  useEffect(() => {
    if (pushBuildFile === undefined && allValues.fileInput) {
      setState({ ...allValues, fileInput: null });
    }
  });

  const onClickHome = () => {
    if (
      dispatch(
        performDeviceInteraction(
          {
            serverId: props.serverId,
            targetUniqueId: props.targetUniqueId,
            deviceInteractionAction: DEVICE_INTERACTION.HOME,
          },
          undefined,
          undefined,
          props.projectId
        )
      )
    ) {
      setState({ ...allValues, homeButton: true });
      setTimeout(() => {
        setState({ ...allValues, homeButton: false });
      }, 2000);
    } else {
      setState({ ...allValues, homeButton: false });
    }
  };

  const onClickBack = () => {
    if (
      dispatch(
        performDeviceInteraction({
          serverId: props.serverId,
          targetUniqueId: props.targetUniqueId,
          deviceInteractionAction: DEVICE_INTERACTION.BACK,
        })
      )
    ) {
      setState({ ...allValues, backButton: true });
      setTimeout(() => {
        setState({ ...allValues, backButton: false });
      }, 2000);
    } else {
      setState({ ...allValues, backButton: false });
    }
  };

  const onClickLock = () => {
    if (
      dispatch(
        performDeviceInteraction({
          serverId: props.serverId,
          targetUniqueId: props.targetUniqueId,
          deviceInteractionAction: DEVICE_INTERACTION.LOCK,
        })
      )
    ) {
      setState({ ...allValues, lockButton: true });
      setTimeout(() => {
        setState({ ...allValues, lockButton: false });
      }, 2000);
    } else {
      setState({ ...allValues, lockButton: false });
    }
  };

  const onClickUnLock = () => {
    if (
      dispatch(
        performDeviceInteraction({
          serverId: props.serverId,
          targetUniqueId: props.targetUniqueId,
          deviceInteractionAction: DEVICE_INTERACTION.UNLOCK,
        })
      )
    ) {
      setState({ ...allValues, unlockButton: true });
      setTimeout(() => {
        setState({ ...allValues, unlockButton: false });
      }, 2000);
    } else {
      setState({ ...allValues, unlockButton: false });
    }
  };

  const onClickVolumeUp = () => {
    dispatch(
      performDeviceInteraction({
        serverId: props.serverId,
        targetUniqueId: props.targetUniqueId,
        deviceInteractionAction: DEVICE_INTERACTION.VOLUME_UP,
      })
    );
  };

  const onClickVolumeDown = () => {
    dispatch(
      performDeviceInteraction({
        serverId: props.serverId,
        targetUniqueId: props.targetUniqueId,
        deviceInteractionAction: DEVICE_INTERACTION.VOLUME_DOWN,
      })
    );
  };

  const onClickBell = () => {
    if (
      dispatch(
        viewNotifications({
          serverId: props.serverId,
          targetUniqueId: props.targetUniqueId,
          deviceInteractionAction: DEVICE_INTERACTION.VIEW_NOTIFICATION,
          scrollDirection: NOTIFICATION_SCROLL_DIRECTION.DOWN,
        })
      )
    ) {
      setState({ ...allValues, bellButton: true });
      setTimeout(() => {
        setState({ ...allValues, bellButton: false });
      }, 2000);
    } else {
      setState({ ...allValues, bellButton: false });
    }
  };

  const changeBuildFile = (e) => {
    if (e.target && e.target.files && e.target.files.length == 1) {
      dispatch(changeBuildFileName(e.target.files[0]));
    }
  };
  const hidePushBuildModal = () => {
    setState({ ...allValues, fileInput: "" });
    dispatch(hideModalBuildPush());
  };

  const hideLogModal = () => {
    dispatch(hideLogModal());
  };

  const onLogTypeChange = (e) => {
    setState({ ...allValues, logType: e.target.value });
  };

  const onClickLogs = (IOS) => {
    if (!IOS && !logStarted) {
      dispatch(showLogModal());
    } else if (IOS && !logStarted) {
      dispatch(
        startDeviceLogs({
          serverId: props.serverId,
          targetUniqueId: props.targetUniqueId,
          logType: allValues.logType,
        })
      );
    } else {
      dispatch(stopDeviceLogs({ serverId: props.serverId, targetUniqueId: props.targetUniqueId }));
    }
  };

  const handleRecentAppTray = () => {
    if (dispatch(recentAppTray({ targetUniqueId: props.targetUniqueId, serverId: props.serverId }))) {
      setState({ ...allValues, recentButton: true });
      setTimeout(() => {
        setState({ ...allValues, recentButton: false });
      }, 2000);
    } else {
      setState({ ...allValues, recentButton: false });
    }
  };

  // The LOG MODAL END
  const onOrientationTypeChange = (e) => {
    if (dispatch(onOrientationChange(e.target.value))) {
      setState({ ...allValues, orientationButton: true });
      setTimeout(() => {
        setState({ ...allValues, orientationButton: false });
      }, 2000);
    } else {
      setState({ ...allValues, orientationButton: false });
    }
  };
  ///
  const {
    logStarted,
    pushBuildFile,
    showDeviceInteractionModeOnly,
    recordingState,
    pushBuildModalVisible,
    logModalVisible,
    orientationModalVisible,
    logStartRequest,
    deviceActions,
    percentageDownload,
    orientationType,
    visibleRecordingSettingModal,
  } = useSelector((state) => state.RecordingReducer);

  const { isLoadingForAIStep } = useSelector((state) => state.RecTestStepCommonViewReducer);
  ///

  let disableControl = false;

  if (
    recordingState === DEVICE_STATUS.PREVIEW ||
    recordingState === DEVICE_STATUS.RESTART ||
    recordingState === DEVICE_STATUS.RECORDING ||
    isLoadingForAIStep !== null
  ) {
    disableControl = true;
  }
  let disableAction = "";
  let allowdExtenstion = ".apk";
  let IOS = false;
  if (props.platformName && props.platformName.toUpperCase() == "IOS") {
    disableAction = "disableControl opacity05";
    allowdExtenstion = ".ipa";
    IOS = true;
  }
  let disableUPload = "";
  if (showDeviceInteractionModeOnly) {
    disableUPload = "disableControl opacity05";
  }
  let deviceActionNameShow = false;
  let defautDraggableX = 1;

  const deviceIconTooltip = `${props.platformName === ANDROID ? "Android" : "IOS"} : OS Version(${props.osVersion}) `;

  const handleAction = (action) => {
    const actionFunctions = {
      HOME: onClickHome,
      BACK: onClickBack,
      LOCK: onClickLock,
      UNLOCK: onClickUnLock,
      KEYBOARD: () => dispatch(showInputTextModal()),
      RECORDING_SETTINGS: () => dispatch(visibleRecordingSettingsModal(true)),
      VOLUME_UP: onClickVolumeUp,
      VOLUME_DOWN: onClickVolumeDown,
      NOTIFICATION: onClickBell,
      APP_PUSH: () => dispatch(showPushBuildModal()),
      XPRESS_APP_STORE: () => dispatch(showAppManagementBuildModal(props.deviceFarmId, props.platformName)),
      ORIENTATION: () => dispatch(showOrientationModal()),
      RECENT: () =>
        dispatch(
          recentAppTray({
            targetUniqueId: props.targetUniqueId,
            serverId: props.serverId,
          })
        ),
    };

    const actionFunction = actionFunctions[action.type];

    if (
      (!disableControl && showDeviceAction(deviceActions, action.type, recordingState)) ||
      (actionFunctions["KEYBOARD" || "RECORDING_SETTINGS"] && !disableControl)
    ) {
      if (actionFunction) {
        actionFunction();
      }
    }
  };

  const deviceActionsList = [
    { type: "HOME", iconName: PRESS_HOME_ICON, tooltip: "Home" },
    { type: "BACK", iconName: PRESS_BACK_ICON, tooltip: "Back", condition: props.platformName == ANDROID },
    { type: "LOCK", iconName: PRESS_LOCK_ICON, tooltip: "Lock" },
    { type: "UNLOCK", iconName: PRESS_LOCK_OPEN_ICON, tooltip: "Unlock" },
    {
      type: "KEYBOARD",
      iconName: PRESS_KEYBOARD_ICON,
      tooltip: "Fast Input (TBD)",
      // condition: showDeviceAction(deviceActions, "KEYBOARD", recordingState),
    }, //need icon
    {
      type: "RECORDING_SETTINGS",
      iconName: SETTING_ICON,
      tooltip: "Recording Settings",
    }, //need icon
    // { type: "VOLUME_UP", iconName: PRESS_VOLUME_UP_ICON, tooltip: "Volume Up" },
    // { type: "VOLUME_DOWN", iconName: PRESS_VOLUME_UP_ICON, tooltip: "Volume Down" }, //need icon
    { type: "NOTIFICATION", iconName: PRESS_NOTIFICATION_ICON, tooltip: "Notifications" },
    { type: "APP_PUSH", iconName: PRESS_CLOUD_UPLOAD, tooltip: "Push Build", condition: props.platformName == ANDROID },
    {
      type: "XPRESS_APP_STORE",
      icon: "cloud-download-alt",
      tooltip: "App Store",
      percentageDownload,
      condition: showDeviceAction(deviceActions, "XPRESS_APP_STORE", recordingState),
    },
    {
      type: "ORIENTATION",
      iconName: DEVICE_ORIENTATION_ICON,
      tooltip: "Orientation",
      condition: showDeviceAction(deviceActions, "ORIENTATION", recordingState),
    },
    {
      type: "RECENT",
      iconName: PRESS_MENU_OPEN,
      tooltip: "Recent App Tray",
      condition: props.platformName == ANDROID,
    },
  ];

  return (
    <React.Fragment>
      {props.platformName && (
        <div>
          <div
            className={`${styles.devices_actions} ${recordingState == DEVICE_STATUS.RECORDING && styles.padding_top}`}
          >
            {deviceActionsList?.map((action) => (
              <Row key={action.type}>
                <Col
                  span={24}
                  className={styles.actions_item}
                  onClick={() => handleAction(action)}
                  onTouchStart={() => handleAction(action)}
                >
                  {action?.condition === undefined ? (
                    <>
                      {action?.icon ? (
                        <FontAwesomeIcon icon={action?.icon} title={action.tooltip} />
                      ) : (
                        <Tooltip
                          title={action.tooltip}
                          color={TOOLTIP_BG_COLOR}
                          mouseEnterDelay={0}
                          mouseLeaveDelay={0}
                        >
                          <div>
                            <SvgLoader
                              iconName={action.iconName}
                              iconColor={
                                disableControl ||
                                ((action?.iconName === "KEYBOARD" || action?.iconName === "RECORDING_SETTINGS") &&
                                  !showDeviceAction(deviceActions, action.type, recordingState))
                                  ? "#D3D3D3"
                                  : "#4A4D53"
                              }
                              disabled={
                                disableControl ||
                                (action?.iconName === "KEYBOARD" &&
                                  !showDeviceAction(deviceActions, action.type, recordingState))
                              }
                            />
                          </div>
                        </Tooltip>
                      )}
                    </>
                  ) : (
                    <>
                      {action?.condition === true ? (
                        action?.percentageDownload &&
                        percentageDownload != undefined &&
                        percentageDownload[props.targetUniqueId] &&
                        percentageDownload[props.targetUniqueId] != null ? (
                          <div>
                            <Progress
                              format={(percent) => percent}
                              strokeWidth={10}
                              width={25}
                              type="circle"
                              percent={percentageDownload[props.targetUniqueId]}
                            />
                          </div>
                        ) : action?.icon ? (
                          <FontAwesomeIcon icon={action?.icon} title={action.tooltip} />
                        ) : (
                          <Tooltip
                            title={action.tooltip}
                            color={TOOLTIP_BG_COLOR}
                            mouseEnterDelay={0}
                            mouseLeaveDelay={0}
                          >
                            <div>
                              <SvgLoader
                                iconName={action.iconName}
                                iconColor={
                                  disableControl || !showDeviceAction(deviceActions, action.type, recordingState)
                                    ? "#D3D3D3"
                                    : "#4A4D53"
                                }
                                disabled={
                                  disableControl || !showDeviceAction(deviceActions, action.type, recordingState)
                                }
                              />
                            </div>
                          </Tooltip>
                        )
                      ) : null}
                    </>
                  )}

                  <span className={"device-interaction-name"}>{deviceActionNameShow && action.tooltip}</span>
                </Col>
              </Row>
            ))}
            <Row>
              {props.platformName && (
                <Tooltip title={deviceIconTooltip} color={TOOLTIP_BG_COLOR} mouseEnterDelay={0} mouseLeaveDelay={0}>
                  <Col span={24} className={styles.platform_actions_item}>
                    <SvgLoader iconName={props.platformName == ANDROID ? ANDROID_LOGO_IMAGE : IOS_LOGO} />
                  </Col>
                </Tooltip>
              )}
            </Row>
            <Row>
              <Tooltip title="Reboot Device" color={TOOLTIP_BG_COLOR} mouseEnterDelay={0} mouseLeaveDelay={0}>
                <Col
                  span={24}
                  className={styles.actions_item}
                  onClick={() => {
                    !disableControl &&
                      showDeviceAction(deviceActions, "RESTART", recordingState) &&
                      setOpenRestartDeviceModal(true);
                  }}
                  onTouchStart={() => {
                    !disableControl &&
                      showDeviceAction(deviceActions, "RESTART", recordingState) &&
                      setOpenRestartDeviceModal(true);
                  }}
                >
                  <span className={`${styles.textRestart} ${disableControl && "disable__text"}`}>Restart</span>
                </Col>
              </Tooltip>
            </Row>
            <Row>
              <Tooltip title="Page Source" color={TOOLTIP_BG_COLOR} mouseEnterDelay={0} mouseLeaveDelay={0}>
                <Col
                  span={24}
                  className={styles.actions_item}
                  onClick={() => {
                    !disableControl && dispatch(openPageSourceInNewTab(props.targetUniqueId, props.serverId));
                  }}
                  onTouchStart={() => {
                    !disableControl && dispatch(openPageSourceInNewTab(props.targetUniqueId, props.serverId));
                  }}
                >
                  <SvgLoader
                    iconName={ICON_SEARCH}
                    iconColor={disableControl ? "#D3D3D3" : "#4A4D53"}
                    disabled={disableControl}
                  />
                </Col>
              </Tooltip>
            </Row>
          </div>
        </div>
      )}
      <DevicePushBuildModalV2
        projectId={props.projectId}
        visible={pushBuildModalVisible}
        targetUniqueId={props.targetUniqueId}
        hidePushBuildModal={() => hidePushBuildModal()}
      />
      <Modal
        title="Select The Log Type"
        visible={logModalVisible}
        onOk={() => {
          startDeviceLogs({
            serverId: props.serverId,
            targetUniqueId: props.targetUniqueId,
            logType: allValues.logType,
          });
        }}
        onCancel={hideLogModal}
        okText="Start Log"
        cancelText="Cancel"
        confirmLoading={logStartRequest}
        loading={logStartRequest}
      >
        <Radio.Group onChange={onLogTypeChange} defaultValue={allValues.logType}>
          <Radio className={"radiostyle"} value={"VERBOSE"}>
            VERBOSE
          </Radio>
          <Radio className={"radiostyle"} value={"INFO"}>
            INFO
          </Radio>
          <Radio className={"radiostyle"} value={"ERROR"}>
            ERROR
          </Radio>
        </Radio.Group>
      </Modal>
      <Modal
        title="Select Orientaion To Change "
        visible={orientationModalVisible}
        onOk={() => {
          dispatch(
            changeOrientation({
              serverId: props.serverId,
              targetUniqueId: props.targetUniqueId,
              orientationType: orientationType,
            })
          );
        }}
        onCancel={dispatch(hideOrientationModal)}
        okText="Change Orientation"
      >
        {!IOS ? (
          <Radio.Group onChange={onOrientationTypeChange}>
            <Row></Row>
            <Radio className={"radiostyle"} value={"0"}>
              {PORTRAIT}
            </Radio>
            <Row></Row>
            <Radio className={"radiostyle"} value={"1"}>
              {LANDSCAPE}
            </Radio>
          </Radio.Group>
        ) : (
          <Radio.Group onChange={onOrientationTypeChange}>
            <Row></Row>
            <Radio className={"radiostyle"} value={"0"}>
              {PORTRAIT}
            </Radio>
            <Row></Row>
            <Radio className={"radiostyle"} value={"1"}>
              {LANDSCAPE}
            </Radio>
          </Radio.Group>
        )}
      </Modal>
      <InputTextModal IOS={IOS} serverId={props.serverId} targetUniqueId={props.targetUniqueId} />
      {visibleRecordingSettingModal && <RecordingSettings />}
      <CommonConfirmationModalV2
        handleOk={() => {
          dispatch(
            restartDevice({
              targetUniqueId: props.targetUniqueId,
              serverId: props.serverId,
              platformName: props.platformName,
              deviceFarmId: props.deviceFarmId,
            })
          );
          setOpenRestartDeviceModal(false);
        }}
        handleCancel={() => setOpenRestartDeviceModal(false)}
        modalOpen={openRestartDeviceModal}
        modalTitle={"Restart Device"}
        modalContent={"Are you sure you want to restart the device?"}
        okText={"Restart"}
        cancelText={"No"}
        isDeleteModal={true}
      />
    </React.Fragment>
  );
};
export default DeviceInteractActions;
