import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal } from "antd";
import AddAppBuildV2 from "../../../../../BuildManagementV2/AddAppBuildV2/AddAppBuildV2";
import { INITIAL_APP_BUILD_DATA } from "../../../../../../Constants";
import { handleSaveBuild, isNotNullBuildDetails } from "../../../../../../Util";
import { getUploadBuildOption } from "../../../../../../actions/Recording";
import { visibleCreateBuildModal } from "../../../../../../actions/TestJobActions";

const UploadBuildModalV2 = ({ setBuildVersionList }) => {
  const dispatch = useDispatch();
  const [appBuildData, setAppBuildData] = useState(INITIAL_APP_BUILD_DATA);
  const { platform, projectId } = useSelector((state) => state.ProjectsReducer);
  const { isVisibleCreateBuildModal } = useSelector((state) => state.TestJobReducer);
  const { deviceFarmList } = useSelector((state) => state.ServerListReducer);
  const { buildUploadRequest, sharedWithFarmInAddAppBuild } = useSelector((state) => state.RecordingReducer);

  useEffect(() => {
    dispatch(getUploadBuildOption(projectId));
  }, []);

  const selectedFarms = [];

  if (deviceFarmList && deviceFarmList.length > 0) {
    deviceFarmList.map((v, k) => {
      return selectedFarms.push(v.id);
    });
  }

  return (
    <Modal
      width="60%"
      title={<span>Create Build</span>}
      visible={isVisibleCreateBuildModal}
      destroyOnClose
      onCancel={() => {
        if (!buildUploadRequest) {
          dispatch(visibleCreateBuildModal(false));
        }
      }}
      closable={!buildUploadRequest}
      maskClosable={!buildUploadRequest}
      confirmLoading={buildUploadRequest}
      loading={buildUploadRequest}
      className="importStepsOrActionsModal create_Build_Modal"
      footer={[
        <div className="modal-Content-Btn">
          <Button
            className={`modal_Buttons modal_Cancel_Btn modal-Button-Width`}
            disabled={buildUploadRequest}
            onClick={() => {
              if (!buildUploadRequest) {
                dispatch(visibleCreateBuildModal(false));
              }
            }}
          >
            Cancel
          </Button>
          <Button
            key="submit"
            type="primary"
            className={`modal_Buttons modal-Ok-Btn`}
            onClick={() =>
              handleSaveBuild(
                selectedFarms,
                sharedWithFarmInAddAppBuild,
                selectedFarms,
                "",
                appBuildData,
                platform,
                dispatch,
                "",
                "",
                "",
                "",
                true,
                setBuildVersionList
              )
            }
            disabled={isNotNullBuildDetails(appBuildData, platform)}
            loading={buildUploadRequest}
          >
            Upload Build
          </Button>
        </div>,
      ]}
    >
      <AddAppBuildV2
        showInModal={true}
        createBuildModal={true}
        appBuildData={appBuildData}
        setAppBuildData={setAppBuildData}
      />
    </Modal>
  );
};

export default UploadBuildModalV2;
