import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Drawer, Radio, Row, Select, Space, Tabs, Tooltip } from "antd";
import { CLOSE_ICON_GRAY } from "../../../../../CdnImagePath";
import {
  DEVICE_EXECUTION_FARM,
  DEVICE_FARM_USE,
  EXECUTION_JOB,
  JOB_DESIGNER,
  SCHEDULE_TEST_JOB,
  TEST_SCENARIOS,
  TOOLTIP_BG_COLOR,
  splitValue,
} from "../../../../../Constants";
import {
  closePreviewSettingDrawer,
  getListOfLocalesForApplicationStrings,
} from "../../../../../actions/RecTestStep/RecTestStepRedirectAction";
import { fetchTestDataSetByProfileId } from "../../../../../actions/TestDataSet/TestDataSetRedirectAction";
import {
  changeExecutionManagerTabAndSubTab,
  changeSelectedTestJobCriteria,
  getTestJobByJobId,
  scheduleNow,
} from "../../../../../actions/TestJobActions";
import CommonConfirmationModalV2 from "../../../../CommonComponents/CommonConfirmationModalV2/CommonConfirmationModalV2";
import styles from "./JobSettingModal.module.scss";

const JobSettingModal = (props) => {
  const dispatch = useDispatch();
  const { selectedRecTestScenarioId, testBlockStep, setCurrentMenuOptionsSelected, selectedTestJob } = props;
  const { testPlanId, id: testJobId } = selectedTestJob || {};
  const { previewSettingDrawerVisible, stepType, getListOfLocales } = useSelector(
    (state) => state.RecTestStepCommonViewReducer
  );
  const { testDataSetList } = useSelector((state) => state.TestDataSetReducer);
  const { getSearchDeviceList } = useSelector((state) => state.ManageFarmReducer);

  const [jobCustomTestDataSet, setJobCustomTestDataSet] = useState(undefined);
  const [jobAppLocale, setJobAppLocale] = useState(undefined);
  const [localReportValue, setLocaleReportValue] = useState(0);
  const [accessibilityReportValue, setAccessibilityReportValue] = useState(0);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  useEffect(() => {
    if (previewSettingDrawerVisible) {
      setLocaleReportValue(0);
      setAccessibilityReportValue(0);
    }
  }, [previewSettingDrawerVisible]);

  useEffect(() => {
    if (previewSettingDrawerVisible) {
      if (stepType === EXECUTION_JOB && testPlanId) {
        dispatch(fetchTestDataSetByProfileId(testPlanId, "", 1, true, false));
      } else if (stepType === TEST_SCENARIOS && selectedRecTestScenarioId) {
        dispatch(fetchTestDataSetByProfileId(selectedRecTestScenarioId, "", 1, true, false));
      } else if (testBlockStep?.id) {
        dispatch(fetchTestDataSetByProfileId(testBlockStep?.id, "", 1, true, false));
      }
      dispatch(getListOfLocalesForApplicationStrings());
    }
  }, [previewSettingDrawerVisible]);
  useEffect(() => {
    if (testDataSetList && jobCustomTestDataSet === undefined) {
      let defaultTestDataList = testDataSetList && testDataSetList.filter((item) => item.isDefault === 1);
      setJobCustomTestDataSet({
        id: defaultTestDataList.length > 0 ? defaultTestDataList[0]?.id : undefined,
        name: defaultTestDataList.length > 0 ? defaultTestDataList[0]?.name : undefined,
      });
    }
  }, [testDataSetList]);
  const deviceDetails = useMemo(
    () =>
      (getSearchDeviceList &&
        getSearchDeviceList.length > 0 &&
        getSearchDeviceList.filter(
          (data) =>
            !data.device.isDeviceOffline &&
            [DEVICE_FARM_USE.SCENARIO_RECORDING, DEVICE_FARM_USE.BOTH].includes(
              data.miscDetails?.farmDetails?.deviceFarmUse
            )
        )) ||
      [],
    [getSearchDeviceList]
  );
  const allDeviceIncludesInDeviceList = selectedTestJob?.devices?.every((i) =>
    deviceDetails?.some((item) => item?.miscDetails?.deviceDetail?.targetUniqueId.toString() === i?.targetUniqueId)
  );

  return (
    <Drawer
      title={
        <div className="display-flex justify-space-between">
          <span>Preview Settings</span>
          <span>
            <img
              src={CLOSE_ICON_GRAY}
              alt="close"
              className="cursor-pointer"
              onClick={() => {
                dispatch(closePreviewSettingDrawer());
              }}
            />
          </span>
        </div>
      }
      placement="right"
      closable={false}
      width={700}
      onClose={() => {
        dispatch(closePreviewSettingDrawer());
      }}
      visible={previewSettingDrawerVisible}
      bodyStyle={{ padding: 15 }}
    >
      <div>
        <Row>
          <Col span={24}>
            <div className="text-align-right">
              <Button
                size="large"
                type="primary"
                className={styles.button}
                disabled={jobCustomTestDataSet === undefined && jobAppLocale === undefined}
                onClick={() => {
                  if (
                    (!allDeviceIncludesInDeviceList &&
                      selectedTestJob?.deviceServiceFarm === DEVICE_EXECUTION_FARM.XPRESS) ||
                    selectedTestJob?.isBuildDeleted === true
                  ) {
                    setOpenConfirmationModal(true);
                  } else {
                    dispatch(
                      scheduleNow(
                        {
                          testJobId: testJobId,
                          isManual: 1,
                          testDataSetId:
                            jobCustomTestDataSet?.id === undefined && jobCustomTestDataSet?.name
                              ? -1
                              : jobCustomTestDataSet?.id,
                          appLocale: jobAppLocale,
                          accessibilityReport: accessibilityReportValue,
                          localeReport: localReportValue,
                        },
                        setCurrentMenuOptionsSelected
                      )
                    );
                    dispatch(closePreviewSettingDrawer());
                  }
                }}
              >
                Run
              </Button>
            </div>
          </Col>
        </Row>
        <Tabs defaultActiveKey="TEST DATA PROFILE">
          <Tabs.TabPane tab="TEST DATA PROFILE" key={"TEST DATA PROFILE"}>
            <Row>
              <Col span={24}>
                <div className={styles.headerTitle}>Set Test Data Profile for Run</div>
              </Col>
              <Col span={24} className="text-align-right">
                <Button
                  type="text"
                  className="resetButton"
                  onClick={() => {
                    // setJobCustomTestDataSet(undefined);
                    let defaultTestDataList = testDataSetList && testDataSetList.filter((item) => item.isDefault === 1);
                    setJobCustomTestDataSet({
                      id: defaultTestDataList.length > 0 ? defaultTestDataList[0]?.id : undefined,
                      name: defaultTestDataList.length > 0 ? defaultTestDataList[0]?.name : undefined,
                    });
                  }}
                >
                  Reset
                </Button>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Radio.Group
                  onChange={(e) => {
                    setJobCustomTestDataSet({
                      id:
                        e.target.value.split(splitValue)[0] && e.target.value.split(splitValue)[0] != "undefined"
                          ? e.target.value.split(splitValue)[0]
                          : undefined,
                      name: e.target.value.split(splitValue)[1],
                    });
                  }}
                  value={jobCustomTestDataSet?.id + splitValue + jobCustomTestDataSet?.name}
                >
                  <Space direction="vertical">
                    {testDataSetList && (
                      <Radio key={0} value={undefined + splitValue + testDataSetList[0].name}>
                        <Tooltip title="Currently selected Execution Profile of Test Plan" color={TOOLTIP_BG_COLOR}>
                          <span>{testDataSetList[0].name}</span>
                        </Tooltip>
                      </Radio>
                    )}
                    <div className={styles.headerTitle}>Custom Profiles</div>
                    {testDataSetList && testDataSetList.length > 1
                      ? testDataSetList.map((value) => {
                          return (
                            value.id && (
                              <Radio key={value.id} value={value.id + splitValue + value.name}>
                                {value.name}
                              </Radio>
                            )
                          );
                        })
                      : "No Data"}
                  </Space>
                </Radio.Group>
              </Col>
            </Row>
          </Tabs.TabPane>
          <Tabs.TabPane tab="APP LOCALE" key={"APP LOCALE"}>
            <Row>
              <Col span={24} className="text-align-right">
                <Button
                  type="text"
                  className="resetButton"
                  onClick={() => {
                    setJobAppLocale(undefined);
                  }}
                >
                  Reset{" "}
                </Button>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <div className={styles.headerTitle}>Set Application Locale for Run</div>
              </Col>
              <Col span={24}>
                <div>
                  <Select
                    showSearch={true}
                    className="setAppLocalValue"
                    value={jobAppLocale}
                    placeholder="Select Locale"
                    onChange={(e) => {
                      setJobAppLocale(e);
                    }}
                  >
                    {getListOfLocales &&
                      getListOfLocales?.map((i) => (
                        <Select.Option key={i?.locales} value={i?.locales}>
                          {i?.locales}
                        </Select.Option>
                      ))}
                  </Select>
                </div>
              </Col>
            </Row>
          </Tabs.TabPane>
          <Tabs.TabPane tab="ACCESSIBILITY REPORT" key={"ACCESSIBILITY_REPORT"}>
            <Row>
              <Col span={24}>
                <div>
                  <Radio.Group
                    onChange={(e) => setAccessibilityReportValue(e.target.value)}
                    value={accessibilityReportValue}
                  >
                    <div>
                      <Radio value={0}>
                        <span className={styles["radio_Or_Checkbox__Text"]}>None</span>
                      </Radio>
                    </div>

                    <div>
                      <Radio value={1}>
                        <span className={styles["radio_Or_Checkbox__Text"]}>Run only when functional testing pass</span>
                      </Radio>
                    </div>
                    <div>
                      <Radio value={2}>
                        <span className={styles["radio_Or_Checkbox__Text"]}>Always run</span>
                      </Radio>
                    </div>
                  </Radio.Group>
                </div>
              </Col>
            </Row>
          </Tabs.TabPane>
          <Tabs.TabPane tab="LOCALE REPORT" key={"LOCALE_REPORT"}>
            <Row>
              <Col span={24}>
                <div>
                  <Radio.Group onChange={(e) => setLocaleReportValue(e.target.value)} value={localReportValue}>
                    <div>
                      <Radio value={0}>
                        <span className={styles["radio_Or_Checkbox__Text"]}>None</span>
                      </Radio>
                    </div>

                    <div>
                      <Radio value={1}>
                        <span className={styles["radio_Or_Checkbox__Text"]}>Run only when functional testing pass</span>
                      </Radio>
                    </div>
                    <div>
                      <Radio value={2}>
                        <span className={styles["radio_Or_Checkbox__Text"]}>Always run</span>
                      </Radio>
                    </div>
                  </Radio.Group>
                </div>
              </Col>
            </Row>
          </Tabs.TabPane>
        </Tabs>
      </div>
      <CommonConfirmationModalV2
        handleOk={() => {
          dispatch(changeExecutionManagerTabAndSubTab(JOB_DESIGNER, false));
          dispatch(getTestJobByJobId({ testJobId: selectedTestJob?.id }));
          selectedTestJob?.isBuildDeleted === true
            ? dispatch(changeSelectedTestJobCriteria(SCHEDULE_TEST_JOB.STEP3))
            : dispatch(changeSelectedTestJobCriteria(SCHEDULE_TEST_JOB.STEP2));
          setOpenConfirmationModal(false);
          dispatch(closePreviewSettingDrawer());
        }}
        handleCancel={() => {
          selectedTestJob?.isBuildDeleted === true
            ? setOpenConfirmationModal(false)
            : dispatch(
                scheduleNow(
                  {
                    testJobId: selectedTestJob?.id,
                    isManual: 1,
                    accessibilityReport: selectedTestJob?.accessibilityReport,
                    localeReport: selectedTestJob?.localeReport,
                  },
                  setCurrentMenuOptionsSelected
                )
              );
          setOpenConfirmationModal(false);
        }}
        modalClose={() => setOpenConfirmationModal(false)}
        modalOpen={openConfirmationModal}
        modalContent={
          selectedTestJob?.isBuildDeleted === true
            ? "The build initially selected for this job is no longer available. Please review builds or cancel the operation."
            : "Some device(s) selected for this job are currently unavailable. It is recommended to review devices list before you continue."
        }
        modalTitle={"Confirm Job Execution"}
        cancelText={selectedTestJob?.isBuildDeleted === true ? "Cancel" : "Run Anyway"}
        okText={selectedTestJob?.isBuildDeleted === true ? "Review Builds" : "Review Devices"}
        isAutoWidth={true}
      />
    </Drawer>
  );
};

export default JobSettingModal;
