import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox, Col, Row, Select, Space, Spin } from "antd";
import { isEqual, isEmpty } from "lodash";
import { changeAppManagerTab, editAppBuild } from "../../../actions/Recording";
import {
  ALL_BUILDS,
  ALPHA,
  ANDROID,
  CONFIRM_MODAL_CONTENT,
  DEV,
  INITIAL_APP_BUILD_DATA,
  IOS,
  isCloudFarm,
  OTHER,
  PROD,
  RC_BETA,
} from "../../../Constants";
import { ICON_CLOSE } from "../../../Constants/SvgConstants";
import {
  buildMapping,
  handleSaveBuild,
  isCloudFarmName,
  isNotNullAndNotEmptyAndNotUndefined,
  isNotNullBuildDetails,
  isNullOrUndefined,
} from "../../../Util";
import SvgLoader from "../../../Util/SvgLoader";
import CommonConfirmationModalV2 from "../../CommonComponents/CommonConfirmationModalV2/CommonConfirmationModalV2";
import CommonInputFieldV2 from "../../CommonComponents/CommonInputFieldV2/CommonInputFieldV2";
import CommonSelectBoxV2 from "../../CommonComponents/CommonSelectBoxV2/CommonSelectBoxV2";
import UploadBuildV2 from "../../CommonComponents/UploadBuildV2/UploadBuildV2";
import SaveResetButtonV2 from "../../CoreComponents/SaveResetButtonV2/SaveResetButtonV2";
import styles from "./AddAppBuildV2.module.scss";

const { Option } = Select;

const AddAppBuildV2 = (props) => {
  const dispatch = useDispatch();

  const { buildId, showInModal, setSaveBuild, saveBuild, appBuildData, setAppBuildData, createBuildModal } = props;

  const { platform } = useSelector((state) => state.ProjectsReducer);
  const { testJobDetail } = useSelector((state) => state.TestJobReducer);
  const { deviceFarmList } = useSelector((state) => state.ServerListReducer);
  const {
    appBuildDetailsById,
    getUploadBuildOptions,
    sharedWithFarmInAddAppBuild,
    buildUploadRequest,
    overwriteBuildSaveRequest,
    getAWSProjectsList,
    pushBuildModalVisible,
  } = useSelector((state) => state.RecordingReducer);

  const [addNewBuild, setAddNewBuild] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  useEffect(() => {
    if (isNotNullAndNotEmptyAndNotUndefined(appBuildDetailsById)) {
      setAppBuildData(appBuildDetailsById);
    } else {
      setAddNewBuild(true);
      setAppBuildData(INITIAL_APP_BUILD_DATA);
    }
  }, [appBuildDetailsById]);

  let cloudList = [];
  if (getUploadBuildOptions) {
    for (let i = 0; i < getUploadBuildOptions.length; i++) {
      cloudList.push(<Option key={getUploadBuildOptions[i]}>{getUploadBuildOptions[i]}</Option>);
    }
  }
  let projectsList = [];
  if (getAWSProjectsList) {
    for (let i = 0; i < getAWSProjectsList.length; i++) {
      projectsList.push(
        <Option key={getAWSProjectsList[i].id} value={getAWSProjectsList[i].id}>
          {getAWSProjectsList[i].awsProjectName}
        </Option>
      );
    }
  }

  const selectedFarms = [];

  if (deviceFarmList && deviceFarmList.length > 0) {
    deviceFarmList.map((v, k) => {
      return selectedFarms.push(v.id);
    });
  }

  const handleChangeBuildForm = (e, filedName) => {
    let _appBuildData = { ...appBuildData };
    if (filedName) {
      _appBuildData[filedName] = e;
    } else {
      const { name, value, checked } = e.target;
      _appBuildData[name] = value;
      switch (name) {
        case isCloudFarm.XP:
          _appBuildData.enableX = checked;
          break;
        case isCloudFarm.LT:
          _appBuildData.enableLT = checked;
          break;
        case isCloudFarm.SL:
          _appBuildData.enableSL = checked;
          break;
        default:
          break;
      }
    }
    setAppBuildData(_appBuildData);
  };

  const setInputTagsAndAppFile = (uploadBuildFile, uploadBuildFileName) => {
    let addFileNameInTag = "";
    let nameToAddInBuildTag = uploadBuildFileName;
    if (appBuildData?.tag === "") {
      addFileNameInTag = nameToAddInBuildTag + ",";
    } else if (appBuildData?.tag !== "") {
      if (appBuildData?.tag.includes(appBuildData?.buildName)) {
        addFileNameInTag = appBuildData?.tag.replace(appBuildData?.buildName, nameToAddInBuildTag);
      }
    } else {
      addFileNameInTag = nameToAddInBuildTag + "," + appBuildData?.tag;
    }

    setAppBuildData({
      ...appBuildData,
      tag: addFileNameInTag,
      buildName: uploadBuildFileName,
      appFile: uploadBuildFile,
    });
  };

  const resetBuildData = () => {
    if (isNotNullAndNotEmptyAndNotUndefined(appBuildDetailsById)) {
      setAppBuildData(appBuildDetailsById);
      dispatch(editAppBuild(false));
      setAddNewBuild(false);
    } else {
      setAppBuildData(INITIAL_APP_BUILD_DATA);
    }
  };
  const disableResetButton = useCallback(() => {
    if (isNotNullAndNotEmptyAndNotUndefined(appBuildDetailsById)) {
      return isEqual(appBuildData, appBuildDetailsById);
    } else {
      return isEqual(appBuildData, INITIAL_APP_BUILD_DATA);
    }
  }, [appBuildData, appBuildDetailsById]);

  useEffect(() => {
    if (disableResetButton()) {
      dispatch(editAppBuild(false));
    } else {
      dispatch(editAppBuild(true));
    }
  }, [disableResetButton, dispatch]);

  const disableUploadButton = () => {
    if (isNotNullAndNotEmptyAndNotUndefined(appBuildDetailsById)) {
      return isEqual(appBuildData, appBuildDetailsById) || isNotNullBuildDetails(appBuildData, platform);
    } else {
      return isNotNullBuildDetails(appBuildData, platform);
    }
  };

  const onBackClick = () => {
    if (disableResetButton()) {
      dispatch(changeAppManagerTab(ALL_BUILDS));
    } else {
      setOpenConfirmModal(true);
    }
  };

  const replaceApkFile = () => {
    setAppBuildData({
      ...appBuildData,
      buildName: "",
      appFile: "",
    });
    setAddNewBuild(true);
  };

  useEffect(() => {
    if (testJobDetail?.buildDetail?.buildType) {
      setAppBuildData({
        ...appBuildData,
        buildType: testJobDetail?.buildDetail?.buildType,
      });
    }
  }, []);

  return (
    <>
      <Spin
        spinning={
          !pushBuildModalVisible &&
          (buildUploadRequest || (!isNullOrUndefined(overwriteBuildSaveRequest) && overwriteBuildSaveRequest))
        }
        tip="Loading"
        className="spinner-center"
      >
        {!showInModal && (
          <Row justify="space-between" align="middle" className={styles["main_Header"]}>
            <Col>
              <div className={styles["main-Header-Text"]}>Build Uploader</div>
            </Col>
            <Col>
              <Space>
                <SaveResetButtonV2
                  submitLabel={isNotNullAndNotEmptyAndNotUndefined(appBuildDetailsById) ? "Update" : "Save"}
                  resetLabel={"Reset"}
                  submitDisable={disableUploadButton()}
                  resetDisable={disableResetButton()}
                  handleSubmit={() =>
                    handleSaveBuild(
                      selectedFarms,
                      sharedWithFarmInAddAppBuild,
                      selectedFarms,
                      buildId,
                      appBuildData,
                      platform,
                      dispatch,
                      "",
                      "",
                      "",
                      appBuildDetailsById
                    )
                  }
                  handleReset={resetBuildData}
                />
                <div className={styles["close_Icon"]}>
                  <SvgLoader iconName={ICON_CLOSE} width={36} height={36} handleClick={onBackClick} />
                </div>
              </Space>
            </Col>
          </Row>
        )}
        <Row
          justify={"center"}
          className={` ${
            showInModal && !createBuildModal
              ? styles["modal_Scrollbar"]
              : createBuildModal
              ? styles["create_Build_Modal"]
              : styles["build_Details"]
          }`}
        >
          <Col xs={{ span: 24 }} lg={!showInModal && { span: 14 }}>
            {!showInModal && <div className={styles["header_Title"]}>Basic Details</div>}
            <div>
              <Row gutter={20}>
                <Col span={12}>
                  <CommonInputFieldV2
                    label="Display Name"
                    placeholder="Please Enter Display Name"
                    name="displayName"
                    value={appBuildData?.displayName}
                    handleChange={(e) => {
                      handleChangeBuildForm(e, "");
                    }}
                    autoFocus
                    required={true}
                    labelBackgroundClass={!showInModal && "label_Background"}
                  />
                </Col>
                <Col span={12}>
                  <CommonInputFieldV2
                    label="Build Version"
                    placeholder="Please Enter Build Version"
                    name="buildVersion"
                    value={appBuildData?.buildVersion}
                    handleChange={(e) => {
                      handleChangeBuildForm(e, "");
                    }}
                    required={true}
                    labelBackgroundClass={!showInModal && "label_Background"}
                  />
                </Col>
              </Row>
              <Col span={24}>
                <CommonInputFieldV2
                  label="Tags"
                  name="tag"
                  placeholder="Provide Your Own/Team Tags For Easy Identification Separated With (,)"
                  value={appBuildData?.tag}
                  handleChange={(e) => {
                    handleChangeBuildForm(e);
                  }}
                  labelBackgroundClass={!showInModal && "label_Background"}
                  type="textarea"
                />
              </Col>

              <Row gutter={20}>
                <Col span={12}>
                  <CommonInputFieldV2
                    label="Build Date"
                    placeholder="Please Select Build Date"
                    required={true}
                    disabledDate={(current) => {
                      return current > new Date();
                    }}
                    handleChange={(e) => {
                      handleChangeBuildForm(e, "buildDate");
                    }}
                    value={appBuildData?.buildDate || null}
                    labelBackgroundClass={!showInModal && "label_Background"}
                    type="datepicker"
                  />
                </Col>
                <Col span={12}>
                  <CommonInputFieldV2
                    label="Build Time"
                    placeholder="Please Select Build Time"
                    handleChange={(e) => {
                      handleChangeBuildForm(e, "buildTime");
                    }}
                    required={true}
                    value={appBuildData?.buildTime || null}
                    className="width-50"
                    labelBackgroundClass={!showInModal && "label_Background"}
                    type="timepicker"
                  />
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <CommonSelectBoxV2
                    label="Build Type"
                    labelBackgroundClass={!showInModal && "label_Background"}
                    required={true}
                    options={
                      <>
                        <Option key={"DEV"}>{DEV}</Option>
                        <Option key={"ALPHA"}>{ALPHA}</Option>
                        <Option key={"BETA"}>{RC_BETA}</Option>
                        <Option key={"PROD"}>{PROD}</Option>
                        <Option key={"OTHER"}>{OTHER}</Option>
                      </>
                    }
                    value={appBuildData?.buildType || undefined}
                    placeholder="Please Select Build Type"
                    handleChange={(e) => {
                      handleChangeBuildForm(e, "buildType");
                    }}
                    disabled={testJobDetail?.buildDetail?.buildType}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <UploadBuildV2
                    addNewBuild={addNewBuild}
                    setAddNewBuild={setAddNewBuild}
                    existingBuildName={appBuildData?.existingBuild && appBuildData?.existingBuild?.name}
                    placeholder={
                      platform === ANDROID ? "Upload APK file" : platform === IOS ? "Upload IPA file" : "Add Build"
                    }
                    tooltip="Pick Up Old APK"
                    labelName="Upload Build"
                    setUploadBuildFile={setInputTagsAndAppFile}
                    acceptFileTypes={platform === ANDROID ? ".apk" : ".ipa"}
                    showInModal={showInModal}
                    replaceApkFile={replaceApkFile}
                  />
                </Col>
              </Row>
              {platform === ANDROID && (
                <Row>
                  <Col span={24}>
                    <CommonInputFieldV2
                      label="App Activity"
                      name="appActivity"
                      placeholder="Please Enter App Activity"
                      value={appBuildData?.appActivity}
                      handleChange={(e) => {
                        handleChangeBuildForm(e, "");
                      }}
                      labelBackgroundClass={!showInModal && "label_Background"}
                      required={true}
                    />
                  </Col>
                </Row>
              )}
              {!isEmpty(getUploadBuildOptions) && (
                <div className={styles["upload_Build_Options"]}>
                  <div className={styles["device_Farm_Label"]}>Device Farms</div>
                  {getUploadBuildOptions?.map((i) => {
                    const field = buildMapping[i];
                    const isChecked = appBuildData[field] || false;
                    return (
                      <Checkbox key={i} name={i} onChange={(e) => handleChangeBuildForm(e)} checked={isChecked}>
                        {isCloudFarmName(i)}
                      </Checkbox>
                    );
                  })}
                </div>
              )}
            </div>
            {showInModal && !createBuildModal && (
              <div className={styles["checkbox_Label"]}>
                <span>
                  <Checkbox
                    checked={saveBuild}
                    onChange={(e) => (e.target.checked ? setSaveBuild(true) : setSaveBuild(false))}
                  />
                </span>
                <span>Save build to Build Manager</span>
              </div>
            )}
          </Col>
        </Row>
      </Spin>
      <CommonConfirmationModalV2
        handleOk={() => {
          dispatch(changeAppManagerTab(ALL_BUILDS));
          dispatch(editAppBuild(false));
          setOpenConfirmModal(false);
        }}
        handleCancel={() => setOpenConfirmModal(false)}
        modalOpen={openConfirmModal}
        modalContent={CONFIRM_MODAL_CONTENT}
        modalTitle={"Discard Changes"}
        okText={"Confirm"}
        isDeleteModal={true}
      />
    </>
  );
};

export default AddAppBuildV2;
