import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col } from "antd";
import { ANDROID, APP_MANAGER, BUILD_URL, DEVICE_EXECUTION_FARM, NONE } from "../../../../../Constants";
import { changeTestJobData } from "../../../../../actions/TestJobActions";
import CommonInputFieldV2 from "../../../../CommonComponents/CommonInputFieldV2/CommonInputFieldV2";
import styles from "./JobCriteriaOptionsV2.module.scss";
import SelectBuildV2 from "./SelectBuildV2";

const SelectBuildTabV2 = (props) => {
  const { buildVersionList, setBuildVersionList } = props;
  const dispatch = useDispatch();

  const { platform } = useSelector((state) => state.ProjectsReducer);
  const { testJobDetail } = useSelector((state) => state.TestJobReducer);

  const { deviceServiceFarm, fileUrl, appActivity, selectedBuild } = testJobDetail;

  const handleChange = (e) => {
    dispatch(
      changeTestJobData({
        ...testJobDetail,
        selectedBuild: e.target.value,
        buildDetail: { buildVersion: "Latest" },
        isUseLatestBuild: 1,
      })
    );
    setBuildVersionList([]);
  };

  return (
    <>
      <div className={styles["job_Details_wrap"]}>
        <div className={styles["build_And_Farm_Title"]}>Build</div>
        <div className="display-flex mt-10">
          <div>
            {deviceServiceFarm === DEVICE_EXECUTION_FARM.XPRESS && (
              <div className={`${styles["radio_Button"]} ${selectedBuild === NONE && styles["active_Radio_Button"]}`}>
                <input
                  className={styles["radio_Input_Box"]}
                  checked={selectedBuild === NONE}
                  type="radio"
                  onChange={handleChange}
                  name="buildType"
                  value={NONE}
                />
                <span className={styles["radio_Button_Text"]}>Use Installed version on Device</span>
              </div>
            )}
          </div>
          <div
            className={`${styles["radio_Button"]} ${selectedBuild === APP_MANAGER && styles["active_Radio_Button"]}`}
          >
            <input
              className={styles["radio_Input_Box"]}
              checked={selectedBuild === APP_MANAGER}
              type="radio"
              onChange={handleChange}
              name="buildType"
              value={APP_MANAGER}
            />
            <span className={styles["radio_Button_Text"]}>App Manager</span>
          </div>
          <div className={`${styles["radio_Button"]} ${selectedBuild === BUILD_URL && styles["active_Radio_Button"]}`}>
            <input
              className={styles["radio_Input_Box"]}
              checked={selectedBuild === BUILD_URL}
              type="radio"
              onChange={handleChange}
              name="buildType"
              value={BUILD_URL}
            />
            <span className={styles["radio_Button_Text"]}>Build URL</span>
          </div>
        </div>
      </div>

      {selectedBuild === APP_MANAGER && (
        <Col span={24}>
          <SelectBuildV2 buildVersionList={buildVersionList} setBuildVersionList={setBuildVersionList} />
        </Col>
      )}
      {selectedBuild === BUILD_URL && (
        <>
            <Col span={12} className={styles["build_Input_Box"]}>
              <CommonInputFieldV2
                label="Build URL"
                placeholder="Enter The URL For The Build To Use"
                handleChange={(e) => {
                  dispatch(changeTestJobData({ ...testJobDetail, fileUrl: e.target.value, buildDetail: { id: 0 } }));
                }}
                value={fileUrl || "https://"}
                required={true}
              />
            </Col>
            <Col span={12}>
              {deviceServiceFarm !== DEVICE_EXECUTION_FARM.XPRESS && platform === ANDROID && (
                <CommonInputFieldV2
                  label="App Activity"
                  placeholder="Please Enter App Activity"
                  handleChange={(e) => {
                    dispatch(changeTestJobData({ ...testJobDetail, appActivity: e.target.value }));
                  }}
                  value={appActivity}
                  required={true}
                />
              )}
            </Col>
        </>
      )}
    </>
  );
};

export default SelectBuildTabV2;
