import { async } from "../api/automation";
import { notification } from "antd";
import MyProjectsByTeam from "../Components/Projects/MyProjectsByTeam";
import {
  REQUEST_MY_PROJECTS,
  REQUEST_MY_PROJECTS_IN_TEAM,
  GET_PROJECT_BY_USER_ID_AND_TEAM_ID,
  GET_PROJECT_BY_USER_ID_AND_ORG_ID,
  REQUEST_TEAM_MEMBERS,
  SAVE_PROJECT,
  TEAM_PROJECT_DETAILS,
  UPDATED_PROJECT_SAVED,
  SUCCESS_STATUS,
  INVITE_MEMBER,
  UPDATED_INVITE_MEMBER,
  GET_INVITATION_LIST,
  GET_PROJECTS_BY_ORG_ID,
  INVITATION_LOADING,
  CREATE_PROJECT_LOADER,
  FAILED_STATUS,
  SUCCESS_TYPE,
  ERROR_TYPE,
  RECORD_10_PER_PAGE,
  VISIBLE_CREATE_PROJECT_MODAL,
  LIST_OF_ORG_MEMBERS,
  LIST_OF_TEAM_MEMBERS,
  PREVIOUS_PROJECT_ID,
  GENERATE_API_KEY_LOADER,
  GENERATE_API_KEY,
  } from "../Constants";
import history from "../history";
import { showNotification, updateResponse } from "../Util";
import _ from "lodash";
import { type } from "wd/lib/commands";

export function goToProjects(organizationId) {
  return () => {
    history.push(`/${organizationId}/projects`);
  };
}

export function myProjects(id, pageNumber, searchWord, filter) {
  return async (dispatch, getState) => {
    let preparedObject = {
      orgId: id,
      pageNumber: pageNumber || 1,
      recordPerPage: RECORD_10_PER_PAGE,
      searchKeywords: searchWord ? searchWord : "",
      filter: filter || "",
    };
    return async(`projectUserAssoc/getByUserIdAndOrgId`, "GET", preparedObject).then((response) => {
      response = updateResponse(response);
      dispatch({ type: REQUEST_MY_PROJECTS, response, pageNumber });
    });
  };
}

export function getProjectsByOrgId(id) {
  return async (dispatch, getState) => {
    let preparedObject = {
      orgId: id,
    };
    return async(`project/getProjectsFromOrg`, "GET", preparedObject).then((response) => {
      response = updateResponse(response);
      dispatch({ type: GET_PROJECTS_BY_ORG_ID, response });
    });
  };
}

export function myProjectsFromTeamId(data) {
  return async (dispatch, getState) => {
    let preparedObject = {
      teamId: data,
    };
    return async("project/getByTeamId", "GET", preparedObject).then((response) => {
      response = updateResponse(response);
      dispatch({ type: REQUEST_MY_PROJECTS_IN_TEAM, response });
    });
  };
}

export function getProjectsByUserIdAndOrgId(org_id, user_id) {
  return async (dispatch, getState) => {
    const projectId = getState().ProjectsReducer.projectId;
    let preparedObject = {
      userId: user_id,
      orgId: org_id,
      projectId,
    };
    return async("projectUserAssoc/getProjectsByUserIdAndOrgId", "GET", preparedObject).then((response) => {
      response = updateResponse(response);
      dispatch({ type: GET_PROJECT_BY_USER_ID_AND_ORG_ID, response });
    });
  };
}

export function getMyTeamMembers(data) {
  return async (dispatch, getState) => {
    let preparedObject = {
      teamId: data,
    };
    return async("teamUserAssoc/getUsersByTeamId", "GET", preparedObject).then((response) => {
      response = updateResponse(response);
      dispatch({ type: REQUEST_TEAM_MEMBERS, response });
      dispatch(myProjectsFromTeamId(data));
    });
  };
}

export function saveProject(data, teamId) {
  return async (dispatch, getState) => {
    dispatch({ type: CREATE_PROJECT_LOADER, data: true });
    return async("project/save", "POST", data)
      .then((response) => {
        response = updateResponse(response);
        if (response.status === SUCCESS_STATUS) {
          showNotification(SUCCESS_TYPE, response.message, true);
          dispatch({ type: SAVE_PROJECT, response });
          if (teamId) {
            dispatch(myProjectsFromTeamId(teamId));
          } else {
            dispatch(myProjects(data.orgId));
          }
          dispatch({ type: UPDATED_PROJECT_SAVED });
        } else {
          showNotification(ERROR_TYPE, response.message);
        }
        dispatch({ type: CREATE_PROJECT_LOADER, data: false });
        dispatch(visibleCreateProjectModal(false));
      })
      .catch((e) => {
        dispatch({ type: CREATE_PROJECT_LOADER, data: false });
        dispatch(visibleCreateProjectModal(false));
      });
  };
}

export function inviteMember(data) {
  return async (dispatch, getState) => {
    dispatch({ type: INVITATION_LOADING, data: true });
    return async("invitation/invite", "POST", data)
      .then((response) => {
        response = updateResponse(response);
        if (response.status === SUCCESS_STATUS) {
          dispatch({ type: INVITE_MEMBER, response });
          dispatch({ type: UPDATED_INVITE_MEMBER });
          showNotification(SUCCESS_TYPE, response.message, true);
        } else {
          showNotification(ERROR_TYPE, response.message);
        }
        dispatch({ type: INVITATION_LOADING, data: false });
      })
      .catch((e) => {
        dispatch({ type: INVITATION_LOADING, data: false });
      });
  };
}

export function saveProjectInTeam(data) {
  return async (dispatch, getState) => {
    dispatch({ type: CREATE_PROJECT_LOADER, data: true });
    return async("project/save", "POST", data)
      .then((response) => {
        response = updateResponse(response);
        if (response.status === SUCCESS_STATUS) {
          showNotification(SUCCESS_TYPE, response.message);
          dispatch({ type: SAVE_PROJECT, response });
          dispatch(myProjectsFromTeamId(localStorage.getItem("teamId")));
          dispatch({ type: UPDATED_PROJECT_SAVED });
        } else {
          showNotification(ERROR_TYPE, response.message);
        }
        dispatch({ type: CREATE_PROJECT_LOADER, data: false });
      })
      .catch(() => {
        dispatch({ type: CREATE_PROJECT_LOADER, data: false });
      });
  };
}

export function getTeamAndProjectName(data) {
  return async (dispatch, getState) => {
    return async("team/getByProjectId", "GET", data).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS && !_.isEmpty(response.data.data)) {
        dispatch({ type: TEAM_PROJECT_DETAILS, response });
      } else {
        history.push("/projects");
      }
    });
  };
}

export function setLoaderForCreateProject(flag) {
  return async (dispatch, getState) => {
    dispatch({ type: CREATE_PROJECT_LOADER, flag });
  };
}

export const visibleCreateProjectModal = (visible) => {
  return async (dispatch) => {
    dispatch({ type: VISIBLE_CREATE_PROJECT_MODAL, visible });
    dispatch({ type: LIST_OF_ORG_MEMBERS, response: [] });
    dispatch({ type: LIST_OF_TEAM_MEMBERS, response: [] });
  };
};

export const previousProjectId = (prevProjectId) => {
  return async (dispatch) => {
    dispatch({ type: PREVIOUS_PROJECT_ID, prevProjectId });
  };
};