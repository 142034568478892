import {
  faArrowLeft,
  faArrowRight,
  faDotCircle,
  faFolder,
  faHandPaper,
  faHandPointer,
  faHome,
  faKeyboard,
  faLock,
  faRocket,
  faSearch,
  faTimes,
  faUnlock,
  faVolumeDown,
  faVolumeMute,
  faVolumeUp,
} from "@fortawesome/free-solid-svg-icons";
import chromeIcon from "../images/icons8-chrome.svg";
import FirefoxIcon from "../images/icons8-firefox.svg";
import operaMiniIcon from "../images/icons8-opera-mini.svg";
import operaIcon from "../images/icons8-opera.svg";
import safariIcon from "../images/icons8-safari.svg";
import { getUniqueId } from "../Util";
import { defaultOpenAiInstructions } from "./DefaultOpenAIScenarioGenerateInstruction";
import { defaultAnthropicInstructions } from "./DefaultAnthropicScenarioGenerateInstruction";
export const DEVICE_SESSION = 1000 * 60 * 30;
export const DEVICE_SESSION_MESSAGE = 1000 * 60;
export const SCREEN_MAX_HEIGHT = 555;
export const SCREEN_MAX_WIDTH = 256;
export const desktopAppUrl = "http://103.240.35.178:2020";
export const REGEX_INTEGER = /^[0-9\b]+$/;
export const ELEMENT_DEFAULT_LOAD_TIME = 4;

export const appStatic = {
  dateFormatForMoment: "DD-MM-Y",
  dateFormat: "DD-MM-YYYY",
  defaultDateFormat: "DD-MMM-YYYY",
  dateFormatForAttendance: "YYYY-MM-DD",
  Year: "YYYY",
  dateTimeFormat: "DD-MM-Y HH:mm:ss",
  timeFormat: "HH:mm:ss",
};

export const CURRENT_TIME = new Date().getTime();
export const defaultDateTimeFormat = "DD-MMM-YYYY HH:mm:ss";
export const defaultDateTimeAmPm = "DD-MMM-YYYY HH:mm A";
export const dateHourMinuteFormat = "YYYY-MM-DD HH:mm:ss";
export const dateHourMinuteSecForFileFormat = "YYYY_MM_DD_HH_mm_ss";
export const notificationStaticMessage = {
  SomethingWentWrong: "Something Went Wrong !",
  PleaseEnterProperDateValues: "Please Enter Proper Date Values.",
  ThereIsNoDataToShow: "There is No Data to Show !",
  logoutMessage: "Logout Successfully",
};

//Device
export const USAGE_DETAILS="Usage Details";
export const SHARED_WITH_PROJECTS="Shared with projects";
export const SHARED_WITH_USERS="Shared with users";

//HeaderTabs
export const TEST_STEP_BLOCKS = "Test_Block";
export const TEST_SCENARIOS = "TestScenario";
export const TEST_PLAN = "TestPlan";
export const REPORT_MANAGER = "ReportManager";
export const EXECUTION_JOB = "ExecutionJob";
export const APP_MANAGER = "AppManager";
export const USE_LATEST_BUILD = "UseLatestBuild";
export const REMOTE_DEVICE = "RemoteDevice";
export const POOL_MANAGEMENTS = "PoolManagement";
export const SETTINGS = "Settings";
export const EXECUTION_REPORT = "ExecutionReport";
export const TEST_DATA_SET_MANAGEMENT = "TestDataSetManagement";
export const PROJECT_STRINGS = "ProjectStrings";
export const SYSTEM_KEYS = "System Keys";

//Test-Blocks
export const TEST_BLOCK_DESIGNER = "Test Block Designer";
export const ALL_DEFINED_BLOCKS = "All Test Blocks";

//MyProject
export const MY_PROJECTS = "My Projects";
export const TEAM_PROJECTS = "Team Projects";

// Scenario option
export const ALL_COMPLETED_SCENARIO = " All Completed Scenarios";
export const PASSED_SCENARIO = "Passed Scenarios Only";
export const FAILED_SCENARIO = "Failed Scenarios Only";
// Farm Management
export const UNASSIGNED_FARM = "Unassigned Farm";
export const ANDROID_FARM = "Android Farm";
export const IOS_FARM = "IOS Farm";
export const COMMON_FARM = "Common Farm";
export const SCENARIO_RECORDING = "SCENARIO_RECORDING";
export const TEST_RUN_EXECUTION = "TEST_RUN_EXECUTION";
export const BOTH = "BOTH";
export const POOL_MANAGEMENT = "POOL_MANAGEMENT";

export const RECORDING_LABEL = "Scenario Recording";
export const EXECUTION_LABEL = "Test Run Execution";
export const BOTH_LABEL = "Both";
export const FREE = "Free";
export const DEVICE = "device";
export const DEVICE_POOL = "devicePool";
export const TEST_DATA = "testData";
export const TEST_DATA_SET = "testDataSet";
export const APPLICATION_STRINGS = "ApplicationStrings";
export const DEVICE_STRINGS = "DeviceStrings";
export const PROJECT_KEYS = "ProjectKeys";
export const KEYS="Keys";
export const PROFILES="Profiles";

export const A = "A";
export const I = "I";
export const ANDROID = "ANDROID";
export const LAMBDA_TEST_ANDROID = "android";
export const IOS = "IOS";
export const WEB = "WEB";

// Testplan Report start
export const TEST_PLAN_REPORT_LIST = "TEST_PLAN_REPORT_LIST";
export const TEST_PLAN_RESULT_BY_RUN_NUMBER = "TEST_PLAN_RESULT_BY_RUN_NUMBER";
export const TEST_PLAN_RESULT_BY_RUN_NUMBER_RESET = "TEST_PLAN_RESULT_BY_RUN_NUMBER_RESET";
export const SAVE_TEST_PLAN = "SAVE_TEST_PLAN";
export const SAVE_TEST_PLAN_DATA = "SAVE_TEST_PLAN_DATA";
export const SAVE_SCHEDULED_TEST_PLAN = "SAVE_SCHEDULED_TEST_PLAN";
export const RESET_SCHEDULED_TEST_PLAN = "RESET_SCHEDULED_TEST_PLAN";
export const TEST_PLAN_DETAILS_LIST = "TEST_PLAN_DETAILS_LIST";
export const TEST_PLAN_LOADING = "TEST_PLAN_LOADING";
export const DELETE_TEST_PLAN_DETAIL = "DELETE_TEST_PLAN_DETAIL";
export const UPDATED_TEST_PLAN_SAVED = "UPDATED_TEST_PLAN_SAVED";
export const GET_FREE_DEVICES_LIST = "GET_FREE_DEVICES_LIST";
export const TEST_STEP_LIST_BY_SCENARIO_ID = "TEST_STEP_LIST_BY_SCENARIO_ID";
export const FETCHING_TEST_PLAN_STEP_LIST = "FETCHING_TEST_PLAN_STEP_LIST";
export const SCENARIO_LOADING = "SCENARIO_LOADING";
export const EXPORT_SCENARIO_LOADING = "EXPORT_SCENARIO_LOADING";
export const PREVIEW_DOWNLOAD_BY_RUN_NUMBER = "PREVIEW_DOWNLOAD_BY_RUN_NUMBER";
export const PREVIEW_ALL_LOCALES_DOWNLOAD_BY_RUN_NUMBER = "PREVIEW_ALL_LOCALES_DOWNLOAD_BY_RUN_NUMBER";
export const GET_REF_RUN_NUMBER_FOR_PREVIEW = "GET_REF_RUN_NUMBER_FOR_PREVIEW";
export const GET_REF_LOCALE_FOR_PREVIEW = "GET_REF_LOCALE_FOR_PREVIEW";
export const RESET_DEVICE_SCREEN_SOURCE = "RESET_DEVICE_SCREEN_SOURCE";
export const CHANGE_SELECTED_ALL_SCENARIO_OPTION = "CHANGE_SELECTED_ALL_SCENARIO_OPTION";
export const REQUEST_INSTALL_BUILD_ON_DEVICE = "REQUEST_INSTALL_BUILD_ON_DEVICE";
export const REQUESTED_INSTALL_BUILD_ON_DEVICE = "REQUESTED_INSTALL_BUILD_ON_DEVICE";
export const CHANGE_SELECTED_COMPARISON_PREVIEW_VIEW = "CHANGE_SELECTED_COMPARISON_PREVIEW_VIEW";
export const CHANGE_DEVICE_SELECTION_FOR_BUILD_INSTALL = "CHANGE_DEVICE_SELECTION_FOR_BUILD_INSTALL";
export const CHANGE_TEST_PLAN_RESULT_LIST_PAGE_NUMBER = "CHANGE_TEST_PLAN_RESULT_LIST_PAGE_NUMBER";
export const HANDLE_CHANGE_REPORT_MANAGER_DATA = "HANDLE_CHANGE_REPORT_MANAGER_DATA";
export const RESET_REPORT_MANAGER_DATA = "RESET_REPORT_MANAGER_DATA";
export const CHANGE_REPEAT_STEP = "CHANGE_REPEAT_STEP";
export const CHANGE_RETRY_STEP = "CHANGE_RETRY_STEP";
export const RUN_DETAILS_BY_RUN_NUMBERS = "RUN_DETAILS_BY_RUN_NUMBERS";
export const RUN_DETAILS_LOADING = "RUN_DETAILS_LOADING";
export const TEST_PLAN_STEPS_LIST_ID = "TEST_PLAN_STEPS_LIST_ID";
export const REMOVE_TEST_PLAN_STEPS_LIST_ID = "REMOVE_TEST_PLAN_STEPS_LIST_ID";
export const EDIT_TEST_PLAN="EDIT_TEST_PLAN";
export const GET_TEST_BLOCK_RESULT_DATA="GET_TEST_BLOCK_RESULT_DATA";
export const TEST_PLAN_CHILD_STEP_LOADING = "TEST_PLAN_CHILD_STEP_LOADING";
export const GET_TEST_PLAN_RESULT_VIDEO_LINK="GET_TEST_PLAN_RESULT_VIDEO_LINK";
export const ACCESSIBILITY_REPORT_LOADER="ACCESSIBILITY_REPORT_LOADER";
export const GET_ACCESSIBILITY_REPORT_DETAILS="GET_ACCESSIBILITY_REPORT_DETAILS";
export const AI_REPORT_STATUS_LOADING="AI_REPORT_STATUS_LOADING";

// Testplan Report end
// Teams
export const SAVE_TEAM = "SAVE_TEAM";
export const ADD_TEAM_MEMBER = "ADD_TEAM_MEMBER";
export const REMOVE_TEAM_MEMBER = "REMOVE_TEAM_MEMBER";
export const UPDATED_TEAM_SAVED = "UPDATED_TEAM_SAVED";
export const REQUEST_MY_TEAMS = "REQUEST_MY_TEAMS";
export const REQUEST_PLATFORM = "REQUEST_PLATFORM";
export const REQUEST_USERS_FROM_ORG = "REQUEST_USERS_FROM_ORG";
export const TEAM_LIST = "TEAM_LIST";
export const SELECTED_TEAM_MEMBERS = "SELECTED_TEAM_MEMBERS";
export const UPDATE_TEAM_MEMBER_FIELD = "UPDATE_TEAM_MEMBER_FIELD";
export const GET_ORG_LIST = "GET_ORG_LIST";
export const CREATE_TEAM_LOADER = "CREATE_TEAM_LOADER";
export const ADD_MEMBER_LOADER = "ADD_MEMBER_LOADER";
export const LIST_OF_TEAM_MEMBERS = "LIST_OF_TEAM_MEMBERS";
export const GET_TEAM_DETAILS_LOADER="GET_TEAM_DETAILS_LOADER";
// Projects
export const SAVE_PROJECT = "SAVE_PROJECT";
export const INVITE_MEMBER = "INVITE_MEMBER";
export const UPDATED_INVITE_MEMBER = "UPDATED_INVITE_MEMBER";
export const UPDATED_PROJECT_SAVED = "UPDATED_PROJECT_SAVED";
export const REQUEST_MY_PROJECTS = "REQUEST_MY_PROJECTS";
export const REQUEST_TEAM_MEMBERS = "REQUEST_TEAM_MEMBERS";
export const REQUEST_MY_PROJECTS_IN_TEAM = "REQUEST_MY_PROJECTS_IN_TEAM";
export const GET_PROJECT_BY_USER_ID_AND_TEAM_ID = "GET_PROJECT_BY_USER_ID_AND_TEAM_ID";
export const GET_PROJECT_BY_USER_ID_AND_ORG_ID = "GET_PROJECT_BY_USER_ID_AND_ORG_ID";
export const SHOW_DELETE_USER_MODAL = "SHOW_DELETE_USER_MODAL";
export const HIDE_DELETE_USER_MODAL = "HIDE_DELETE_USER_MODAL";
export const TEAM_PROJECT_DETAILS = "TEAM_PROJECT_DETAILS";
export const GET_INVITATION_LIST = "GET_INVITATION_LIST";
export const GET_PROJECTS_BY_ORG_ID = "GET_PROJECTS_BY_ORG_ID";
export const CREATE_PROJECT_LOADER = "CREATE_PROJECT_LOADER";
export const VISIBLE_CREATE_PROJECT_MODAL="VISIBLE_CREATE_PROJECT_MODAL";
export const PREVIOUS_PROJECT_ID="PREVIOUS_PROJECT_ID";
export const GENERATE_API_KEY_LOADER="GENERATE_API_KEY_LOADER";
export const GENERATE_API_KEY="GENERATE_API_KEY";
export const GET_API_KEY="GET_API_KEY";

// Invitation
export const INVITATION_LOADING = "INVITATION_LOADING";
export const GET_INVITATION_LOADING="GET_INVITATION_LOADING"
//QueryBuilder start
export const CHANGE_QUERY_BUILDER_OPERATOR = "CHANGE_QUERY_BUILDER_OPERATOR";
export const GET_QUERY_BUILD_DATA = "GET_QUERY_BUILD_DATA";
export const ADD_TAG_QUERY_BUILDER = "ADD_TAG_QUERY_BUILDER";
export const UPDATE_TAG_VALUE_QUERY_BUILDER = "UPDATE_TAG_VALUE_QUERY_BUILDER";
export const ADD_GROUP_QUERY_BUILDER = "ADD_GROUP_QUERY_BUILDER";
export const REMOVE_GROUP_QUERY_BUILDER = "REMOVE_GROUP_QUERY_BUILDER";
export const REMOVE_TAG_QUERY_BUILDER = "REMOVE_TAG_QUERY_BUILDER";
export const RESET_QUERY_BUILDER = "RESET_QUERY_BUILDER";

//Organization
export const GET_ORG_STEP_DETAIL = "GET_ORG_STEP_DETAIL";
export const SAVE_ORGANIZATION = "SAVE_ORGANIZATION";
export const GET_ORGANIZATION = "SAVE_ORGANIZATION";
export const JOIN_ORGANIZATION = "JOIN_ORGANIZATION";
export const SAVE_TEAM_DATA = "SAVE_TEAM_DATA";
export const SAVE_PROJECT_DATA = "SAVE_PROJECT_DATA";
export const LIST_OF_ORG_MEMBERS = "LIST_OF_ORG_MEMBERS";
export const ORGANIZATION_LOADING="ORGANIZATION_LOADING";
export const SET_ORGANIZATION_CURRENT_STEP="SET_ORGANIZATION_CURRENT_STEP"

//Workspace
export const SAVE_WORKSPACE = "SAVE_WORKSPACE";
export const GET_WORKSPACE = "SAVE_WORKSPACE";
export const JOIN_WORKSPACE = "JOIN_WORKSPACE";

//Organization Device
export const GET_ORG_DEVICE_LIST = "GET_ORG_DEVICE_LIST";
export const ORG_DEVICE_ACTIVE = "ORG_DEVICE_ACTIVE";
export const POST_ASSIGN_DEVICE = "POST_ASSIGN_DEVICE";
export const GET_ALL_DEVICES = "GET_ALL_DEVICES";
export const CHANGE_DEVICE_STATUS = "CHANGE_DEVICE_STATUS";
export const ORG_DEVICE_LOADING = "ORG_DEVICE_LOADING";

//Project Settings
export const GET_PROJECT_DETAIL_SETTING = "GET_PROJECT_DETAIL_SETTING";
export const GET_AWS_PROJECTS = "GET_AWS_PROJECTS";
export const GET_AWS_POOLS = "GET_AWS_POOLS";
export const REMOVE_AWS_POOLS = "REMOVE_AWS_POOLS";
export const PROJECT_LOADING = "PROJECT_LOADING";
export const UPDATE_PREVIOUS_AWS_DEVICE_FARM_TAB_DETAILS = "UPDATE_PREVIOUS_AWS_DEVICE_FARM_TAB_DETAILS";
export const GET_AWS_DEVICE_FARMS = "GET_AWS_DEVICE_FARMS";
export const GET_TEST_RAIL_CONFIG_DATA = "GET_TEST_RAIL_CONFIG_DATA";
export const GET_TEST_RAIL_CONFIG_PROJECT_LIST = "GET_TEST_RAIL_CONFIG_PROJECT_LIST";
export const TEST_RAIL_LOADING = "TEST_RAIL_LOADING";
export const AWS_DEVICE_LOADING = "AWS_DEVICE_LOADING";
export const TEST_RAIL_CASE_LOADING = "TEST_RAIL_CASE_LOADING";
export const CHANGE_GENERAL_TAB_DATA = "CHANGE_GENERAL_TAB_DATA";
export const CHANGE_S3CONFIG_TAB_DATA = "CHANGE_S3CONFIG_TAB_DATA";
export const CHANGE_AWS_DEVICE_FARM_TAB_DATA = "CHANGE_AWS_DEVICE_FARM_TAB_DATA";
export const UPDATE_SAUCELABS_DETAILS = "UPDATE_SAUCELABS_DETAILS";
export const VALIDATE_SAUCELABS_CREDENTIALS = "VALIDATE_SAUCELABS_CREDENTIALS";
export const SAUCELABS_LOADING = "SAUCELABS_LOADING";
export const VALIDATE_LAMBDA_TEST_CREDENTIALS = "VALIDATE_LAMBDA_TEST_CREDENTIALS";
export const LAMBDA_TEST_LOADING="LAMBDA_TEST_LOADING";
export const GET_LAMBDA_TEST_DETAILS="GET_LAMBDA_TEST_DETAILS";
export const GET_EXTERNAL_FARM_DATA_CENTER_LIST="GET_EXTERNAL_FARM_DATA_CENTER_LIST";
export const GET_EXTERNAL_FARM_DETAILS="GET_EXTERNAL_FARM_DETAILS";
export const EXTERNAL_FARM_LOADING="EXTERNAL_FARM_LOADING";
export const VALIDATE_EXTERNAL_FARM_CREDENTIALS="VALIDATE_EXTERNAL_FARM_CREDENTIALS";

//Error Investigation
export const FETCH_ERROR_INVESTIGATION_DATA = "FETCH_ERROR_INVESTIGATION_DATA";
export const CHANGE_ERROR_INVESTIGATION_DATA = "CHANGE_ERROR_INVESTIGATION_DATA";
export const PREVIOUS_ERROR_INVESTIGATION_DATA = "PREVIOUS_ERROR_INVESTIGATION_DATA";
export const FETCH_OTHER_RUN_LIST = "FETCH_OTHER_RUN_LIST";
export const ERROR_INVESTIGATION_LOADING = "ERROR_INVESTIGATION_LOADING";
export const SELECTED_RUN_RESULT_VIEW = "SELECTED_RUN_RESULT_VIEW";
export const SET_RUN_RESULT_SCENARIO_ID = "SET_RUN_RESULT_SCENARIO_ID";

//Server Management
export const FETCH_SERVER_TYPES = "FETCH_SERVER_TYPES";
export const FETCH_ALL_SERVER = "FETCH_ALL_SERVER";
export const CHANGE_REGISTER_SERVER_DATA = "CHANGE_REGISTER_SERVER_DATA";
export const PREVIOUS_REGISTER_SERVER_DATA = "PREVIOUS_REGISTER_SERVER_DATA";
export const SERVER_LOADING = "SERVER_LOADING";
export const SERVER_DEVICE_LOADING = "SERVER_DEVICE_LOADING";

export const FETCH_ALL_PROJECTS = "FETCH_ALL_PROJECTS";
export const FETCH_DEVICE_DETAILS = "FETCH_DEVICE_DETAILS";
export const FETCH_ALL_ORG_LIST="FETCH_ALL_ORG_LIST";

export const START_STOP_VIRTUAL_DEVICE_LOADER = "START_STOP_VIRTUAL_DEVICE_LOADER";
export const OPEN_ADD_EMULATOR_MODAL_VISIBLE = "OPEN_ADD_EMULATOR_MODAL_VISIBLE";
export const CLOSE_ADD_EMULATOR_MODAL_VISIBLE = "CLOSE_ADD_EMULATOR_MODAL_VISIBLE";
export const CREATE_VIRTUAL_DEVICE_LOADER = "CREATE_VIRTUAL_DEVICE_LOADER";
export const EMULATOR_SUPPORTED_OS_LIST = "EMULATOR_SUPPORTED_OS_LIST";
export const EMULATOR_SUPPORTED_DEVICE_LIST = "EMULATOR_SUPPORTED_DEVICE_LIST";
export const UPDATE_ADD_EMULATOR = "UPDATE_ADD_EMULATOR";
export const VISIBLE_REGISTER_SERVER_MODAL="VISIBLE_REGISTER_SERVER_MODAL";
export const HIDE_REGISTER_SERVER_MODAL="HIDE_REGISTER_SERVER_MODAL";


// project settings
export const GENERAL = "General";
export const S3_CONFIGURATION = "S3Configuration";
export const AWS_DEVICE_FARM = "AWSDeviceFarm";
export const TEST_RAILS_CONFIG = "TestrailConfiguration";
export const SAUCELABS = "Saucelabs";
export const LAMBDA_TEST = "LambdaTest";

//Farm Management
export const CREATE_FARM = "CREATE_FARM";
export const GET_DEVICE_FARM = "GET_DEVICE_FARM";
export const GET_DEVICE = "GET_DEVICE";
export const ASSIGN_DEVICE_TO_FARM = "ASSIGN_DEVICE_TO_FARM";
export const SEARCH_DEVICE_BY_FARM = "SEARCH_DEVICE_BY_FARM";
export const RESET_SEARCH_DEVICE_BY_FARM = "RESET_SEARCH_DEVICE_BY_FARM";
export const DEVICE_LOADING = "DEVICE_LOADING";
export const DEVICE_LOADING_FOR_DEVICE_MANAGE_SCREEN = "DEVICE_LOADING_FOR_DEVICE_MANAGE_SCREEN";
export const FETCHING_DEVICE_LIST = "FETCHING_DEVICE_LIST";

//Build Test Plan
export const MANAGE_TEST_SCENARIO = "Manage Test Scenario";
export const MANAGE_TEST_DATA = "Manage Test Data";
// export const TEST_PLAN = "1";
export const BUILD_TEST_PLAN = "Build Test Plan";
export const ALL_TEST_PLANS = "All Test Plans";

//App Manager
export const ALL_BUILDS = "All Builds";
export const ADD_BUILDS = "Build Uploader";
export const DEV = "Dev";
export const ALPHA = "Alpha";
export const RC_BETA = "Beta";
export const PROD = "Prod";
export const OTHER = "Other";
export const AWS = "AWS";
export const SAUCE_LAB = "SAUCELABS";
export const LAMBDATEST="LAMBDATEST"

// Server Manager
export const ALL_SERVER = "Manage Local Devices";
export const REGISTER_SERVER = "Register Local Server";
export const CHANGE_SERVER_MANAGER_TAB = "CHANGE_SERVER_MANAGER_TAB";

// Report manager view
export const EXECUTION_VIEW = "executionView";
export const DEVICE_VIEW = "deviceView";
export const SCENARIO_VIEW = "scenarioView";
export const LOCALE_VIEW = "localeView";
export const INVESTIGATION_VIEW = "investigationView";
export const COMPARISON_SINGLE_LOCALE = "comparisonSingleLocale";
export const COMPARISON_ALL_LOCALES = "comparisonAllLocales";
export const SHOW_INSTALL_BUILD_ON_DEVICE = "SHOW_INSTALL_BUILD_ON_DEVICE";
export const HIDE_INSTALL_BUILD_ON_DEVICE = "HIDE_INSTALL_BUILD_ON_DEVICE";

// Test Job
export const SAVE_TEST_JOB = "SAVE_TEST_JOB";
export const FETCHED_CURL_FOR_JOB = "FETCHED_CURL_FOR_JOB";
export const FETCHED_CURL_FOR_BUILD_UPLOAD = "FETCHED_CURL_FOR_BUILD_UPLOAD";
export const REMOVE_TEST_JOB = "REMOVE_TEST_JOB";
export const GET_TEST_JOB = "GET_TEST_JOB";
export const GET_TEST_JOB_QUEUE_LIST = "GET_TEST_JOB_QUEUE_LIST";
export const UPDATE_TEST_JOB_ERROR_DETAIL = "UPDATE_TEST_JOB_ERROR_DETAIL";
export const GET_TEST_JOB_ID_NAME_LIST = "GET_TEST_JOB_ID_NAME_LIST";
export const GET_TEST_JOB_SCHEDULE = "GET_TEST_JOB_SCHEDULE";
export const GET_TEST_JOB_BY_ID = "GET_TEST_JOB_BY_ID";
export const GET_TEST_JOB_SCHEDULE_BY_ID = "GET_TEST_JOB_SCHEDULE_BY_ID";
export const RESET_GET_TEST_JOB_BY_ID_DATA = "RESET_GET_TEST_JOB_BY_ID_DATA";
export const GET_TIMEZONE = "GET_TIMEZONE";
export const LOADING_JOB = "LOADING_JOB";
export const BUILD_VERSION_LIST = "BUILD_VERSION_LIST";
export const TEST_RAIL_SUITES_BY_TEST_PLAN_ID = "TEST_RAIL_SUITES_BY_TEST_PLAN_ID";
export const CHANGE_TEST_JOB_DATA = "CHANGE_TEST_JOB_DATA";
export const CLOSE_TEST_JOB_SCHEDULE_DRAWER = "CLOSE_TEST_JOB_SCHEDULE_DRAWER";
export const OPEN_TEST_JOB_SCHEDULE_DRAWER = "OPEN_TEST_JOB_SCHEDULE_DRAWER";
export const CHANGE_SELECTED_TEST_JOB_CRITERIA = "CHANGE_SELECTED_TEST_JOB_CRITERIA";
export const CHANGE_EXECUTION_MANAGER_TAB = "CHANGE_EXECUTION_MANAGER_TAB";
export const CHANGE_TEST_JOB_SCHEDULE_DATA = "CHANGE_TEST_JOB_SCHEDULE_DATA";
export const RESET_GET_TEST_JOB_SCHEDULE_DATA = "RESET_GET_TEST_JOB_SCHEDULE_DATA";
export const SET_SCHEDULE_TEST_PLAN = "SET_SCHEDULE_TEST_PLAN";
export const RUN_RESULT_LOADING = "RUN_RESULT_LOADING";
export const CURL_FETCH_FOR_JOB_LOADING = "CURL_FETCH_FOR_JOB_LOADING";
export const CURL_FETCH_FOR_BUILD_LOADING = "CURL_FETCH_FOR_BUILD_LOADING";
export const CURL_FETCH_FOR_BUILD_LOADING_FALSE = "CURL_FETCH_FOR_BUILD_LOADING_FALSE";
export const CHANGE_REPORT_RESULT_TAB = "CHANGE_REPORT_RESULT_TAB";
export const SET_RESULT_TYPE = "SET_RESULT_TYPE";
export const CHANGE_SAUCE_LABS_DEVICE_LIST_PAGE_NUMBER = "CHANGE_SAUCE_LABS_DEVICE_LIST_PAGE_NUMBER";
export const CHANGE_BUILD_JOB_LIST_FILTER = "CHANGE_BUILD_JOB_LIST_FILTER";
export const CREATE_BUILD_JOB="CREATE_BUILD_JOB";
export const EXTERNAL_FARM_DEVICE_LOADING="EXTERNAL_FARM_DEVICE_LOADING";
export const GET_EXTERNAL_FARM_DEVICE="GET_EXTERNAL_FARM_DEVICE";
export const CHANGE_EXTERNAL_FARM_DEVICE_LIST_PAGE_NUMBER="CHANGE_EXTERNAL_FARM_DEVICE_LIST_PAGE_NUMBER";
export const SEARCH_EXTERNAL_FARM_DEVICE_LIST="SEARCH_EXTERNAL_FARM_DEVICE_LIST";
export const EDIT_BUILD_JOB="EDIT_BUILD_JOB";
export const GET_BUILDS_VERSIONS="GET_BUILDS_VERSIONS";
export const GET_BUILD_VERSIONS_LOADER="GET_BUILD_VERSIONS_LOADER";
export const VISIBLE_CREATE_BUILD_MODAL="VISIBLE_CREATE_BUILD_MODAL";

//TestDataSet
export const BUILD_TEST_DATA_SET = "BUILD_TEST_DATA_SET";
export const STEPS_DATA = "STEPS_DATA";

//Execution Reports
export const FETCH_EXECUTION_REPORTS = "FETCH_EXECUTION_REPORTS";
export const LOADING_REPORTS = "LOADING_REPORTS";

//Test Rails
export const TEST_RAIL_CASES = "TEST_RAIL_CASES";
export const GET_TEST_SUITS = "GET_TEST_SUITS";

// Test Scenario
export const STEP_DISABLED = "Step Disabled";
export const TEST_BLOCK = "Test Block";
export const ENABLE = "Enable";
export const DISABLE = "Disable";

//Execution Type
export const SERIAL = "SERIAL";
export const PARALLEL_OS = "PARALLEL_OS";
export const PARALLEL_SPEED = "PARALLEL_SPEED";
export const SERIAL_FOR_DISPLAY = "Serial (Test Plan Execution on one Device at a time)";
export const PARALLEL_OS_FOR_DISPLAY = "Parallel Repetition (Repeat Test Plan execution across all devices)";
export const PARALLEL_SPEED_FOR_DISPLAY = "  Parallel Distribution (Split Test Plan execution across all devices)";

export const executionModeShortText = {
  SERIAL: "Serial",
  PARALLEL_OS: "Parallel Repetition",
  PARALLEL_SPEED: "  Parallel Distribution",
};

export const STEP_ITEMS = {
  WHEN_TO_RUN_BY_VERIFYING_ELEMENT: "When To Run By Verifying Element",
  WHEN_TO_RUN_BY_VERIFYING_TEST_DATA: "When To Run By Verifying Test Data",
  REPEAT_TILL: "Repeat Till",
  STEPS: "Steps to Repeat",
};

//job
export const EXECUTION_MANAGER = {
  JOB_MANAGER: "JOB_MANAGER",
  EXECUTION_QUEUE: "EXECUTION_QUEUE",
};

export const EXECUTION_MANAGER_DISPLAY_TEXT = {
  JOB_MANAGER: "Job Manager",
  EXECUTION_QUEUE: "Execution Queue",
};

export const privacyOption = ["Private", "Public", "Customize"];

export const PRIVATE = "private";
export const PUBLIC = "public";

export const ACTION_VIEW = 1;
export const ACTION_NOT_VIEW = 0;
export const CUSTOM = "custom";

export const APPLY_FOR = "Apply For";
export const REMOVE_FOR = "Remove For";

export const ALL_JOBS = "ALL_JOBS";
export const ALL_JOBS_DISPLAY_TEXT = "All Jobs";
export const JOB_DESIGNER = "JOB_DESIGNER";
export const JOB_DESIGNER_DISPLAY_TEXT = "Job Designer";
export const ALL_RUNS = "ALL_RUNS";
export const RUNNING_JOB = "RUNNING_JOB";
export const IN_QUEUE_JOB = "IN_QUEUE_JOB";
export const COMPLETED_JOB = "COMPLETED_JOB";
export const ALL_RUNS_DISPLAY_TEXT = "All";
export const RUNNING_JOB_DISPLAY_TEXT = "Running Jobs";
export const IN_QUEUE_JOB_DISPLAY_TEXT = "In-Queue Jobs";
export const COMPLETED_JOB_DISPLAY_TEXT = "Completed Jobs";
export const ALL_SCHEDULE_JOBS = "ALL_SCHEDULE_JOBS";
export const ALL_SCHEDULE_JOBS_DISPLAY_TEXT = "All Schedule Jobs";
export const JOB_SCHEDULER = "JOB_SCHEDULER";
export const JOB_SCHEDULER_DISPLAY_TEXT = "Job Scheduler";

export const ALL_JOB = "allJob";
export const COMPLETEDJOB = "DONE";
export const SCHEDULEDJOB = "SCHEDULED";
export const BUILD_JOB = "buildJob";
export const BUILD_URL = "Build URL";
export const NONE = "None";

//Schedule Time
export const AT = "at";
export const EVERY = "Every";
export const weekDays = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

//Execution Frequency
export const ONETIME = "ONETIME";
export const RECURRING = "RECURRING";
export const ALL = "";

//schedule Time
export const IMMEDIATE = "IMMEDIATE";
export const SCHEDULED = "SCHEDULED";

//QueryBuilder end
export const APP_BUILD_RECORD_PER_PAGE = 10;
export const INSTALL_PAGE_APP_BUILD_RECORD_PER_PAGE = 4;
export const ERROR_STATUS = "Error";
export const SUCCESS_STATUS = "Success";
export const SUCCESS_TYPE = "success";
export const FAILED_STATUS = "Failed";
export const RunKilled = "Run killed!.";
export const defaultPageLimit = 10;
export const TEST_ELEMENT = "TEST_ELEMENT";
export const DEVICE_INTERACTION_MODE = {
  SELECT: "select",
  INTERACTIVE: "INTERACTIVE",
  DRAW: "Draw",
  HIGHLIGHT_CHILD: "highlight_child",
  BLOCK: "BLOCK",
};

//local device server(Electron app)
export const SELECTED_ORG = "SELECTED_ORG";
export const LOGIN_STATUS_LOADING="LOGIN_STATUS_LOADING";
export const SUCCESS_LOCAL_DEVICE_SERVER_LOGIN="SUCCESS_LOCAL_DEVICE_SERVER_LOGIN";

export const ERROR_TYPE = "error";
export const WARNING_TYPE = "warning";
export const INFO_TYPE = "info";

export const DEVICE_STATUS = {
  INTERACTION: "INTERACTION",
  RECORDING: "RECORDING",
  PAUSE: "PAUSE",
  PREVIEW: "PREVIEW",
  TEST_PLAN: "TEST_PLAN",
  RESTART: "RESTART",
  EXECUTION: "EXECUTION",
};
export const RECORDING_SCREEN = {
  SHOW_SCENARIO_LIST: "SHOW_SCENARIO_LIST",
  SHOW_RECORDING: "SHOW_RECORDING",
  SHOW_EXECUTE_SCENARIO: "SHOW_EXECUTE_SCENARIO",
  SHOW_EDIT_PREVIEW: "SHOW_EDIT_PREVIEW",
};
export const EXECUTION_SCREEN_TAB = {
  EXECUTE: "EXECUTE",
  RESULT: "RESULT",
};
export const DEVICE_SESSION_LOGTYPE = {
  VERBOSE: "VERBOSE",
  ERROR: "ERROR",
  DEBUG: "DEBUG",
  INFO: "INFO",
  WARNING: "WARNING",
};
export const APP_MANAGEMENT_SCREEN = {
  APP_BUILDS_LISTS: "APP_BUILDS_LISTS",
  ADD_APP_BUILD: "ADD_APP_BUILD",
  EDIT_APP_BUILD: "EDIT_APP_BUILD",
};
export const APP_BROWSER_IMAGE_FROM_PACKAGE = {
  "com.android.chrome": chromeIcon,
  "org.mozilla.firefox": FirefoxIcon,
  "com.opera.browser": operaIcon,
  "com.apple.mobilesafari": safariIcon,
  "com.google.chrome.ios": chromeIcon,
  "com.opera.mini.native": operaMiniIcon,
};
export const QUICK_LAUNCH_APP = {
  SETTINGS_APP: "SETTINGS_APP",
  WIFI_APP: "WIFI_APP",
  DEVELOPER_MENU: "DEVELOPER_MENU",
  MANAGE_APPS: "MANAGE_APPS",
  APP_STORE: "APP_STORE",
};

export const ElementType = {
  DIFFERENT_VERSION: "DIFFERENT_VERSION",
  PARENT_ELEMENT: "PARENT_ELEMENT",
  CUSTOM: "CUSTOM",
};

export const ElementIdentifyMethod = {
  XPATH: "XPATH",
  RESOURCE_ID: "RESOURCE_ID",
  TEXT: "TEXT",
  CONTENT_DESC: "CONTENT_DESC",
};

export const XpathGenerateMethod = {
  UNIQUE_RESOURCE_ID_AND_TEXT: "UNIQUE_RESOURCE_ID_AND_TEXT",
  UNIQUE_CLASS_AND_TEXT: "UNIQUE_CLASS_AND_TEXT",
  UNIQUE_TEXT: "UNIQUE_TEXT",
  PARENT_REF: "PARENT_REF",
  ACTION_BY_LOCATOR: "ACTION_BY_LOCATOR",
  UNIQUE_NAME: "UNIQUE_NAME",
  UNIQUE_RESOURCE_ID: "UNIQUE_RESOURCE_ID",
};

export const APPLICATION_STRING_TAB = {
  ALL: "ALL",
  IMPORTED: "IMPORTED",
  CUSTOM: "CUSTOM",
};

export function parseCoordinates(element) {
  let { bounds, x, y, width, height } = element.attributes || {};

  if (bounds) {
    let boundsArray = bounds.split(/\[|\]|,/).filter((str) => str !== "");
    return {
      x1: boundsArray[0],
      y1: boundsArray[1],
      x2: boundsArray[2],
      y2: boundsArray[3],
    };
  } else if (x) {
    x = parseInt(x, 10);
    y = parseInt(y, 10);
    width = parseInt(width, 10);
    height = parseInt(height, 10);
    return { x1: x, y1: y, x2: x + width, y2: y + height };
  } else {
    return {};
  }
}

export function checkVisibility(element) {
  let { visible, displayed } = element.attributes || {};
  if (visible == "true" || displayed == "true") {
    return { draw: true };
  } else if (visible == "false" || displayed == "false") {
    return { draw: false };
  }
}

export const verifyMenuItems = [
  { value: "verify", displayName: "Verify" },
  { value: "verifyText", displayName: "Verify by text" },
  { value: "verifyByUserInputText", displayName: "Verify by user input text" },
  {
    value: "verifyByRefStepText",
    displayName: "Verify by reference step text",
  },
];
export const tapMenuItems = [
  { value: "tap", displayName: "Tap" },
  { value: "tapByIndex", displayName: "Tap by index" },
  // {value:"tapByText",displayName:"Tap by text"}
];

export const ELEMENT_ACTION = {
  SWIPE: "Swipe",
  TAP: "tap",
  LONG_PRESS: "longPress",
  TAP_BY_INDEX: "tapByIndex",
  TAP_BY_COORDINATES: "tapByCoordinates",
  BACK: "back",
  SEND_KEYS: "sendKeys",
  CLEAR: "clear",
  DEVICE_INTERACTION: "deviceInteraction",
  LAUNCH_APP: "launchApp",
  PAUSE: "pause",
  CAPTURE_SCREENSHOT: "captureScreenshot",
  CLEAR_TEXT: "clearText",
  INSERT_VALUE_TO_OUTPUT_TEST_DATA: "insertValueToOutputTestData",
  SWIPE_OVER_ELEMENT: "swipeOverElement",
  SCREENSHOT_ELEMENT: "ScreenShot Element",
};

export const INSERT_VALUE_TO_OUTPUT_TEST_DATA_TXT = "Assign Value to Test Data";
// postActionSettings
export const SELECT_TEXT_TO_TEST_DATA = "SELECT_TEXT_TO_TEST_DATA";
export const CAPTURE_SCREENSHOT_AFTER = "CAPTURE_SCREENSHOT_AFTER";
export const DELAY_EXECUTION_ACTION = "DELAY_EXECUTION_ACTION";
export const REPEAT_EXECUTION_STEP = "REPEAT_EXECUTION_STEP";

export const GROUP_ACTION = {
  ALWAYS_RUN: "Always Run",
  CUSTOM: "custom",
};

export const APP_BUILD_UPLOAD_STATUS = {
  IN_PROGRESS: "In-Progress",
  DONE: "Done",
  CANCELED: "Cancel-Upload",
  EMPTY: "Empty",
  PENDING: "Pending",
};

export const APP_BUILD_DOWNLOAD_STATUS = {
  IN_PROGRESS: "In-Progress",
  DONE: "Done",
  FAILED: "Failed",
};

export const DEVICE_INTERACTION = {
  HOME: "HOME",
  BACK: "BACK",
  ENTER: "ENTER",
  LOCK: "LOCK",
  UNLOCK: "UNLOCK",
  VOLUME_UP: "VOLUME_UP",
  VOLUME_DOWN: "VOLUME_DOWN",
  VOLUME_MUTE: "VOLUME_MUTE",
  VIEW_NOTIFICATION: "VIEW_NOTIFICATION",
};

export const SWIPE_DIRECTION = {
  UP: "UP",
  DOWN: "DOWN",
  LEFT: "LEFT",
  RIGHT: "RIGHT",
};
export const SWIPE_DIRECTION_TEXT = {
  UP: "DOWN TO UP",
  DOWN: "UP TO DOWN",
  LEFT: "RIGHT TO LEFT",
  RIGHT: "LEFT TO RIGHT",
};

export const NOTIFICATION_SCROLL_DIRECTION = {
  UP: "UP",
  DOWN: "DOWN",
};

export const DEVICE_EXECUTION_FARM = {
  XPRESS: "XPRESS",
  AWS: "AWS",
  SAUCELABS: "SAUCELABS",
  LAMBDA_TEST: "LAMBDATEST",
};

/* Note: value of an array has been used statically by [] (index) 
  be careful before changing an order. */
export const TEST_DATA_SCOPE = {
  TEST_SCENARIO: "Test Scenario",
  PROJECT: "Project",
  ALL: "All",
  TEST_STEP_BLOCK: "Test Block",
  SYSTEM_KEYS: "System Key",
};

export const defaultLanguage = "en-US";
export const elementProperties = [
  "checkable",
  "checked",
  "clickable",
  "enabled",
  "focusable",
  "focused",
  "long-clickable",
  "password",
  "scrollable",
  "selected",
  "displayed",
];
export const actionOnElementMapping = {
  Swipe: "actions",
  tap: "actions",
  longPress: "actions",
  tapByIndex: "actions",
  tapByCoordinates: "actions",
  back: "actions",
  sendKeys: "actions",
  clear: "actions",
  deviceInteraction: "actions",
  verifications: "verifications",
  launchApp: "launchApp",
  step: "step",
};

// Error Investigation
export const NEW_INVESTIGATION = "New Investigation";
export const PREVIOUS_INVESTIGATION = "Use Existing Investigation";
export const failureReasonOptions = [
  "Needs investigation",
  "Product Bug",
  "Automation Fix Needed",
  "Product Change",
  "Repro In-Frequently - Potential Bug",
  "Unknown Failure - No Repro",
  "Environment Issue (Account or Device specific, Internet etc)",
  "Step Fix Needed",
];
export const OTHER_RUNS_OPTION = { INVESTIGATED: "investigated", PENDING: "pending", ALL: "all" };

export const XPATH_ELEMENT_ATTRIBUTES_SPLITTER = "##";
export const XPATH_ELEMENT_SPLITTER = "$$";
export const ACTIONS = "actions";
export const ACTIONS_OPTIONS = "Select Actions";
export const STEP = "step";
export const VERIFICATIONS = "verifications";
export const CAPTURE_SCREENSHOT = "captureScreenshot";
export const SET_APP_LOCALE = "setAppLocale";
export const SET_APP_LOCALE_TXT = "Set App Locale Value";
export const LAUNCH_APP = "launchApp";
export const SWIPE_OVER_ELEMENT = "swipeOverElement";
export const QUICK_SWIPE = "Swipe";
export const onlyVerificationSelectionRequiredWithFlagToIncludeText = {
  PresenceOfElementByText: "1",
  PresenceOfElement: "0",
};

export const verifyElementPropertiesText = [
  { key: "checkable", name: "is checkable", value: "",elementPropertyName:"checkable" },
  { key: "not checkable", name: "is not checkable", value: "",elementPropertyName:"checkable" },
  { key: "checked", name: "is checked", value: "",elementPropertyName:"checked" },
  { key: "not checked", name: "is not checked", value: "",elementPropertyName:"checked" },
  { key: "clickable", name: "is clickable", value: "",elementPropertyName:"clickable" },
  { key: "not clickable", name: "is not clickable", value: "",elementPropertyName:"clickable" },
  { key: "enabled", name: "is enabled", value: "",elementPropertyName:"enabled" },
  { key: "not enabled", name: "is not enabled", value: "",elementPropertyName:"enabled" },
  { key: "focusable", name: "is focusable", value: "",elementPropertyName:"focusable" },
  { key: "not focusable", name: "is not focusable", value: "",elementPropertyName:"focusable" },
  { key: "focused", name: "is focused", value: "",elementPropertyName:"focused" },
  { key: "not focused", name: "is not focused", value: "",elementPropertyName:"focused" },
  { key: "scrollable", name: "is scrollable", value: "",elementPropertyName:"scrollable" },
  { key: "not scrollable", name: "is not scrollable", value: "",elementPropertyName:"scrollable" },
  { key: "selected", name: "is selected", value: "",elementPropertyName:"selected" },
  { key: "not selected", name: "is not selected", value: "",elementPropertyName:"selected" },
  { key: "displayed", name: "is displayed", value: "",elementPropertyName:"displayed" },
  { key: "not displayed", name: "is not displayed", value: "",elementPropertyName:"displayed" },
];

export const VerificationPresenceValues = {
  "Presence of element By Text": "By Text",
  "Presence of element": "By Locator",
};
export const VerificationAbsenceValues = {
  "Absence of element": "PreviousElementList",
};

export function blankVerificationItem() {
  return { operator: "And", data: [], uuid: getUniqueId() };
}

export const verificationDropdownValues = {
  "Presence of element By Text": "PresenceOfElementByText",
  "Presence of element": "PresenceOfElement",
  "Verify Element Property": "ElementProperty",
  "Absence of element": "PreviousElementList",
  "Is empty": "",
  "Is not empty": "",
  Contains: "Textbox",
  "Does not contain": "Textbox",
  "Starts with": "Textbox",
  "Ends with": "Textbox",
  Exact: "Textbox",
  "Greater than": "Number",
  "Greater than or equal to": "Number",
  "Less than": "Number",
  "Less than or equal to": "Number",
  "Length greater than or equal to": "Number",
  "Length less than or equal to": "Number",
  "Length equal to": "Number",
  "Equals to reference text": "ReferenceTextDropdown",
  "Contains to reference text": "ReferenceTextDropdown",
  "Equals to content description": "ReferenceTextDropdown",
  "Equals value of Test Data Key": "ReferenceTestDataDropdown",
  "Contains value of Test Data Key": "ReferenceTestDataDropdown",
};
export const verificationDropdownValuesMandatory = [
  "Contains",
  "Does not contain",
  "Starts with",
  "Ends with",
  "Exact",
  "Greater than",
  "Greater than or equal to",
  "Less than",
  "Less than or equal to",
  "Length greater than or equal to",
  "Length less than or equal to",
  "Length equal to",
  "Equals to reference text",
  "Contains to reference text",
  "Equals value of Test Data Key",
  "Contains value of Test Data Key",
  "Equals to content description",
];
export const verifyElementTextValuesForRecElement = {
  "Is empty": "",
  "Is not empty": "",
  Contains: "Textbox",
  "Does not contain": "Textbox",
  "Starts with": "Textbox",
  "Ends with": "Textbox",
  "Does not equal": "Textbox",
  Exact: "Textbox",
  // "Greater than": "Number",
  // "Greater than or equal to": "Number",
  // "Less than": "Number",
  // "Less than or equal to": "Number",
  // "Length greater than or equal to": "Number",
  // "Length less than or equal to": "Number",
  // "Length equal to": "Number",
  "Equals value of Test Data Key": "ReferenceTestDataDropdown",
  "Contains value of Test Data Key": "ReferenceTestDataDropdown",
  "Does not equal value of Test Data Key": "ReferenceTestDataDropdown",
  "Does not contain value of Test Data Key": "ReferenceTestDataDropdown",
};

export const verifyApplicationStrings = ["Equals to Locale value of App String", "Contains Locale value of App String"];
export const verifyDeviceStrings = [
  "Equals to Locale value of Device String",
  "Contains Locale value of Device String",
];
export const verificationExistenceDisplayName = {
  exist: "Exists",
  "does not exist": "Does not exist",
};

export const verificationExistenceValues = {
  exist: "exist",
  does_not_exist: "does not exist",
};

export const verifyElementTextValues = {
  "Is empty": "",
  "Is not empty": "",
  Contains: "Textbox",
  "Does not contain": "Textbox",
  "Starts with": "Textbox",
  "Ends with": "Textbox",
  "Does not equal": "Textbox",
  Exact: "Textbox",
  // "Greater than": "Number",
  // "Greater than or equal to": "Number",
  // "Less than": "Number",
  // "Less than or equal to": "Number",
  // "Length greater than or equal to": "Number",
  // "Length less than or equal to": "Number",
  // "Length equal to": "Number",
  "Equals to reference text": "ReferenceTextDropdown",
  "Contains to reference text": "ReferenceTextDropdown",
  "Equals to custom reference text": "CustomReferenceVerification",
  "Equals to content description": "ReferenceTextDropdown",
  "Equals value of Test Data Key": "ReferenceTestDataDropdown",
  "Contains value of Test Data Key": "ReferenceTestDataDropdown",
  "Does not equal value of Test Data Key": "ReferenceTestDataDropdown",
  "Does not contain value of Test Data Key": "ReferenceTestDataDropdown",
};
export const CustomReferenceVerification = "CustomReferenceVerification";
export const ReferenceTextDropdown = "ReferenceTextDropdown";
export const ReferenceTestDataDropdown = "ReferenceTestDataDropdown";
export const ApplicationStringsDropdown = "ApplicationStringsDropdown";
export const Number = "Number";
export const TextBox = "Textbox";

export const customTextVerificationDropdownValue = {
  Text: "Textbox",
  ReferenceText: "ReferenceTextDropdown",
  ReferenceTestData: "ReferenceTestDataDropdown",
  ApplicationStringsDropdown: "ApplicationStringsDropdown",
};

export const TAP_CATEGORY = {
  SINGLE_TAP: "SINGLE_TAP",
  DOUBLE_TAP: "DOUBLE_TAP",
  LONG_PRESS: "LONG_PRESS",
};

export const DEVICE_FARM_USE = {
  SCENARIO_RECORDING: "SCENARIO_RECORDING",
  TEST_RUN_EXECUTION: "TEST_RUN_EXECUTION",
  BOTH: "BOTH",
};
export const absenceDropdownValues = {
  "Absence of element": "PreviousElementList",
};

export const actionTypeIcon = {
  Swipe: faHandPaper,
  swipeOverElement: faHandPaper,
  tap: faHandPointer,
  longPress: faHandPointer,
  tapByIndex: faHandPointer,
  tapByCoordinates: faDotCircle,
  back: faArrowLeft,
  forward: faArrowRight,
  sendKeys: faKeyboard,
  clear: faTimes,
  deviceInteraction: faHandPointer,
  verifications: faSearch,
  HOME: faHome,
  BACK: faArrowLeft,
  LOCK: faLock,
  UNLOCK: faUnlock,
  VOLUME_UP: faVolumeUp,
  VOLUME_DOWN: faVolumeDown,
  VOLUME_MUTE: faVolumeMute,
  launchApp: faRocket,
  step: faFolder,
};

export const MINIMUM_SWIPE_VALUE = 60;
export const textNumericSymbol = [
  "q",
  "w",
  "e",
  "r",
  "t",
  "y",
  "u",
  "i",
  "o",
  "p",
  "a",
  "s",
  "d",
  "f",
  "g",
  "h",
  "j",
  "k",
  "l",
  "z",
  "x",
  "c",
  "v",
  "b",
  "n",
  "m",
  "Q",
  "W",
  "E",
  "R",
  "T",
  "Y",
  "U",
  "I",
  "O",
  "P",
  "A",
  "S",
  "D",
  "F",
  "G",
  "H",
  "J",
  "K",
  "L",
  "Z",
  "X",
  "C",
  "V",
  "B",
  "N",
  "M",
  "0",
  "9",
  "8",
  "7",
  "6",
  "5",
  "4",
  "3",
  "2",
  "1",
  ")",
  "(",
  "*",
  "&",
  "^",
  "%",
  "$",
  "#",
  "@",
  "!",
  "~",
  "`",
  "[",
  "]",
  "{",
  "}",
  "|",
  ":",
  ";",
  "?",
  ">",
  "<",
  "",
  ",",
  ".",
  "/",
  "\\",
  '"',
  "'",
  " ",
  "Backspace",
  "Enter",
  "+",
  "=",
  "_",
  "-",
];
export const SAVE_MINIMUM_UNSAVED_STEP = 1;

export const xpressComponentType = {
  menu: "Menu",
  page: "Page",
  pageComponents: "PageComponents",
  rights: "Rights",
};

export function customRefTextDefaultList() {
  return [
    {
      order: 1,
      key: "Text",
      value: "",
    },
  ];
}
export const PROXY_STATUS = {
  STOPPED: "STOPPED",
  STARTED: "STARTED",
  START_REQUEST: "START_REQUEST",
  STOP_REQUEST: "STOP_REQUEST",
};

export const DEVICE_TYPE = {
  SHARED: "Share with All Projects",
  CUSTOM: "Custom",
};

export const ORGANIZATION_STEP_FLOW = {
  STEP1: 1,
  STEP2: 2,
  STEP3: 3,
};

export const SELECTOR_TYPE_TEXT = {
  CHILD_ELEMENT_WITHIN_HIERARCHY: "Child element within hierarchy",
  SELECT_ELEMENT_ON_SCREEN: "Specific Element on screen",
  SELECT_IMAGE_ON_SCREEN: "Selected area of screen",
  CUSTOM_ELEMENT: "Custom Element",
};

export const SELECTOR_TYPE = {
  SELECT_ELEMENT_ON_SCREEN: "SELECT_ELEMENT_ON_SCREEN",
  SELECT_IMAGE_ON_SCREEN: "SELECT_IMAGE_ON_SCREEN",
  CUSTOM_ELEMENT: "CUSTOM_ELEMENT",
};
export const SELECTOR_DEVICE_INTERACTION_MODE_MAPPING = {
  SELECT_ELEMENT_ON_SCREEN: "SELECT",
  SELECT_IMAGE_ON_SCREEN: "DRAW",
  ADVANCED_SELECTOR: "",
  BLOCK: "BLOCK",
};

export const RUN_RESULT_STATUS = {
  IN_PROGRESS: "IN_PROGRESS",
  IN_QUEUE: "IN_QUEUE",
  DONE: "DONE",
  ABORTED: "ABORTED",
  WAITING_FOR_DEVICE_TO_EXECUTED: "WAITING_FOR_DEVICE_TO_EXECUTED",
  UPLOADING_RESULT: "UPLOADING RESULT",
};

export const REC_TEST_STEP_PREVIEW_STATUS_TYPE = {
  PASSED: "Passed",
  FAILED: "Failed",
  SKIPPED: "Skipped",
  CONTINUE_AFTER_FAILED: "CONTINUE_AFTER_FAILED",
  PRECONDITION_FAILED: "PRECONDITION_FAILED",
  IN_PROGRESS: "IN_PROGRESS",
};
export const REC_TEST_STEP_PREVIEW_STATUS_TYPE_TOOLTIP = {
  PASSED: "Passed",
  FAILED: "Failed",
  SKIPPED: "Skipped",
  CONTINUE_AFTER_FAILED: "Ignored failure, continuing execution",
  PRECONDITION_FAILED: "Precondition not met! Continuing execution to next step.",
};

export const STOP_PREVIEW_ERROR_MSG_FOR_SCENARIO =
  "Closing Scenario will stop the Preview Run. Are you sure you want to continue?";
export const STOP_PREVIEW_ERROR_MSG_FOR_TEST_BLOCK =
  "Closing Test block will stop the Preview Run. Are you sure you want to continue?";

export const TEST_PLAN_RESULT_TYPE = {
  // IN_PROGRESS: "In-Progress",
  IN_PROGRESS: "IN_PROGRESS",
  IN_PROGRESS_TXT: "In-Progress",
  IN_QUEUE: "IN_QUEUE",
  PREPARING_RUN_TO_EXECUTE: "PREPARING_RUN_TO_EXECUTE",
  ASSIGNED_TO_DEVICES: "ASSIGNED_TO_DEVICES",
  PASSED: "Passed",
  QUEUED_WAITING_FOR_DEVICE_AVAILABILITY: "QUEUED_WAITING_FOR_DEVICE_AVAILABILITY",
  QUEUED_WAITING_FOR_DEVICE_AVAILABILITY_TXT: "Queued - Waiting for Device Availability",
  TOTAL_CASES: "Total Cases",
  TOTAL_SCENARIO_TO_EXECUTE: "Total Scenarios Executed",
  TOTAL_SCENARIOS: " Total Scenarios",
  TOTAL_DEVICES: " Total Devices",
  TOTAL_PENDING: "Total Pending",
  SKIPPED_PRECONDITION_FAILED: "Skipped (Precondition failed, skipped Grouped-Steps execution)",
  SKIPPED_PRECONDITION_FAILED_FOR_SCENARIO: "Precondition failed, skipped Grouped-Steps execution",
  FAILED_PRECONDITION_NOT_MET: "Failed (Precondition not met)",
  FAILED_LOOP_STEP_CONDITION_NOT_MET: "Failed (Condition not met)",
  FAILED_PRECONDITION_NOT_MET_FOR_SCENARIO: "Precondition not met",
  FAILED_ACTION_VERIFICATION_FAILED: "Failed (Precondition met, but Action/Verification failed)",
  FAILED_ACTION_VERIFICATION_FAILED_FOR_SCENARIO: "Precondition met, but Action/Verification failed",
  FAILED: "Failed",
  ABORTED: "Aborted",
  WAITING_FOR_DEVICE_TO_EXECUTED: "WAITING_FOR_DEVICE_TO_EXECUTED",
  WAITING_FOR_DEVICE_TO_EXECUTED_TXT: "Waiting For Device Availability",
  TERMINATED: "Terminated",
  SKIPPED: "Skipped",
  STEP_DISABLED_SKIPPED: "Step Disabled , Skipped",
  FAILED_IGNORE_AND_CONTINUE: "Failed (Ignore and Continue)",
  FAILED_IGNORE_AND_CONTINUE_FOR_SCENARIO: "Ignore and Continue",
  CONTINUE_AFTER_FAILED: "CONTINUE_AFTER_FAILED",
  UPLOADING_RESULT: "UPLOADING_RESULT",
  UPLOADING_RESULT_TXT: "Uploading Results",
  DONE: "DONE",
  // CONTINUE_AFTER_FAILED: "IF Condition failed, continuing execution to next step!",
};

export const PASS_COLOR = "#12bf24";
export const FAIL_COLOR = "#e72e2e";
export const INPROGRESS_COLOR = "#32bfff";
export const TOTALCASE_COLOR = "#2808d4";
export const GRAY_COLOR = "#808080";
export const ABORT_COLOR = "#e8bc3a";

export const STEPS = "Step";
export const PRE_CONDITION = "Precondition";
export const LOOP_CONDITION = "Condition";
export const ACTION = "Action";
export const VERIFICATION = "Verification";
export const EXECUTE = "execute";

export const SHARED = "Shared";
export const OWNED = "Owned";
export const MY_PLANS = "My Plans";
export const MY_JOBS = "My Jobs";
export const MY_BUILDS = "My Builds";
export const MY_TEST_BLOCKS = "My TestBlocks";
export const MY_SCENARIOS = "My Scenarios";
export const SHARED_WITH_ME = "Shared with me";
export const All_RECORDS = "All";

export const PROJECT = "projects";
export const TEAMS = "teams";
export const FARMS = "farms";
export const USAGE_REPORT = "UsageReport";
export const DEVICE_USAGE = "deviceUsage";
export const APP_MANAGE = "appManage";
export const FARM = "Farms";
export const DEVICE_USAGE_REPORT = "DeviceUsageReport";
export const APP_MANAGEMENT = "AppManagement";
export const DEVICE_DETAILS = "DeviceDetailsList";
export const SERVER_MANAGEMENT = "Device Server Management";
export const ADMIN_DEVICE_SERVER_MANAGEMENT = "Admin Device Server Management";

export const OPERATION = "OPERATION";
export const ELEMENTS = "ELEMENTS";
export const AI_TARGET_ELEMENT = "AI_TARGET_ELEMENT";
export const POST_OPERATION_SETTINGS = "POST_OPERATION_SETTINGS";
export const VERIFICATION_FOR_TEST_DATA = "VERIFICATION_FOR_TEST_DATA";
export const VERIFY_TEST_DATA = "VERIFY_TEST_DATA";

// Schedule Test Job

export const SCHEDULE_TEST_JOB = {
  STEP1: 1,
  STEP2: 2,
  STEP3: 3,
  STEP4: 4,
  STEP5: 5,
};

export const ADD_MEMBERS = {
  team: "Add Team Members By Email",
  project: "Add Project Members By Email",
};
export const ACTIONS_TAB = {
  ACTIONS: "Actions",
  MANAGE_TEST_DATA: "Manage Test Data",
  POST_ACTION_SETTINGS: "Post Action Settings",
};

export const PLAY_TYPE = {
  ALL_STEPS: "ALL_STEPS",
  PLAY_THIS_STEP: "PLAY_THIS_STEP",
  PLAY_TILL_HERE: "PLAY_TILL_HERE",
  PLAY_FROM_HERE: "PLAY_FROM_HERE",
};
export const DEFAULT_PAGE = 1;
export const RECORD_PER_PAGE = 5;
export const RECORD_10_PER_PAGE = 10;
export const RECORD_15_PER_PAGE = 15;

export const SELECT_ELEMENT = "SELECT_ELEMENT";
export const UNSELECT_ELEMENT = "UNSELECT_ELEMENT";
export const SELECT_HOVERED_ELEMENT = "SELECT_HOVERED_ELEMENT";
export const UNSELECT_HOVERED_ELEMENT = "UNSELECT_HOVERED_ELEMENT";
export const SET_EXPANDED_PATHS = "SET_EXPANDED_PATHS";
export const SET_INTERACTIONS_NOT_AVAILABLE = "SET_INTERACTIONS_NOT_AVAILABLE";
export const SET_SELECTED_ELEMENT_ID = "SET_SELECTED_ELEMENT_ID";

export const SERVER_LIST = "SERVER_LIST";
export const SERVER_DEVICES = "SERVER_DEVICES";
export const ACQUIRE_DEVICE = "ACQUIRE_DEVICE";
export const CONFIGURE_LOCAL_SERVICE_SETTING = "CONFIGURE_LOCAL_SERVICE_SETTING";
export const RELEASE_DEVICE = "RELEASE_DEVICE";

// export const RECORDING_STATUS="RECORDING_STATUS";
export const RECORDING_START = "RECORDING_START";
export const RECORDING_STOP = "RECORDING_STOP";
export const RECORDING_PAUSE = "RECORDING_PAUSE";
export const RECORDING_RESUME = "RECORDING_RESUME";
export const GET_DEVICE_SCREEN = "GET_DEVICE_SCREEN";
export const GET_DEVICE_DETAILS = "GET_DEVICE_DETAILS";
export const TAP_ON_COORDINATES = "TAP_ON_COORDINATES";
export const SAVE_REC_TEST_STEP_DEVICE_INTERACTION = "SAVE_REC_TEST_STEP_DEVICE_INTERACTION";
export const GET_DEVICE_SCREEN_SOURCE = "GET_DEVICE_SCREEN_SOURCE";

export const ACCEPT_INVITATION_REQUEST = "ACCEPT_INVITATION_REQUEST";
export const ADD_ACTION_STEP = "ADD_ACTION_STEP";
export const ADD_VERIFICATION_STEP = "ADD_VERIFICATION_STEP";
export const ADD_LAUNCH_APP_STEP = "ADD_LAUNCH_APP_STEP";
export const ADD_SWIPE_OVER_ELEMENT_STEP = "ADD_SWIPE_OVER_ELEMENT_STEP";
export const SELECTED_STEP = "SELECTED_STEP";
export const UPDATE_STEP_NAME = "UPDATE_STEP_NAME";
export const CHANGE_ELEMENT_ACTION = "CHANGE_ELEMENT_ACTION";
export const UPDATE_ACTION_VALUE = "UPDATE_ACTION_VALUE";
export const CHANGE_TAP_BY_INDEX = "CHANGE_TAP_BY_INDEX";
export const CHANGE_ENTER_TEXT = "CHANGE_ENTER_TEXT";
export const CHANGE_APPEND_RANDOM_TEXT = "CHANGE_APPEND_RANDOM_TEXT";
export const SHOW_TEST_DATA = "SHOW_TEST_DATA";
export const HIDE_TEST_DATA = "HIDE_TEST_DATA";
export const SAVE_TEST_DATA = "SAVE_TEST_DATA";
export const CHANGE_TEST_DATA_APPEND_RANDOM_TEXT = "CHANGE_TEST_DATA_APPEND_RANDOM_TEXT";
export const CHANGE_TEST_DATA_VARIABLE_DEFAULT_VALUE = "CHANGE_TEST_DATA_VARIABLE_DEFAULT_VALUE";
export const CHANGE_TEST_DATA_VARIABLE_NAME = "CHANGE_TEST_DATA_VARIABLE_NAME";
export const CONFIRM_STEP = "CONFIRM_STEP";
export const SELECT_ELEMENT_SCREENSHOT = "SELECT_ELEMENT_SCREENSHOT";
export const CHANGE_SKIP_AFTER_FAIL = "CHANGE_SKIP_AFTER_FAIL";
export const SHOW_ELEMENT_PROPERTIES_MODAL = "SHOW_ELEMENT_PROPERTIES_MODAL";
export const HIDE_ELEMENT_PROPERTIES_MODAL = "HIDE_ELEMENT_PROPERTIES_MODAL";
export const CHANGE_SWIPE_PERCENTAGE = "CHANGE_SWIPE_PERCENTAGE";
export const SWIPE = "SWIPE";
export const CHANGE_VERIFICATION_DROPDOWN_VALUES = "CHANGE_VERIFICATION_DROPDOWN_VALUES";
export const SHOW_NEW_VERIFICATION = "SHOW_NEW_VERIFICATION";
export const HIDE_NEW_VERIFICATION = "HIDE_NEW_VERIFICATION";
export const CHANGE_VERIFICATION_VALUES = "CHANGE_VERIFICATION_VALUES";
export const ADD_VERIFICATION_VALUES = "ADD_VERIFICATION_VALUES";
export const REMOVE_VERIFICATION_VALUES = "REMOVE_VERIFICATION_VALUES";
export const CHANGE_VERIFICATION_VALUE_FOR_REF = "CHANGE_VERIFICATION_VALUE_FOR_REF";
export const CHANGE_ABSENCE_VALUE = "CHANGE_ABSENCE_VALUE";
export const REMOVE_STEP = " REMOVE_STEP";
export const UPDATE_RECORDING_SET_NAME = "UPDATE_RECORDING_SET_NAME";
export const SHOW_MESSAGE = "SHOW_MESSAGE";
export const UPDATE_SESSION_CREATE_STATUS = " UPDATE_SESSION_CREATE_STATUS";
export const ADD_PREVIEW_REQUEST = "ADD_PREVIEW_REQUEST";
export const UPDATE_PREVIEW_REQUEST = "UPDATE_PREVIEW_REQUEST";
export const STARTED_PREVIEW_RESULT = "STARTED_PREVIEW_RESULT";
export const STOPPED_PREVIEW = "STOPPED_PREVIEW";
export const STOP_PREVIEW_REQUEST = "STOP_PREVIEW_REQUEST";
export const UPDATE_PREVIEW_STATUS = "UPDATE_PREVIEW_STATUS";
export const REMOVE_PREVIEW_RESULT = "REMOVE_PREVIEW_RESULT";
export const SAVE_SCENARIO_RECORDING = "SAVE_SCENARIO_RECORDING";
export const UPDATE_SERVER_DETAIL = "UPDATE_SERVER_DETAIL";
export const SHOW_PUSH_BUILD_MODAL = "SHOW_PUSH_BUILD_MODAL";
export const HIDE_PUSH_BUILD_MODAL = "HIDE_PUSH_BUILD_MODAL";
export const CHANGE_BUILD_FILE = "CHANGE_BUILD_FILE";
export const PUSH_BUILD_REQUEST = "PUSH_BUILD_REQUEST";
export const PUSH_BUILD = "PUSH_BUILD";
export const LOGIN = "LOGIN";
export let listOfRouts = [];
export const CHANGE_SELECTED_BUILD_TEST_SCENARIO_TAB = "CHANGE_SELECTED_BUILD_TEST_SCENARIO_TAB";
export const RESET_PREVIOUS_STATE = "RESET_PREVIOUS_STATE";
export const UPDATE_INSTALLED_APP_LIST = "UPDATE_INSTALLED_APP_LIST";
export const UPDATE_INSTALLED_APP_ACTIVITY_LIST = "UPDATE_INSTALLED_APP_ACTIVITY_LIST";
export const REQUESTING_APP_PACKAGE_ACTIVITY = "REQUESTING_APP_PACKAGE_ACTIVITY";
export const SELECT_LAUNCH_APP = "SELECT_LAUNCH_APP";
export const SELECT_LAUNCH_APP_ACTIVITY = "SELECT_LAUNCH_APP_ACTIVITY";
export const CHANGE_REMOVE_APP_PREVIOUS_SESSION = "CHANGE_REMOVE_APP_PREVIOUS_SESSION";
export const ALL_SCENARIOS = "ALL_SCENARIOS";
export const LOAD_SELECTED_SCENARIO = "LOAD_SELECTED_SCENARIO";
export const SHOW_LOAD_SCENARIO_MODAL = "SHOW_LOAD_SCENARIO_MODAL";
export const SAVE_SCENARIO_RECORDING_REQUEST = "SAVE_SCENARIO_RECORDING_REQUEST";
export const CLEAR_PREVIEW_IN_SINGLE_COLUMN_VIEW = "CLEAR_PREVIEW_IN_SINGLE_COLUMN_VIEW";
export const CLEAR_PREVIEW_IN_SINGLE_COLUMN_VIEW_DONE = "CLEAR_PREVIEW_IN_SINGLE_COLUMN_VIEW_DONE";
export const CLEAR_PREVIEW_IN_MULTI_COLUMN_VIEW = "CLEAR_PREVIEW_IN_MULTI_COLUMN_VIEW";
export const CLEAR_PREVIEW_IN_MULTI_COLUMN_VIEW_DONE = "CLEAR_PREVIEW_IN_MULTI_COLUMN_VIEW_DONE";
export const SAVE_SCENARIO_RECORDING_REQUESTED = "SAVE_SCENARIO_RECORDING_REQUESTED";
export const CLEAR_PREVIEW_DONE = "CLEAR_PREVIEW_DONE";
export const DEVICE_DISCONNECT = "DEVICE_DISCONNECT";
export const GET_ROUTE_LIST = "GET_ROUTE_LIST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const GET_CHILD_COMPONENTS = "GET_CHILD_COMPONENTS";
export const GET_CHILD_COMPONENTS_BY_TYPE_PARENT_ID = "GET_CHILD_COMPONENTS_BY_TYPE_PARENT_ID";
export const LOGOUT = "LOGOUT";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const RESET_SCREEN = "RESET_SCREEN";
export const VIEW_NOTIFICATION = "VIEW_NOTIFICATION";
export const START_LOGS_ON_DEVICE = "START_LOGS_ON_DEVICE";
export const STOP_LOGS_ON_DEVICE = "STOP_LOGS_ON_DEVICE";
export const CHANGE_ORIENTATION = "CHANGE_ORIENTATION";
export const HIDE_LOG_MODAL = "HIDE_LOG_MODAL";
export const SHOW_LOG_MODAL = "SHOW_LOG_MODAL";
export const ON_LOG_TYPE_CHANGE = "ON_LOG_TYPE_CHANGE";
export const HIDE_ORIENTATION_MODAL = "HIDE_ORIENTATION_MODAL";
export const SHOW_ORIENTATION_MODAL = "SHOW_ORIENTATION_MODAL";
export const ON_ORIENTATION_CHANGE = "ON_ORIENTATION_CHANGE";
export const UPDATE_SWIPE_COORDINATES = "UPDATE_SWIPE_COORDINATES";
export const TAP_ON_COORDINATES_REQUESTED = "TAP_ON_COORDINATES_REQUESTED";
export const OPEN_RECENT_APP_TRAY = "OPEN_RECENT_APP_TRAY";
export const RESTART_DEVICE_REQUESTED = "RESTART_DEVICE_REQUESTED";
export const RESTART_DEVICE = "RESTART_DEVICE";
export const CHECK_USER_NAME_EXISTS = "CHECK_USER_NAME_EXISTS";
export const SIGN_UP = "SIGN_UP";
export const SIGN_UP_FAIL = "SIGN_UP_FAIL";
export const VERIFY_SIGNUP_REQUEST = "VERIFY_SIGNUP_REQUEST";
export const CHANGE_SIGNUP_FIRST_NAME = "CHANGE_SIGNUP_FIRST_NAME";
export const CHANGE_SIGNUP_FULL_NAME = "CHANGE_SIGNUP_FULL_NAME";
export const CHANGE_SIGNUP_LAST_NAME = "CHANGE_SIGNUP_LAST_NAME";
export const CHANGE_SIGNUP_USER_NAME = "CHANGE_SIGNUP_USER_NAME";
export const CHANGE_SIGNUP_EMAIL = "CHANGE_SIGNUP_EMAIL";
export const CHANGE_SIGNUP_PASSWORD = "CHANGE_SIGNUP_PASSWORD";
export const SET_SIGNUP_ERROR = "SET_SIGNUP_ERROR";
export const SIGNUP_REQUEST = "SIGNUP_REQUEST";
export const SET_PASSWORD_VALIDATE_ERROR = "SET_PASSWORD_VALIDATE_ERROR";
export const CURRENT_SCREEN_ORIENTATION = "CURRENT_SCREEN_ORIENTATION";
export const FAILED_START_LOGS_ON_DEVICE = "FAILED_START_LOGS_ON_DEVICE";
export const FORCE_STOP_LOGS_ON_DEVICE = "FORCE_STOP_LOGS_ON_DEVICE";
export const FAILED_TO_ROTATE_SCREEN = "FAILED_TO_ROTATE_SCREEN";
export const UPDATE_DEVICE_MODAL = "UPDATE_DEVICE_MODAL";
export const RELEASE_DEVICE_BY_ID = "RELEASE_DEVICE_BY_ID";
export const INVALID_USER = "INVALID_USER";
export const CHANGE_LOGIN_USER_NAME = "CHANGE_LOGIN_USER_NAME";
export const CHANGE_LOGIN_PASSWORD = "CHANGE_LOGIN_PASSWORD";
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOG_START_REQUEST = "LOG_START_REQUEST";
export const ALERT_USER = "ALERT_USER";
export const ALERT_CANCEL = "ALERT_CANCEL";
export const CHECK_AND_RESTART_ANDROID_NODE_SERVICE_REQUESTED = "CHECK_AND_RESTART_ANDROID_NODE_SERVICE_REQUESTED";
export const CHECK_AND_RESTART_ANDROID_NODE_SERVICE_REQUEST = "CHECK_AND_RESTART_ANDROID_NODE_SERVICE_REQUEST";
export const CHANGE_LOGIN_USER_EMAIL = "CHANGE_LOGIN_USER_EMAIL";
export const CHANGE_LOGIN_USER_TOKEN = "CHANGE_LOGIN_USER_TOKEN";
export const GENERATE_TOKEN = "GENERATE_TOKEN";
export const CHECK_USER_EMAIL_EXISTS = "CHECK_USER_EMAIL_EXISTS";
export const RECORDING_START_REQUEST = "RECORDING_START_REQUEST";
export const RECORDING_STOP_REQUEST = "RECORDING_STOP_REQUEST";
export const FAILED_UPDATE_PREVIEW_REQUEST = "FAILED_UPDATE_PREVIEW_REQUEST";
export const DEVICE_STATE = "DEVICE_STATE";
export const ACQUIRE_RELEASE_DEVICE_REQUEST = "ACQUIRE_RELEASE_DEVICE_REQUEST";
export const GENERATE_TOKEN_REQUEST = "GENERATE_TOKEN_REQUEST";
export const SHOW_INPUT_TEXT_MODAL = "SHOW_INPUT_TEXT_MODAL";
export const HIDE_INPUT_TEXT_MODAL = "HIDE_INPUT_TEXT_MODAL";
export const UPDATE_INPUT_TEXT = "UPDATE_INPUT_TEXT";
export const CURRENT_DEVICE_EDIT_NOTE_UDID = "CURRENT_DEVICE_EDIT_NOTE_UDID";
export const CHANGE_CURRENT_DEVICE_NOTE = "CHANGE_CURRENT_DEVICE_NOTE";
export const SHOW_RESTART_DEVICE_MODAL = "SHOW_RESTART_DEVICE_MODAL";
export const HIDE_RESTART_DEVICE_MODAL = "HIDE_RESTART_DEVICE_MODAL";
export const HIDE_DEVICE_NOTE_MODAL = "HIDE_DEVICE_NOTE_MODAL";
export const CURRENT_DEVICE_NOTE_HISTORY = "CURRENT_DEVICE_NOTE_HISTORY";
export const UPDATE_DEVICE_NOTE = "UPDATE_DEVICE_NOTE";
export const UPDATE_STATUS_CHECK_AND_RESTART_ANDROID_NODE_SERVICE =
  "UPDATE_STATUS_CHECK_AND_RESTART_ANDROID_NODE_SERVICE";
export const ENTER_OTP = "ENTER_OTP";
export const ENTER_OTP_OFF = "ENTER_OTP_OFF";
export const CHANGE_LOGIN_OTP = "CHANGE_LOGIN_OTP";
export const FAILED_GENERATE_TOKEN = "FAILED_GENERATE_TOKEN";
export const SIGN_UP_VERIFICATION_CONFIRMED = "SIGN_UP_VERIFICATION_CONFIRMED";
export const PASSWORD_INPUT = "PASSWORD_INPUT";
export const SHOW_USER_DETAILS = "SHOW_USER_DETAILS";
export const LOGGED_IN_USER_DETAILS = "LOGGED_IN_USER_DETAILS";
export const DEVICE_FARM_LIST = "DEVICE_FARM_LIST";
export const INSERT_TEXT_REQUESTED = "INSERT_TEXT_REQUESTED";
export const INSERT_TEXT_REQUEST = "INSERT_TEXT_REQUEST";
export const UPDATE_TAKE_STEP_SCREENSHOT = "UPDATE_TAKE_STEP_SCREENSHOT";
export const DOWNLOAD_PREVIEW_STEP_IMAGES = "DOWNLOAD_PREVIEW_STEP_IMAGES";
export const DOWNLOAD_PREVIEW_STEP_IMAGES_REQUEST = "DOWNLOAD_PREVIEW_STEP_IMAGES_REQUEST";
export const DOWNLOAD_PREVIEW_STEP_IMAGES_REQUESTED = "DOWNLOAD_PREVIEW_STEP_IMAGES_REQUESTED";
export const RECORDING_START_REQUEST_FAILED = "RECORDING_START_REQUEST_FAILED";
export const FAST_INPUT_ICON_INDICATION = "FAST_INPUT_ICON_INDICATION";
export const STOP_FAST_INPUT_INDICATION = "STOP_FAST_INPUT_INDICATION";
export const LANDSCAPE = "Landscape";
export const PORTRAIT = "Portrait";
export const STOPPED_PREVIEW_LOADER = "STOPPED_PREVIEW_LOADER";
export const ADD_NEW_SCENARIO = "ADD_NEW_SCENARIO";
export const BACK_TO_SCENARIO_LIST = "BACK_TO_SCENARIO_LIST";
export const EDIT_SCENARIO = "EDIT_SCENARIO";
export const TABLE_EXECUTE_BUTTON_LOADER = "TABLE_EXECUTE_BUTTON_LOADER";
export const TABLE_EXECUTE_BUTTON_LOADER_STOP = "TABLE_EXECUTE_BUTTON_LOADER_STOP";
export const LOAD_SELECTED_SCENARIO_FOR_EXECUTION = "LOAD_SELECTED_SCENARIO_FOR_EXECUTION";
export const CLEAR_EXECUTION_DATA = "CLEAR_EXECUTION_DATA";
export const ADD_EXECUTION_REQUEST = "ADD_EXECUTION_REQUEST";
export const UPDATE_EXECUTION_REQUEST = "UPDATE_EXECUTION_REQUEST";
export const STOP_EXECUTION_LOADER = "STOP_EXECUTION_LOADER";
export const STARTED_EXECUTION = "STARTED_EXECUTION";
export const FAILED_UPDATE_EXECUTION_REQUEST = "FAILED_UPDATE_EXECUTION_REQUEST";
export const CLEAR_EXECUTION_DATA_DONE = "CLEAR_EXECUTION_DATA_DONE";
export const START_EXECUTE_SCREEN = "START_EXECUTE_SCREEN";
export const STOPPED_EXECUTION = "STOPPED_EXECUTION";
export const STOP_EXECUTION_REQUEST = "STOP_EXECUTION_REQUEST";
export const UPDATE_EXECUTION_RESULT = "UPDATE_EXECUTION_RESULT";
export const CHANGE_SELECTED_EXECUTION_PANEL_TAB = "CHANGE_SELECTED_EXECUTION_PANEL_TAB";
export const HIDE_CLONE_REC_TEST_SCENARIO_CONFIRMATION_MODAL = "HIDE_CLONE_REC_TEST_SCENARIO_CONFIRMATION_MODAL";
export const SHOW_CLONE_REC_TEST_SCENARIO_CONFIRMATION_MODAL = "SHOW_CLONE_REC_TEST_SCENARIO_CONFIRMATION_MODAL";
export const CLONE_REC_TEST_SCENARIO = "CLONE_REC_TEST_SCENARIO";
export const FIND_ELEMENT_REQUEST = " FIND_ELEMENT_REQUEST";
export const FIND_ELEMENT_REQUESTED = " FIND_ELEMENT_REQUESTED";
export const INSERT_CUSTOM_TEXT_VERIFICATION_ROW = "INSERT_CUSTOM_TEXT_VERIFICATION_ROW";
export const REMOVE_CUSTOM_TEXT_VERIFICATION_ROW = "REMOVE_CUSTOM_TEXT_VERIFICATION_ROW";
export const CHANGE_CUSTOM_TEXT_VERIFICATION_VALUE_FOR_REF = "CHANGE_CUSTOM_TEXT_VERIFICATION_VALUE_FOR_REF";
export const ADD_CUSTOM_TEXT_VERIFICATION_VALUES = "ADD_CUSTOM_TEXT_VERIFICATION_VALUES";
export const CHANGE_CUSTOM_TEXT_VERIFICATION_DROPDOWN_VALUES = "CHANGE_CUSTOM_TEXT_VERIFICATION_DROPDOWN_VALUES";
export const CHANGE_CUSTOM_TEXT_VERIFICATION_VALUES = "CHANGE_CUSTOM_TEXT_VERIFICATION_VALUES";
export const UPDATE_CUSTOM_REF_TYPE_VALUE = "UPDATE_CUSTOM_REF_TYPE_VALUE";
export const UPDATE_CUSTOM_REF_TEXT_VALUE = "UPDATE_CUSTOM_REF_TEXT_VALUE";
export const UPDATE_CUSTOM_REF_OPTION_VALUE = "UPDATE_CUSTOM_REF_TEXT_VALUE";
export const RESET_CUSTOM_REF_LIST = "RESET_CUSTOM_REF_LIST";
export const TOGGLE_ELEMENT_PROPERTIES_MODAL = "TOGGLE_ELEMENT_PROPERTIES_MODAL";
export const CONFIRM_STEP_REQUEST = "CONFIRM_STEP_REQUEST";
export const CONFIRM_STEP_REQUESTED = "CONFIRM_STEP_REQUESTED";
export const STOP_PREVIEW_REQUESTED = "STOP_PREVIEW_REQUESTED";
export const STOP_EXECUTION_REQUESTED = "STOP_EXECUTION_REQUESTED";
export const UPDATE_VERIFICATION_KEY = "UPDATE_VERIFICATION_KEY";
export const UPDATE_VERIFICATION_VALUE = "UPDATE_VERIFICATION_VALUE";
export const UPDATE_REFERENCE_STEP_VALUE = "UPDATE_REFERENCE_STEP_VALUE";
export const UPDATE_REFERENCE_STEP_TEST_DATA = "UPDATE_REFERENCE_STEP_TEST_DATA";
export const ADD_VERIFICATION_ROW = "ADD_VERIFICATION_ROW";
export const REMOVE_VERIFICATION_ROW = "REMOVE_VERIFICATION_ROW";
export const OPERATOR_CHANGE = "OPERATOR_CHANGE";
export const REMOVE_STEP_REQUEST = "REMOVE_STEP_REQUEST";
export const REMOVE_STEP_REQUESTED = "REMOVE_STEP_REQUESTED";
export const LOADING_SCENARIO_FOR_EDIT = "LOADING_SCENARIO_FOR_EDIT";
export const FLUSH_THE_RECORDING_DATA = "FLUSH_THE_RECORDING_DATA";
export const LOADING_SCENARIO_LIST = "LOADING_SCENARIO_LIST";
export const RESET_ELEMENT_SELECTION = "RESET_ELEMENT_SELECTION";
export const REMOVE_BLANK_STEP = "REMOVE_BLANK_STEP";
export const SHOW_UNSAVED_STEP_MODAL_VISIBLE = "SHOW_UNSAVED_STEP_MODAL_VISIBLE";
export const ON_CANCEL_UNSAVED_STEP_MODAL_VISIBLE = "ON_CANCEL_UNSAVED_STEP_MODAL_VISIBLE";
export const ALL_APP_BUILD_DETAILS = "ALL_APP_BUILD_DETAILS";
export const ALL_APP_BUILD_DETAILS_FAILED = "ALL_APP_BUILD_DETAILS_FAILED";
export const SHOW_ADD_APP_BUILD_MENU = "SHOW_ADD_APP_BUILD_MENU";
export const CHANGE_SHARED_IN_ADD_BUILD = "CHANGE_SHARED_IN_ADD_BUILD";
export const CHANGE_IS_LATEST_IN_ADD_BUILD = "CHANGE_IS_LATEST_IN_ADD_BUILD";
export const SET_ADD_APP_BUILD_DETAILS_FORM_ERROR = "SET_ADD_APP_BUILD_DETAILS_FORM_ERROR";
export const BACK_TO_APP_MANAGEMENT = "BACK_TO_APP_MANAGEMENT";
export const SELECT_APP_BUILD_FARM_LIST_OPTION = "SELECT_APP_BUILD_FARM_LIST_OPTION";
export const CHANGE_PLATFORM_TYPE_IN_ADD_BUILD = "CHANGE_PLATFORM_TYPE_IN_ADD_BUILD";
export const SET_BUILD_FILE_AND_FILE_NAME = "SET_BUILD_FILE_AND_FILE_NAME";
export const ALL_APP_BUILD_DETAILS_FOR_FARM_AND_DEVICE = "ALL_APP_BUILD_DETAILS_FOR_FARM_AND_DEVICE";
export const ALL_APP_BUILD_DETAILS_FOR_FARM_AND_DEVICE_FAILED = "ALL_APP_BUILD_DETAILS_FOR_FARM_AND_DEVICE_FAILED";
export const CHANGE_BUILD_TYPE_IN_ADD_BUILD = "CHANGE_BUILD_TYPE_IN_ADD_BUILD";
export const CHANGE_BUILD_VERSION_IN_ADD_BUILD = "CHANGE_BUILD_VERSION_IN_ADD_BUILD";
export const CHANGE_BUILD_DATE_TIME_IN_ADD_BUILD = "CHANGE_BUILD_DATE_TIME_IN_ADD_BUILD";
export const UPLOAD_BUILD_REQUEST_COMPLETE = "UPLOAD_BUILD_REQUEST_COMPLETE";
export const UPLOAD_BUILD_REQUEST = "UPLOAD_BUILD_REQUEST";
export const UPLOAD_BUILD_REQUEST_FAILED = "UPLOAD_BUILD_REQUEST_FAILED";
export const INSTALL_APP_IN_DEVICE_FROM_SFTP_REQUEST = "INSTALL_APP_IN_DEVICE_FROM_SFTP_REQUEST";
export const INSTALLED_APP_IN_DEVICE_FROM_SFTP = "INSTALLED_APP_IN_DEVICE_FROM_SFTP";
export const SELECT_THE_BUILD_TO_INSTALL = "SELECT_THE_BUILD_TO_INSTALL";
export const ADDING_BUILD_ALREADY_EXIST = "ADDING_BUILD_ALREADY_EXIST";
export const OVERWRITE_UPLOAD_BUILD_REQUEST = "OVERWRITE_UPLOAD_BUILD_REQUEST";
export const OVERWRITE_UPLOAD_BUILD_REQUEST_FAILED = "OVERWRITE_UPLOAD_BUILD_REQUEST_FAILED";
export const OVERWRITE_UPLOAD_BUILD_REQUEST_COMPLETE = "OVERWRITE_UPLOAD_BUILD_REQUEST_COMPLETE";
export const SEARCH_QUERY_RESULT_APP_DETAILS = "SEARCH_QUERY_RESULT_APP_DETAILS";
export const SEARCH_QUERY_APP_DETAILS_REQUEST = "SEARCH_QUERY_APP_DETAILS_REQUEST";
export const SEARCH_QUERY_APP_DETAILS_FAILED = "SEARCH_QUERY_APP_DETAILS_FAILED";
export const SEARCH_QUERY_APP_DETAILS_COMPLETED = "SEARCH_QUERY_APP_DETAILS_COMPLETED";
export const INSTALL_APP_IN_DEVICE_FROM_SFTP_REQUEST_FAILED = "INSTALL_APP_IN_DEVICE_FROM_SFTP_REQUEST_FAILED";
export const SHOW_INSTALL_APP_BUILD_MODAL = "SHOW_INSTALL_APP_BUILD_MODAL";
export const HIDE_INSTALL_APP_MODAL = "HIDE_INSTALL_APP_MODAL";
export const UPLOAD_BUILD_PROGRESS = "UPLOAD_BUILD_PROGRESS";
export const DOWNLOAD_BUILD_PROGRESS = "DOWNLOAD_BUILD_PROGRESS";
export const DEVICE_SESSION_LOGS_STREAM = "DEVICE_SESSION_LOGS_STREAM";
export const DEVICE_SESSION_LOGS_STREAM_REQUEST = "DEVICE_SESSION_LOGS_STREAM_REQUEST";
export const DEVICE_SESSION_LOGS_STREAM_STARTED = "DEVICE_SESSION_LOGS_STREAM_STARTED";
export const DEVICE_SESSION_LOGS_STREAM_FAILED = "DEVICE_SESSION_LOGS_STREAM_FAILED";
export const DEVICE_SESSION_LOGS_STREAM_STOPPING_REQUEST = "DEVICE_SESSION_LOGS_STREAM_STOPPING_REQUEST";
export const DEVICE_SESSION_LOGS_STREAM_STOPPED = "DEVICE_SESSION_LOGS_STREAM_STOPPED";
export const DEVICE_SESSION_LOGS_STREAM_STOPPING_FAILED = "DEVICE_SESSION_LOGS_STREAM_STOPPING_FAILED";
export const CHANGE_DEVICE_SESSION_LOGTYPE = "CHANGE_DEVICE_SESSION_LOGTYPE";
export const CLEAR_THE_DEVICE_SESSION_LOGS = "CLEAR_THE_DEVICE_SESSION_LOGS";
export const LIST_OFF_APPS_INSTALLED_FOR_LOGS = "LIST_OFF_APPS_INSTALLED_FOR_LOGS";
export const CHANGE_APP_FOR_DEVICE_LOGS = "CHANGE_APP_FOR_DEVICE_LOGS";
export const FLUSH_THE_DASHBOARD_ATTRIBUTE = "FLUSH_THE_DASHBOARD_ATTRIBUTE";
export const UPDATE_CLIPBOARD_DATA = "UPDATE_CLIPBOARD_DATA";
export const ALL_BROWSER_INSTALLED_IN_DEVICE = "ALL_BROWSER_INSTALLED_IN_DEVICE";
export const CHANGE_URL_FOR_DEVICE_BROWSER_NAVIGATION = "CHANGE_URL_FOR_DEVICE_BROWSER_NAVIGATION";
export const SET_SPLITTER_DRAGGED = "SET_SPLITTER_DRAGGED";
export const DISABLE_KEYBOARD_IN_DEVICE = "DISABLE_KEYBOARD_IN_DEVICE";
export const PROXY_STOPPED = "PROXY_STOPPED";
export const PROXY_STARTED = "PROXY_STARTED";
export const PROXY_START_REQUEST = "PROXY_START_REQUEST";
export const PROXY_STOP_REQUEST = "PROXY_STOP_REQUEST";
export const PROXY_CLEAR_DATA = "PROXY_CLEAR_DATA";
export const INVALID_USER_PERMISSION_MESSAGE_FROM_API = "Invalid User Action Permission Denied!";
export const GET_PROXY_STREAMING_DATA = "GET_PROXY_STREAMING_DATA";
export const DEVICE_USAGE_REPORT_DATA = "DEVICE_USAGE_REPORT_DATA";
export const SEARCH_DEVICE_USAGE_REPORT_FROM_KEYWORD_REQUEST = "SEARCH_DEVICE_USAGE_REPORT_FROM_KEYWORD_REQUEST";
export const SEARCH_DEVICE_USAGE_REPORT_FROM_KEYWORD_REQUEST_FAILED =
  "SEARCH_DEVICE_USAGE_REPORT_FROM_KEYWORD_REQUEST_FAILED";
export const INVALID_USER_MESSAGE_FROM_API = "INVALID_USER_MESSAGE_FROM_API";
export const UPDATE_STATUS_CHECK_AND_RESTART_IOS_RECORDING_SERVICE =
  "UPDATE_STATUS_CHECK_AND_RESTART_IOS_RECORDING_SERVICE";
export const DEVICE_LOADED = "DEVICE_LOADED";
export const CHANGE_APP_BUILD_LIST_PAGE_NUMBER = "CHANGE_APP_BUILD_LIST_PAGE_NUMBER";
export const CHANGE_INSTALL_PAGE_APP_BUILD_LIST_PAGE_NUMBER = "CHANGE_INSTALL_PAGE_APP_BUILD_LIST_PAGE_NUMBER";
export const CHANGE_SEARCH_TEXT_FOR_SEARCH_QUERY_INSTALL_APP = "CHANGE_SEARCH_TEXT_FOR_SEARCH_QUERY_INSTALL_APP";
export const APP_BUILD_DETAILS_BY_ID = "APP_BUILD_DETAILS_BY_ID";
export const RESET_APP_BUILD_DETAILS_BY_ID = "RESET_APP_BUILD_DETAILS_BY_ID";
export const RESET_DEVICE_FARM_LIST = "RESET_DEVICE_FARM_LIST";

/**SAVE SSO Status */
export const SAVE_SSO_CONFIG = "SAVE_SSO_CONFIG";
export const GET_SSO_CONFIG = "GET_SSO_CONFIG";
export const HANDLE_CERT = "HANDLE_CERT";
export const HANDLE_ISSUE_URL = "HANDLE_ISSUE_URL";
export const HANDLE_SIGN_ON_URL = "HANDLE_SIGN_ON_URL";
// export const HANDLE_SIGN_ON_LOGOUT_REQUEST_URL = "HANDLE_SIGN_ON_LOGOUT_REQUEST_URL";
export const HANDLE_SIGN_ON_LOGOUT_SERVICE_URL = "HANDLE_SIGN_ON_LOGOUT_SERVICE_URL";
export const LIST_OFF_APPS_INSTALLED_FOR_DEFAULT_APP = "LIST_OFF_APPS_INSTALLED_FOR_DEFAULT_APP";
export const LIST_OF_DEVICE_DEFAULT_APPS = "LIST_OF_DEVICE_DEFAULT_APPS";
export const SHOW_SET_DEFAULT_APP_MODAL = "SHOW_SET_DEFAULT_APP_MODAL";
export const HIDE_SET_DEFAULT_APP_MODAL = "HIDE_SET_DEFAULT_APP_MODAL";
export const CHANGE_THE_SELECTED_APP_IN_DEFAULT_SET = "CHANGE_THE_SELECTED_APP_IN_DEFAULT_SET";
export const SAVE_DEFAULT_APPS_REQUEST_FOR_DEVICE = "SAVE_DEFAULT_APPS_REQUEST_FOR_DEVICE";
export const SAVE_DEFAULT_APPS_REQUEST_FOR_DEVICE_SUCCESS = "SAVE_DEFAULT_APPS_REQUEST_FOR_DEVICE_SUCCESS";
export const SAVE_DEFAULT_APPS_REQUEST_FOR_DEVICE_FAILED = "SAVE_DEFAULT_APPS_REQUEST_FOR_DEVICE_FAILED";
export const CHANGE_DELETED_DEFAULT_APP_LIST = "CHANGE_DELETED_DEFAULT_APP_LIST";
export const SAVE_DEFAULT_APPS_DELETE_REQUEST_FOR_DEVICE = "SAVE_DEFAULT_APPS_DELETE_REQUEST_FOR_DEVICE";
export const SAVE_DEFAULT_APPS_DELETE_REQUEST_FOR_DEVICE_SUCCESS =
  "SAVE_DEFAULT_APPS_DELETE_REQUEST_FOR_DEVICE_SUCCESS";
export const SAVE_DEFAULT_APPS_DELETE_REQUEST_FOR_DEVICE_FAILED = "SAVE_DEFAULT_APPS_DELETE_REQUEST_FOR_DEVICE_FAILED";
export const SET_DEVICE_CLEAN_UP_ON_SESSION_OPEN = "SET_DEVICE_CLEAN_UP_ON_SESSION_OPEN";
export const RESET_SSO_FORM_FILLED = "RESET_SSO_FORM_FILLED";
export const VALIDATE_USER_SIGNUP_SSO = "VALIDATE_USER_SIGNUP_SSO";
export const VALIDATE_USER_SIGNUP_SSO_REQUEST = "VALIDATE_USER_SIGNUP_SSO_REQUEST";
export const USER_SIGNUP_SSO_BACK = "USER_SIGNUP_SSO_BACK";
export const VALIDATE_USER_LOGIN_SSO_REQUEST = "VALIDATE_USER_LOGIN_SSO_REQUEST";
export const VALIDATE_USER_LOGIN_SSO = "VALIDATE_USER_LOGIN_SSO";
export const USER_LOGIN_SSO_BACK = "USER_LOGIN_SSO_BACK";
export const SAVE_SSO_CONFIGURATION_REQUEST = "SAVE_SSO_CONFIGURATION_REQUEST";
export const SAVE_SSO_CONFIGURATION_REQUEST_FAILED = "SAVE_SSO_CONFIGURATION_REQUEST_FAILED";
export const DEVICE_USAGE_REPORT_DATA_REQUEST = "DEVICE_USAGE_REPORT_DATA_REQUEST";
export const DEVICE_USAGE_REPORT_DATA_REQUEST_FAILED = "DEVICE_USAGE_REPORT_DATA_REQUEST_FAILED";
export const GET_APP_MANAGER_BUILD_DETAILS_REQUEST = "GET_APP_MANAGER_BUILD_DETAILS_REQUEST";
export const ALL_APP_BUILD_DETAILS_FOR_FARM_AND_DEVICE_REQUEST = "ALL_APP_BUILD_DETAILS_FOR_FARM_AND_DEVICE_REQUEST";
export const ERROR_ALL_BROWSER_INSTALLED_IN_DEVICE = "ERROR_ALL_BROWSER_INSTALLED_IN_DEVICE";
export const ENABLE_LOGOUT_SERVICE = "ENABLE_LOGOUT_SERVICE";
export const ADD_REC_TEST_STEP = "ADD_REC_TEST_STEP";
export const ADD_REC_TEST_STEP_FOR_MULTI_COLUMN_VIEW = "ADD_REC_TEST_STEP_FOR_MULTI_COLUMN_VIEW";
export const UPDATE_GROUP_ACTION_VALUE = " UPDATE_GROUP_ACTION_VALUE";
export const SET_SELECTED_GROUP = "SET_SELECTED_GROUP";
export const LOAD_RECORDING_SCREEN_STATUS = "LOAD_RECORDING_SCREEN_STATUS";
export const BACK_PREVIOUS_STEP = "BACK_PREVIOUS_STEP";
export const UPDATE_BREAD_SCRUM = "UPDATE_BREAD_SCRUM";
export const SET_CURRENT_GROUP_ON_BREAD_CRUMB_CLICK = "SET_CURRENT_GROUP_ON_BREAD_CRUMB_CLICK";
export const RECORDING_SET_NAME = "RECORDING_SET_NAME";
export const SET_SELECTED_PREVIEW_GROUP = "SET_SELECTED_PREVIEW_GROUP";
export const SET_CURRENT_PREVIEW_GROUP_ON_BREAD_CRUMB_CLICK = "SET_CURRENT_PREVIEW_GROUP_ON_BREAD_CRUMB_CLICK";
export const BACK_PREVIOUS_PREVIEW_STEP = "BACK_PREVIOUS_PREVIEW_STEP";
export const SET_CURRENT_RECORDING_GROUP_FROM_URL = "SET_CURRENT_RECORDING_GROUP_FROM_URL";
export const SET_CURRENT_EXECUTE_GROUP_FROM_URL = "SET_CURRENT_EXECUTE_GROUP_FROM_URL";
export const SET_DEVICE_INTERVAL_ID = "SET_DEVICE_INTERVAL_ID";
export const REMOVE_DEVICE_INTERVAL_ID = "SET_DEVICE_INTERVAL_ID";
export const CHANGE_SHELL_COMMAND_FOR_DEVICE = "CHANGE_SHELL_COMMAND_FOR_DEVICE";
export const SAVE_SHELL_COMMAND_OUTPUT = "SAVE_SHELL_COMMAND_OUTPUT";
export const RESET_SHELL_COMMAND_OUTPUT = "RESET_SHELL_COMMAND_OUTPUT";
export const ADD_PREVIEW_REQUESTED = "ADD_PREVIEW_REQUESTED";
export const UPDATE_RECORDING_SCREEN_STATUS = "UPDATE_RECORDING_SCREEN_STATUS";
export const UPDATE_CLOSE_LAUNCH_APP_PREVIEW_SESSION = "UPDATE_CLOSE_LAUNCH_APP_PREVIEW_SESSION";
export const CHANGE_SWIPE_DIRECTION_OVER_ELEMENT = "CHANGE_SWIPE_DIRECTION_OVER_ELEMENT";
export const CHANGE_SWIPE_PERCENTAGE_OVER_ELEMENT = "CHANGE_SWIPE_PERCENTAGE_OVER_ELEMENT";
export const CHANGE_PAUSE_TIME = "CHANGE_PAUSE_TIME";
export const CHANGE_IS_CAPTURE_SCREEN_SHOT = "CHANGE_IS_CAPTURE_SCREEN_SHOT";
export const CHANGE_CAPTURE_SCREEN_SHOT_DELAY_TIME = "CHANGE_CAPTURE_SCREEN_SHOT_DELAY_TIME";
export const CHANGE_IS_LOOP_STEP = "CHANGE_IS_LOOP_STEP";
export const TEST_PLAN_LIST = "TEST_PLAN_LIST";
export const FETCHING_TEST_PLAN_LIST = "FETCHING_TEST_PLAN_LIST";
export const SHOW_SCHEDULE_TEST_PLAN_MODAL = "SHOW_SCHEDULE_TEST_PLAN_MODAL";
export const HIDE_SCHEDULE_TEST_PLAN_MODAL = "HIDE_SCHEDULE_TEST_PLAN_MODAL";
export const FETCHING_TEST_PLAN_RECORDER_SCENARIO = "FETCHING_TEST_PLAN_RECORDER_SCENARIO";
export const TEST_PLAN_RECORDED_TEST_SCENARIO_LIST = "TEST_PLAN_RECORDED_TEST_SCENARIO_LIST";
export const GET_TEST_PLAN_BY_ID = "GET_TEST_PLAN_BY_ID";
export const CHANGE_TEST_PLAN_EXECUTE_COUNT = "CHANGE_TEST_PLAN_EXECUTE_COUNT";
export const FETCHING_TEST_PLAN_RESULT_LIST = "FETCHING_TEST_PLAN_RESULT_LIST";
export const TEST_PLAN_RESULT_LIST = "TEST_PLAN_RESULT_LIST";
export const CHANGE_SCENARIO_LIST_FILTER = "CHANGE_SCENARIO_LIST_FILTER";
export const FETCHING_REC_TEST_SCENARIO_LIST = "FETCHING_REC_TEST_SCENARIO_LIST";
export const REC_TEST_SCENARIO_LIST = "REC_TEST_SCENARIO_LIST";
export const REC_TEST_SCENARIO_LIST_BY_PROJECT_ID = "REC_TEST_SCENARIO_LIST_BY_PROJECT_ID";
export const SHOW_DEVICE_SELECTION_MODAL = "SHOW_DEVICE_SELECTION_MODAL";
export const HIDE_DEVICE_SELECTION_MODAL = "HIDE_DEVICE_SELECTION_MODAL";
export const SHOW_DELETE_REC_TEST_SCENARIO_CONFIRMATION_MODAL = "SHOW_DELETE_REC_TEST_SCENARIO_CONFIRMATION_MODAL";
export const HIDE_DELETE_REC_TEST_SCENARIO_CONFIRMATION_MODAL = "HIDE_DELETE_REC_TEST_SCENARIO_CONFIRMATION_MODAL";
export const SELECTED_REC_TEST_SCENARIO_ID_UNDEFINED = "SELECTED_REC_TEST_SCENARIO_ID_UNDEFINED";
export const CHANGE_FREE_DEVICE_FOR_RECORDING = "CHANGE_FREE_DEVICE_FOR_RECORDING";
export const SEARCH_SCENARIO_KEYWORD = "SEARCH_SCENARIO_KEYWORD";
export const REQUEST_ADD_NEW_SCENARIO = "REQUEST_ADD_NEW_SCENARIO";
export const REQUESTED_ADD_NEW_SCENARIO = "REQUESTED_ADD_NEW_SCENARIO";
export const REQUEST_LOAD_SCENARIO = "REQUEST_LOAD_SCENARIO";
export const REQUESTED_LOAD_SCENARIO = "REQUESTED_LOAD_SCENARIO";
export const REC_TEST_STEP_LIST = "REC_TEST_STEP_LIST";
export const SCENARIO_DETAIL_FOR_EDIT = "SCENARIO_DETAIL_FOR_EDIT";
export const TEST_BLOCK_REC_TEST_STEP_LIST = "TEST_BLOCK_REC_TEST_STEP_LIST";
export const FETCHING_TEST_STEP_LIST = "FETCHING_TEST_STEP_LIST";
export const FETCHED_TEST_STEP_LIST = "FETCHED_TEST_STEP_LIST";
export const LOAD_REC_STEP_DETAIL = "LOAD_REC_STEP_DETAIL";
export const LOAD_REC_STEP_ACTION_DETAIL = "LOAD_REC_STEP_ACTION_DETAIL";
export const ADD_REC_TEST_STEP_ACTION_SINGLE_COLUMN_VIEW = "ADD_REC_TEST_STEP_ACTION_SINGLE_COLUMN_VIEW";
export const ADD_REC_TEST_STEP_ACTION_MULTI_COLUMN_VIEW = "ADD_REC_TEST_STEP_ACTION_MULTI_COLUMN_VIEW";
export const UPDATE_REC_TEST_STEP_MULTI_COLUMN_VIEW = "UPDATE_REC_TEST_STEP_MULTI_COLUMN_VIEW";
export const UPDATE_REC_TEST_STEP_SINGLE_COLUMN_VIEW = "UPDATE_REC_TEST_STEP_SINGLE_COLUMN_VIEW";
export const UPDATE_REC_ELEMENT_DETAIL_IN_REC_TEST_STEP_SINGLE_COLUMN_VIEW =
  "UPDATE_REC_ELEMENT_DETAIL_IN_REC_TEST_STEP_SINGLE_COLUMN_VIEW";
export const UPDATE_REC_ELEMENT_DETAIL_IN_REC_TEST_STEP_MULTI_COLUMN_VIEW =
  "UPDATE_REC_ELEMENT_DETAIL_IN_REC_TEST_STEP_MULTI_COLUMN_VIEW";
export const SHOW_DELETE_REC_TEST_STEP_CONFIRMATION_MODAL = "SHOW_DELETE_REC_TEST_STEP_CONFIRMATION_MODAL";
export const HIDE_DELETE_REC_TEST_STEP_CONFIRMATION_MODAL = "HIDE_DELETE_REC_TEST_STEP_CONFIRMATION_MODAL";
export const SELECT_CHILD_ELEMENT_PURPOSE = "SELECT_CHILD_ELEMENT_PURPOSE";
export const SHOW_DELETE_REC_ELEMENT_CONFIRMATION_MODAL = "SHOW_DELETE_REC_ELEMENT_CONFIRMATION_MODAL";
export const HIDE_DELETE_REC_ELEMENT_CONFIRMATION_MODAL = "HIDE_DELETE_REC_ELEMENT_CONFIRMATION_MODAL";
export const SHOW_DELETE_REC_TEST_STEP_PRE_CONDITION_CONFIRMATION_MODAL =
  "SHOW_DELETE_REC_TEST_STEP_PRE_CONDITION_CONFIRMATION_MODAL";
export const HIDE_DELETE_REC_TEST_STEP_PRE_CONDITION_CONFIRMATION_MODAL =
  "HIDE_DELETE_REC_TEST_STEP_PRE_CONDITION_CONFIRMATION_MODAL";
export const DELETE_REC_TEST_STEP = "DELETE_REC_TEST_STEP";
export const REQUESTED_DELETE_REC_TEST_STEP = "REQUESTED_DELETE_REC_TEST_STEP";
export const REQUEST_DELETE_REC_TEST_STEP = "REQUEST_DELETE_REC_TEST_STEP";
export const DELETE_REC_TEST_STEP_PRE_CONDITION = "DELETE_REC_TEST_STEP_PRE_CONDITION";
export const REQUESTED_DELETE_REC_TEST_STEP_PRE_CONDITION = "REQUESTED_DELETE_REC_TEST_STEP_PRE_CONDITION";
export const REQUEST_DELETE_REC_TEST_STEP_PRE_CONDITION = "REQUEST_DELETE_REC_TEST_STEP_PRE_CONDITION";
export const CHANGE_REC_TEST_STEP_NAME = "CHANGE_REC_TEST_STEP_NAME";
export const CHANGE_REC_TEST_STEP_ACTION_SELECTED_ELEMENT = "CHANGE_REC_TEST_STEP_ACTION_SELECTED_ELEMENT";
export const RESET_REC_TEST_STEP_ACTION_SELECTED_ELEMENT = "RESET_REC_TEST_STEP_ACTION_SELECTED_ELEMENT";
export const SET_ELEMENT_DETAILS = "SET_ELEMENT_DETAILS";
export const UNBLOCK_REC_TEST_STEP_ACTION = "UNBLOCK_REC_TEST_STEP_ACTION";
export const BLOCK_REC_TEST_STEP_ACTION = "BLOCK_REC_TEST_STEP_ACTION";
export const REQUEST_PERFORM_REC_TEST_STEP_ACTION = "REQUEST_PERFORM_REC_TEST_STEP_ACTION";
export const REQUESTED_PERFORM_REC_TEST_STEP_ACTION = "REQUESTED_PERFORM_REC_TEST_STEP_ACTION";
export const CHANGE_REC_TEST_STEP_ELEMENT_XPATH_ACTIVE = "CHANGE_REC_TEST_STEP_ELEMENT_XPATH_ACTIVE";
export const UPDATE_DEVICE_DETAIL_FOR_REC_TEST_SCENARIO = "UPDATE_DEVICE_DETAIL_FOR_REC_TEST_SCENARIO";
export const RESET_SWIPE_BY_COORDINATES = "RESET_SWIPE_BY_COORDINATES";
export const SAVE_REC_TEST_STEP_ACTION = "SAVE_REC_TEST_STEP_ACTION";
export const CHANGE_STEP_NAME = "CHANGE_STEP_NAME";
export const CHANGE_SWIPE_TO_FIND_ELEMENT = "CHANGE_SWIPE_TO_FIND_ELEMENT";
export const CHANGE_SWIPE_TO_BRING_ELEMENT_TO_CENTER = "CHANGE_SWIPE_TO_BRING_ELEMENT_TO_CENTER";
export const CHANGE_SWIPE_COUNT_TO_FIND_ELEMENT = "CHANGE_SWIPE_COUNT_TO_FIND_ELEMENT";
export const CHANGE_SWIPE_DIRECTION_TO_FIND_ELEMENT = "CHANGE_SWIPE_DIRECTION_TO_FIND_ELEMENT";
export const CHANGE_ELEMENT_LOAD_TIME = "CHANGE_ELEMENT_LOAD_TIME";
export const CHANGE_RETRY_COUNT = "CHANGE_RETRY_COUNT";
export const CHANGE_INVERT_RESULT = "CHANGE_INVERT_RESULT";
export const CHANGE_HORIZONTAL_RELATIVE_POINT = "CHANGE_HORIZONTAL_RELATIVE_POINT";
export const CHANGE_VERTICAL_RELATIVE_POINT = "CHANGE_VERTICAL_RELATIVE_POINT";
export const ADD_REC_TEST_SUB_STEP = "ADD_REC_TEST_SUB_STEP";
export const SHOW_REC_TEST_SCENARIO_LIST_MODAL_VISIBLE = "SHOW_REC_TEST_SCENARIO_LIST_MODAL_VISIBLE";
export const HIDE_REC_TEST_SCENARIO_LIST_MODAL_VISIBLE = "HIDE_REC_TEST_SCENARIO_LIST_MODAL_VISIBLE";
export const SHOW_TEST_RAIL_MODAL = "SHOW_TEST_RAIL_MODAL";
export const HIDE_TEST_RAIL_MODAL = "HIDE_TEST_RAIL_MODAL";
export const CHANGE_REC_TEST_SCENARIO_NAME = "CHANGE_REC_TEST_SCENARIO_NAME";
export const RESET_REC_TEST_SCENARIO_NAME = "RESET_REC_TEST_SCENARIO_NAME";
export const SHOW_REC_TEST_STEP_ACTION_ELEMENT_DETAIL_DRAWER = "SHOW_REC_TEST_STEP_ACTION_ELEMENT_DETAIL_DRAWER";
export const SET_SELECTED_REC_STEP_TAB_KEY = "SET_SELECTED_REC_STEP_TAB_KEY";
export const HIDE_REC_TEST_STEP_ACTION_ELEMENT_DETAIL_DRAWER = "HIDE_REC_TEST_STEP_ACTION_ELEMENT_DETAIL_DRAWER";
export const CHANGE_CONTINUE_AFTER_FAILURE = "CHANGE_CONTINUE_AFTER_FAILURE";
export const CHANGE_DISABLE_STEP = "CHANGE_DISABLE_STEP";
export const CHANGE_DISABLE_REC_STEP = "CHANGE_DISABLE_REC_STEP";
export const MAKE_SUB_REC_TEST_STEP = "MAKE_SUB_REC_TEST_STEP";
export const ADD_CONDITION_TO_STEP = "ADD_CONDITION_TO_STEP";
export const DUPLICATE_REC_TEST_STEP = "DUPLICATE_REC_TEST_STEP";
export const UPDATE_DEVICE_STATE = "UPDATE_DEVICE_STATE";
export const PREVIEW_COMPLETED = "PREVIEW_COMPLETED";
export const UPDATE_PREVIEW_STATUS_WITH_REC_TEST_STEP = "UPDATE_PREVIEW_STATUS_WITH_REC_TEST_STEP";
export const TEST_PLAN_STEPS_LIST = "TEST_PLAN_STEPS_LIST";
export const CHANGED_REC_TEST_STEP_NAME = "CHANGED_REC_TEST_STEP_NAME";
export const CHANGED_REC_TEST_SCENARIO_NAME = "CHANGED_REC_TEST_SCENARIO_NAME";
export const UPDATE_REC_TEST_STEP_ACTIONS = "UPDATE_REC_TEST_STEP_ACTIONS";
export const REQUEST_IMPORT_REC_TEST_STEP_ACTION = "REQUEST_IMPORT_REC_TEST_STEP_ACTION";
export const REQUESTED_IMPORT_REC_TEST_STEP_ACTION = "REQUESTED_IMPORT_REC_TEST_STEP_ACTION";
export const SHOW_IMPORT_REC_TEST_STEP_MODAL = "SHOW_IMPORT_REC_TEST_STEP_MODAL";
export const HIDE_IMPORT_REC_TEST_STEP_MODAL = "HIDE_IMPORT_REC_TEST_STEP_MODAL";
export const REQUEST_FETCHING_REC_TEST_SCENARIO_STEP_ACTIONS = "REQUEST_FETCHING_REC_TEST_SCENARIO_STEP_ACTIONS";
export const REC_TEST_SCENARIO_STEP_ACTIONS_LIST = "REC_TEST_SCENARIO_STEP_ACTIONS_LIST";
export const REQUESTED_FETCHING_REC_TEST_SCENARIO_STEP_ACTIONS = "REQUESTED_FETCHING_REC_TEST_SCENARIO_STEP_ACTIONS";
export const CHANGE_SELECTED_REC_TEST_STEP_OR_ACTION = "CHANGE_SELECTED_REC_TEST_STEP_OR_ACTION";
export const CHANGE_SEARCH_VALUE_FOR_IMPORT_REC_TEST_STEP_OR_ACTION_TREE =
  "CHANGE_SEARCH_VALUE_FOR_IMPORT_REC_TEST_STEP_OR_ACTION_TREE";
export const CHANGE_SELECTED_TEST_BLOCKS = "CHANGE_SELECTED_TEST_BLOCKS";
export const REQUEST_CLEAR_PREVIEW = "REQUEST_CLEAR_PREVIEW";
export const REQUESTED_CLEAR_PREVIEW = "REQUESTED_CLEAR_PREVIEW";
export const CLEAR_UNSAVED_STEP = "CLEAR_UNSAVED_STEP";
export const DEVICE_INTERACTION_SELECT_MODE="DEVICE_INTERACTION_SELECT_MODE";
export const SHOW_GENERATE_STEPS_FROM_AI_MODAL = "SHOW_GENERATE_STEPS_FROM_AI_MODAL";
export const HIDE_AI_GENERATE_STEPS_FROM_REC_TEST_STEP = "HIDE_AI_GENERATE_STEPS_FROM_REC_TEST_STEP";
export const SHOW_GENERATE_STEPS_DEBUG_FROM_AI_MODAL = "SHOW_GENERATE_STEPS_DEBUG_FROM_AI_MODAL";
export const HIDE_GENERATE_STEPS_DEBUG_FROM_AI_MODAL = "HIDE_GENERATE_STEPS_DEBUG_FROM_AI_MODAL";
export const SHOW_AI_GENERATE_STEPS_ROW = "SHOW_AI_GENERATE_STEPS_ROW";
export const HIDE_AI_GENERATE_STEPS_ROW = "HIDE_AI_GENERATE_STEPS_ROW";
export const STOP_GENERATE_STEPS_FROM_AI_MODAL = "STOP_GENERATE_STEPS_FROM_AI_MODAL";
export const CHANGE_AI_SCENARIO_OBJECTIVE = "CHANGE_AI_SCENARIO_OBJECTIVE";
export const CHANGE_SCENARIO_GENERATE_STEP_TYPE = "CHANGE_SCENARIO_GENERATE_STEP_TYPE";
export const CHANGE_AI_SCENARIO_INSTRUCTIONS = "CHANGE_AI_SCENARIO_INSTRUCTIONS";
export const CHANGE_AI_MODEL_TYPE = "CHANGE_AI_MODEL_TYPE";
export const SCENARIO_GENERATED_STEPS = "SCENARIO_GENERATED_STEPS";
export const UPDATE_LAST_GENERATED_STEP_INDEX = "UPDATE_LAST_GENERATED_STEP_INDEX";
export const SCENARIO_GENERATE_REQUEST_START = "SCENARIO_GENERATE_REQUEST_START";
export const SCENARIO_GENERATE_REQUEST_STOP = "SCENARIO_GENERATE_REQUEST_STOP";
export const SCENARIO_GENERATE_DEBUG_REQUEST_START = "SCENARIO_GENERATE_DEBUG_REQUEST_START";
export const SCENARIO_GENERATE_DEBUG_REQUEST_STOP = "SCENARIO_GENERATE_DEBUG_REQUEST_STOP";
export const SHOW_UNSAVED_REC_TEST_STEP_ACTION_MODAL = "SHOW_UNSAVED_REC_TEST_STEP_ACTION_MODAL";
export const HIDE_UNSAVED_REC_TEST_STEP_ACTION_MODAL = "HIDE_UNSAVED_REC_TEST_STEP_ACTION_MODAL";
export const RESET_STATE = "RESET_STATE";
export const CHANGE_PAGE_SOURCE_FETCHING_INTERVAL = "CHANGE_PAGE_SOURCE_FETCHING_INTERVAL";
export const START_DEVICE_SCREEN_LOADER = "START_DEVICE_SCREEN_LOADER";
export const STOP_DEVICE_SCREEN_LOADER = "STOP_DEVICE_SCREEN_LOADER";
export const REQUEST_DRAG_AND_DROP = "REQUEST_DRAG_AND_DROP";
export const REQUESTED_DRAG_AND_DROP = "REQUESTED_DRAG_AND_DROP";
export const REQUEST_UPDATE_REC_TEST_STEP_ACTION_STEP_NAME_CONTINUE_ON_FAILURE =
  "REQUEST_UPDATE_REC_TEST_STEP_ACTION_STEP_NAME_CONTINUE_ON_FAILURE";
export const SHOW_DELETE_LOOP_CONDITION_MODAL_VISIBLE = "SHOW_DELETE_LOOP_CONDITION_MODAL_VISIBLE";
export const HIDE_DELETE_LOOP_CONDITION_MODAL_VISIBLE = "HIDE_DELETE_LOOP_CONDITION_MODAL_VISIBLE";
export const UPDATE_REC_TEST_STEP_ACTION_STEP_NAME_CONTINUE_ON_FAILURE =
  "UPDATE_REC_TEST_STEP_ACTION_STEP_NAME_CONTINUE_ON_FAILURE";
export const REQUESTED_UPDATE_REC_TEST_STEP_ACTION_STEP_NAME_CONTINUE_ON_FAILURE =
  "REQUESTED_UPDATE_REC_TEST_STEP_ACTION_STEP_NAME_CONTINUE_ON_FAILURE";
export const RESET_SAVED_REC_TEST_STEP_ACTION = "RESET_SAVED_REC_TEST_STEP_ACTION";
export const CHANGE_REC_TEST_STEP_PRE_CONDITION_PANEL = "CHANGE_REC_TEST_STEP_PRE_CONDITION_PANEL";
export const CHANGE_STEP_HAS_PRECONDITION = "CHANGE_STEP_HAS_PRECONDITION";
export const CHANGE_STEP_PRECONDITION_TITLE = "CHANGE_STEP_PRECONDITION_TITLE";
export const CHANGE_REC_TEST_STEP_LOOP_COUNT = "CHANGE_REC_TEST_STEP_LOOP_COUNT";
export const ADD_VERIFICATION_FOR_REPEAT_STEP = "ADD_VERIFICATION_FOR_REPEAT_STEP";
export const DELETE_REC_TEST_SCENARIO = "DELETE_REC_TEST_SCENARIO";
export const CHANGE_DEVICE_TAB_ACTION_KEY = "CHANGE_DEVICE_TAB_ACTION_KEY";
export const SAVE_SCENARIO_FILTER = "SAVE_SCENARIO_FILTER";
export const GET_SCENARIO_FILTER = "GET_SCENARIO_FILTER";
export const GET_SCENARIO_FILTER_DATA = "GET_SCENARIO_FILTER_DATA";
export const GET_TAG_BY_TEST_PLAN_ID = "GET_TAG_BY_TEST_PLAN_ID";
export const TOGGLE_OTHER_ELEMENT_POPOVER = "TOGGLE_OTHER_ELEMENT_POPOVER";
export const LOAD_REC_TEST_SCENARIO_TAGS = "LOAD_REC_TEST_SCENARIO_TAGS";
export const SELECTED_TAGS_RECORD_SCENARIO = "SELECTED_TAGS_RECORD_SCENARIO";
export const CHANGE_SELECTED_TAG_VALUE_OF_SCHEDULER = "CHANGE_SELECTED_TAG_VALUE_OF_SCHEDULER";
export const CREATE_NEW_FILTER_FLAG = "CREATE_NEW_FILTER_FLAG";
export const CHANGED_FILTER_NAME_FIELD = "CHANGED_FILTER_NAME_FIELD";
export const UPDATE_TAG_SCENARIO_FIELD = "UPDATE_TAG_SCENARIO_FIELD";
export const SCHEDULE_SAVE_CHECKED = "SCHEDULE_SAVE_CHECKED";
export const SCHEDULER_SPINNER = "SCHEDULER_SPINNER";
export const RECORD_SCENARIO_UPDATE_SPINNER = "RECORD_SCENARIO_UPDATE_SPINNER";
export const EMAIL_VALIDITY_CHECKED = "EMAIL_VALIDITY_CHECKED";
export const RESET_RECORDED_SCENARIO = "RESET_RECORDED_SCENARIO";
export const TAG_LIST_RECORD_SCENARIO = "TAG_LIST_RECORD_SCENARIO";
export const SCHEDULER_BUILD_LABEL = "SCHEDULER_BUILD_LABEL";
export const TAG_IN_QUERY_BUILDER = "TAG_IN_QUERY_BUILDER";
export const CLICKED_SAVED_SCENARIO_FILTER = "CLICKED_SAVED_SCENARIO_FILTER";
export const CHANGE_EXECUTION_FARM_SELECTION = "CHANGE_EXECUTION_FARM_SELECTION";
export const CHANGE_LODESTONE_DEVICE_SELECTION = "CHANGE_LODESTONE_DEVICE_SELECTION";
export const CHANGE_LODESTONE_DEVICE_FARM_SELECTION = "CHANGE_LODESTONE_DEVICE_FARM_SELECTION";
export const CHANGE_AWS_DEVICE_POOL_SELECTION = "CHANGE_AWS_DEVICE_POOL_SELECTION";
export const CHANGE_AWS_DEVICE_PROJECT_SELECTION = "CHANGE_AWS_DEVICE_PROJECT_SELECTION";
export const CHANGE_AWS_DEVICE_BUILD_SELECTION = "CHANGE_AWS_DEVICE_BUILD_SELECTION";
export const GET_AWS_DEVICE_FARM_PROJECTS = "GET_AWS_DEVICE_FARM_PROJECTS";
export const GET_AWS_DEVICE_FARM_POOLS = "GET_AWS_DEVICE_FARM_POOLS";
export const GET_AWS_DEVICE_FARM_BUILDS = "GET_AWS_DEVICE_FARM_BUILDS";
export const RE_RECORD_REC_TEST_STEP_ELEMENT = "RE_RECORD_REC_TEST_STEP_ELEMENT";
export const CLEAR_RE_RECORD_ELEMENT_STEP_TAG = "CLEAR_RE_RECORD_ELEMENT_STEP_TAG";
export const CHANGE_SHOW_TEST_DATA_VISIBILITY = "CHANGE_SHOW_TEST_DATA_VISIBILITY";
export const TEST_DATA_LIST_REQUEST = "TEST_DATA_LIST_REQUEST";
export const TEST_DATA_LIST_REQUESTED = "TEST_DATA_LIST_REQUESTED";
export const CHANGE_TEST_DATA_LIST_SEARCH_VALUE = "CHANGE_TEST_DATA_LIST_SEARCH_VALUE";
export const CHANGE_TEST_DATA_LIST_FILTER_VALUE = "CHANGE_TEST_DATA_LIST_FILTER_VALUE";
export const TEST_DATA_LIST = "TEST_DATA_LIST";
export const SELECT_TEST_DATA = "SELECT_TEST_DATA";
export const SELECT_OUTPUT_TEST_DATA = "SELECT_OUTPUT_TEST_DATA";
export const CHANGE_INSERT_VALUE_TO_TEST_DATA = "CHANGE_INSERT_VALUE_TO_TEST_DATA";
export const SELECT_OUTPUT_TEST_DATA_REC_ELEMENT = "SELECT_OUTPUT_TEST_DATA_REC_ELEMENT";
export const CHANGE_TEST_DATA_VALUE_FOR_ACTION = "CHANGE_TEST_DATA_VALUE_FOR_ACTION";
export const CHANGE_IGNORE_TEST_PLAN_DATA = "CHANGE_IGNORE_TEST_PLAN_DATA";
export const SHOW_ADD_TEST_DATA_FORM = "SHOW_ADD_TEST_DATA_FORM";
export const HIDE_ADD_TEST_DATA_FORM = "HIDE_ADD_TEST_DATA_FORM";
export const RESET_ADD_TEST_DATA_FORM = "RESET_ADD_TEST_DATA_FORM";
export const CHANGE_NEW_TEST_DATA_NAME = "CHANGE_NEW_TEST_DATA_NAME";
export const CHANGE_NEW_TEST_DATA_VALUE = "CHANGE_NEW_TEST_DATA_VALUE";
export const CHANGE_NEW_TEST_DATA_SCOPE = "CHANGE_NEW_TEST_DATA_SCOPE";
export const CHANGE_NEW_TEST_DATA_TYPE = "CHANGE_NEW_TEST_DATA_TYPE";
export const SAVE_TEST_DATA_FORM_REQUEST = "SAVE_TEST_DATA_FORM_REQUEST";
export const SAVE_TEST_DATA_FORM_REQUESTED = "SAVE_TEST_DATA_FORM_REQUESTED";
export const DELETE_TEST_PLAN = "DELETE_TEST_PLAN";
export const TEST_DATA_SET_LIST_REQUEST = "TEST_DATA_SET_LIST_REQUEST";
export const TEST_DATA_SET_LIST_REQUESTED = "TEST_DATA_SET_LIST_REQUESTED";
export const TEST_DATA_LIST_FOR_SCENARIO = "TEST_DATA_LIST_FOR_SCENARIO";
export const TEST_DATA_SET_LIST = "TEST_DATA_SET_LIST";
export const SYSTEM_KEY_LIST = "SYSTEM_KEY_LIST";
export const LIST_OF_LOCALES = "LIST_OF_LOCALES";
export const RESET_TEST_DATA_SET = "RESET_TEST_DATA_SET";
export const REMOVE_PREVIOUS_TEST_DATA_SET = "REMOVE_PREVIOUS_TEST_DATA_SET";
export const GET_TEST_DATA_SET_BY_ID = "GET_TEST_DATA_SET_BY_ID";
export const TEST_DATA_SET_LIST_FOR_SELECTION_REQUEST = "TEST_DATA_SET_LIST_FOR_SELECTION_REQUEST";
export const TEST_DATA_SET_LIST_FOR_SELECTION_REQUESTED = "TEST_DATA_SET_LIST_FOR_SELECTION_REQUESTED";
export const TEST_DATA_SET_LIST_FOR_SELECTION = "TEST_DATA_SET_LIST_FOR_SELECTION";
export const RESET_TEST_DATA_SET_LIST_FOR_SELECTION = "RESET_TEST_DATA_SET_LIST_FOR_SELECTION";
export const GET_LIST_OF_TEST_DATA_BY_SCENARIO_ID = "GET_LIST_OF_TEST_DATA_BY_SCENARIO_ID";
export const TEST_DATA_LIST_FOR_TEST_DATA_SET = "TEST_DATA_LIST_FOR_TEST_DATA_SET";
export const SAVE_TEST_DATA_SET = "SAVE_TEST_DATA_SET";
export const SHOW_ADD_TEST_DATA_SET_FORM = "SHOW_ADD_TEST_DATA_SET_FORM";
export const HIDE_ADD_TEST_DATA_SET_FORM = "HIDE_ADD_TEST_DATA_SET_FORM";
export const CHANGE_TEST_DATA_SET_NAME = "CHANGE_TEST_DATA_SET_NAME";
export const CHANGE_TEST_DATA_SET_LOCALE = "CHANGE_TEST_DATA_SET_LOCALE";
export const CHANGE_TEST_DATA_SET_DEVICE_ASSIGN_TEST_DATA_SET = "CHANGE_TEST_DATA_SET_DEVICE_ASSIGN_TEST_DATA_SET";
export const ADD_TEST_DATA_ROW_IN_NEW_TEST_DATA_SET = "ADD_TEST_DATA_ROW_IN_NEW_TEST_DATA_SET";
export const REMOVE_TEST_DATA_ROW_IN_NEW_TEST_DATA_SET = "REMOVE_TEST_DATA_ROW_IN_NEW_TEST_DATA_SET";
export const CHANGE_TEST_DATA_NAME_IN_TEST_DATA_SET_FORM = "CHANGE_TEST_DATA_NAME_IN_TEST_DATA_SET_FORM";
export const UPDATE_TEST_DATA_SET_DATA = "UPDATE_TEST_DATA_SET_DATA";
export const CHANGE_TEST_DATA_DEFAULT_VALUE_IN_TEST_DATA_SET_FORM =
  "CHANGE_TEST_DATA_DEFAULT_VALUE_IN_TEST_DATA_SET_FORM";
export const CHANGE_TEST_DATA_CUSTOM_VALUE_IN_TEST_DATA_SET_FORM =
  "CHANGE_TEST_DATA_CUSTOM_VALUE_IN_TEST_DATA_SET_FORM";
export const LOAD_VALUE_ADD_TEST_DATA_FORM = "LOAD_VALUE_ADD_TEST_DATA_FORM";
export const CHANGE_TEST_DATA_CUSTOM_VALUE = "CHANGE_TEST_DATA_CUSTOM_VALUE";
export const RESEND_INVITATION_REQUEST = "RESEND_INVITATION_REQUEST";
export const DELETE_INVITATION_REQUEST = "DELETE_INVITATION_REQUEST";
export const FIND_DEVICE_BY_UDID = "FIND_DEVICE_BY_UDID";
export const STOP_DEVICE_STREAMING = "STOP_DEVICE_STREAMING";
export const REQUEST_FOR_DEVICE_LOAD = "REQUEST_FOR_DEVICE_LOAD";
export const REQUEST_FOR_DEVICE_UNLOAD = "REQUEST_FOR_DEVICE_UNLOAD";
export const REQUESTED_FOR_DEVICE_LOAD = "REQUESTED_FOR_DEVICE_LOAD";
export const REQUESTED_FOR_DEVICE_UNLOAD = "REQUESTED_FOR_DEVICE_UNLOAD";
export const UPDATE_SCENARIO_NAME = "UPDATE_SCENARIO_NAME";
export const S3BUCKET = "xpress-run-bucket";
export const S3BUCKET_REGION = "ap-south-1";
export const S3ACCESS_KEY_ID = "AKIAXPLYSVKTEHMQ4QX7";
export const S3SECRET_ACCESS_KEY = "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";
export const CF_DISTRIBUTION_DOMAIN = "d30nqiiihqg2gc.cloudfront.net";
export const CF_KEY_PAIR_ID = "APKAIZVYFXTIOZGICPJQ";
export const S3HOST = "https://s3.ap-south-1.amazonaws.com/";
export const PRE_SIGNED_URL_EXPIRATION_DAYS = 1000;
export const CHANGE_BUILD_SCENARIO_STEP = "CHANGE_BUILD_SCENARIO_STEP";
export const CHANGE_TEST_PLAN_TAB = "CHANGE_TEST_PLAN_TAB";
export const CHANGE_TEST_PLAN_DETAILS = "CHANGE_TEST_PLAN_DETAILS";
export const SAVE_TEST_PLAN_NAME = "SAVE_TEST_PLAN_NAME";
export const SAVE_TEST_PLAN_SCENARIO = "SAVE_TEST_PLAN_SCENARIO";
export const SAVE_TEST_PLAN_DATASET = "SAVE_TEST_PLAN_DATASET";
export const TEST_DATA_LIST_BY_TEST_DATASET_ID = "TEST_DATA_LIST_BY_TEST_DATASET_ID";
export const GET_TEST_SCENARIO_STEP_DATA = "GET_TEST_SCENARIO_STEP_DATA";
export const GET_TEST_SCENARIO_STEPS_DATA = "GET_TEST_SCENARIO_STEPS_DATA";
export const CHANGE_SELECTOR_TYPE = "CHANGE_SELECTOR_TYPE";
export const CHANGE_DEVICE_INTERACTION_MODE = "CHANGE_DEVICE_INTERACTION_MODE";
export const UPDATE_DEVICE_SELECTION_DATA = "UPDATE_DEVICE_SELECTION_DATA";
export const CHANGE_APP_MANAGER_TAB = "CHANGE_APP_MANAGER_TAB";
export const SET_SELECTED_APP_BUILD = "SET_SELECTED_APP_BUILD";
export const GET_UPLOAD_BUILD_OPTIONS = "GET_UPLOAD_BUILD_OPTIONS";
export const GET_AWS_PROJECTS_LIST = "GET_AWS_PROJECTS_LIST";
export const RESET_REMOTE_DEVICE_DATA = "RESET_REMOTE_DEVICE_DATA";
export const RESET_TEST_RAIL_CASE_ID_AND_SUITE_ID = "RESET_TEST_RAIL_CASE_ID_AND_SUITE_ID";
export const CLOSE_DEVICE = "CLOSE_DEVICE";
export const RESET_TEST_RAIL_SUITE_ID = "RESET_TEST_RAIL_SUITE_ID";
export const SET_TEST_RAIL_CASE_ID = "SET_TEST_RAIL_CASE_ID";
export const UPDATE_PREVIOUS_SEARCH_VALUE = "UPDATE_PREVIOUS_SEARCH_VALUE";
export const GET_TEST_PLAN_ID_NAME_LIST = "GET_TEST_PLAN_ID_NAME_LIST";
export const CHANGE_SELECTED_TEST_SCENARIO_FOR_RESULT_LOGS = "CHANGE_SELECTED_TEST_SCENARIO_FOR_RESULT_LOGS";
export const CLEAR_SELECTED_TEST_SCENARIO_FOR_RESULT_LOGS = "CLEAR_SELECTED_TEST_SCENARIO_FOR_RESULT_LOGS";
export const REQUEST_FOR_DOWNLOAD_REPORT_LOGS = "REQUEST_FOR_DOWNLOAD_REPORT_LOGS";
export const REQUESTED_FOR_DOWNLOAD_REPORT_LOGS = "REQUESTED_FOR_DOWNLOAD_REPORT_LOGS";
export const SET_SELECTED_SCENARIO = "SET_SELECTED_SCENARIO";
export const SET_TEST_RAIL_SUITE_ID = "SET_TEST_RAIL_SUITE_ID";
export const REMOVE_CONDITION_FROM_REC_TEST_STEP = "REMOVE_CONDITION_FROM_REC_TEST_STEP";
export const REMOVE_LOOP_CONDITION_FROM_REC_TEST_STEP = "REMOVE_LOOP_CONDITION_FROM_REC_TEST_STEP";
export const CLOSE_TEST_PLAN_TREND_DRAWER = "CLOSE_TEST_PLAN_TREND_DRAWER";
export const OPEN_TEST_PLAN_TREND_DRAWER = "OPEN_TEST_PLAN_TREND_DRAWER";
export const GET_TEST_PLAN_SCENARIO_WISE_TREND_DATA = "GET_TEST_PLAN_SCENARIO_WISE_TREND_DATA";
export const EXPORT_RUN_COMPARISON_DOC = "EXPORT_RUN_COMPARISON_DOC";
export const UNINSTALL_BUILD_BEFORE_INSTALL = "UNINSTALL_BUILD_BEFORE_INSTALL";
export const SELECT_ALL_REC_TEST_SCENARIO_STEP_IDS = "SELECT_AL_REC_TEST_SCENARIO_STEP_IDS";
export const RESET_ALL_REC_TEST_SCENARIO_STEP_IDS = "RESET_ALL_REC_TEST_SCENARIO_STEP_IDS";
export const CHANGE_ADVANCE_ELEMENT_SELECTOR_DRAWER_VISIBILITY = "CHANGE_ADVANCE_ELEMENT_SELECTOR_DRAWER_VISIBILITY";
export const CHANGE_SELECT_CHILD_ELEMENT = "CHANGE_SELECT_CHILD_ELEMENT";
export const ADD_ELEMENT_INTO_STEP = "ADD_ELEMENT_INTO_STEP";
export const RESET_UNSAVED_ELEMENTS = "RESET_UNSAVED_ELEMENTS";
export const CHANGE_CUSTOM_ELEMENT_SEARCH_KEY = "CHANGE_CUSTOM_ELEMENT_SEARCH_KEY";
export const CHANGE_CUSTOM_ELEMENT_SEARCH_VALUE = "CHANGE_CUSTOM_ELEMENT_SEARCH_VALUE";
export const CHANGE_CUSTOM_ELEMENT_FILTER_TYPE_VALUE = "CHANGE_CUSTOM_ELEMENT_FILTER_TYPE_VALUE";
export const CHANGE_CUSTOM_ELEMENT_ADD_FILTER_TYPE = "CHANGE_CUSTOM_ELEMENT_ADD_FILTER_TYPE";
export const CHANGE_CUSTOM_ELEMENT_ADD_TEST_DATA = "CHANGE_CUSTOM_ELEMENT_ADD_TEST_DATA";
export const REMOVE_ELEMENT_INTO_STEP = "REMOVE_ELEMENT_INTO_STEP";
export const CHANGE_ELEMENT_NAME_INTO_STEP = "CHANGE_ELEMENT_NAME_INTO_STEP";
export const CHANGE_DISABLE_ELEMENT = "CHANGE_DISABLE_ELEMENT";
export const CHANGE_ELEMENT_DESCRIPTION_INTO_STEP = "CHANGE_ELEMENT_DESCRIPTION_INTO_STEP";
export const CHANGE_ELEMENT_TAGS_INTO_STEP = "CHANGE_ELEMENT_TAGS_INTO_STEP";
export const FETCH_PREVIEW_RUNS = "FETCH_PREVIEW_RUNS";
export const SET_TEC_TEST_SCENARIO_ID = "SET_TEC_TEST_SCENARIO_ID";
export const FETCH_LIST_OF_MEMBER = "FETCH_LIST_OF_MEMBER";
export const GET_LIST_OF_BLOCKS = "GET_LIST_OF_BLOCKS";
export const LOADING_LIST_OF_BLOCKS = "LOADING_LIST_OF_BLOCKS";
export const SHOW_TEST_BLOCK_LIST_MODAL_VISIBLE = "SHOW_TEST_BLOCK_LIST_MODAL_VISIBLE";
export const HIDE_TEST_BLOCK_LIST_MODAL_VISIBLE = "HIDE_TEST_BLOCK_LIST_MODAL_VISIBLE";
export const ADD_TEST_BLOCK_STEP = "ADD_TEST_BLOCK_STEP";
export const ADD_TEST_BLOCK_CHILD_STEP = "ADD_TEST_BLOCK_CHILD_STEP";
export const RESET_ALL_TEST_BLOCK_CHILD_STEP_IDS = "RESET_ALL_TEST_BLOCK_CHILD_STEP_IDS";
export const CHANGE_TEST_BLOCK_STEP_NAME = "CHANGE_TEST_BLOCK_STEP_NAME";
export const SET_TEST_BLOCK_STEP = "SET_TEST_BLOCK_STEP";
export const RESET_TEST_BLOCK_STEP = "RESET_TEST_BLOCK_STEP";
export const RESET_TEST_BLOCk_STEP_SCENARIO_NAME = "RESET_TEST_BLOCk_STEP_SCENARIO_NAME";
export const REC_TEST_STEP = "REC_TEST_STEP";
export const IS_DELETE_REC_TEST_STEP_REF = "IS_DELETE_REC_TEST_STEP_REF";
export const MAKE_STEP_EDITABLE_REQUEST = "MAKE_STEP_EDITABLE_REQUEST";
export const UPDATE_STEP_TYPE = "UPDATE_STEP_TYPE";
export const RESET_EDIT_STEP = "RESET_EDIT_STEP";
export const RESET_TEST_DATA_CONTENT = "RESET_TEST_DATA_CONTENT";
export const UPDATE_UNSAVED_REC_TEST_STEP = "UPDATE_UNSAVED_REC_TEST_STEP";
export const UPDATE_SELECTED_REC_TEST_STEP = "UPDATE_SELECTED_REC_TEST_STEP";
export const UPDATE_UNSAVED_REC_TEST_STEP_AND_REC_ELEMENT = "UPDATE_UNSAVED_REC_TEST_STEP_AND_REC_ELEMENT";
export const CHANGE_REC_TEST_STEP_COLUMN_VIEW = "CHANGE_REC_TEST_STEP_COLUMN_VIEW";
export const EDIT_REC_TEST_STEP = "EDIT_REC_TEST_STEP";
export const EDIT_REC_TEST_STEP_ELEMENT = "EDIT_REC_TEST_STEP_ELEMENT";
export const ADD_TEST_BLOCK_STEP_ACTION = "ADD_TEST_BLOCK_STEP_ACTION";
export const DELETE_TEST_BLOCK_STEP = "DELETE_TEST_BLOCK_STEP";
export const SET_SELECTED_TEST_BLOCK = "SET_SELECTED_TEST_BLOCK";
export const REMOVE_SELECTED_REC_TEST_STEP_OR_ACTION = "REMOVE_SELECTED_REC_TEST_STEP_OR_ACTION";
export const SELECT_TEST_BLOCK__STEP_DATA = "SELECT_TEST_BLOCK__STEP_DATA";
export const TEST_DATA_SET_SELECTION_SCENARIO = "TEST_DATA_SET_SELECTION_SCENARIO";
export const CHANGE_TEST_PLAN_DATA = "CHANGE_TEST_PLAN_DATA";
export const SHOW_TEST_DATA_SET_SELECTION_MODAL = "SHOW_TEST_DATA_SET_SELECTION_MODAL";
export const HIDE_TEST_DATA_SET_SELECTION_MODAL = "HIDE_TEST_DATA_SET_SELECTION_MODAL";
export const ASSIGN_TEST_DATA_SET = "ASSIGN_TEST_DATA_SET";
export const SET_TEST_DATA_SET_VIEW = "SET_TEST_DATA_SET_VIEW";
export const RESET_TEST_DATA_SET_VIEW = "RESET_TEST_DATA_SET_VIEW";
export const SET_TEST_DATA_SET_ASSIGNEE_DETAIL = "SET_TEST_DATA_SET_ASSIGNEE_DETAIL";
export const SET_APPLICATION_STRING_ASSIGNEE_DETAIL = "SET_APPLICATION_STRING_ASSIGNEE_DETAIL";
export const SET_DEVICE_STRING_ASSIGNEE_DETAIL = "SET_DEVICE_STRING_ASSIGNEE_DETAIL";
export const ADD_MULTI_COLUMN_VIEW_STEP = "ADD_MULTI_COLUMN_VIEW_STEP";
export const CLEAR_SELECTED_REC_TEST_STEP_DETAIL_FOR_MULTI_COLUMN_VIEW =
  "CLEAR_SELECTED_REC_TEST_STEP_DETAIL_FOR_MULTI_COLUMN_VIEW";
export const SELECTED_REC_TEST_STEP_DETAIL = "SELECTED_REC_TEST_STEP_DETAIL";
export const SELECTED_REC_TEST_STEP_PRECONDITION_DETAIL = "SELECTED_REC_TEST_STEP_PRECONDITION_DETAIL";
export const SELECTED_REC_TEST_STEP_LOOP_CONDITION_DETAIL = "SELECTED_REC_TEST_STEP_LOOP_CONDITION_DETAIL";
export const LOAD_STEP_CONDITION_STEP = "LOAD_STEP_CONDITION_STEP";
export const UNLOAD_STEP_CONDITION_STEP = "UNLOAD_STEP_CONDITION_STEP";
export const ADD_REC_TEST_STEP_MULTI_COLUMN_VIEW = "ADD_REC_TEST_STEP_MULTI_COLUMN_VIEW";
export const RESET_REC_TEST_DATA = "RESET_REC_TEST_DATA";
export const HANDLE_SELECT_REC_TEST_STEP_ACTION = "HANDLE_SELECT_REC_TEST_STEP_ACTION";
export const RESET_IMPORT_REC_TEST_DATA = "RESET_IMPORT_REC_TEST_DATA";
export const DELETE_MULTI_COLUMN_VIEW_STEP = "DELETE_MULTI_COLUMN_VIEW_STEP";
export const CHANGE_MULTIPLE_COLUMN_VIEW_STEP_NAME = "CHANGE_MULTIPLE_COLUMN_VIEW_STEP_NAME";
export const RESET_MULTIPLE_COLUMN_VIEW_STEP_NAME = "RESET_MULTIPLE_COLUMN_VIEW_STEP_NAME";
export const UPDATE_MULTIPLE_COLUMN_VIEW_ACTION_STEP_NAME_CONTINUE_ON_FAILURE =
  "UPDATE_MULTIPLE_COLUMN_VIEW_ACTION_STEP_NAME_CONTINUE_ON_FAILURE";
export const CHANGE_MULTI_COLUMN_VIEW_DISABLE_STEP = "CHANGE_MULTI_COLUMN_VIEW_DISABLE_STEP";
export const SELECTED_RENAME_STEP_TAG = "SELECTED_RENAME_STEP_TAG";
export const UPDATE_UNSAVED_REC_TEST_STEP_PATH = "UPDATE_UNSAVED_REC_TEST_STEP_PATH";
export const SELECTED_REC_TEST_STEP_UPDATED_STEP_NAME = "SELECTED_REC_TEST_STEP_UPDATED_STEP_NAME";
export const MULTI_COLUMN_VIEW_DATA_LOADING = "MULTI_COLUMN_VIEW_DATA_LOADING";
export const REC_TEST_STEP_INNER_DETAILS_LOADING = "REC_TEST_STEP_INNER_DETAILS_LOADING";
export const MULTI_COLUMN_VIEW_TEST_BLOCK_DATA_LOADING = "MULTI_COLUMN_VIEW_TEST_BLOCK_DATA_LOADING";
export const SELECTED_TEST_STEP_BLOCK = "SELECTED_TEST_STEP_BLOCK";
export const MULTI_COLUMN_UPDATE_PREVIEW_STATUS = "MULTI_COLUMN_UPDATE_PREVIEW_STATUS";
export const CLEAR_PREVIEW_FOR_MULTI_COLUMN_VIEW = "CLEAR_PREVIEW_FOR_MULTI_COLUMN_VIEW";
export const HIDE_TEST_BUILD_DATA_SET_TAB = "HIDE_TEST_BUILD_DATA_SET_TAB";
export const SELECT_REC_TEST_STEPS = "SELECT_REC_TEST_STEPS";
export const CHANGE_TEST_BLOCK_LIST_FILTER = "CHANGE_TEST_BLOCK_LIST_FILTER";
export const HANDLE_DISABLE_BUTTON = "HANDLE_DISABLE_BUTTON";
export const CAPTURE_EXPANDED_STEPS = "CAPTURE_EXPANDED_STEPS";
export const CLEAR_EXPANDED_STEPS = "CLEAR_EXPANDED_STEPS";
export const CAPTURE_EXPANDED_ELEMENTS = "CAPTURE_EXPANDED_ELEMENTS";
export const CLEAR_EXPANDED_ELEMENTS = "CLEAR_EXPANDED_ELEMENTS";
export const RESET_PREVIOUS_TEST_DATA_SET_FOR_EDIT = "RESET_PREVIOUS_TEST_DATA_SET_FOR_EDIT";
export const RESET_MANAGE_KEYS_DATA = "RESET_MANAGE_KEYS_DATA";
export const VISIBLE_TEST_DATA_DRAWER = "VISIBLE_TEST_DATA_DRAWER";
export const GET_APPLICATION_STRINGS = "GET_APPLICATION_STRINGS";
export const APPLICATION_DRAWER_VISIBLE = "APPLICATION_DRAWER_VISIBLE";
export const GET_LIST_OF_LOCALS = "GET_LIST_OF_LOCALS";
export const APPLICATION_STRING_LOADER = "APPLICATION_STRING_LOADER";
export const DEVICE_STRING_LOADER = "DEVICE_STRING_LOADER";
export const GET_DEVICE_STRINGS = "GET_DEVICE_STRINGS";
export const ADD_DEVICE_STRINGS_FORM_VISIBLE = "ADD_DEVICE_STRINGS_FORM_VISIBLE";
export const RESET_DEVICE_STRING_DATA = "RESET_DEVICE_STRING_DATA";
export const LOAD_EDIT_DEVICE_STRING_DETAILS = "LOAD_EDIT_DEVICE_STRING_DETAILS";
export const CHANGE_DEVICE_STRING_NAME = "CHANGE_DEVICE_STRING_NAME";
export const SELECT_DEVICE_STRING_LOCALE = "SELECT_DEVICE_STRING_LOCALE";
export const CHANGE_DEVICE_STRING_VALUE = "CHANGE_DEVICE_STRING_VALUE";
export const DELETE_DEVICE_STRING_LOCALE = "DELETE_DEVICE_STRING_LOCALE";
export const ADD_DEVICE_STRING_LOCALE_AND_VALUE = "ADD_DEVICE_STRING_LOCALE_AND_VALUE";
export const DEVICE_DRAWER_VISIBLE = "DEVICE_DRAWER_VISIBLE";
export const ADD_APPLICATION_STRINGS_FORM_VISIBLE = "ADD_APPLICATION_STRINGS_FORM_VISIBLE";
export const IMPORT_APPLICATION_STRINGS_FORM_VISIBLE = "IMPORT_APPLICATION_STRINGS_FORM_VISIBLE";
export const CHANGE_REMOVE_PREVIOUS_APPLICATION_STRING_VALUE = "CHANGE_REMOVE_PREVIOUS_APPLICATION_STRING_VALUE";
export const UPDATE_IMPORT_APPLICATION_STRINGS_DATA = "UPDATE_IMPORT_APPLICATION_STRINGS_DATA";
export const ADD_EMPTY_LOCALE_OPTIONS_FOR_IMPORT = "ADD_EMPTY_LOCALE_OPTIONS_FOR_IMPORT";
export const REMOVE_LOCALE_OPTIONS_FOR_IMPORT = "REMOVE_LOCALE_OPTIONS_FOR_IMPORT";
export const UPLOAD_APPLICATION_STRING_XML = "UPLOAD_APPLICATION_STRING_XML";
export const SELECT_APPLICATION_STRING_LOCALE_FOR_IMPORT = "SELECT_APPLICATION_STRING_LOCALE_FOR_IMPORT";
export const SELECT_APPLICATION_STRING_FILE_FOR_IMPORT = "SELECT_APPLICATION_STRING_FILE_FOR_IMPORT";
export const REQUEST_UPLOAD_APPLICATION_STRING = "REQUEST_UPLOAD_APPLICATION_STRING";
export const REQUESTED_UPLOAD_APPLICATION_STRING = "REQUESTED_UPLOAD_APPLICATION_STRING";
export const ERROR_UPLOAD_APPLICATION_STRING = "ERROR_UPLOAD_APPLICATION_STRING";
export const CHANGE_APPLICATION_STRING_NAME = "CHANGE_APPLICATION_STRING_NAME";
export const CHANGE_APPLICATION_STRING_VALUE = "CHANGE_APPLICATION_STRING_VALUE";
export const SELECT_APPLICATION_STRING_LOCALE = "SELECT_APPLICATION_STRING_LOCALE";
export const RESET_APPLICATION_STRING_DATA = "RESET_APPLICATION_STRING_DATA";
export const SELECTED_TEST_DATA_SET_TAB = "SELECTED_TEST_DATA_SET_TAB";
export const GET_TEST_STEPS_DATA_LOADING = "GET_TEST_STEPS_DATA_LOADING";
export const LOADING_EXECUTION_TEST_DATA_SET_ASSOC_LIST = "LOADING_EXECUTION_TEST_DATA_SET_ASSOC_LIST";
export const GET_TEST_STEPS_DATA_LIST = "GET_TEST_STEPS_DATA_LIST";
export const GET_TEST_STEPS_DATA_LIST_APPLICATION_STRING = "GET_TEST_STEPS_DATA_LIST_APPLICATION_STRING";
export const GET_TEST_STEPS_DATA_LIST_DEVICE_STRING = "GET_TEST_STEPS_DATA_LIST_DEVICE_STRING";
export const TEST_DATA_SET_INFO_MODAL = "TEST_DATA_SET_INFO_MODAL";
export const TEST_DATA_KEYS = "TEST_DATA_KEYS";
export const TEST_DATA_SYSTEM_KEYS = "TEST_DATA_SYSTEM_KEYS";
export const GET_TEST_DATA_BASE_LIST_LOADING = "GET_TEST_DATA_BASE_LIST_LOADING";
export const GET_TEST_DATA_BASE_LIST = "GET_TEST_DATA_BASE_LIST";
export const GET_TEST_DATA_BASE = "GET_TEST_DATA_BASE";
export const TEST_DATA_APPLICATION_STRINGS = "TEST_DATA_APPLICATION_STRINGS";
export const UPDATED_TEST_BLOCK_NAME = "UPDATED_TEST_BLOCK_NAME";
export const VISIBLE_GENERAL_SETTINGS_DRAWER = "VISIBLE_GENERAL_SETTINGS_DRAWER";
export const UPDATE_AI_STEP_OBJECTIVE = "UPDATE_AI_STEP_OBJECTIVE";
export const VISIBLE_IDENTIFICATION_RULES_DRAWER = "VISIBLE_IDENTIFICATION_RULES_DRAWER";
export const LOAD_EDIT_APPLICATION_STRING_DETAILS = "LOAD_EDIT_APPLICATION_STRING_DETAILS";
export const DELETE_APPLICATION_STRING_LOCALE = "DELETE_APPLICATION_STRING_LOCALE";
export const ADD_APPLICATION_STRING_LOCALE_AND_VALUE = "ADD_APPLICATION_STRING_LOCALE_AND_VALUE";
export const VISIBLE_TEST_DATA_VISIBILITY = "VISIBLE_TEST_DATA_VISIBILITY";
export const UPDATE_TEST_BLOCK_STEP_NAME = "UPDATE_TEST_BLOCK_STEP_NAME";
export const ALL_TEST_SCENARIOS = "All Test Scenarios";
export const BUILD_TEST_SCENARIO = "Scenario Designer";
export const TEST_STEPS_LIST_ASSOCIATED_WITH_TEST_DATA = "TEST_STEPS_LIST_ASSOCIATED_WITH_TEST_DATA";
export const TEST_STEPS_LIST_ASSOCIATED_WITH_TEST_DATA_LOADING = "TEST_STEPS_LIST_ASSOCIATED_WITH_TEST_DATA_LOADING";
export const VISIBLE_DEVICE_MORE_DETAILS_MODAL = "VISIBLE_DEVICE_MORE_DETAILS_MODAL";
export const APPLICATION_STRING_INFO = "APPLICATION_STRING_INFO";
export const APPLICATION_STRING_CURRENT_TAB = "APPLICATION_STRING_CURRENT_TAB";
export const APPLICATION_STRING_SEARCH_KEYWORD = "APPLICATION_STRING_SEARCH_KEYWORD";
export const DEVICE_STRING_INFO = "DEVICE_STRING_INFO";
export const SET_TEST_DATA_SET_PROFILE_FOR_PREVIEW = "SET_TEST_DATA_SET_PROFILE_FOR_PREVIEW";
export const SET_APP_LOCALE_FOR_PREVIEW = "SET_APP_LOCALE_FOR_PREVIEW";
export const OPEN_PREVIEW_SETTING_DRAWER = "OPEN_PREVIEW_SETTING_DRAWER";
export const CLOSE_PREVIEW_SETTING_DRAWER = "CLOSE_PREVIEW_SETTING_DRAWER";
export const SELECT_REC_TEST_STEP_FOR_PLAY="SELECT_REC_TEST_STEP_FOR_PLAY";
export const UPLOAD_AND_SAVE_BUILD_REQUEST="UPLOAD_AND_SAVE_BUILD_REQUEST";
export const VISIBLE_RECORDING_SETTINGS_MODAL="VISIBLE_RECORDING_SETTINGS_MODAL";
export const SHOW_ADD_PROJECT_KEYS_FORM="SHOW_ADD_PROJECT_KEYS_FORM";
export const LOAD_EDIT_PROJECT_STRING_DETAILS="LOAD_EDIT_PROJECT_STRING_DETAILS";
export const HIDE_ADD_PROJECT_KEYS_FORM="HIDE_ADD_PROJECT_KEYS_FORM";
export const CHANGE_NEW_PROJECT_KEY_VALUE="CHANGE_NEW_PROJECT_KEY_VALUE";
export const SAVE_PROJECT_KEYS_FORM_REQUEST="SAVE_PROJECT_KEYS_FORM_REQUEST";
export const SAVE_PROJECT_KEYS_FORM_REQUESTED="SAVE_PROJECT_KEYS_FORM_REQUESTED";
export const CHANGE_NEW_PROJECT_KEY_NAME="CHANGE_NEW_PROJECT_KEY_NAME";
export const REMOVE_APPLICATION_STRINGS_XML = "REMOVE_APPLICATION_STRINGS_XML";
export const CHANGE_PARENT_REC_TEST_STEP_FOR_AI_GENERATED_STEP = "CHANGE_PARENT_REC_TEST_STEP_FOR_AI_GENERATED_STEP";
export const EDIT_APP_BUILD="EDIT_APP_BUILD";
export const RELEASE_DEVICE_LOADING="RELEASE_DEVICE_LOADING";
export const PUSH_UPLOAD_BUILD_ID="PUSH_UPLOAD_BUILD_ID";
export const START_PREVIEW_LOADER="START_PREVIEW_LOADER";
export const GET_DEVICE_PROJECT_LIST="GET_DEVICE_PROJECT_LIST";
export const HIDE_DEVICE_MORE_DETAILS_MODAL="HIDE_DEVICE_MORE_DETAILS_MODAL";
export const FETCH_DEVICE_PROJECT_LIST_LOADER="FETCH_DEVICE_PROJECT_LIST_LOADER";
export const SET_ACCESSIBILITY_REPORT="SET_ACCESSIBILITY_REPORT";
export const SET_LOCAL_REPORT="SET_LOCAL_REPORT";
export const VISIBLE_GENERATE_STEPS_FROM_AI_MODAL="VISIBLE_GENERATE_STEPS_FROM_AI_MODAL";
export const HIDE_GENERATE_STEPS_FROM_AI_MODAL="HIDE_GENERATE_STEPS_FROM_AI_MODAL";

//Pending Invitation
export const SENT="SENT";
export const RECEIVED="RECEIVED";

export const splitValue = "#xpress#";
export const locales = {
  Afrikaans: "af-ZA",
  Arabic: "ar",
  Bulgarian: "bg-BG",
  Catalan: "ca-AD",
  Czech: "cs-CZ",
  Welsh: "cy-GB",
  Danish: "da-DK",
  "German (Austria) ": "de-AT",
  "German (Switzerland) ": "de-CH",
  "German (Germany) ": "de-DE",
  Greek: "el-GR",
  "English (UK) ": "en-GB",
  "English (US) ": "en-US",
  "Spanish (Chile) ": "es-CL",
  "Spanish (Spain) ": "es-ES",
  "Spanish (Mexico) ": "es-MX",
  Estonian: "et-EE",
  Basque: "eu",
  Persian: "fa-IR",
  Finnish: "fi-FI",
  "French (Canada) ": "fr-CA",
  "French (France) ": "fr-FR",
  Hebrew: "he-IL",
  Hindi: "hi-IN",
  Croatian: "hr-HR",
  Hungarian: "hu-HU",
  Indonesian: "id-ID",
  Icelandic: "is-IS",
  Italian: "it-IT",
  Japanese: "ja-JP",
  Khmer: "km-KH",
  Korean: "ko-KR",
  Latin: "la",
  Lithuanian: "lt-LT",
  Latvian: "lv-LV",
  Mongolian: "mn-MN",
  "Norwegian (Bokmål) ": "nb-NO",
  Dutch: "nl-NL",
  "Norwegian (Nynorsk) ": "nn-NO",
  Polish: "pl-PL",
  "Portuguese (Brazil) ": "pt-BR",
  "Portuguese (Portugal) ": "pt-PT",
  Romanian: "ro-RO",
  Russian: "ru-RU",
  Slovak: "sk-SK",
  Slovenian: "sl-SI",
  Serbian: "sr-RS",
  Swedish: "sv-SE",
  Thai: "th-TH",
  Turkish: "tr-TR",
  Ukrainian: "uk-UA",
  Vietnamese: "vi-VN",
  "Chinese (PRC) ": "zh-CN",
  "Chinese (Taiwan) ": "zh-TW",
};

export const TOOLTIP_BG_COLOR = "white";

export const elementDetailDisplayText = {
  searchKey: "Search By",
  useFilterType: "Filter",
  recElementTypeName: "Element Type",
  recElementTypePlatform: "Platform",
  name: "Name",
  useTestData: "Test Data",
  elementSelectorType: "Element Selector",
  filterType: "Filter Type",
  searchValue: "Search Value",
  elementType: "Element Type",
  testDataValue: "Test Data Value",
};

export const elementSearchKeyDisplayText = {
  contains_text: "containing Text",
  exact_text: "having exact Text(Case Sensitive)",
  text: "having exact Text(Case Insensitive)",
};

export const recTestStepSettings = [
  "previous_retryCount",
  "previous_swipeCountToFindElement",
  "previous_swipeDirection",
  "previous_swipeToFindElement",
  "previous_isCaptureScreenshot",
  "previous_outputTestDataId",
  "previous_outputTestData",
  "previous_pauseTime",
  "previous_isLoopStep",
  "previous_loopCount",
  "previous_continueAfterFail",
  "previous_stepName",
  "previous_captureScreenShotDelayTime",
  "previous_packageName",
  "previous_packageActivity",
  "previous_recSwipeAttributes",
  "previous_invertResult",
  "previous_changeApplicationStringsLocale",
];

export const recElementSettings = [
  "previous_retryCount",
  "previous_elementMaxLoadTime",
  "previous_swipeToBringElementToCenter",
  "previous_name",
  "previous_isActive",
  "previous_recElementLocatorProperties",
  "previous_searchValue",
  "previous_filterType",
  "previous_searchKey",
  "previous_applicationStrings",
];

export const NOTES_IN_TEST_SCENARIO =
  "Updating or Deleting the Key/Value will affect the Test Scenario Steps where the Key is used. Please review the impact before making the changes.";
export const NOTES_IN_TEST_BLOCK =
  "Updating or Deleting the Key/Value will affect the Test Block Steps where the Key is used. The Test Scenarios that include the Test Blocks will subsequently be affected. Please review the impact before making the changes.";
export const NOTES_IN_TEST_DATA =
  "Updating or Deleting the Key/Value will affect the Steps where the Key is used. Please review the impact before making the changes.";

export const INITIAL_APP_BUILD_DATA={
  buildId: "",
  buildVersion: "",
  displayName: "",
  buildDate: "",
  buildTime: "",
  tag: "",
  buildType: "",
  buildName: "",
  uploadToCloud: "",
  awsDeviceFarmProject: "",
  existingBuild: {},
  appFile: {},
  isPushToCloud: 0,
  enableLT: false,
  enableSL: false,
  enableX: false,
  appActivity:""
};

export const allowedAppActivtyForFarm=[SAUCE_LAB,LAMBDATEST];

//deviceImage
export const GOOGLE_PIXEL_4A="google Pixel 4a";
export const SAMSUNG_A02="samsung A02";
export const SAMSUNG_A52="samsung A52";
export const MOTOROLA_MOTO_G04="motorola Moto G04"
export const SAMSUNG_M04="samsung M04";
export const SAMSUNG_A05="samsung A05";
export const IPHONE_11="Apple iPhone 11";
export const IPHONE_X="Apple iPhone X"
export const IPHONE_SE="Apple iPhone SE";
export const IPHONE_XR="Apple iPhone XR";
export const IPHONE_12="Apple iPhone 12";
export const SAMSUNG_M12="samsung M12";
export const GOOGLE_PIXEL_6_PRO="google Pixel 6 Pro";

export const ONE_PLUS_6T="Oneplus ONEPLUS A6010";
export const IPHONE_11_MWLY2="Apple MWLY2";
export const VIVO="vivo V2141";

export const AI_SCENARIO_GENERATE_STEP_TYPE = {
  CLEAR_EXISTING_STEPS_AND_START:"CLEAR_EXISTING_STEPS_AND_START",
  APPEND_WITH_EXISTING_STEPS:"APPEND_WITH_EXISTING_STEPS"
}
export const APPLICATION_STRING_INFO_MESSAGE =
  " This Step is linked to an Application String. Please add at least one relative identification rule before saving changes to ensure proper functionality.";
export const DEVICE_STRING_INFO_MESSAGE =
  " This Step is linked to a Device String. Please add at least one relative identification rule before saving changes to ensure proper functionality.";

export const CONFIRM_MODAL_CONTENT="Are you sure, you want to exit? Your changes will be lost!";
export const GENERATE_AI_STEPS_CONFIRM_MODEL_TEXT="Are you sure you want to stop the generation?"
export const SYSTEM_KEY_WARNING_CONTENT = "System Keys are grouped into a 'System Keys Set' that can then be mapped to specific devices at time of\n" +
  "execution! e.g. Multiple System Key Sets with different Login credentials can be created and mapped to different\n" +
  "devices to avoid using the same Account during parallel execution on those devices.";


export const defaultModelInstructions={
  "gpt-4o-2024-05-13":defaultOpenAiInstructions,
  "ft:gpt-4o-2024-08-06:personal:81examples:ARViP8S6":defaultOpenAiInstructions,
  "Anthropic":defaultAnthropicInstructions
}

//AI Report
export const ACCESSIBILITY_REPORT="Accessibility Report";
export const LOCAL_REPORT="Local Report";

export const stepTitleActionMapping ={
  "Swipe":"Scroll Page",
  "tap":"Tap",
  "longPress": "Long Press",
  "verifications":"Verify Element",
  "sendKeys":"Input Text" ,
  "clearText":"Clear Text" ,
  "tapByIndex":"Tap By Index",
  "tapByCoordinates":"",
  "HOME":"Navigate to Home",
  "BACK":"Navigate Back",
  "ENTER":"Keypad Enter",
  "clear":"Keypad Clear",
  "deviceInteraction":"",
  "launchApp" :"Launch App",
  "pause":"Add Delay",
  "captureScreenshot":"",
  "insertValueToOutputTestData":"Assign Value to Test Data",
  "swipeOverElement":"Swipe Element",
  "ScreenShot Element" :"Screenshot Element",
}

export const isCloudFarm = {
  XP: "XPRESS",
  LT: "LAMBDATEST",
  SL: "SAUCELABS",
};
