import { Tooltip } from "antd";
import { isEmpty } from "lodash";
import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ARROW_DOWN } from "../../../../../CdnImagePath";
import {
  ACTION_VIEW,
  CUSTOM,
  DEVICE_EXECUTION_FARM,
  DEVICE_FARM_USE,
  INFO_TYPE,
  JOB_DESIGNER,
  PRIVATE,
  PUBLIC,
  RECURRING,
  SCHEDULE_TEST_JOB,
  TEST_PLAN,
  TOOLTIP_BG_COLOR,
  defaultDateTimeAmPm,
} from "../../../../../Constants";
import {
  DARK_GREEN,
  ICON_EDIT_NEW,
  ICON_PLAY,
  ICON_SCHEDULED_NEW,
  SILVER_GRAY,
} from "../../../../../Constants/SvgConstants";
import { getFormateDateAndTime, showNotification } from "../../../../../Util";
import SvgLoader from "../../../../../Util/SvgLoader";
import { openPreviewSettingDrawer } from "../../../../../actions/RecTestStep/RecTestStepRedirectAction";
import { changeTestDataSetView } from "../../../../../actions/TestDataSet/TestDataSetCommonAction";
import {
  changeExecutionManagerTabAndSubTab,
  changeSelectedTestJobCriteria,
  getTestJobByJobId,
  openTestJobScheduleDrawer,
  scheduleNow,
} from "../../../../../actions/TestJobActions";
import ActionComponentV2 from "../../../../CoreComponents/ActionComponentV2/ActionComponentV2";
import styles from "./AllJobsDetailsColumnsV2.module.scss";
import CommonConfirmationModalV2 from "../../../../CommonComponents/CommonConfirmationModalV2/CommonConfirmationModalV2";

const JobDetailsActionsColumnV2 = (props) => {
  const dispatch = useDispatch();
  const { row, setCurrentMenuOptionsSelected, handleActionChange, setSelectedTestJob } = props;
  const { loggedInUserDetails } = useSelector((state) => state.HomeReducer);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const { getSearchDeviceList } = useSelector((state) => state.ManageFarmReducer);

  const showTooltipTitle = (row) =>
    ` Execution on Every ${row?.minutes}th past the ${row?.hours}th
    ${row?.days}
    (${row?.timeZoneName})`;

  const TestJobActions = (row) => {
    let arr = [
      {
        title: "Duplicate Job",
        name: "duplicateJob",
      },
    ];
    if (row?.allowExec === ACTION_VIEW) {
      arr.push({ title: "Get URL", name: "getUrl" });
    }
    if (row?.allowDelete === ACTION_VIEW) {
      arr.push({ title: "Delete Job", name: "deleteJob" });
    }
    if (loggedInUserDetails?.userEmail === row?.createdBy?.email || row?.allowAdmin === ACTION_VIEW) {
      arr.push({
        title: "Permissions",
        name: "permissions",
        children: [
          ((row?.isPrivate !== 1 && loggedInUserDetails?.userEmail === row?.createdBy?.email) ||
            (row?.isPrivate === 1 &&
              !isEmpty(row?.testJobRights?.data) &&
              loggedInUserDetails?.userEmail === row?.createdBy?.email)) && {
            title: "Make Private",
            name: PRIVATE,
          },
          row?.isPrivate !== 0 && {
            title: "Make Public",
            name: PUBLIC,
          },
          {
            title: "Custom",
            name: CUSTOM,
          },
        ],
      });
    }
    return arr;
  };

  const deviceDetails = useMemo(
    () =>
      (getSearchDeviceList &&
        getSearchDeviceList.length > 0 &&
        getSearchDeviceList.filter(
          (data) =>
            !data.device.isDeviceOffline &&
            [DEVICE_FARM_USE.SCENARIO_RECORDING, DEVICE_FARM_USE.BOTH].includes(
              data.miscDetails?.farmDetails?.deviceFarmUse
            )
        )) ||
      [],
    [getSearchDeviceList]
  );
  const allDeviceIncludesInDeviceList = row?.devices?.every((i) =>
    deviceDetails?.some((item) => item?.miscDetails?.deviceDetail?.targetUniqueId.toString() === i?.targetUniqueId)
  );

  return (
    <div className={styles["action-icon"]}>
      {row?.allowExec === ACTION_VIEW && (
        <>
          <div className={styles["button_For_Action_Step"]}>
            <Tooltip color={TOOLTIP_BG_COLOR} title="Execute" mouseEnterDelay={0} mouseLeaveDelay={0}>
              <div
                className="cursor__pointer"
                onClick={() => {
                  (!allDeviceIncludesInDeviceList && row?.deviceServiceFarm === DEVICE_EXECUTION_FARM.XPRESS) ||
                  row?.isBuildDeleted === true
                    ? setOpenConfirmationModal(true)
                    : row?.testPlanDetail?.totalSteps === 0
                    ? showNotification(
                        INFO_TYPE,
                        "A Job needs at least 1 scenario to execute. Please update TestPlan and try again!",
                        true
                      )
                    : dispatch(
                        scheduleNow(
                          {
                            testJobId: row?.id,
                            isManual: 1,
                            accessibilityReport: row?.accessibilityReport,
                            localeReport: row?.localeReport,
                          },
                          setCurrentMenuOptionsSelected
                        )
                      );
                }}
              >
                <SvgLoader iconName={ICON_PLAY} iconColor={DARK_GREEN} width={"1.625rem"} height={"1.625rem"} />
              </div>
            </Tooltip>
            <Tooltip color={TOOLTIP_BG_COLOR} title="Custom Job" mouseEnterDelay={0} mouseLeaveDelay={0}>
              <div
                className="cursor__pointer"
                onClick={() => {
                  setSelectedTestJob(row);
                  dispatch(changeTestDataSetView(TEST_PLAN));
                  dispatch(openPreviewSettingDrawer());
                }}
              >
                <SvgLoader iconName={ARROW_DOWN} iconColor={SILVER_GRAY} width={"1.625rem"} height={"1.625rem"} />
              </div>
            </Tooltip>
          </div>
        </>
      )}
      {row.allowEdit === ACTION_VIEW && (
        <Tooltip color={TOOLTIP_BG_COLOR} title="Edit" mouseEnterDelay={0} mouseLeaveDelay={0}>
          <div className={styles["job_Action__Buttons"]}>
            <SvgLoader
              iconName={ICON_EDIT_NEW}
              handleClick={() => {
                dispatch(changeExecutionManagerTabAndSubTab(JOB_DESIGNER, false));
                dispatch(getTestJobByJobId({ testJobId: row?.id }));
              }}
            />
          </div>
        </Tooltip>
      )}

      {row?.allowExec === ACTION_VIEW && (
        <Tooltip
          color={TOOLTIP_BG_COLOR}
          title={
            row?.lastRun?.status === "SCHEDULED"
              ? row?.frequency === RECURRING
                ? showTooltipTitle(row)
                : getFormateDateAndTime(row?.updateDate, defaultDateTimeAmPm)
              : "Schedule"
          }
          mouseEnterDelay={0}
          mouseLeaveDelay={0}
        >
          <div className={styles["job_Action__Buttons"]}>
            <SvgLoader
              iconName={ICON_SCHEDULED_NEW}
              iconColor={row?.isJobScheduled === 1 && row?.frequency !== null ? "#4a9bf2" : SILVER_GRAY}
              handleClick={() => {
                dispatch(openTestJobScheduleDrawer(row?.id));
              }}
            />
          </div>
        </Tooltip>
      )}
      <ActionComponentV2 actions={TestJobActions(row)} data={row} handleActionChange={handleActionChange} />
      <CommonConfirmationModalV2
        handleOk={() => {
          dispatch(changeExecutionManagerTabAndSubTab(JOB_DESIGNER, false));
          dispatch(getTestJobByJobId({ testJobId: row?.id }));
          row?.isBuildDeleted === true
            ? dispatch(changeSelectedTestJobCriteria(SCHEDULE_TEST_JOB.STEP3))
            : dispatch(changeSelectedTestJobCriteria(SCHEDULE_TEST_JOB.STEP2));
          setOpenConfirmationModal(false);
        }}
        handleCancel={() => {
          row?.isBuildDeleted === true
            ? setOpenConfirmationModal(false)
            : dispatch(
                scheduleNow(
                  {
                    testJobId: row?.id,
                    isManual: 1,
                    accessibilityReport: row?.accessibilityReport,
                    localeReport: row?.localeReport,
                  },
                  setCurrentMenuOptionsSelected
                )
              );
          setOpenConfirmationModal(false);
        }}
        modalClose={() => setOpenConfirmationModal(false)}
        modalOpen={openConfirmationModal}
        modalContent={
          row?.isBuildDeleted === true
            ? "The build initially selected for this job is no longer available. Please review builds or cancel the operation."
            : "Some device(s) selected for this job are currently unavailable. It is recommended to review devices list before you continue."
        }
        modalTitle={"Confirm Job Execution"}
        cancelText={row?.isBuildDeleted === true ? "Cancel" : "Run Anyway"}
        okText={row?.isBuildDeleted === true ? "Review Builds" : "Review Devices"}
        isAutoWidth={true}
      />
    </div>
  );
};

export default JobDetailsActionsColumnV2;
