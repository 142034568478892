import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input, Select, Tooltip } from "antd";
import { isEmpty } from "lodash";
import {
  ELEMENT_ACTION,
  OPERATION,
  TOOLTIP_BG_COLOR,
  splitValue,
} from "../../../../../Constants";
import {
  DEEP_BLUE_COLOR,
  DISABLE_COLOR,
  SELECT_TEST_DATA_ICON_NEW,
} from "../../../../../Constants/SvgConstants";
import SvgLoader from "../../../../../Util/SvgLoader";
import {
  changeEnterText,
  selectTestData,
} from "../../../../../actions/RecTestStep/RecTestStepRedirectAction";
import { selectRecTestSteps } from "../../../../../actions/RecTestStepAction";
import { visibleTestDataDrawer } from "../../../../../actions/TestDataAction";
import AppendRandomTextV2 from "./AppendRandomTextV2";
import ManageTestDataV2 from "../../ManageTestDataV2/ManageTestDataV2";
import styles from "./EnterTextV2.module.scss";

const EnterTextV2 = (props) => {
  const dispatch = useDispatch();
  const { data, showTestDataContent, testDataChecked, disabled } = props;
  const { testDataList } = useSelector(
    (state) => state.RecTestStepCommonViewReducer
  );
  const { openTestDataSetDrawerByTabName, tagForOpenDrawer } = useSelector(
    (props) => props.TestDataReducer
  );
  const { action, userInputValue } = data.recTestStepProperty;

  return (
    <div className={styles["enter_Text_Details"]}>
      {!!testDataChecked ? (
        <div className={`${styles["enter_Text_Details"]} gap-14`}>
          <div className={styles["input_Or_Select_Label"]}>Test Data</div>
          <div className={styles["select_Box_And_Select_Icon"]}>
            <Select
              className={styles["select_Test_Data"]}
              disabled={disabled}
              showSearch
              addonBefore="App Package Name"
              placeholder="Select a Test Data Key"
              value={
                data.recTestStepProperty?.testData
                  ? data.recTestStepProperty?.testData.id +
                    splitValue +
                    data.recTestStepProperty?.testData.value
                  : undefined
              }
              onChange={(value) =>
                dispatch(
                  selectTestData(
                    data.tag,
                    value.split(splitValue)[0],
                    value.split(splitValue)[1]
                  )
                )
              }
            >
              {!isEmpty(testDataList) &&
                testDataList?.map((v, k) => {
                  return (
                    <Select.Option
                      key={v.id + splitValue + v.value}
                      value={v.id + splitValue + v.value}
                    >
                      {v.name + (v.value ? " (" + v.value + ")" : "")}
                    </Select.Option>
                  );
                })}
            </Select>
            <Tooltip title="Select Test Data" color={TOOLTIP_BG_COLOR}>
              <SvgLoader
                iconName={SELECT_TEST_DATA_ICON_NEW}
                disabled={props.disabled}
                iconColor={props.disabled ? DISABLE_COLOR : DEEP_BLUE_COLOR}
                width="1.38rem"
                handleClick={() => {
                  if (!props.disabled) {
                    dispatch(visibleTestDataDrawer(OPERATION, data?.tag));
                    dispatch(selectRecTestSteps(data));
                  }
                }}
              />
            </Tooltip>
          </div>
        </div>
      ) : (
        <div className={`${styles["enter_Text_Details"]} gap-14`}>
          <div className={styles["input_Or_Select_Label"]}>Text to Input</div>
          <Input
            required={action === ELEMENT_ACTION.SEND_KEYS}
            type="text"
            name=""
            value={userInputValue}
            onChange={(e) => {
              dispatch(changeEnterText(e.target.value, data.tag));
            }}
            placeholder="Enter the text"
            disabled={showTestDataContent || disabled}
            className={styles["test_Data_Set_Input"]}
          />
        </div>
      )}
      {openTestDataSetDrawerByTabName === OPERATION &&
        data?.tag === tagForOpenDrawer && (
          <ManageTestDataV2 {...props} tabName={OPERATION} />
        )}
    </div>
  );
};

export default EnterTextV2;
