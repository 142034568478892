import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { changeAppBUildListPageNumber, getAllBuildDetails } from "../../../actions/Recording";
import { All_RECORDS, ALPHA, MY_BUILDS, OWNED, SHARED, SHARED_WITH_ME } from "../../../Constants";
import { getSelectedBuildType } from "../../../Util";
import HeaderButtonsGroupV2 from "../../CommonComponents/HeaderButtonsGroupV2/HeaderButtonsGroupV2";
import FilterComponent from "../../CoreComponents/FilterComponent/FilterComponent";
import CommonSearchV2 from "../../CoreComponents/SearchComponent/CommonSearchV2";
import styles from "./AppBuildListV2.module.scss";

const filterPermission = [
  { id: OWNED, value: MY_BUILDS },
  { id: SHARED, value: SHARED_WITH_ME },
  { id: "", value: All_RECORDS },
];

const filterList = [
  {
    title: "All",
    name: "",
  },
  {
    title: ALPHA,
    name: "ALPHA",
  },
  {
    title: "Beta",
    name: "BETA",
  },
  {
    title: "Dev",
    name: "DEV",
  },
  {
    title: "Prod",
    name: "PROD",
  },
  {
    title: "Other",
    name: "OTHER",
  },
];

const AppBuildListHeaderV2 = (props) => {
  const dispatch = useDispatch();
  const {
    executionTab,
    projectId,
    searchKeyword,
    stepType,
    setSearchKeyword,
    rights,
    setRights,
    devicePushBuild,
    selectBuildType,
    setSelectBuildType,
  } = props;
  const [activeFilter, setActiveFilter] = useState(rights ? rights : { id: OWNED, value: MY_BUILDS });

  const handleFilters = (e) => {
    setActiveFilter(e);
    setRights(e);
    dispatch(getAllBuildDetails(false, searchKeyword, projectId, "", e?.id, stepType));
  };

  return (
    <div
      className={`${styles["app_Build_Details_Header"]} ${
        devicePushBuild ? styles["device_Push_Build_Modal_Search"] : ""
      }`}
    >
      <div>
        {!executionTab && !devicePushBuild && (
          <HeaderButtonsGroupV2
            data={filterPermission}
            activeButton={activeFilter?.id}
            onClick={(e) => handleFilters(e)}
          />
        )}
      </div>
      <div className="display-flex">
        <div>
          <CommonSearchV2
            placeHolder="Search By Build Names, Build Type, Build Version"
            searchDetails={(searchText) =>
              dispatch(
                getAllBuildDetails(
                  false,
                  searchText,
                  projectId,
                  executionTab ? "DONE" : "",
                  executionTab ? "" : rights?.id,
                  stepType,
                  executionTab ? 1 : 0
                )
              )
            }
            searchKeyword={searchKeyword}
            setSearchKeyword={setSearchKeyword}
          />
        </div>
        <FilterComponent
          filterList={filterList}
          handleFilterChange={(filter) => {
            dispatch(changeAppBUildListPageNumber(1));
            setSelectBuildType(filter);
            dispatch(
              getAllBuildDetails(
                false,
                searchKeyword,
                projectId,
                executionTab ? "DONE" : "",
                executionTab ? "" : rights?.id,
                stepType,
                executionTab ? 1 : 0,
                true,
                filter
              )
            );
          }}
          selectedFilter={getSelectedBuildType(selectBuildType)}
        />
      </div>
    </div>
  );
};

export default AppBuildListHeaderV2;
