import React, { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input, Spin } from "antd";
import isEmpty from "lodash/isEmpty";
import {
  ALL_TEST_SCENARIOS,
  CONFIRM_MODAL_CONTENT,
  GENERATE_AI_STEPS_CONFIRM_MODEL_TEXT,
  PLAY_TYPE,
  RESET_TEST_DATA_SET_VIEW,
  STOP_PREVIEW_ERROR_MSG_FOR_SCENARIO,
} from "../../../Constants";
import {
  changeRecTestScenarioName,
  changeSearchScenarioKeyword,
  updateRecTestScenarioName,
} from "../../../actions/RecTestScenarioAction";
import { resetEditRecTestStep } from "../../../actions/RecTestStep/RecTestStepCommonAction";
import {
  hideAIGenerateStepsRow,
  stopGenerateStepsFromAIModal,
} from "../../../actions/RecTestStep/RecTestStepRedirectAction";
import { stopPreview } from "../../../actions/RecTestStepAction";
import { recordingStop, resetElement } from "../../../actions/Recording";
import {
  setApplicationAssigneeDetail,
  setTestDataSetAssigneeDetail,
} from "../../../actions/TestDataSet/TestDataSetCommonAction";
import { resetPreviousTestDataSetForEdit } from "../../../actions/TestDataSetAction";
import CommonConfirmationModalV2 from "../../CommonComponents/CommonConfirmationModalV2/CommonConfirmationModalV2";
import EditTestHeaderOptionsV2 from "../../CommonComponents/EditTestHeaderOptionsV2/EditTestHeaderOptionsV2";
import RecTestStepSelectionBoxV2 from "./RecTestStepSelectionBoxV2";
import styles from "./TestBlockAndScenarioHeaderV2.module.scss";

const RecTestScenarioHeaderV2 = ({ setCurrentOptions, projectId, orgId }) => {
  const dispatch = useDispatch();

  const {
    selectedRecTestScenarioName,
    previous_RecTestScenarioName,
    isPreviewRunning,
    requestingForPreview,
    selectedDeviceByUdid,
    recTestStepList,
  } = useSelector((state) => state.RecTestStepReducer);
  const {
    requestingPerformRecTestStepAction,
    unSavedRecStepTag,
    scenarioGenerateRequestLoading,
    scenarioObjective,
    scenarioGenerateInstructions,
    selectedModelType,
    isLoadingForAIStep,
  } = useSelector((state) => state.RecTestStepCommonViewReducer);
  const { recordingState } = useSelector((state) => state.RecordingReducer);
  const { selectedTagsObject, updatingRecordingScenarioSpinner } = useSelector((state) => state.RecTestScenarioReducer);
  const { testDataSetAssigneeDetail, previous_testDataSetForEdit } = useSelector((state) => state.TestDataSetReducer);

  const [isInputActive, setIsInputActive] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const disableScenarioUpdate = useMemo(() => {
    return (
      selectedRecTestScenarioName === "" ||
      previous_RecTestScenarioName === undefined ||
      (previous_RecTestScenarioName !== undefined && previous_RecTestScenarioName === selectedRecTestScenarioName)
    );
  }, [selectedRecTestScenarioName, previous_RecTestScenarioName]);

  const isDisabled = useMemo(() => {
    return unSavedRecStepTag !== undefined || isPreviewRunning || isLoadingForAIStep !== null;
  }, [unSavedRecStepTag, isPreviewRunning, isLoadingForAIStep]);

  const isStepRunning = isPreviewRunning || (requestingForPreview.includes(PLAY_TYPE.ALL_STEPS) && !isPreviewRunning);

  const handleConfirmBack = () => {
    setCurrentOptions(ALL_TEST_SCENARIOS);
    if (recordingState === "RECORDING" || unSavedRecStepTag || previous_testDataSetForEdit) {
      dispatch(changeSearchScenarioKeyword(""));
      dispatch({ type: RESET_TEST_DATA_SET_VIEW });
      dispatch(recordingStop());
      dispatch(resetElement());
      dispatch(resetEditRecTestStep());
      dispatch(setTestDataSetAssigneeDetail(undefined));
      dispatch(resetPreviousTestDataSetForEdit());
      dispatch(setApplicationAssigneeDetail(undefined));
    } else if (isPreviewRunning || (requestingForPreview.includes(PLAY_TYPE.ALL_STEPS) && !isPreviewRunning)) {
      dispatch(stopPreview());
    } else if (scenarioGenerateRequestLoading) {
      dispatch(
        stopGenerateStepsFromAIModal({
          objective: scenarioObjective,
          rules: scenarioGenerateInstructions,
          deviceId: selectedDeviceByUdid?.targetUniqueId,
          modelType: selectedModelType,
        })
      );
      dispatch(hideAIGenerateStepsRow());
    }
  };
  const onBackClick = () => {
    if (
      recordingState === "RECORDING" ||
      unSavedRecStepTag ||
      previous_testDataSetForEdit ||
      isStepRunning ||
      scenarioGenerateRequestLoading
    ) {
      setOpenConfirmModal(true);
    } else {
      setCurrentOptions(ALL_TEST_SCENARIOS);
      dispatch(changeSearchScenarioKeyword(""));
      dispatch({ type: RESET_TEST_DATA_SET_VIEW });
      dispatch(setTestDataSetAssigneeDetail(undefined));
      dispatch(setApplicationAssigneeDetail(undefined));
      dispatch(hideAIGenerateStepsRow());
    }
  };

  const changeScenarioName = useCallback(
    (e) => {
      let scenarioChangedUpdateVisible = true;
      if (e.target.value.length > 0) {
        scenarioChangedUpdateVisible = false;
      } else {
        scenarioChangedUpdateVisible = true;
      }
      dispatch(changeRecTestScenarioName(e.target.value, scenarioChangedUpdateVisible));
      setIsInputActive(true);
    },
    [dispatch]
  );

  const handleSaveScenarioName = useCallback(() => {
    setIsInputActive(false);
    !disableScenarioUpdate
      ? dispatch(updateRecTestScenarioName(selectedTagsObject, true))
      : dispatch(changeRecTestScenarioName(previous_RecTestScenarioName, true));
  }, [disableScenarioUpdate, dispatch, previous_RecTestScenarioName, selectedTagsObject]);

  return (
    <>
      <div className={styles["header"]}>
        {!isEmpty(recTestStepList) && <RecTestStepSelectionBoxV2 disabled={isDisabled} />}
        <div className={`${styles["header_Title"]} ${isInputActive && styles["action_Button_Hide"]}`}>
          <div className={styles["text_Input"]}>
            <div className={styles["step_Text"]}>Scenario: </div>
            <Input
              className={styles["input_Style"]}
              onChange={(e) => changeScenarioName(e)}
              value={selectedRecTestScenarioName}
              onBlur={() => {
                handleSaveScenarioName();
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  handleSaveScenarioName();
                }
              }}
              autoFocus
              disabled={isPreviewRunning || isLoadingForAIStep !== null}
            />
            <Spin spinning={updatingRecordingScenarioSpinner}></Spin>
          </div>
        </div>
        {!isInputActive && (
          <EditTestHeaderOptionsV2
            disabled={isDisabled}
            testDataSetAssigneeDetail={testDataSetAssigneeDetail}
            projectId={projectId}
            orgId={orgId}
            onBackClick={onBackClick}
          />
        )}
      </div>
      <CommonConfirmationModalV2
        handleOk={() => {
          handleConfirmBack();
          setOpenConfirmModal(false);
        }}
        handleCancel={() => setOpenConfirmModal(false)}
        modalOpen={openConfirmModal}
        modalContent={
          scenarioGenerateRequestLoading
            ? GENERATE_AI_STEPS_CONFIRM_MODEL_TEXT
            : isStepRunning
            ? STOP_PREVIEW_ERROR_MSG_FOR_SCENARIO
            : CONFIRM_MODAL_CONTENT
        }
        modalTitle={
          scenarioGenerateRequestLoading ? "Stop AI Step Generation" : isStepRunning ? "Stop Preview" : "Discard Changes"
        }
        okText={"Confirm"}
        isDeleteModal={true}
      />
    </>
  );
};

export default RecTestScenarioHeaderV2;
