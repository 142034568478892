import {
  ADD_TEST_BLOCK_CHILD_STEP,
  ADD_TEST_BLOCK_STEP,
  ADD_TEST_BLOCK_STEP_ACTION,
  CHANGE_TEST_BLOCK_LIST_FILTER,
  CHANGE_TEST_BLOCK_STEP_NAME,
  CHANGE_TEST_DATA_LIST_FILTER_VALUE,
  DELETE_TEST_BLOCK_STEP,
  DEVICE_INTERACTION_MODE,
  DEVICE_LOADING,
  ELEMENT_ACTION,
  ERROR_TYPE,
  FETCHED_TEST_STEP_LIST,
  FETCHING_TEST_STEP_LIST,
  GET_LIST_OF_BLOCKS,
  HIDE_ADD_TEST_DATA_FORM,
  HIDE_TEST_BLOCK_LIST_MODAL_VISIBLE,
  LAUNCH_APP,
  LOADING_LIST_OF_BLOCKS,
  MY_TEST_BLOCKS,
  OWNED,
  RECORD_10_PER_PAGE,
  REC_TEST_STEP_LIST,
  REQUESTED_ADD_NEW_SCENARIO,
  REQUESTED_DELETE_REC_TEST_STEP,
  REQUEST_ADD_NEW_SCENARIO,
  REQUEST_DELETE_REC_TEST_STEP,
  RESET_ALL_TEST_BLOCK_CHILD_STEP_IDS,
  RESET_TEST_BLOCK_STEP,
  SCENARIO_GENERATE_REQUEST_STOP,
  SELECTED_TEST_STEP_BLOCK,
  SELECTOR_TYPE,
  SET_SELECTED_TEST_BLOCK,
  SET_TEST_BLOCK_STEP,
  SHOW_TEST_BLOCK_LIST_MODAL_VISIBLE,
  STEP,
  SUCCESS_STATUS,
  SUCCESS_TYPE,
  TEST_BLOCK_DESIGNER,
  TEST_DATA_SCOPE,
  TEST_STEP_BLOCKS,
} from "../Constants";
import { getUniqueId, isActionNeedToSelectElement, showNotification, updateResponse } from "../Util";
import { async } from "../api/automation";
import { resetAllRecScenarioStepIds, updateScenarioOrTagField } from "./RecTestScenarioAction";
import {
  editRecTestStep,
  editRecTestStepElement,
  resetEditRecTestStep,
  resetPreviewSettings,
} from "./RecTestStep/RecTestStepCommonAction";
import {
  clearExpandedElements,
  clearExpandedSteps,
  clearPreview,
  hideAIGenerateStepsFromRecTestStep,
  hideDeleteRecTestStepPreConditionConfirmationModal,
  updateStepType,
} from "./RecTestStep/RecTestStepRedirectAction";
import { selectRecTestSteps } from "./RecTestStepAction";
import {
  changeDeviceInteractionMode,
  changeDeviceTabActionKey,
  recordingStart,
  recordingStop,
  resetElement,
} from "./Recording";
import { fetchTestData } from "./TestDataAction";
let controller = new AbortController();

export function getListOfBlocksByProjectId(
  projectId,
  pageNumber,
  searchWord,
  rights,
  needChildSteps = false,
  recordPerPage = RECORD_10_PER_PAGE
) {
  return async (dispatch, getState) => {
    const { testBlockListFilter } = getState().TestBlockReducer;
    dispatch({ type: LOADING_LIST_OF_BLOCKS, flag: true });
    if (needChildSteps || rights) {
      controller.abort(); // abort previous fetch here
      controller = new AbortController();
    }
    const signal = controller.signal;
    async(
      "recTestStepBlock/getListOfBlocksByProjectId",
      "GET",
      {
        projectId: projectId,
        pageNumber: pageNumber ? pageNumber : 1,
        recordPerPage: recordPerPage,
        searchKeywords: searchWord ? searchWord : "",
        rights: needChildSteps ? "" : rights ? rights?.id : testBlockListFilter?.id,
        needChildSteps: needChildSteps,
      },
      undefined,
      signal
    ).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        dispatch({ type: GET_LIST_OF_BLOCKS, response });
      } else {
        dispatch({ type: GET_LIST_OF_BLOCKS, response: undefined });
      }
      if (response?.status) {
        dispatch({ type: LOADING_LIST_OF_BLOCKS, flag: false });
      }
    });
  };
}

export function saveTestStepBlockRights(data, projectId, rights) {
  return async (dispatch) => {
    return async("recTestStepRights/customSave", "POST", data).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        showNotification(SUCCESS_TYPE, response.message);
        dispatch(getListOfBlocksByProjectId(projectId, 1, "", rights));
      } else {
        showNotification(ERROR_TYPE, response.message);
      }
    });
  };
}

export function showTestBlocksListModalVisible(projectId) {
  return async (dispatch) => {
    dispatch(getListOfBlocksByProjectId(projectId, 1, "", true));
    dispatch({ type: SHOW_TEST_BLOCK_LIST_MODAL_VISIBLE });
  };
}

export function hideTestBlocksListModalVisible() {
  return async (dispatch) => {
    dispatch({ type: HIDE_TEST_BLOCK_LIST_MODAL_VISIBLE });
  };
}

export function changeTestBlockStepName(value) {
  return async (dispatch) => {
    dispatch({ type: CHANGE_TEST_BLOCK_STEP_NAME, value });
  };
}

export function changeTestBlockListFilter(value, projectId, searchKeyword) {
  return async (dispatch, getState) => {
    dispatch({ type: CHANGE_TEST_BLOCK_LIST_FILTER, value });
    dispatch(getListOfBlocksByProjectId(projectId, 1, searchKeyword, value));
  };
}

export function addTestBlockStep(searchKeyword, navigateToEditBuildScenario) {
  return (dispatch, getState) => {
    dispatch({ type: REQUEST_ADD_NEW_SCENARIO });
    const projectId = getState().ProjectsReducer.projectId;
    let recTestStep = {
      tag: getUniqueId(),
      orderId: 1,
      recTestStepProperty: {
        action: STEP,
        operator: "AND",
        selectorType: SELECTOR_TYPE.SELECT_ELEMENT_ON_SCREEN,
        recElements: [],
        stepName: "Test Block",
      },
      recTestSteps: [],
      isTestBlock: 1,
      project: { id: projectId },
    };
    async("recTestStep/customSave", "POST", recTestStep).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        let blockElementGuid = getUniqueId();
        // dispatch(editRecTestStepElement(response.data.tag, blockElementGuid));
        dispatch({ type: ADD_TEST_BLOCK_STEP, response, blockElementGuid });
        dispatch({ type: SET_SELECTED_TEST_BLOCK, data: response.data });
        dispatch({
          type: REC_TEST_STEP_LIST,
          response,
          recTestScenarioId: response.data.id,
          recTestScenarioName: response.data.name,
          testBlockStep: response.data,
          platform: response.data.platform,
          stepType: TEST_STEP_BLOCKS,
        });
        dispatch(editTestStepBlocks(response?.data, projectId));
        navigateToEditBuildScenario(TEST_BLOCK_DESIGNER);
        dispatch(updateStepType(TEST_STEP_BLOCKS));
        dispatch({ type: REQUESTED_ADD_NEW_SCENARIO });
        dispatch(changeTestBlockListFilter({ id: OWNED, value: MY_TEST_BLOCKS }, projectId, searchKeyword));
      }
    });
  };
}

export function addTestBlockChildStep(parentRecTestStepId = undefined, withPrecondition, withVerifyTestData = false) {
  return (dispatch, getState) => {
    const { testBlockStepList, testBlockChildStepList } = getState().TestBlockReducer;
    const projectId = getState().ProjectsReducer.projectId;
    let parentRecTestStep = undefined;
    if (testBlockChildStepList) {
      parentRecTestStep = testBlockChildStepList.filter((item) => item.id === parentRecTestStepId);
    }
    let orderId = 1;
    if (parentRecTestStep && parentRecTestStep.length > 0) {
      orderId =
        parentRecTestStep[0].recTestSteps && parentRecTestStep[0].recTestSteps.length > 0
          ? parentRecTestStep[0].recTestSteps.length + 1
          : 1;
    } else {
      orderId = getState().TestBlockReducer.testBlockChildStepList
        ? getState().TestBlockReducer.testBlockChildStepList.length + 1
        : 1;
    }
    let recTestStep = {
      tag: getUniqueId(),
      orderId,
      parentRecTestStep: {
        id: testBlockStepList?.map((i) => i?.id)[0] || getState().TestBlockReducer.selectedTestBlockStepId,
      },
      recTestStepProperty: {
        action: STEP,
        operator: "AND",
        selectorType: SELECTOR_TYPE.SELECT_ELEMENT_ON_SCREEN,
        recElements: [],
        stepName:
          "New Step-" +
          (parentRecTestStepId
            ? parentRecTestStep.length > 0
              ? parentRecTestStep[0].recTestSteps.length + 1
              : 1
            : getState().TestBlockReducer.testBlockChildStepList
            ? getState().TestBlockReducer.testBlockChildStepList.length + 1
            : 1),
      },
      recTestScenario: null,
      recTestSteps: [],
      isTestBlock: 1,
      project: { id: projectId },
    };
    if (parentRecTestStepId) {
      recTestStep = {
        ...recTestStep,
        parentRecTestStep: { id: parentRecTestStepId },
      };
    }
    if (withPrecondition) {
      recTestStep["recTestStepProperty"] = {
        ...recTestStep["recTestStepProperty"],
        recStepVerifyElementProperties: [],
        recElements: [],
      };
    }
    async("recTestStep/customSave", "POST", recTestStep).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        dispatch(editRecTestStep(response.data.tag));
        dispatch({ type: ADD_TEST_BLOCK_CHILD_STEP, response, withPrecondition, withVerifyTestData });
        if (withPrecondition) {
          dispatch(recordingStart());
        }
      }
    });
  };
}
export function addTestBlockStepAction(newRecTestStepActionDetail, parentRecTestStepId) {
  return (dispatch, getState) => {
    let newRecTestStepTag = getUniqueId();
    let newRecTestStepElementGuid = getUniqueId();
    dispatch(editRecTestStepElement(newRecTestStepTag, newRecTestStepElementGuid));
    dispatch({
      type: ADD_TEST_BLOCK_STEP_ACTION,
      newRecTestStepActionDetail,
      parentRecTestStepId,
      newRecTestStepTag,
      newRecTestStepElementGuid,
    });
    if (isActionNeedToSelectElement(newRecTestStepActionDetail)) {
      dispatch(recordingStart());
    } else if (
      [
        ELEMENT_ACTION.SWIPE,
        ELEMENT_ACTION.PAUSE,
        LAUNCH_APP,
        ELEMENT_ACTION.INSERT_VALUE_TO_OUTPUT_TEST_DATA,
      ].includes(newRecTestStepActionDetail)
    ) {
      dispatch(changeDeviceInteractionMode(DEVICE_INTERACTION_MODE.BLOCK));
    }
  };
}

export function setSelectedTestStepBlocks(data) {
  return async (dispatch) => {
    dispatch({ type: SET_SELECTED_TEST_BLOCK, data });
  };
}

export function resetAllTestBlockChildStepIds() {
  return async (dispatch) => {
    dispatch({ type: RESET_ALL_TEST_BLOCK_CHILD_STEP_IDS });
  };
}

export function deleteTestBlockChildStep(selectedRectTestScenarioStepId) {
  return (dispatch, getState) => {
    dispatch({ type: REQUEST_DELETE_REC_TEST_STEP });
    const { testBlockStep } = getState().RecTestStepReducer;
    const { selectedRecTestStepIdForDelete, selectedRecTestStepTagForDelete } = getState().RecTestStepCommonViewReducer;
    // Delete the unsaved step directly
    if (selectedRecTestStepIdForDelete === undefined && selectedRecTestStepTagForDelete !== undefined) {
      dispatch({
        type: DELETE_TEST_BLOCK_STEP,
        deleteFromUnSavedStep: true,
        selectedRecTestStepIdForDelete,
        selectedRecTestStepTagForDelete,
      });
      dispatch(resetEditRecTestStep());
      // Delete the saved step directly from server
    } else if (
      (selectedRecTestStepIdForDelete !== undefined && selectedRecTestStepTagForDelete !== undefined) ||
      selectedRectTestScenarioStepId
    ) {
      let recTestScenarioRecTestStepsAssocs = {
        recTestSteps: selectedRecTestStepIdForDelete
          ? [{ id: selectedRecTestStepIdForDelete }]
          : selectedRectTestScenarioStepId && selectedRectTestScenarioStepId?.map((i) => ({ id: i })),
        projectId: getState().ProjectsReducer.projectId,
        isDeleteReference: getState().RecTestStepCommonViewReducer.isDelateRecTestStepRef,
      };
      async("recTestStepBlock/customDeleteBlock", "POST", recTestScenarioRecTestStepsAssocs).then((response) => {
        response = updateResponse(response);
        if (response.status === SUCCESS_STATUS) {
          dispatch(
            getTestBlockStepListByTestBlockId(
              testBlockStep.id,
              testBlockStep,
              testBlockStep.projectId,
              testBlockStep.platform
            )
          );
          dispatch({
            type: DELETE_TEST_BLOCK_STEP,
            deleteFromUnSavedStep: false,
            selectedRecTestStepIdForDelete,
            selectedRecTestStepTagForDelete,
            response,
          });
          showNotification("success", "Successfully Deleted Step.", "");
        } else {
          showNotification("error", response.message, "");
        }
        dispatch({ type: REQUESTED_DELETE_REC_TEST_STEP });
        dispatch(hideDeleteRecTestStepPreConditionConfirmationModal());
        dispatch(resetAllRecScenarioStepIds());
      });
    } else {
      dispatch({ type: REQUESTED_DELETE_REC_TEST_STEP });
      showNotification("error", "Error In Deleting The Step.", "");
    }
    dispatch(resetElement());
    dispatch(recordingStop());
  };
}

export function getTestBlockStepListByTestBlockId(id, testBlockStep, projectId, platform,recTestStep,stepGenerationComplete) {
  return async (dispatch, getState) => {
    dispatch({ type: FETCHING_TEST_STEP_LIST });
    dispatch({ type: CHANGE_TEST_DATA_LIST_FILTER_VALUE, value: TEST_DATA_SCOPE.ALL });
    async("recTestStepBlock/getChildStepsByBlockId", "GET", { recTestStepId: testBlockStep?.id || id }).then(
      (response) => {
        response = updateResponse(response);
        dispatch(resetEditRecTestStep());
        if (stepGenerationComplete) {
          dispatch(hideAIGenerateStepsFromRecTestStep(recTestStep?.id));
          dispatch({ type: SCENARIO_GENERATE_REQUEST_STOP });
        }
        dispatch({
          type: REC_TEST_STEP_LIST,
          response,
          testBlockStep,
          platform,
          stepType: TEST_STEP_BLOCKS,
        });
        dispatch(updateStepType(TEST_STEP_BLOCKS));
        if (projectId) {
          dispatch(fetchTestData(projectId));
        }
        dispatch({ type: FETCHED_TEST_STEP_LIST });
        dispatch(updateScenarioOrTagField(false));
        dispatch(clearPreview());
        dispatch({ type: HIDE_ADD_TEST_DATA_FORM });
      }
    );
  };
}

export function setTestBlockStep(testBlockStep) {
  return async (dispatch, getState) => {
    dispatch({ type: SET_TEST_BLOCK_STEP, testBlockStep });
  };
}

export function resetTestBlockStep() {
  return async (dispatch, getState) => {
    dispatch({ type: RESET_TEST_BLOCK_STEP });
  };
}

export function editTestStepBlocks(testBlockStep, projectId) {
  return async (dispatch, getState) => {
    dispatch(setTestBlockStep(testBlockStep));
    new Promise((resolve) => resolve(dispatch(changeDeviceTabActionKey(TEST_STEP_BLOCKS, projectId)))).then(() => {
      dispatch(clearExpandedElements());
      dispatch(clearExpandedSteps());
      dispatch(resetPreviewSettings());
      dispatch(selectRecTestSteps(undefined));
      dispatch({
        type: SELECTED_TEST_STEP_BLOCK,
        projectId,
        testBlockStep,
      });
      dispatch(getTestBlockStepListByTestBlockId("", testBlockStep, projectId, testBlockStep.platform));
      dispatch({ type: DEVICE_LOADING, response: { isLoading: false } });
    });
  };
}
