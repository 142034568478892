import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row, Select } from "antd";
import { isEmpty } from "lodash";
import { changeTestJobData, getBuildVersions, visibleCreateBuildModal } from "../../../../../actions/TestJobActions";
import { ALPHA, DEV, OTHER, PROD, RC_BETA } from "../../../../../Constants";
import { CREATE_ICON, WHITE_COLOR } from "../../../../../Constants/SvgConstants";
import CommonSelectBoxV2 from "../../../../CommonComponents/CommonSelectBoxV2/CommonSelectBoxV2";
import CustomButtonV2 from "../../../../CommonComponents/CustomButtonV2/CustomButtonV2";
import styles from "./JobCriteriaOptionsV2.module.scss";
import UploadBuildModalV2 from "./UploadBuildModalV2/UploadBuildModalV2";

const { Option } = Select;

const SelectBuildV2 = (props) => {
  const { buildVersionList, setBuildVersionList } = props;
  const dispatch = useDispatch();
  const { testJobDetail, isVisibleCreateBuildModal, isGetBuildVersionsLoader } = useSelector(
    (state) => state.TestJobReducer
  );
  const modifiedBuildVersionList = [
    { buildVersion: "Latest", displayName: "Latest Version", uuid: "latest" },
    ...buildVersionList,
  ];

  const handleChangeBuildType = (value) => {
    dispatch(
      changeTestJobData({
        ...testJobDetail,
        buildDetail: { ...testJobDetail?.buildDetail, buildType: value, buildVersion: "Latest" },
      })
    );
    dispatch(getBuildVersions(value, setBuildVersionList));
  };

  useEffect(() => {
    if (testJobDetail?.buildDetail?.buildType || testJobDetail?.buildType !== null) {
      dispatch(
        getBuildVersions(testJobDetail?.buildType || testJobDetail?.buildDetail?.buildType, setBuildVersionList)
      );
    }
  }, []);

  const isShowCreateBtnAndSelectBuildVersion = () => {
    if (isEmpty(buildVersionList)) {
      return (
        <CustomButtonV2
          buttonLabel={"Upload Build"}
          handleClick={() => dispatch(visibleCreateBuildModal(true))}
          buttonIcon={CREATE_ICON}
          iconColor={WHITE_COLOR}
          className="common_Blue_Button"
        />
      );
    } else {
      return (
        <Row>
          <Col span={12}>
            <CommonSelectBoxV2
              label="Build Version"
              required={true}
              defaultValue={"Latest"}
              options={
                <>
                  {modifiedBuildVersionList &&
                    modifiedBuildVersionList?.map((i, k) => (
                      <Option value={i?.buildVersion} key={k}>
                        {i?.buildVersion}
                      </Option>
                    ))}
                </>
              }
              value={testJobDetail?.buildDetail?.buildVersion || testJobDetail?.buildVersion || undefined}
              placeholder="Please Select Build Version"
              handleChange={(e) => {
                dispatch(
                  changeTestJobData({
                    ...testJobDetail,
                    buildDetail: { ...testJobDetail?.buildDetail, buildVersion: e },
                    buildVersion: e,
                  })
                );
              }}
            />
          </Col>
        </Row>
      );
    }
  };

  return (
    <>
      <Row>
        <Col span={12} className={styles["build_Input_Box"]}>
          <CommonSelectBoxV2
            label="Build Type"
            required={true}
            options={
              <>
                <Option key={"DEV"}>{DEV}</Option>
                <Option key={"ALPHA"}>{ALPHA}</Option>
                <Option key={"BETA"}>{RC_BETA}</Option>
                <Option key={"PROD"}>{PROD}</Option>
                <Option key={"OTHER"}>{OTHER}</Option>
              </>
            }
            value={testJobDetail?.buildDetail?.buildType || undefined || testJobDetail?.buildType}
            placeholder="Please Select Build Type"
            handleChange={(e) => handleChangeBuildType(e)}
          />
        </Col>
      </Row>
      {(testJobDetail?.buildDetail?.buildType !== undefined || testJobDetail?.buildType !== null) &&
        !isGetBuildVersionsLoader &&
        isShowCreateBtnAndSelectBuildVersion()}

      {isVisibleCreateBuildModal && <UploadBuildModalV2 setBuildVersionList={setBuildVersionList} />}
    </>
  );
};

export default SelectBuildV2;
