import { Row, Tabs } from "antd";
import _ from "lodash";
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { STEP } from "../../../../Constants";
import { conditionalStepPagination, isActionNeedToSelectElement } from "../../../../Util";
import { changeRetryStep } from "../../../../actions/TestPlanAction";
import EmptyRecords from "../../../CommonComponents/EmptyRecords/EmptyRecords";
import { StyledInnerTabs } from "../TestPlanResultStepElementDetails";
import ErrorInvestigationDetailsTab from "./ErrorInvestigationDetailsTab";
import ExecutionInfo from "./ExecutionInfo";
import ImageEvidenceTab from "./ImageEvidenceTab";

const { TabPane } = Tabs;

const RetryDetailsTab = (props) => {
  const dispatch = useDispatch();
  const {
    retrySteps,
    action,
    testExecutionErrorInvestigationDetails,
    preconditionResult,
    result,
    retryStepInRepeatStep,
    data,
  } = props;
  const { currentRetryStep } = useSelector((state) => state.TestPlanReducer);

  const retryStep = useMemo(() => {
    const firstPageIndex = currentRetryStep[data?.id] ? (currentRetryStep[data?.id] - 1) * 1 : retrySteps?.length - 1;
    const lastPageIndex = firstPageIndex + 1;

    return retrySteps?.slice(firstPageIndex, lastPageIndex);
  }, [currentRetryStep, retrySteps]);
  return (
    <div className="w-100">
      {!_.isEmpty(retryStep) ? (
        retryStep.map((retryData, i) => {
          return (
            <>
              <div className="display-flex align-items-center justify-end">
                <div className="display-flex align-items-center font-weight-500">
                  <span>Retry Attempt :</span>
                  <div className="mt-less-3">
                    {conditionalStepPagination(
                      retrySteps,
                      currentRetryStep,
                      data,
                      (pageNumber) => dispatch(changeRetryStep(pageNumber)),
                      retrySteps
                    )}
                  </div>
                </div>
              </div>
              {retryData?.testExecutionElementDetails.map((testExecutionElementDetail) => {
                return (
                  <StyledInnerTabs>
                    {(isActionNeedToSelectElement(action) ||
                      (retryData?.action === STEP && retryData?.isStepHasPreCondition === 1)) && (
                      <TabPane
                        xs={24}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        className="ant-tabs-tab"
                        tab="Image Evidence"
                        key="Image Evidence"
                      >
                        <ImageEvidenceTab
                          recordedStepImageLink={retryData?.recordedStepImageLink}
                          failureImage={retryData?.failureScreenshotLink}
                          result={retryData?.result}
                          continueAfterFail={retryData?.continueAfterFail}
                          testExecutionElementDetail={testExecutionElementDetail}
                          preconditionResult={retryData?.preconditionResult}
                          beforeExecutionScreenshotUrl={retryData?.beforeExecutionScreenshotUrl}
                        />
                      </TabPane>
                    )}
                    <TabPane
                      className="ant-tabs-tab"
                      xs={24}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      tab="Execution Info"
                      key="Execution Info"
                    >
                      <ExecutionInfo
                        outputTestDataName={retryData.outputTestDataName}
                        outputTestDataValue={retryData.outputTestDataValue}
                        testExecutionElementDetail={testExecutionElementDetail}
                        testDataName={retryData?.testDataName}
                        testDataValue={retryData?.testDataValue}
                        testDataCustomValue={retryData?.testDataCustomValue}
                        selectedElementImageUrl={retryData?.selectedElementImageUrl}
                        selectorType={retryData?.selectorType}
                        retryCounts={retryData?.retryCounts}
                        retryIndex={retryData?.retryIndex}
                        failurePageSourceLink={retryData?.failurePagesourceLink}
                        action={action}
                        text={retryData.text}
                        aiGeneratedStep={retryData?.aiGeneratedStep}
                        aiGeneratedRecordedStep={retryData?.aiGeneratedRecordedStep}
                        aiGeneratedRecordedPagesource={retryData?.aiGeneratedRecordedPagesource}
                        isStepHasPreCondition={retryData?.isStepHasPreCondition}
                      />
                    </TabPane>
                    {!preconditionResult && result === "Failed" && (
                      <TabPane
                        className="ant-tabs-tab"
                        xs={24}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        tab="Error Investigation"
                        key="Error Investigation"
                      >
                        <ErrorInvestigationDetailsTab
                          errorInvestigationDetails={testExecutionErrorInvestigationDetails}
                        />
                      </TabPane>
                    )}
                  </StyledInnerTabs>
                );
              })}
            </>
          );
        })
      ) : (
        <Row className="w-100 display-block">
          <EmptyRecords description="No Data Found" />
        </Row>
      )}
    </div>
  );
};

export default RetryDetailsTab;
