import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { ELEMENT_ACTION, SWIPE_DIRECTION_TEXT } from "../../../../../Constants";
import {
  DEEP_BLUE_COLOR,
  ICON_ARROW_DOWN,
  ICON_ARROW_LEFT,
  ICON_ARROW_RIGHT,
  ICON_ARROW_UP,
  SHADOW_GRAY_COLOR,
} from "../../../../../Constants/SvgConstants";
import SvgLoader from "../../../../../Util/SvgLoader";
import { changeSwipeDirectionOverElement } from "../../../../../actions/RecTestStep/RecTestStepRedirectAction";
import styles from "./SwipeElementV2.module.scss";

const swipeDirections = [
  { direction: "UP", icon: ICON_ARROW_UP },
  { direction: "LEFT", icon: ICON_ARROW_LEFT },
  { direction: "RIGHT", icon: ICON_ARROW_RIGHT },
  { direction: "DOWN", icon: ICON_ARROW_DOWN },
];

const SwipeDirectionLabelAndIconsV2 = (props) => {
  const dispatch = useDispatch();
  const { isForView, disabled, data } = props;

  //Destructure object
  const { tag: tagOfData } = data ? data : {};
  const { recSwipeAttributes, action } = data && data?.recTestStepProperty ? data?.recTestStepProperty : {};
  const currentDirection = recSwipeAttributes ? recSwipeAttributes.direction : {};

  const handleDirectionChange = useCallback(
    (direction) => {
      if (!isForView && !disabled) {
        dispatch(changeSwipeDirectionOverElement(direction, tagOfData));
      }
    },
    [dispatch, isForView, disabled, tagOfData]
  );

  return (
    <div className={styles["swipe_Label_And_Icons"]}>
      <div className={styles["swipe_Label"]}>
        {action === ELEMENT_ACTION.SWIPE ? "Swipe Direction (from center of page)" : "Swipe Over Element"}
      </div>

      <div className={styles["swipe_Icons_Text"]}>
        <div className={!isForView && !disabled ? "cursor__pointer" : "cursor__not_allowed"}>
          {swipeDirections?.map(({ direction, icon }) => (
            <div
              key={direction}
              className={`${styles[`swipe_${direction}`]} ${styles["swipe_Direction_Icons"]}`}
              onClick={() => handleDirectionChange(direction)}
            >
              <SvgLoader
                iconName={icon}
                iconColor={currentDirection === direction ? DEEP_BLUE_COLOR : SHADOW_GRAY_COLOR}
                disabled={disabled}
              />
            </div>
          ))}
        </div>
        {currentDirection && (
          <div className={styles["swipe_Direction_Text"]}>{SWIPE_DIRECTION_TEXT[currentDirection]}</div>
        )}
      </div>
    </div>
  );
};

export default SwipeDirectionLabelAndIconsV2;
