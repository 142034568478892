import React from "react";
import _ from "lodash";
import { Col, Collapse, Row, Tabs } from "antd";
import { conditionalStepPagination, isActionNeedToSelectElement } from "../../../../Util";
import { StyledInnerTabs } from "../TestPlanResultStepElementDetails";
import ImageEvidenceTab from "./ImageEvidenceTab";
import { faReply } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ExecutionInfo from "./ExecutionInfo";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import RetryDetailsTab from "./RetryDetailsTab";
import { changeRepeatStep } from "../../../../actions/TestPlanAction";

const { TabPane } = Tabs;

const RepeatSteps = (props) => {
  const dispatch = useDispatch();
  const { repeatSteps, action, testExecutionErrorInvestigationDetails, preconditionResult, result, data } = props;
  const { currentRepeatStep } = useSelector((state) => state.TestPlanReducer);
  const repeatStep = useMemo(() => {
    const firstPageIndex = currentRepeatStep[data?.id] ? (currentRepeatStep[data?.id] - 1) * 1 : 0;
    const lastPageIndex = firstPageIndex + 1;

    return repeatSteps?.slice(firstPageIndex, lastPageIndex);
  }, [currentRepeatStep, repeatSteps]);

  return (
    <div className="w-98">
      {!_.isEmpty(repeatStep) &&
        repeatStep?.map((i, j) => {
          const retryStep = !_.isEmpty(i?.retrySteps);
          return (
            <>
              {retryStep && <div className="font-weight-500">Repeat Step</div>}
              <Row
                className={
                  "display-flex align-items-center justify-space-between " +
                  (retryStep ? " font-weight-500 mt-10" : "font-weight-500")
                }
              >
                <Col xl={16} xs={14}>
                  {i?.executedLoopCount > 0 ? i?.executedLoopCount : ""}
                  <FontAwesomeIcon className="font-color-orange mr-10 ml-10  mt-5" icon={faReply} />
                  {i?.name}
                </Col>
                <Col className="display-flex align-items-center pull-right">
                  <span>Repeat Count :</span>
                  <div className="mt-less-3">
                    {conditionalStepPagination(repeatSteps, currentRepeatStep, data, (pageNumber) =>
                      dispatch(changeRepeatStep(pageNumber))
                    )}
                  </div>
                </Col>
              </Row>
              {retryStep && (
                <RetryDetailsTab
                  retrySteps={i?.retrySteps}
                  action={action}
                  testExecutionErrorInvestigationDetails={testExecutionErrorInvestigationDetails}
                  preconditionResult={preconditionResult}
                  result={result}
                  retryStepInRepeatStep={true}
                  data={data}
                />
              )}
              <div className={retryStep && "mt-20"}>
                {i?.testExecutionElementDetails &&
                  i?.testExecutionElementDetails.map((testExecutionElementDetail) => {
                    return (
                      <StyledInnerTabs>
                        {isActionNeedToSelectElement(action) && (
                          <TabPane
                            xs={24}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            className="ant-tabs-tab"
                            tab="Image Evidence"
                            key="1"
                            style={{ height: "auto" }}
                          >
                            <ImageEvidenceTab
                              recordedStepImageLink={i?.recordedStepImageLink}
                              failureImage={i?.failureScreenshotLink}
                              result={i?.result}
                              continueAfterFail={i?.continueAfterFail}
                              testExecutionElementDetail={testExecutionElementDetail}
                              preconditionResult={i?.preconditionResult}
                              beforeExecutionScreenshotUrl={i?.beforeExecutionScreenshotUrl}
                            />
                          </TabPane>
                        )}
                        <TabPane
                          className="ant-tabs-tab"
                          xs={24}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          tab="Execution Info"
                          key="Execution Info"
                        >
                          <ExecutionInfo
                            outputTestDataName={i.outputTestDataName}
                            outputTestDataValue={i.outputTestDataValue}
                            testExecutionElementDetail={testExecutionElementDetail}
                            testDataName={i?.testDataName}
                            testDataValue={i?.testDataValue}
                            testDataCustomValue={i?.testDataCustomValue}
                            selectedElementImageUrl={i?.selectedElementImageUrl}
                            selectorType={i?.selectorType}
                            retryCounts={i?.retryCounts}
                            retryIndex={i?.retryIndex}
                            failurePageSourceLink={i?.failurePagesourceLink}
                            action={action}
                            text={i?.text}
                            aiGeneratedStep={i?.aiGeneratedStep}
                            aiGeneratedRecordedStep={i?.aiGeneratedRecordedStep}
                            aiGeneratedRecordedPagesource={i?.aiGeneratedRecordedPagesource}
                            isStepHasPreCondition={data?.isStepHasPreCondition}
                          />
                        </TabPane>
                      </StyledInnerTabs>
                    );
                  })}
              </div>
            </>
          );
        })}
    </div>
  );
};

export default RepeatSteps;
