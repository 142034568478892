import { default as React } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Collapse, Input } from "antd";
import { isEmpty } from "lodash";
import {
  CONDITION_DELETE_IMAGE,
  CREATE_ICON,
  DEEP_BLUE_COLOR,
  DISABLE_COLOR,
  NEW_COLLAPSE_CLOSE_ICON,
  NEW_COLLAPSE_OPEN_ICON,
  SAVE_ICON,
  WHITE_COLOR,
} from "../../../../Constants/SvgConstants";
import { hasNotAllowedNegativeValue, showUnSavedNotification } from "../../../../Util";
import SvgLoader from "../../../../Util/SvgLoader";
import {
  addVerificationForRepeatStep,
  changeRecTestStepLoopCount,
  changeRecTestStepPreConditionTitle,
  showDeleteLoopConditionModalVisible,
  updateRecTestStepAction,
} from "../../../../actions/RecTestStep/RecTestStepRedirectAction";
import RecTestStepDetailV2 from "../../StepInnerComponents/RecTestStepDetailV2/RecTestStepDetailV2";
import styles from "./RecTestStepConditionV2.module.scss";

const { Panel } = Collapse;

const RecTestStepLoopConditionV2 = (props) => {
  const dispatch = useDispatch();
  const {
    recTestStep,
    isDeviceLoaded,
    projectId,
    isSkipStep,
    disabled,
    isTestBlock,
    recTestSteps,
    isShowPerformActionsForSpecificElements,
    isShowPerformActionsForNLP,
    isDisabledSpecificElements,
    isDisabledNLP,
  } = props;
  const { unSavedRecStepTag } = useSelector((state) => state.RecTestStepCommonViewReducer);
  const { isPreviewRunning } = useSelector((state) => state.RecTestStepReducer);

  //Destructure object
  const {
    loopCount,
    addLoopCondition,
    recStepVerifyElementProperties,
    preConditionTitle,
    isLoopStep,
    isLoopCountSaved,
    previous_loopCount,
    isAIStep
  } = recTestStep && recTestStep.recTestStepProperty ? recTestStep.recTestStepProperty : {};

  const isDisabledInput =
    disabled || isSkipStep || isPreviewRunning || isShowPerformActionsForSpecificElements || isShowPerformActionsForNLP;

  return (
    isLoopStep === 1 && (
      <div>
        <div>
          <div className={styles["loop_Condition_Label"]}>Count Reach to</div>
        </div>
        <div className={styles["loop_Condition_Details"]}>
          <Input
            type="number"
            className={styles["repeat_Count_Input_Box"]}
            value={loopCount}
            disabled={isDisabledInput}
            min={0}
            onKeyDown={(e) => hasNotAllowedNegativeValue(e)}
            onChange={(e) => !disabled && dispatch(changeRecTestStepLoopCount(e.target.value, recTestStep.tag))}
          />

          {isAIStep==0 && (addLoopCondition === undefined || addLoopCondition === false) &&
            isEmpty(recStepVerifyElementProperties) && (
              <>
                <Button
                  disabled={isLoopCountSaved === undefined && previous_loopCount === undefined}
                  type="primary"
                  onClick={() => dispatch(updateRecTestStepAction(recTestStep))}
                  className={styles["save-button"]}
                >
                  <div className={styles["loop_Condition_Details"]}>
                    <SvgLoader
                      iconName={SAVE_ICON}
                      iconColor={
                        isLoopCountSaved === undefined && previous_loopCount === undefined ? DISABLE_COLOR : WHITE_COLOR
                      }
                    />
                    <span>Save</span>
                  </div>
                </Button>
                <div
                  onClick={() => !disabled && dispatch(addVerificationForRepeatStep(recTestStep.tag))}
                  className={`${styles["add_Element_Verification"]} ${
                    disabled && styles["disabled_Add_Element_Verification"]
                  }`}
                >
                  <SvgLoader
                    iconName={CREATE_ICON}
                    iconColor={disabled ? DISABLE_COLOR : DEEP_BLUE_COLOR}
                    width="0.88rem"
                    height="0.88rem"
                  />
                  Add Element Verification
                </div>
              </>
            )}
        </div>
        {(addLoopCondition || recStepVerifyElementProperties.length > 0 || isAIStep==1) && (
          <>
            <div className={`${styles["loop_Condition_Label"]} mt-10`}>And Verification Succeeds</div>
            <Collapse
              unSavedStep={showUnSavedNotification(recTestStep, unSavedRecStepTag)}
              expandIconPosition="left"
              isSkipStep={isSkipStep}
              isTestBlock={isTestBlock}
              className={"repeat_Condition_Collapse"}
              bordered={false}
              expandIcon={({ isActive }) => (
                <div>
                  <SvgLoader iconName={isActive ? NEW_COLLAPSE_OPEN_ICON : NEW_COLLAPSE_CLOSE_ICON} />
                </div>
              )}
            >
              <Panel
                showArrow={true}
                header={
                  <div className={styles["input_Box_And_Delete_Icon"]}>
                    <div className={styles["inputTitleOrInputBox"]}>
                      <span className={styles["inputTitle"]}>Title</span>
                      <Input
                        className={styles["inputStyle"]}
                        collapsible={"false"}
                        type="text"
                        placeholder="Provide a title/description for the verification"
                        value={preConditionTitle}
                        disabled={isDisabledInput}
                        onChange={(e) => {
                          !disabled && dispatch(changeRecTestStepPreConditionTitle(e.target.value, recTestStep.tag));
                        }}
                      />
                    </div>
                    <SvgLoader
                      disabled={isLoopStep !== 1}
                      iconName={CONDITION_DELETE_IMAGE}
                      handleClick={() =>
                        dispatch(showDeleteLoopConditionModalVisible(recTestStep, recTestStep.tag, true))
                      }
                    />
                  </div>
                }
              >
                <RecTestStepDetailV2
                  isSkipStep={isSkipStep || props.isSkipStep}
                  data={recTestStep}
                  parentIndex={recTestStep.orderId}
                  isDeviceLoaded={isDeviceLoaded}
                  projectId={projectId}
                  recTestSteps={recTestSteps}
                  disabled={disabled}
                  isTestBlock={isTestBlock}
                  isShowPerformActionsForSpecificElements={isShowPerformActionsForSpecificElements}
                  isShowPerformActionsForNLP={isShowPerformActionsForNLP}
                  isDisabledSpecificElements={isDisabledSpecificElements}
                  isDisabledNLP={isDisabledNLP}
                />
              </Panel>
            </Collapse>
          </>
        )}
      </div>
    )
  );
};

export default RecTestStepLoopConditionV2;
