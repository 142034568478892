import { message, notification } from "antd";
import * as htmlToImage from "html-to-image";
import _ from "lodash";
import moment from "moment";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import {
  A,
  ADDING_BUILD_ALREADY_EXIST,
  ADD_ACTION_STEP,
  ADD_LAUNCH_APP_STEP,
  ADD_NEW_SCENARIO,
  ADD_SWIPE_OVER_ELEMENT_STEP,
  ADD_VERIFICATION_STEP,
  ADD_VERIFICATION_VALUES,
  ALL_APP_BUILD_DETAILS,
  ALL_APP_BUILD_DETAILS_FAILED,
  ALL_APP_BUILD_DETAILS_FOR_FARM_AND_DEVICE,
  ALL_APP_BUILD_DETAILS_FOR_FARM_AND_DEVICE_FAILED,
  ALL_APP_BUILD_DETAILS_FOR_FARM_AND_DEVICE_REQUEST,
  ALL_BROWSER_INSTALLED_IN_DEVICE,
  ALL_BUILDS,
  ALL_SCENARIOS,
  APP_BUILD_DETAILS_BY_ID,
  APP_BUILD_DOWNLOAD_STATUS,
  BACK_PREVIOUS_PREVIEW_STEP,
  BACK_PREVIOUS_STEP,
  BACK_TO_APP_MANAGEMENT,
  BACK_TO_SCENARIO_LIST,
  BLOCK_REC_TEST_STEP_ACTION,
  CHANGE_ABSENCE_VALUE,
  CHANGE_APP_BUILD_LIST_PAGE_NUMBER,
  CHANGE_APP_FOR_DEVICE_LOGS,
  CHANGE_APP_MANAGER_TAB,
  CHANGE_BUILD_DATE_TIME_IN_ADD_BUILD,
  CHANGE_BUILD_FILE,
  CHANGE_BUILD_TYPE_IN_ADD_BUILD,
  CHANGE_BUILD_VERSION_IN_ADD_BUILD,
  CHANGE_DEVICE_INTERACTION_MODE,
  CHANGE_DEVICE_SELECTION_FOR_BUILD_INSTALL,
  CHANGE_DEVICE_SESSION_LOGTYPE,
  CHANGE_DEVICE_TAB_ACTION_KEY,
  CHANGE_ELEMENT_ACTION,
  CHANGE_INSTALL_PAGE_APP_BUILD_LIST_PAGE_NUMBER,
  CHANGE_ORIENTATION,
  CHANGE_PAGE_SOURCE_FETCHING_INTERVAL,
  CHANGE_PLATFORM_TYPE_IN_ADD_BUILD,
  CHANGE_SEARCH_TEXT_FOR_SEARCH_QUERY_INSTALL_APP,
  CHANGE_SELECTED_BUILD_TEST_SCENARIO_TAB,
  CHANGE_SELECTED_EXECUTION_PANEL_TAB,
  CHANGE_SHARED_IN_ADD_BUILD,
  CHANGE_SHELL_COMMAND_FOR_DEVICE,
  CHANGE_SKIP_AFTER_FAIL,
  CHANGE_TAP_BY_INDEX,
  CHANGE_TEST_DATA_APPEND_RANDOM_TEXT,
  CHANGE_TEST_DATA_VARIABLE_DEFAULT_VALUE,
  CHANGE_TEST_DATA_VARIABLE_NAME,
  CHANGE_URL_FOR_DEVICE_BROWSER_NAVIGATION,
  CHANGE_VERIFICATION_DROPDOWN_VALUES,
  CHANGE_VERIFICATION_VALUES,
  CHANGE_VERIFICATION_VALUE_FOR_REF,
  CHECK_AND_RESTART_ANDROID_NODE_SERVICE_REQUEST,
  CHECK_AND_RESTART_ANDROID_NODE_SERVICE_REQUESTED,
  CLEAR_EXECUTION_DATA,
  CLEAR_EXECUTION_DATA_DONE,
  CLEAR_THE_DEVICE_SESSION_LOGS,
  CONFIRM_STEP,
  CONFIRM_STEP_REQUEST,
  CONFIRM_STEP_REQUESTED,
  CURL_FETCH_FOR_BUILD_LOADING,
  CURL_FETCH_FOR_BUILD_LOADING_FALSE,
  CURRENT_SCREEN_ORIENTATION,
  DEVICE_DISCONNECT,
  DEVICE_EXECUTION_FARM,
  DEVICE_INTERACTION_MODE,
  DEVICE_LOADED,
  DEVICE_SESSION_LOGS_STREAM,
  DEVICE_SESSION_LOGS_STREAM_FAILED,
  DEVICE_SESSION_LOGS_STREAM_REQUEST,
  DEVICE_SESSION_LOGS_STREAM_STARTED,
  DEVICE_SESSION_LOGS_STREAM_STOPPED,
  DEVICE_SESSION_LOGS_STREAM_STOPPING_FAILED,
  DEVICE_SESSION_LOGS_STREAM_STOPPING_REQUEST,
  DEVICE_SESSION_LOGTYPE,
  DEVICE_STATE,
  DEVICE_STATUS,
  DEVICE_USAGE_REPORT_DATA,
  DEVICE_USAGE_REPORT_DATA_REQUEST,
  DEVICE_USAGE_REPORT_DATA_REQUEST_FAILED,
  DISABLE_KEYBOARD_IN_DEVICE,
  DOWNLOAD_BUILD_PROGRESS,
  DOWNLOAD_PREVIEW_STEP_IMAGES_REQUESTED,
  EDIT_APP_BUILD,
  EDIT_SCENARIO,
  ELEMENT_ACTION,
  ERROR_ALL_BROWSER_INSTALLED_IN_DEVICE,
  ERROR_STATUS,
  ERROR_TYPE,
  EXECUTION_JOB,
  FAILED_TO_ROTATE_SCREEN,
  FAST_INPUT_ICON_INDICATION,
  FETCHED_CURL_FOR_BUILD_UPLOAD,
  FIND_DEVICE_BY_UDID,
  FIND_ELEMENT_REQUEST,
  FIND_ELEMENT_REQUESTED,
  FLUSH_THE_DASHBOARD_ATTRIBUTE,
  FLUSH_THE_RECORDING_DATA,
  GET_APP_MANAGER_BUILD_DETAILS_REQUEST,
  GET_AWS_PROJECTS_LIST,
  GET_DEVICE_DETAILS,
  GET_DEVICE_SCREEN,
  GET_DEVICE_SCREEN_SOURCE,
  GET_PROXY_STREAMING_DATA,
  GET_UPLOAD_BUILD_OPTIONS,
  HIDE_ELEMENT_PROPERTIES_MODAL,
  HIDE_INPUT_TEXT_MODAL,
  HIDE_INSTALL_APP_MODAL,
  HIDE_INSTALL_BUILD_ON_DEVICE,
  HIDE_LOG_MODAL,
  HIDE_NEW_VERIFICATION,
  HIDE_ORIENTATION_MODAL,
  HIDE_PUSH_BUILD_MODAL,
  I,
  INITIAL_APP_BUILD_DATA,
  INSERT_CUSTOM_TEXT_VERIFICATION_ROW,
  INSERT_TEXT_REQUEST,
  INSERT_TEXT_REQUESTED,
  INSTALLED_APP_IN_DEVICE_FROM_SFTP,
  INSTALL_APP_IN_DEVICE_FROM_SFTP_REQUEST,
  INSTALL_APP_IN_DEVICE_FROM_SFTP_REQUEST_FAILED,
  INSTALL_PAGE_APP_BUILD_RECORD_PER_PAGE,
  IOS,
  LOAD_RECORDING_SCREEN_STATUS,
  LOAD_SELECTED_SCENARIO_FOR_EXECUTION,
  LOG_START_REQUEST,
  ON_CANCEL_UNSAVED_STEP_MODAL_VISIBLE,
  ON_LOG_TYPE_CHANGE,
  ON_ORIENTATION_CHANGE,
  OPEN_RECENT_APP_TRAY,
  PASSWORD_INPUT,
  PRIVATE,
  PROXY_CLEAR_DATA,
  PROXY_STARTED,
  PROXY_START_REQUEST,
  PROXY_STOPPED,
  PROXY_STOP_REQUEST,
  PUSH_BUILD,
  PUSH_BUILD_REQUEST,
  PUSH_UPLOAD_BUILD_ID,
  RECORDING_RESUME,
  RECORDING_SCREEN,
  RECORDING_START,
  RECORDING_STOP,
  RECORD_10_PER_PAGE,
  REMOVE_BLANK_STEP,
  REMOVE_CUSTOM_TEXT_VERIFICATION_ROW,
  REMOVE_STEP,
  REMOVE_STEP_REQUEST,
  REMOVE_STEP_REQUESTED,
  REMOVE_VERIFICATION_VALUES,
  REPORT_MANAGER,
  REQUESTED_FOR_DEVICE_LOAD,
  REQUESTED_FOR_DEVICE_UNLOAD,
  REQUESTED_INSTALL_BUILD_ON_DEVICE,
  REQUESTED_PERFORM_REC_TEST_STEP_ACTION,
  REQUEST_FOR_DEVICE_LOAD,
  REQUEST_FOR_DEVICE_UNLOAD,
  REQUEST_INSTALL_BUILD_ON_DEVICE,
  REQUEST_PERFORM_REC_TEST_STEP_ACTION,
  RESET_APP_BUILD_DETAILS_BY_ID,
  RESET_CUSTOM_REF_LIST,
  RESET_ELEMENT_SELECTION,
  RESET_PREVIOUS_STATE,
  RESET_REC_TEST_STEP_ACTION_SELECTED_ELEMENT,
  RESET_SCREEN,
  RESET_SHELL_COMMAND_OUTPUT,
  RESET_SWIPE_BY_COORDINATES,
  RESTART_DEVICE_REQUESTED,
  SAVE_MINIMUM_UNSAVED_STEP,
  SAVE_SCENARIO_RECORDING,
  SAVE_SCENARIO_RECORDING_REQUEST,
  SAVE_SCENARIO_RECORDING_REQUESTED,
  SAVE_SHELL_COMMAND_OUTPUT,
  SEARCH_QUERY_APP_DETAILS_COMPLETED,
  SEARCH_QUERY_APP_DETAILS_FAILED,
  SEARCH_QUERY_APP_DETAILS_REQUEST,
  SEARCH_QUERY_RESULT_APP_DETAILS,
  SELECTED_STEP,
  SELECTOR_TYPE,
  SELECT_APP_BUILD_FARM_LIST_OPTION,
  SELECT_ELEMENT,
  SELECT_ELEMENT_SCREENSHOT,
  SELECT_HOVERED_ELEMENT,
  SELECT_THE_BUILD_TO_INSTALL,
  SERVER_DEVICES,
  SET_ADD_APP_BUILD_DETAILS_FORM_ERROR,
  SET_CURRENT_EXECUTE_GROUP_FROM_URL,
  SET_CURRENT_GROUP_ON_BREAD_CRUMB_CLICK,
  SET_CURRENT_PREVIEW_GROUP_ON_BREAD_CRUMB_CLICK,
  SET_EXPANDED_PATHS,
  SET_SELECTED_APP_BUILD,
  SET_SELECTED_ELEMENT_ID,
  SET_SELECTED_GROUP,
  SET_SELECTED_PREVIEW_GROUP,
  SET_SPLITTER_DRAGGED,
  SHOW_ADD_APP_BUILD_MENU,
  SHOW_INPUT_TEXT_MODAL,
  SHOW_INSTALL_APP_BUILD_MODAL,
  SHOW_INSTALL_BUILD_ON_DEVICE,
  SHOW_LOAD_SCENARIO_MODAL,
  SHOW_LOG_MODAL,
  SHOW_MESSAGE,
  SHOW_NEW_VERIFICATION,
  SHOW_ORIENTATION_MODAL,
  SHOW_PUSH_BUILD_MODAL,
  SHOW_UNSAVED_STEP_MODAL_VISIBLE,
  SHOW_USER_DETAILS,
  START_DEVICE_SCREEN_LOADER,
  START_EXECUTE_SCREEN,
  START_LOGS_ON_DEVICE,
  STOP_DEVICE_SCREEN_LOADER,
  STOP_DEVICE_STREAMING,
  STOP_FAST_INPUT_INDICATION,
  STOP_LOGS_ON_DEVICE,
  SUCCESS_STATUS,
  SUCCESS_TYPE,
  TABLE_EXECUTE_BUTTON_LOADER,
  TABLE_EXECUTE_BUTTON_LOADER_STOP,
  TAP_ON_COORDINATES,
  TAP_ON_COORDINATES_REQUESTED,
  TEST_PLAN,
  TEST_SCENARIOS,
  TEST_STEP_BLOCKS,
  TOGGLE_ELEMENT_PROPERTIES_MODAL,
  TOGGLE_OTHER_ELEMENT_POPOVER,
  UNBLOCK_REC_TEST_STEP_ACTION,
  UNINSTALL_BUILD_BEFORE_INSTALL,
  UNSELECT_ELEMENT,
  UNSELECT_HOVERED_ELEMENT,
  UPDATE_CLOSE_LAUNCH_APP_PREVIEW_SESSION,
  UPDATE_CUSTOM_REF_OPTION_VALUE,
  UPDATE_CUSTOM_REF_TEXT_VALUE,
  UPDATE_CUSTOM_REF_TYPE_VALUE,
  UPDATE_DEVICE_DETAIL_FOR_REC_TEST_SCENARIO,
  UPDATE_GROUP_ACTION_VALUE,
  UPDATE_INPUT_TEXT,
  UPDATE_RECORDING_SET_NAME,
  UPDATE_SERVER_DETAIL,
  UPDATE_SESSION_CREATE_STATUS,
  UPDATE_STATUS_CHECK_AND_RESTART_ANDROID_NODE_SERVICE,
  UPDATE_STATUS_CHECK_AND_RESTART_IOS_RECORDING_SERVICE,
  UPDATE_STEP_NAME,
  UPDATE_SWIPE_COORDINATES,
  UPDATE_TAKE_STEP_SCREENSHOT,
  UPLOAD_AND_SAVE_BUILD_REQUEST,
  UPLOAD_BUILD_REQUEST,
  UPLOAD_BUILD_REQUEST_COMPLETE,
  UPLOAD_BUILD_REQUEST_FAILED,
  VIEW_NOTIFICATION,
  VISIBLE_RECORDING_SETTINGS_MODAL,
} from "../Constants";
import { async, prepareEventSource, sync } from "../api/automation";
import history from "../history";
import * as util from "./../Util/";
import {
  backToScenarioList,
  escapeSpecialCaseChar,
  escapeSpecialCaseCharAndroid,
  getUniqueId,
  updateResponse,
} from "./../Util/";
import { getRecTestScenarioList } from "./RecTestScenarioAction";
import {
  findRecTestStepByTag,
  getInstalledApp,
  hideDeleteRecElementConfirmationModal,
  saveDeviceInteraction,
  setElementDetails,
} from "./RecTestStep/RecTestStepRedirectAction";
import {
  getRecTestStepListByRecTestScenarioId,
  stopPreview,
  updateDeviceDetailForRecTestScenario,
} from "./RecTestStepAction";
import {
  acquireDevice,
  generateDeviceToken,
  getDeviceByFarmId,
  getDeviceFarmList,
  getDevices,
  updateInvalidUser,
} from "./ServerListAction";
import { getListOfBlocksByProjectId } from "./TestBlocksAction";
import { getBuildVersions, getTestJob, visibleCreateBuildModal } from "./TestJobActions";
import { closeConnectionsOfResult, getTestPlanAndScenarioResultList, getTestPlanDetailsList } from "./TestPlanAction";
// import * as uuid from "uuid";

let clientClosedStatus = false;
let previewEventSource = "";
let pageSourceRequesting = false;
let pageEventSource = undefined;
let stopPageEventSourceTimeOut = undefined;
let iosStreamingSource = "";
let client = undefined;
let _socket = undefined;
let executionEventSource = "";
let moniterBuildUpload = "";
let moniterBuildDownload = "";
let connectionCloseRequest = false;
let blobUrl = undefined;
let streamLogsForDeviceSession = undefined;
function getNodeServicePort(state, targetUniqueId) {
  let port = 0;
  if (state.ServerListReducer.selectedServerDevices != null) {
    let devices = state.ServerListReducer.selectedServerDevices;
    devices = devices.filter((item) => item.device.targetUniqueId == targetUniqueId);
    port = devices[0].deviceMinicapNodeServicePort;
  }
  return port;
}
export function changePageSourceFetchingInterval(value) {
  return async (dispatch, getState) => {
    dispatch({ type: CHANGE_PAGE_SOURCE_FETCHING_INTERVAL, value });
    _.debounce((e) => {
      if (getState().RecordingReducer.deviceInteractionMode === DEVICE_INTERACTION_MODE.SELECT) {
        if (pageEventSource) {
          clearInterval(pageEventSource);
          // pageEventSource && pageEventSource.close();
          pageEventSource = undefined;
        }
        dispatch(startPageSource());
      }
    }, 900);
  };
}
export function recordingStart(deviceInteractionMode = undefined) {
  return async (dispatch, getState) => {
    dispatch({ type: RECORDING_START, deviceInteractionMode });
    if (deviceInteractionMode === undefined && pageEventSource === undefined) {
      dispatch(startPageSource());
    }
  };
}

export function openPageSourceInNewTab(targetUniqueId = undefined, serverId = undefined) {
  return (dispatch, getState) => {
    const { selectedDeviceByUdid } = getState().RecTestStepReducer;
    if (selectedDeviceByUdid === undefined) {
      return;
    }
    if (targetUniqueId === undefined && serverId === undefined) {
      const { selectedDeviceByUdid } = getState().RecTestStepReducer;
      targetUniqueId = selectedDeviceByUdid?.targetUniqueId;
      serverId = selectedDeviceByUdid?.autoServerDetail?.id;
    }
    let serverIp = getServerIp(getState(), targetUniqueId);
    async(
      "device/getPageSource",
      "GET",
      { targetUniqueId: targetUniqueId, serverId: serverId },
      serverIp,
      undefined,
      dispatch
    ).then((response) => {
      let blob = new Blob([response.data], { type: "text/xml" });
      let url = URL.createObjectURL(blob);
      window.open(url);
      URL.revokeObjectURL(url);
    });
  };
}
export function startPageSource(targetUniqueId = undefined, serverId = undefined) {
  return (dispatch, getState) => {
    const { selectedDeviceByUdid } = getState().RecTestStepReducer;
    if (selectedDeviceByUdid === undefined) {
      return;
    }
    if (stopPageEventSourceTimeOut) {
      stopPageEventSourceTimeOut.map((v) => clearTimeout(v));
      stopPageEventSourceTimeOut = undefined;
      if (pageEventSource !== undefined) {
        return;
      }
    }
    if (targetUniqueId === undefined && serverId === undefined) {
      const { selectedDeviceByUdid } = getState().RecTestStepReducer;
      targetUniqueId = selectedDeviceByUdid?.targetUniqueId;
      serverId = selectedDeviceByUdid?.autoServerDetail?.id;
    }
    let serverIp = getServerIp(getState(), targetUniqueId);

    dispatch({ type: FIND_ELEMENT_REQUEST });
    pageSourceRequesting = true;
    async(
      "device/getPageSource",
      "GET",
      { targetUniqueId: targetUniqueId, serverId: serverId },
      serverIp,
      undefined,
      dispatch
    ).then((response) => {
      dispatch({
        type: GET_DEVICE_SCREEN_SOURCE,
        response: response,
      });
      dispatch({ type: FIND_ELEMENT_REQUESTED });
      pageSourceRequesting = false;
    });
    pageEventSource = setInterval(() => {
      while (!pageSourceRequesting) {
        pageSourceRequesting = true;
        async(
          "device/getPageSource",
          "GET",
          { targetUniqueId: targetUniqueId, serverId: serverId },
          serverIp,
          undefined,
          dispatch
        ).then((response) => {
          dispatch({
            type: GET_DEVICE_SCREEN_SOURCE,
            response: response,
          });
          pageSourceRequesting = false;
        });
      }
    }, getState().RecordingReducer.pageSourceFetchingInterval * 1000);

    // if (stopPageEventSourceTimeOut) {
    //   stopPageEventSourceTimeOut.map((v) => clearTimeout(v));
    //   stopPageEventSourceTimeOut = undefined;
    //   if (pageEventSource !== undefined) {
    //     return;
    //   }
    // }
    // dispatch({ type: FIND_ELEMENT_REQUEST });
    // pageEventSource = prepareEventSource(
    //   "device/getPageSourceStream",
    //   { targetUniqueId: targetUniqueId, serverId: serverId },
    //   serverIp
    // );
    // pageEventSource.onopen = (event: any) => {
    //   dispatch({ type: FIND_ELEMENT_REQUESTED });
    // };
    // pageEventSource.onmessage = (event: any) => {
    //   if (
    //     // getState().RecordingReducer.isStepAdeded &&
    //     getState().RecordingReducer.deviceInteractionMode === DEVICE_INTERACTION_MODE.SELECT
    //   ) {
    //     if (event.data !== "") {
    //       let data = event.data;
    //       let eventParseResponse = JSON.parse(data);
    //       eventParseResponse = updateResponse(eventParseResponse);
    //       if (eventParseResponse.status === SUCCESS_STATUS) {
    //         dispatch({
    //           type: GET_DEVICE_SCREEN_SOURCE,
    //           response: eventParseResponse,
    //         });
    //       }
    //     }
    //   } else if (stopPageEventSourceTimeOut === undefined && event.target) {
    //     event.target.close();
    //   }
    // };
  };
}
export function recordingStop(serverId, targetUniqueId, platform) {
  return async (dispatch, getState) => {
    dispatch({ type: RECORDING_STOP });
    stopPageSource();
  };
}

// export function stopAppiumServiceForAndroid(serverId, targetUniqueId, platform, isDeviceLoaded = false) {
//   return async (dispatch, getState) => {
//     dispatch({ type: RECORDING_STOP_REQUEST });
//     if (platform === "A") {
//       return async(
//         "device/stopAppiumService",
//         "POST",
//         { serverId, targetUniqueId },
//         getState().RecordingReducer.serverDetail.serverIp
//       ).then((response) => {
//         if (response.status === SUCCESS_STATUS) {
//           pageEventSource && pageEventSource.close();
//           dispatch({ type: RECORDING_STOP });
//         } else {
//           notification["error"]({
//             message: "Recording Session",
//             description: response.message,
//           });
//         }
//       });
//     } else {
//       pageEventSource && pageEventSource.close();
//       dispatch({ type: RECORDING_STOP });
//     }
//   };
// }
export function recordingResume() {
  return (dispatch) => {
    dispatch({ type: RECORDING_RESUME });
  };
}

export function getDeviceScreenSource(serverId, targetUniqueId) {
  return (dispatch) => {
    if (pageEventSource !== "") {
      pageEventSource.onmessage = (event: any) => {
        if (event.data != "") {
          let data = event.data;
          let eventParseResponse = JSON.parse(data);
          eventParseResponse = updateResponse(eventParseResponse);
          if (eventParseResponse.status === SUCCESS_STATUS) {
            dispatch({
              type: GET_DEVICE_SCREEN_SOURCE,
              response: eventParseResponse,
            });
          }
        }
      };
    }
  };
}

export function getDeviceDetails(serverId, targetUniqueId, isUpdateStateValue, deviceFarmId) {
  return (dispatch, getState) => {
    let data = {
      targetUniqueId: targetUniqueId,
      logedInUser: getState().RecordingReducer.logedInUser,
    };
    let response = sync("device/getSessionDetails", "GET", data, getState().RecordingReducer.serverDetail.serverIp);
    // response = updateResponse(response);
    if (response.status === SUCCESS_STATUS) {
      if (
        (response.data.deviceType === IOS || getState().RecordingReducer.recordingState == DEVICE_STATUS.RECORDING) &&
        iosStreamingSource
      ) {
        iosStreamingSource.close();
        // dispatch(checkIOSStreamingIsAlive(serverId, targetUniqueId, deviceFarmId));
      }
      response.isUpdateStateValue = isUpdateStateValue;
      dispatch({ type: GET_DEVICE_DETAILS, response });
      if (response && response.data && response.data.isRecordingStarted) {
        dispatch(startPageSource(targetUniqueId, serverId));
      }
    } else if (response.data && response.data.isDeviceOffline) {
      dispatch({ type: DEVICE_STATE, response });
    } else {
      dispatch({
        type: REQUESTED_FOR_DEVICE_LOAD,
      });
      notification["error"]({
        message: "Device Session",
        description: "Permission denied for accessing device. Please acquire before using!!",
      });
      dispatch({ type: SHOW_MESSAGE, response });
    }
  };
}

export function handleTap(serverId, targetUniqueId, xCoordinate, yCoordinate, tapType, tapDuration, projectId) {
  return async (dispatch, getState) => {
    dispatch({ type: TAP_ON_COORDINATES_REQUESTED, xCoordinate, yCoordinate });
    return async(
      "device/tapOnCoordinates",
      "POST",
      {
        serverId,
        targetUniqueId,
        xCoordinate,
        yCoordinate,
        tapType,
        tapDuration,
        logedInUser: getState().RecordingReducer.logedInUser,
      },
      getState().RecordingReducer.serverDetail.serverIp,
      undefined,
      dispatch
    ).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        dispatch({ type: TAP_ON_COORDINATES, response });
        dispatch(removeFocusFromInputText());
        return true;
      } else {
        util.checkForInValidUserActionPermissionDenied(response) &&
          dispatch(stopAppiumStreaming(serverId, targetUniqueId, true, projectId));
        dispatch({ type: TAP_ON_COORDINATES, response });
        util.showNotification("error", response.message, "");
      }
    });
  };
}
export function performDeviceInteraction(data, recTestStepTag, projectId) {
  let self = this;
  return async (dispatch, getState) => {
    // For Recording enable elements loader on Performing Action

    if (recTestStepTag && ["HOME", "BACK", "ENTER"].includes(data.deviceInteractionAction)) {
      dispatch({ type: REQUEST_PERFORM_REC_TEST_STEP_ACTION });
    }
    if (data.targetUniqueId && data.serverId) {
      return async(
        "device/performDeviceInteraction",
        "POST",
        {
          targetUniqueId: data.targetUniqueId,
          serverId: data.serverId,
          deviceInteractionAction: data.deviceInteractionAction,
          logedInUser: getState().RecordingReducer.logedInUser,
        },
        getState().RecordingReducer.serverDetail?.serverIp,
        undefined,
        dispatch
      ).then((response) => {
        response = updateResponse(response);
        util.checkForInValidUserActionPermissionDenied(response) &&
          dispatch(stopAppiumStreaming(data.serverId, data.targetUniqueId, true, projectId));
        if (response.status === SUCCESS_STATUS && recTestStepTag) {
          dispatch(saveDeviceInteraction(data.deviceInteractionAction, response.data, recTestStepTag));
        } else {
          if (
            data.deviceInteractionAction == "UNLOCK" ||
            (data.deviceInteractionAction == "LOCK" && !response.data == "" && response.data["state"] == true)
          ) {
            message.error("Device is already  " + data.deviceInteractionAction);
          } else if (response.status === Error)
            notification["error"]({
              message: "Device Session",
              description: response.message,
            });
          dispatch({ type: SHOW_MESSAGE, response });
          dispatch({ type: REQUESTED_PERFORM_REC_TEST_STEP_ACTION });
        }
      });
    } else if (recTestStepTag && ["HOME", "BACK", "ENTER"].includes(data.deviceInteractionAction)) {
      dispatch(saveDeviceInteraction(data.deviceInteractionAction, undefined, recTestStepTag));
    }
  };
}

export function selectElement(
  path,
  source,
  serverId,
  targetUniqueId,
  xCoordinate,
  yCoordinate,
  bounds,
  tapByIndexData,
  projectId,
  parentElement
) {
  return async (dispatch, getState) => {
    let recTestStep = [];
    let _buildDesignerType = TEST_SCENARIOS;
    recTestStep = findRecTestStepByTag(getState());
    htmlToImage.toPng(document.getElementById("imageAndHiligherContainer")).then(function (dataUrl) {
      dispatch({
        type: SELECT_ELEMENT_SCREENSHOT,
        selectedElementScreenShot: dataUrl,
      });
    });
    dispatch({
      type: RESET_REC_TEST_STEP_ACTION_SELECTED_ELEMENT,
      reRecordRecStepTag: getState().RecTestStepCommonViewReducer.unSavedRecStepTag,
      recElementGuid: getState().RecTestStepCommonViewReducer.unSavedRecElementGuid,
    });
    // Set the selected element in the source tree
    new Promise((resolve) =>
      resolve(
        dispatch({
          type: SELECT_ELEMENT,
          path,
          source,
          xCoordinate,
          yCoordinate,
          bounds,
          recTestStep,
          parentElement,
          _buildDesignerType,
        })
      )
    ).then(() => {
      const state = getState();
      const {
        attributes,
        xpath,
        path,
        recElementLocatorProperties,
        id,
        tags,
        description,
        guid,
        elementType,
        elementSelectorType,
      } = state.RecordingReducer.selectedElement;
      const sourceXML = state.RecordingReducer.currentDeviceScreenSourceXml;
      // util.getElementCustomXpath(
      //   state.RecordingReducer.currentDeviceScreenSource,
      //   state.RecordingReducer.selectedElement
      // );
      // Expand all of this element's ancestors so that it's visible in the source tree
      let { expandedPaths } = state.RecordingReducer;
      if (path) {
        let pathArr = path.split(".").slice(0, path.length - 1);
        while (pathArr.length > 1) {
          pathArr.splice(pathArr.length - 1);
          let path = pathArr.join(".");
          if (expandedPaths.indexOf(path) < 0) {
            expandedPaths.push(path);
          }
        }
      }
      dispatch({ type: SET_EXPANDED_PATHS, paths: expandedPaths });

      // Find the optimal selection strategy. If none found, fall back to XPath.
      const strategyMap = _.toPairs(util.getLocators(attributes, sourceXML));
      strategyMap.push(["xpath", state.RecordingReducer.selectedElement.customXpath]);
      let recElements = _.cloneDeep(recTestStep.recTestStepProperty?.recElements);
      let recElement = util.getUnSavedRecElement(
        recElements,
        getState().RecTestStepCommonViewReducer.unSavedRecElementGuid
      );
      recElement = {
        ...recElement,
        id: recElement["id"] ? recElement["id"] : id,
        name: util.getSelectedElementText({ attributes }),
        tags,
        description,
        elementType: elementType ? elementType : recElement["elementType"],
        elementSelectorType: elementSelectorType ? elementSelectorType : recElement["elementSelectorType"],
        recElementProperties: util.generateKeyValueArray(attributes),
        recElementLocatorProperties,
      };
      if (elementType === undefined) {
        recElement = {
          ...recElement,
          id: recElement["id"] ? recElement["id"] : id,
          elementType: recElement?.childRecElements?.length > 0 ? "PARENT_ELEMENT" : "DIFFERENT_VERSION",
          guid: getState().RecTestStepCommonViewReducer.unSavedRecElementGuid,
          elementSelectorType: SELECTOR_TYPE.SELECT_ELEMENT_ON_SCREEN,
          recElementProperties: util.generateKeyValueArray(attributes),
          isActive: 1,
        };
      }
      let parentRecElement = util.getParentRecElement(recElements);
      if (parentRecElement) {
        parentRecElement["childRecElements"] = [recElement];
      } else {
        parentRecElement = recElement;
      }
      // Debounce find element so that if another element is selected shortly after, cancel the previous search
      dispatch(
        findElement(
          parentRecElement,
          // strategyMap,
          path,
          serverId,
          targetUniqueId,
          attributes,
          xCoordinate,
          yCoordinate,
          tapByIndexData,
          _buildDesignerType
        )
      );
    });
  };
}

export function unselectElement() {
  return (dispatch) => {
    dispatch({ type: UNSELECT_ELEMENT });
  };
}
export function selectHoveredElement(path, source) {
  return (dispatch) => {
    dispatch({ type: SELECT_HOVERED_ELEMENT, path, source });
  };
}

export function unselectHoveredElement(path) {
  return (dispatch) => {
    dispatch({ type: UNSELECT_HOVERED_ELEMENT, path });
  };
}
export function findElement(
  recElement,
  path,
  serverId,
  targetUniqueId,
  attributes,
  xCoordinate,
  yCoordinate,
  tapByIndexData,
  _buildDesignerType
) {
  return async (dispatch, getState) => {
    dispatch({ type: FIND_ELEMENT_REQUEST });
    dispatch({ type: BLOCK_REC_TEST_STEP_ACTION });
    return async(
      "device/findElements",
      "POST",
      { targetUniqueId, serverId, recElements: [recElement] },
      // { targetUniqueId, serverId, strategy, value: selector },
      getState().RecordingReducer.serverDetail.serverIp,
      undefined,
      dispatch
    ).then((response) => {
      response = updateResponse(response);
      // Set the elementId, variableName and variableType for the selected element
      // (check first that the selectedElementPath didn't change, to avoid race conditions)
      if (
        response.status === SUCCESS_STATUS &&
        response.data &&
        response.data.length > 0 &&
        util.isElementFound(response.data[0]) &&
        getState().RecordingReducer.selectedElementPath === path
      ) {
        let elementDetails =
          response.data[0].childRecElements && response.data[0].childRecElements.length > 0
            ? response.data[0].childRecElements[0]
            : response.data[0];
        // if (_buildDesignerType === TEST_SCENARIOS) {
        dispatch(
          setElementDetails(
            elementDetails,
            attributes,
            getState().RecordingReducer.selectedElement,
            getState().RecordingReducer.selectedElementScreenShot,
            xCoordinate === undefined && yCoordinate === undefined
          )
        );
        dispatch({
          type: SET_SELECTED_ELEMENT_ID,
          elementDetails,
          selectedElementAttributes: attributes,
        });
        if (tapByIndexData) {
          dispatch({ type: CHANGE_TAP_BY_INDEX, ...tapByIndexData });
        }
        return true;
      } else {
        util.showNotification("error", "Not able to find element.");

        dispatch({
          type: RESET_REC_TEST_STEP_ACTION_SELECTED_ELEMENT,
          reRecordRecStepTag: getState().RecTestStepCommonViewReducer.unSavedRecStepTag,
          recElementGuid: getState().RecTestStepCommonViewReducer.unSavedRecElementGuid,
        });
      }
      dispatch({ type: UNBLOCK_REC_TEST_STEP_ACTION });
      dispatch({ type: FIND_ELEMENT_REQUESTED });
      return true;
    });
    // }
    // dispatch({ type: SET_INTERACTIONS_NOT_AVAILABLE });
    // return true;
  };
}

export function updateStepName(value) {
  return (dispatch) => {
    dispatch({ type: UPDATE_STEP_NAME, value });
  };
}

export function changeElementAction(value) {
  return (dispatch) => {
    dispatch({ type: CHANGE_ELEMENT_ACTION, value });
  };
}
export function updateGroupActionValue(value) {
  return (dispatch) => {
    dispatch({ type: UPDATE_GROUP_ACTION_VALUE, value });
  };
}

export function changeTestDataVariableName(value) {
  return (dispatch) => {
    dispatch({ type: CHANGE_TEST_DATA_VARIABLE_NAME, value });
  };
}
export function changeTestDataVariableDefaultValue(value) {
  return (dispatch) => {
    dispatch({ type: CHANGE_TEST_DATA_VARIABLE_DEFAULT_VALUE, value });
  };
}
export function changeTestDataAppendRandomText(checked) {
  return (dispatch) => {
    dispatch({ type: CHANGE_TEST_DATA_APPEND_RANDOM_TEXT, checked });
  };
}
export function confirmStep(serverId, targetUniqueId) {
  let self = this;
  return async (dispatch, getState) => {
    dispatch({ type: CONFIRM_STEP_REQUEST });
    let state = getState().RecordingReducer;

    if (state.selectedAction === ELEMENT_ACTION.TAP_BY_INDEX && state.tapbyIndexValue === "") {
      notification["error"]({
        message: "Error in performing actions.",
        description: "Please Select The Element.",
      });
    }
    if (state.selectedAction === ELEMENT_ACTION.TAP_BY_INDEX && state.tapbyIndexValue === "") {
      notification["error"]({
        message: "Error in performing actions.",
        description: "Please Select The Element.",
      });
    }
    let serviceMethod = util.getServiceMethod(state.selectedAction);
    let serviceData = util.getServiceData(state, serverId, targetUniqueId);
    //Find the current Group
    let currentSteps = undefined;
    if (state.selectedGroup && state.selectedGroup.id && state.selectedGroup.recTestSteps) {
      currentSteps = state.selectedGroup;
      if (currentSteps != undefined) {
        currentSteps = currentSteps.recTestSteps;
      }
    }
    if (currentSteps == undefined) {
      currentSteps = state.elementList;
    }

    if (serviceMethod) {
      return async(
        "device/" + serviceMethod,
        "POST",
        serviceData,
        state.serverDetail.serverIp,
        undefined,
        dispatch
      ).then((response) => {
        response = updateResponse(response);
        if (response.status === SUCCESS_STATUS) {
          dispatch({ type: CONFIRM_STEP, response, callToServer: true });
          if (
            state.recordingSetId != 0 &&
            currentSteps.filter((item) => item.id === undefined).length >= SAVE_MINIMUM_UNSAVED_STEP
          ) {
            dispatch(saveRecording(currentSteps));
          }
          return true;
        } else {
          dispatch({ type: CONFIRM_STEP_REQUESTED });
          notification["error"]({
            message: "Error",
            description: response.message,
          });
        }
      });
    } else {
      //For add verification
      dispatch({ type: CONFIRM_STEP, callToServer: false });
      if (
        state.recordingSetId != 0 &&
        currentSteps.filter((item) => item.id === undefined).length >= SAVE_MINIMUM_UNSAVED_STEP
      ) {
        return dispatch(saveRecording(currentSteps));
      }
    }
  };
}
export function changeSkipAfterFail(checked) {
  return (dispatch) => {
    dispatch({ type: CHANGE_SKIP_AFTER_FAIL, checked });
  };
}
export function hideElementPropertiesModal() {
  return (dispatch) => {
    dispatch({ type: HIDE_ELEMENT_PROPERTIES_MODAL });
  };
}

export function swipeByCordinates(
  serverId,
  targetUniqueId,
  xStart,
  yStart,
  xEnd,
  yEnd,
  swipeCoordinates,
  longPress,
  projectId
) {
  return async (dispatch, getState) => {
    dispatch({ type: UPDATE_SWIPE_COORDINATES, xStart, yStart, xEnd, yEnd });
    let state = getState().RecordingReducer;
    return async(
      "device/swipeByCordinates",
      "POST",
      {
        xCoordinate: xStart,
        yCoordinate: yStart,
        xCoordinateOnMouseUp: xEnd,
        yCoordinateOnMouseUp: yEnd,
        serverId,
        targetUniqueId,
        swipeCoordinates: swipeCoordinates,
        longPress: longPress,
        logedInUser: state.logedInUser,
      },
      state.serverDetail.serverIp,
      undefined,
      dispatch
    ).then((response) => {
      response = updateResponse(response);
      util.checkForInValidUserActionPermissionDenied(response) &&
        dispatch(stopAppiumStreaming(serverId, targetUniqueId, true, projectId));
      if (response.status === SUCCESS_STATUS) {
        dispatch({
          type: RESET_SWIPE_BY_COORDINATES,
          response,
          callToServer: true,
        });
        //Find the current Group
        let currentSteps = undefined;
        if (state.selectedGroup && state.selectedGroup.id) {
          currentSteps = util.findCurrentGroup(state.elementList, state.selectedGroup.id);
          if (currentSteps != undefined) {
            currentSteps = currentSteps.recTestSteps;
          }
        }
        if (currentSteps == undefined) {
          currentSteps = state.elementList;
        }

        if (
          state.recordingSetId != 0 &&
          currentSteps.filter((item) => item.id === undefined).length == SAVE_MINIMUM_UNSAVED_STEP
        ) {
          dispatch(saveRecording(currentSteps));
        }
        return true;
      } else {
        dispatch({ type: SHOW_MESSAGE, response });
      }
    });
  };
}
export function refreshDeviceSession(serverId, targetUniqueId, deviceFarmId) {
  return async (dispatch, getState) => {
    dispatch({ type: UPDATE_SESSION_CREATE_STATUS, status: true });
    return async(
      "device/refreshSession",
      "GET",
      { serverId, targetUniqueId },
      getState().RecordingReducer.serverDetail.serverIp,
      undefined,
      dispatch
    ).then((response) => {});
  };
}

export function changeVerificationDropdownValues(value) {
  return (dispatch) => {
    dispatch({ type: CHANGE_VERIFICATION_DROPDOWN_VALUES, value });
  };
}
export function changeCurrentSelectedVerificationValue(value) {
  return (dispatch) => {
    dispatch({ type: CHANGE_VERIFICATION_VALUES, value });
  };
}
export function showNewVerfication() {
  return (dispatch) => {
    dispatch({ type: SHOW_NEW_VERIFICATION });
  };
}
export function hideNewVerfication() {
  return (dispatch) => {
    dispatch({ type: HIDE_NEW_VERIFICATION });
  };
}
export function showPushBuildModal() {
  return (dispatch) => {
    dispatch({ type: SHOW_PUSH_BUILD_MODAL });
  };
}

export function hideModalBuildPush() {
  return (dispatch) => {
    dispatch({ type: HIDE_PUSH_BUILD_MODAL });
  };
}

export function hideLogModal() {
  return (dispatch) => {
    dispatch({ type: HIDE_LOG_MODAL });
  };
}
export function showLogModal() {
  return (dispatch) => {
    dispatch({ type: SHOW_LOG_MODAL });
  };
}

export function showInputTextModal() {
  return (dispatch) => {
    dispatch({ type: SHOW_INPUT_TEXT_MODAL });
  };
}
export function hideInputTextModal() {
  return (dispatch) => {
    dispatch({ type: HIDE_INPUT_TEXT_MODAL });
  };
}

export function updateInputText(value) {
  return (dispatch) => {
    dispatch({ type: UPDATE_INPUT_TEXT, value });
  };
}

export function onInsertText(serverId, targetUniqueId, insertedText, isIOS) {
  let text = isIOS ? insertedText : escapeSpecialCaseChar(insertedText);
  return async (dispatch, getState) => {
    dispatch({ type: INSERT_TEXT_REQUEST });
    return async(
      "device/inputTextInRow",
      "POST",
      {
        serverId: serverId,
        targetUniqueId: targetUniqueId,
        textToInsert: text,
        logedInUser: getState().RecordingReducer.logedInUser,
      },
      getState().RecordingReducer.serverDetail.serverIp,
      undefined,
      dispatch
    ).then((response) => {
      if (response.status === SUCCESS_STATUS) {
        dispatch({ type: INSERT_TEXT_REQUESTED });
        dispatch({ type: HIDE_INPUT_TEXT_MODAL });

        return true;
      } else {
        dispatch({ type: INSERT_TEXT_REQUESTED });
        dispatch({ type: SHOW_MESSAGE, response });
        util.showNotification(ERROR_TYPE, response.message);
        dispatch({ type: HIDE_INPUT_TEXT_MODAL });
      }
    });
  };
}

export function onLogTypeChange(logType) {
  return (dispatch) => {
    dispatch({ type: ON_LOG_TYPE_CHANGE, logType });
  };
}
export function hideOrientationModal() {
  return (dispatch) => {
    dispatch({ type: HIDE_ORIENTATION_MODAL });
  };
}
export function showOrientationModal() {
  return (dispatch) => {
    dispatch({ type: SHOW_ORIENTATION_MODAL });
  };
}
export function onOrientationChange(orientationType) {
  return (dispatch) => {
    dispatch({ type: ON_ORIENTATION_CHANGE, orientationType });
  };
}

export function setCurrentOrientationChange(orientationType) {
  return (dispatch) => {
    dispatch({
      type: CURRENT_SCREEN_ORIENTATION,
      response: { orientationType: orientationType },
    });
  };
}

export function changeBuildFileName(pushBuildFile) {
  return (dispatch) => {
    dispatch({ type: CHANGE_BUILD_FILE, pushBuildFile });
  };
}
export function pushBuild(targetUniqueId, buildId, serverIp, setAppBuildData) {
  return (dispatch, getState) => {
    dispatch({ type: PUSH_BUILD_REQUEST });
    async(
      "device/pushBuild",
      "POST",
      {
        targetUniqueIds: targetUniqueId,
        buildId: buildId,
        logedInUser: getState().RecordingReducer.logedInUser,
      },
      serverIp ? serverIp : getState().RecordingReducer.serverDetail.serverIp,
      undefined,
      dispatch
    ).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        dispatch({ type: PUSH_BUILD, response });
        setAppBuildData && setAppBuildData(INITIAL_APP_BUILD_DATA);
        message.success(response.message);
      } else {
        dispatch({ type: SHOW_MESSAGE, response });
        message.error(response.message);
      }
    });
  };
}
//old Verification
export function addVerificationValue() {
  return (dispatch) => {
    dispatch({ type: ADD_VERIFICATION_VALUES });
  };
}
//old Verification
export function removeVerificationValues(id) {
  return (dispatch) => {
    dispatch({ type: REMOVE_VERIFICATION_VALUES, id });
  };
}
//old Verification
export function changeVerificationValueForRef(id) {
  return (dispatch) => {
    dispatch({ type: CHANGE_VERIFICATION_VALUE_FOR_REF, id });
  };
}
//old Verification
export function changeAbsenceValue(id) {
  return (dispatch) => {
    dispatch({ type: CHANGE_ABSENCE_VALUE, id });
  };
}
export function removeStep(tag) {
  return (dispatch, getState) => {
    dispatch({ type: REMOVE_STEP_REQUEST });
    let savedStep = [];
    if (getState().RecordingReducer.selectedGroup) {
      savedStep = getState().RecordingReducer.selectedGroup.recTestSteps.filter(
        (item) => item.tag === tag && item.id != undefined
      );
    } else {
      savedStep = getState().RecordingReducer.elementList.filter((item) => item.tag === tag && item.id != undefined);
    }
    if (savedStep.length == 1) {
      var jsonData = { id: savedStep[0].id };
      async("recTestStep/customDelete", "POST", jsonData).then((response) => {
        response = updateResponse(response);
        if (response.status === SUCCESS_STATUS) {
          dispatch({ type: REMOVE_STEP, tag, response });
          notification["success"]({
            message: "Step Delete",
            description: "Step deleted successfully.",
          });
          dispatch(hideDeleteRecElementConfirmationModal());
        } else {
          dispatch({ type: REMOVE_STEP_REQUESTED });
          notification["error"]({
            message: "",
            description: response.message,
          });
        }
      });
    } else {
      dispatch({ type: REMOVE_STEP, tag });
    }
  };
}
export function setSelectedStep(value) {
  return (dispatch) => {
    dispatch({ type: SELECTED_STEP, value });
  };
}

export function addActionStep(data) {
  return (dispatch) => {
    dispatch({ type: ADD_ACTION_STEP, data });
  };
}

export function addSwipeOverElementStep(data) {
  return (dispatch) => {
    dispatch({ type: ADD_SWIPE_OVER_ELEMENT_STEP, data });
  };
}

export function addVerificationStep(data) {
  return (dispatch) => {
    dispatch({ type: ADD_VERIFICATION_STEP, data });
  };
}

export function addLaunchAppStep(data) {
  return (dispatch) => {
    dispatch({ type: ADD_LAUNCH_APP_STEP, data });
  };
}

export function updateRecordingSetName(value) {
  return (dispatch) => {
    dispatch({ type: UPDATE_RECORDING_SET_NAME, value });
  };
}

export function saveRecording(currentSteps) {
  return (dispatch, getState) => {
    let state = getState().RecordingReducer;
    if (!currentSteps) {
      currentSteps = state.elementList;
    }

    if (!state.saveRecordingRequest) {
      dispatch({ type: SAVE_SCENARIO_RECORDING_REQUEST });
      var saveList = util.updateStepOrderId(currentSteps);
      var jsonData = {
        id: state.recordingSetId,
        name: state.recordingSetName,
        platform: state.deviceDetail.platformName,
        // "recTestSteps": saveList.filter(item=>item.id == undefined)
      };
      let unsavedSteps = saveList.filter((item) => item.id == undefined);
      // let unsavedSteps = saveList.filter(item=>item.id == undefined);
      if (state.selectedGroup && state.selectedGroup.id && unsavedSteps.length > 0) {
        unsavedSteps.map((item) => {
          item.parentRecTestStep = { id: state.selectedGroup.id };
        });
        jsonData.recTestSteps = unsavedSteps;
        // jsonData.parentRecTestStep = {id:state.selectedGroup.id}
      } else {
        jsonData.recTestSteps = unsavedSteps;
      }
      async("recTestScenario/customSave", "POST", jsonData).then((response) => {
        if (response.status === SUCCESS_STATUS) {
          dispatch({ type: SAVE_SCENARIO_RECORDING, unsavedSteps, response });
          notification["success"]({
            message: "Saved",
            description: "Scenario saved successfully.",
          });
        } else {
          notification["error"]({
            message: "Save Failed",
            description: "Unable to save scenario.",
          });
        }
        dispatch({ type: SAVE_SCENARIO_RECORDING_REQUESTED, response });
      });
    }
  };
}

export function saveRecordingInLocal() {
  return (dispatch, getState) => {
    var jsonData = {
      name: getState().RecordingReducer.recordingSetName,
      recTestScenarios: [
        {
          name: "MyScenario",
          recTestSteps: getState().RecordingReducer.elementList.filter(
            (item) => item.id !== getState().RecordingReducer.blankStepId
          ),
        },
      ],
    };
    util.saveJSON(
      jsonData,
      getState().RecordingReducer.recordingSetName
        ? getState().RecordingReducer.recordingSetName
        : "RecordingScript.json"
    );
  };
}
export function startDeviceScreenStreaming(
  platform,
  serverId,
  targetUniqueId,
  deviceFarmId,
  nodeServicePort,
  recTestScenarioId
) {
  return async (dispatch, getState) => {
    dispatch({
      type: CHECK_AND_RESTART_ANDROID_NODE_SERVICE_REQUEST,
      targetUniqueId,
    });
    return async(
      "device/checkAndRestartAndroidNodeService",
      "POST",
      { serverId, targetUniqueId, nodeServicePort, newSession: true },
      getState().RecordingReducer.serverDetail.serverIp,
      undefined,
      dispatch
    ).then((response) => {
      if (response.status === SUCCESS_STATUS) {
        dispatch({
          type: UPDATE_STATUS_CHECK_AND_RESTART_ANDROID_NODE_SERVICE,
          response,
        });
        dispatch({ type: CHECK_AND_RESTART_ANDROID_NODE_SERVICE_REQUESTED });
        let newPath =
          "/device/" + (platform == IOS ? I : A) + "/" + serverId + "/" + deviceFarmId + "/" + targetUniqueId;
        if (recTestScenarioId) {
          newPath += "/" + recTestScenarioId + "/recording";
        }
        history.push(newPath);
      } else {
        notification["error"]({
          message: "Device Session",
          description: response.message,
        });
      }
      dispatch({
        type: UPDATE_STATUS_CHECK_AND_RESTART_ANDROID_NODE_SERVICE,
        response,
      });
      dispatch({ type: CHECK_AND_RESTART_ANDROID_NODE_SERVICE_REQUESTED });
      if (response.invalidUser) {
        dispatch(getDevices(deviceFarmId));
      }
    });
  };
}
function getServerIp(state, targetUniqueId) {
  const { selectedServerDevices } = state.ServerListReducer;
  const { serverDetail } = state.RecordingReducer;
  let serverIp = serverDetail ? serverDetail.serverIp : undefined;
  if (serverIp === undefined && selectedServerDevices && selectedServerDevices.length > 0) {
    selectedServerDevices.map((v) => {
      if (v.device.targetUniqueId === targetUniqueId) {
        serverIp = v.serverDetail.serverIp;
      }
    });
  }
  return serverIp;
}
export function startDeviceSession(
  platform,
  serverId,
  targetUniqueId,
  deviceFarmId,
  isForRestart,
  projectId,
  orgId,
  activeTab
) {
  return async (dispatch, getState) => {
    let device = "";
    let deviceObj = getState().ManageFarmReducer.getSearchDeviceList.filter(
      (item) => item["device"]["targetUniqueId"] == targetUniqueId
    );
    if (deviceObj.length === 1) {
      device = deviceObj[0]["device"];
      new Promise((resolve) => resolve(dispatch(acquireDevice(device, deviceObj[0]["serverDetail"])))).then(() => {
        new Promise((resolve) => resolve(dispatch(generateDeviceToken()))).then(() => {
          new Promise((resolve) =>
            resolve(dispatch(getServerDetail(serverId, targetUniqueId, true, deviceFarmId)))
          ).then((response) => {
            if (response) {
              dispatch(
                startAppiumStreaming(
                  platform,
                  serverId,
                  device.targetUniqueId,
                  deviceFarmId,
                  false,
                  projectId,
                  orgId,
                  activeTab
                )
              );
            }
          });
        });
      });
    }
  };
}
export function stopPageSource() {
  if (stopPageEventSourceTimeOut === undefined) {
    stopPageEventSourceTimeOut = [];
  }
  stopPageEventSourceTimeOut.push(
    setTimeout(() => {
      if (pageEventSource) {
        clearInterval(pageEventSource);
        // pageEventSource && pageEventSource.close();
        pageEventSource = undefined;
      }
    }, 30000)
  );
}
export function startAppiumStreaming(
  platform,
  serverId,
  targetUniqueId,
  deviceFarmId,
  isForRestart,
  projectId,
  orgId,
  activeTab
) {
  return async (dispatch, getState) => {
    let serverIp = getServerIp(getState(), targetUniqueId);
    const { deviceInteractionMode } = getState().RecordingReducer;
    const { projectId } = getState().ProjectsReducer;
    const orgId = util.getOrgId();

    dispatch({ type: REQUEST_FOR_DEVICE_LOAD });
    dispatch({
      type: CHECK_AND_RESTART_ANDROID_NODE_SERVICE_REQUEST,
      targetUniqueId,
    });
    return async(
      "device/startDeviceScreenStreaming",
      "POST",
      { serverId, targetUniqueId, newSession: true, projectId, orgId },
      serverIp,
      undefined,
      dispatch
    ).then((response) => {
      response = updateResponse(response);
      dispatch({ type: CHECK_AND_RESTART_ANDROID_NODE_SERVICE_REQUESTED });
      dispatch({
        type: UPDATE_STATUS_CHECK_AND_RESTART_IOS_RECORDING_SERVICE,
        response,
      });
      dispatch({ type: DEVICE_LOADED, isLoaded: true });
      if (response.status === SUCCESS_STATUS) {
        dispatch(updateDeviceDetailForRecTestScenario(targetUniqueId, serverId, platform));
        dispatch(getInstalledApp(targetUniqueId, serverId));
        if (deviceInteractionMode === DEVICE_INTERACTION_MODE.SELECT) {
          if (pageEventSource !== undefined) {
            stopPageSource();
            dispatch(startPageSource(targetUniqueId, serverId));
          } else {
            dispatch(startPageSource(targetUniqueId, serverId));
          }
        } else if (deviceInteractionMode === DEVICE_INTERACTION_MODE.DRAW) {
          dispatch(recordingStart(deviceInteractionMode));
        } else {
          stopPageSource();
        }
        dispatch({ type: REQUESTED_FOR_DEVICE_LOAD });
        setTimeout(() => {
          localStorage.removeItem("isDeviceLoaded");
        }, 1000);
      } else if (response.status === ERROR_STATUS) {
        dispatch(stopAppiumStreaming(serverId, targetUniqueId, false, projectId));
        // history.push("/" + orgId + "/project/" + projectId);
        // dispatch(stopAppiumStreaming(serverId, targetUniqueId));
        dispatch({ type: REQUESTED_FOR_DEVICE_LOAD });
        dispatch({ type: DEVICE_LOADED, isLoaded: false });
        if (!util.checkForInValidUserActionPermissionDenied(response)) {
          notification["error"]({
            message: "Not able to start service. Please try after sometime.",
            description: "",
          });
        }
      }
    });
  };
}

export function checkIOSStreamingIsAlive(serverId, targetUniqueId, deviceFarmId) {
  return async (dispatch, getState) => {
    iosStreamingSource = prepareEventSource(
      "device/checkStatusForIosRecording",
      { targetUniqueId: targetUniqueId },
      getState().RecordingReducer.serverDetail.serverIp
    );
    let retry = 0;
    iosStreamingSource.onmessage = (event: any) => {
      if (event.data != "") {
        if (event.data == "false") {
          if (retry > 10) {
            retry = 0;
            if (iosStreamingSource) {
              iosStreamingSource.close();
            }
            notification["error"]({
              message: "Looks like this session has ended. Please try after sometime.",
              description: "",
            });
            setTimeout(() => {
              dispatch(stopAppiumStreaming(serverId, targetUniqueId));
              history.push("/farms/" + (deviceFarmId ? deviceFarmId : ""));
              window.location.reload();
            }, 3000);
          }
          retry++;
        } else {
          retry = 0;
        }
      }
    };
  };
}

export function stopAppiumStreaming(serverId, targetUniqueId, isDeviceLoaded = false, projectId) {
  return async (dispatch, getState) => {
    dispatch({ type: REQUEST_FOR_DEVICE_UNLOAD });
    dispatch({ type: STOP_DEVICE_STREAMING });
    if (iosStreamingSource) {
      iosStreamingSource.close();
    }
    const { isPreviewRunning } = getState().RecTestStepReducer;
    const { projectId } = getState().ProjectsReducer;
    const orgId = util.getOrgId();

    if (isPreviewRunning) {
      dispatch(stopPreview());
    }
    stopPageSource();
    return async(
      "device/stopDeviceScreenStreaming",
      "POST",
      { serverId, targetUniqueId, projectId, orgId },
      getState().RecordingReducer.serverDetail?.serverIp,
      undefined,
      dispatch
    ).then((response) => {
      return async(
        "device/releaseDevice",
        "POST",
        { targetUniqueId: targetUniqueId, serverId: serverId, projectId, orgId },
        getState().RecordingReducer.serverDetail?.serverIp
      ).then((response) => {
        dispatch({ type: FIND_DEVICE_BY_UDID, response: undefined, deviceFarmId: undefined });
        dispatch({ type: UPDATE_DEVICE_DETAIL_FOR_REC_TEST_SCENARIO });
        new Promise((resolve) => {
          let farmId = [];
          getState().ServerListReducer.deviceFarmList.map((data) => {
            farmId.push(data.id);
          });
          resolve(
            dispatch(
              getDeviceByFarmId({
                farmId,
                deviceName: "",
                deviceFarmName: "",
                osVersion: "",
                searchKeyword: "",
                deviceFarmUse: "",
              })
            )
          );
        }).then(() => {
          dispatch({ type: DEVICE_LOADED, isLoaded: false });
          dispatch({ type: REQUESTED_FOR_DEVICE_UNLOAD });
        });
      });
    });
  };
}
export function getDeviceScreenByWebsocket(serverId, targetUniqueId, restartMinicap = false) {
  return async (dispatch, getState) => {
    if (
      getState().RecordingReducer.deviceDetail &&
      getState().RecordingReducer.deviceDetail.minicapNodeServicePort &&
      getState().RecordingReducer.serverDetail.serverIp
    ) {
      let minicapPort = getState().RecordingReducer.deviceDetail.minicapNodeServicePort;
      let socketUrl = "";
      let socketIp = getState().RecordingReducer.serverDetail.serverIp.split("://")[1].split(":")[0];
      socketUrl += getState().RecordingReducer.serverDetail.serverIp.split("://")[0] == "https" ? "wss://" : "ws://";
      socketUrl += socketIp + ":" + minicapPort;

      // if(client){
      //   clientClosedStatus=true;
      //   client.close();
      //   client=undefined;
      // }
      var data = {
        token: localStorage.getItem("xpressDeviceToken"),
        uname: localStorage.getItem("requestedUser"),
      };

      socketUrl += "?param=" + JSON.stringify(data);
      if (client === undefined) {
        client = new W3CWebSocket(socketUrl, "minicap");
        client.binaryType = "blob";

        client.onclose = function (message) {
          // if(!getState().ServerListReducer.checkAndRestartAndroidNodeServiceRequest){
          //   dispatch(checkAndRestartAndroidNodeService(serverId,targetUniqueId))
          // }.d

          console.log("onClose");
          if (this.clientClosedStatus) {
            notification["error"]({
              message: "",
              description: "Looks like this session has ended. Please refresh to continue.",
            });
          }
        };

        client.onerror = function (e) {
          console.log("onError");
          console.log(e);
          // if(!getState().ServerListReducer.checkAndRestartAndroidNodeServiceRequest){
          //   dispatch(checkAndRestartAndroidNodeService(serverId,targetUniqueId))
          // }
          if (this.clientClosedStatus) {
            notification["error"]({
              message: "",
              description: "Looks like this session has ended. Please refresh to continue.",
            });
          }
        };
        var URL = window.URL || window.webkitURL;
        client.onmessage = function (message) {
          if (!connectionCloseRequest) clientClosedStatus = false;
          if (typeof message.data == "object") {
            URL.revokeObjectURL(blobUrl);
            var blob = new Blob([message.data], { type: "image/jpeg" });
            blobUrl = URL.createObjectURL(blob);
            dispatch({ type: GET_DEVICE_SCREEN, response: blobUrl });
          } else if (
            typeof message.data == "string" &&
            message.data.includes("minicapStatus") &&
            JSON.parse(message.data).state == false
          ) {
            clientClosedStatus = true;
            client.close();
            client = undefined;
            return dispatch(getDeviceScreenByWebsocket(serverId, targetUniqueId, true));
          } else if (typeof message.data == "string" && message.data.includes("GetClipboardResponse")) {
            dispatch({
              type: "UPDATE_CLIPBOARD_DATA",
              value: JSON.parse(message.data).message.text,
            });
          } else if (typeof message.data == "string" && message.data.includes("shellOutput")) {
            dispatch({
              type: SAVE_SHELL_COMMAND_OUTPUT,
              data: JSON.parse(message.data).data,
            });
          }
        };

        client.onopen = function () {
          client.send("1920x1080/" + getUniqueId());
          connectionCloseRequest = false;
        };
      }
    }
  };
}

export function checkAndRestartAndroidNodeService(serverId, targetUniqueId) {
  return async (dispatch, getState) => {
    dispatch({
      type: CHECK_AND_RESTART_ANDROID_NODE_SERVICE_REQUEST,
      targetUniqueId,
    });
    dispatch({ type: START_DEVICE_SCREEN_LOADER });
    return async(
      "device/checkAndRestartAndroidNodeService",
      "POST",
      {
        serverId,
        targetUniqueId,
        newSession: getState().RecordingReducer.newDeviceSession,
      },
      getState().RecordingReducer.serverDetail.serverIp,
      undefined,
      dispatch
    ).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        if (client) {
          clientClosedStatus = true;
          client.close();
          client = undefined;
        }
        dispatch({ type: CHECK_AND_RESTART_ANDROID_NODE_SERVICE_REQUESTED });
        dispatch({ type: STOP_DEVICE_SCREEN_LOADER });
        dispatch(getDeviceScreenByWebsocket(serverId, targetUniqueId));
      } else {
        dispatch({ type: SHOW_MESSAGE, response });
        dispatch({
          type: STOP_DEVICE_SCREEN_LOADER,
        });
        notification["error"]({
          message: "",
          description: "Looks like this session has ended. Please refresh to continue.",
        });
      }
    });
  };
}

export function getDeviceScreen(response) {
  return async (dispatch, getState) => {
    return dispatch({ type: GET_DEVICE_SCREEN, response });
  };
}

export function getServerDetail(serverId, targetUniqueId, isUpdateStateValue, deviceFarmId) {
  return async (dispatch, getState) => {
    let response = sync("server/getServerDetailsById", "GET", { serverId: serverId });
    if (response.status === SUCCESS_STATUS) {
      dispatch({ type: UPDATE_SERVER_DETAIL, response });
      dispatch(getDeviceDetails(serverId, targetUniqueId, isUpdateStateValue, deviceFarmId));
      return true;
    }
    return false;
  };
}
export function changeSelectedBuildTestScenarioTab(selectedKey) {
  if (selectedKey == DEVICE_STATUS.TEST_PLAN || selectedKey == DEVICE_STATUS.PREVIEW) {
    return (dispatch) => {
      dispatch({
        type: CHANGE_SELECTED_BUILD_TEST_SCENARIO_TAB,
        data: { selectedKey: selectedKey, changeModeToPrevious: false },
      });
      dispatch({ type: RECORDING_STOP });
    };
  } else if (selectedKey == DEVICE_STATUS.RECORDING) {
    return (dispatch) => {
      dispatch({
        type: CHANGE_SELECTED_BUILD_TEST_SCENARIO_TAB,
        data: { selectedKey: selectedKey, changeModeToPrevious: true },
      });
      dispatch({ type: RESET_PREVIOUS_STATE });
    };
  }
}

export function getAllScenarios() {
  return async (dispatch) => {
    async("recTestScenario/getAllScenarios", "GET", {}).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        dispatch({ type: ALL_SCENARIOS, response });
        return true;
      } else {
        return false;
      }
    });
  };
}

export function showLoadScenarioModal(flag) {
  return (dispatch) => {
    dispatch({ type: SHOW_LOAD_SCENARIO_MODAL, flag });
  };
}

export function resetDeviceScreen(serverId, targetUniqueId, deviceFarmId, platform) {
  return (dispatch) => {
    // if (platform === "A") {
    //   dispatch(stopDeviceScreenStreaming(serverId, targetUniqueId));
    // } else
    if (platform === "I" || platform === "A") {
      dispatch(stopAppiumStreaming(serverId, targetUniqueId));
    }
    dispatch({ type: RESET_SCREEN, response: null });
  };
}

export function convertImageURLToBase65(tag) {
  return (dispatch, getState) => {
    let previewResultElementList = getState().RecordingReducer.previewResultElementList;
    if (previewResultElementList && previewResultElementList.length > 0) {
      let step = previewResultElementList.filter((step) => step.tag === tag);
      if (
        step &&
        step.length > 0 &&
        step[0].recTestStepProperty &&
        ((step[0].recTestStepProperty?.screenShotUrl != null &&
          step[0].recTestStepProperty?.screenShotUrl.includes("http")) ||
          (step[0].recTestStepProperty?.screenShot != null && step[0].recTestStepProperty?.screenShot.includes("http")))
      ) {
        htmlToImage.toPng(document.getElementById("stepImage" + tag)).then(function (dataUrl) {
          previewResultElementList.map((step) => {
            if (step.tag === tag) {
              step.recTestStepProperty.screenShotUrl = null;
              step.recTestStepProperty.screenShot = dataUrl.split("data:image/png;base64,")[1];
            }
          });
        });
      }
    }
  };
}
export function deviceDisconnect() {
  return (dispatch) => {
    dispatch({ type: DEVICE_DISCONNECT });
  };
}
export function viewNotifications(data) {
  let self = this;
  return async (dispatch, getState) => {
    return async(
      "device/viewNotifications",
      "POST",
      {
        serverId: data.serverId,
        targetUniqueId: data.targetUniqueId,
        deviceInteractionAction: data.deviceInteractionAction,
        scrollDirection: data.scrollDirection,
        logedInUser: getState().RecordingReducer.logedInUser,
      },
      getState().RecordingReducer.serverDetail.serverIp,
      undefined,
      dispatch
    ).then((response) => {
      if (response.status === SUCCESS_STATUS) {
        dispatch({ type: VIEW_NOTIFICATION, response });
        let state = getState().RecordingReducer;

        //Find the current Group
        let currentSteps = undefined;
        if (state.selectedGroup && state.selectedGroup.id) {
          currentSteps = util.findCurrentGroup(state.elementList, state.selectedGroup.id);
          if (currentSteps != undefined) {
            currentSteps = currentSteps.recTestSteps;
          }
        }
        if (currentSteps == undefined) {
          currentSteps = state.elementList;
        }

        if (
          state.recordingSetId != 0 &&
          state.elementList.filter((item) => item.id === undefined).length == SAVE_MINIMUM_UNSAVED_STEP
        ) {
          dispatch(saveRecording(currentSteps));
        }
        return true;
      } else {
        dispatch({ type: SHOW_MESSAGE, response });
        return false;
      }
    });
  };
}

export function startDeviceLogs(data) {
  let self = this;
  return async (dispatch, getState) => {
    dispatch({ type: LOG_START_REQUEST });
    return async(
      "device/startDeviceLogs",
      "POST",
      {
        targetUniqueId: data.targetUniqueId,
        serverId: data.serverId,
        logType: data.logType,
        logedInUser: getState().RecordingReducer.logedInUser,
      },
      getState().RecordingReducer.serverDetail.serverIp,
      undefined,
      dispatch
    ).then((response) => {
      if (response.status === SUCCESS_STATUS) {
        notification["success"]({
          message: SUCCESS_STATUS,
          description: "The Logs Are Started On Device",
        });
        dispatch({ type: START_LOGS_ON_DEVICE });
        return true;
      } else {
        notification["error"]({
          message: "Failed To Started Logs On Device",
          description: "Logs Starting Failed",
        });
        dispatch({ type: SHOW_MESSAGE, response });
      }
    });
  };
}

// export function startDeviceLogs(serverId,targetUniqueId,logType){
//   let self = this;
//     return async (dispatch,getState) => {
//       return async('device/startDeviceLogs', 'GET', {targetUniqueId:targetUniqueId,serverId:serverId,logType:logType},serverId==="Local",getState().RecordingReducer.serverDetail.serverIp)
//       .then(response => {
//       if(response.status===Success){
//         dispatch({type:START_LOGS_ON_DEVICE,response});
//         return true;
//       }else{
//         dispatch({type:SHOW_MESSAGE,response});
//       }
//       });
//     };
//   }

export function stopDeviceLogs(data) {
  let self = this;
  return async (dispatch, getState) => {
    return async(
      "device/stopDeviceLogs",
      "GET_FOR_FILE",
      {
        targetUniqueId: data.devicetargetUniqueIdId,
        serverId: data.serverId,
        logedInUser: getState().RecordingReducer.logedInUser,
      },
      getState().RecordingReducer.serverDetail.serverIp,
      undefined,
      dispatch
    ).then((response) => {
      dispatch({ type: STOP_LOGS_ON_DEVICE });
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = data.targetUniqueId + ".txt";
        a.click();
      });
    });
  };
}

export function changeOrientation(data) {
  let self = this;
  return async (dispatch, getState) => {
    return async(
      "device/changeOrientation",
      "POST",
      {
        targetUniqueId: data.targetUniqueId,
        serverId: data.serverId,
        orientationType: data.orientationType,
        logedInUser: getState().RecordingReducer.logedInUser,
      },
      getState().RecordingReducer.serverDetail.serverIp
    ).then((response) => {
      if (response.status === SUCCESS_STATUS) {
        dispatch({ type: CHANGE_ORIENTATION, data, response });
        return true;
      } else {
        notification["error"]({
          message: "",
          description: "Orientation is not supported for this screen. ",
        });
        dispatch({ type: FAILED_TO_ROTATE_SCREEN, response });
      }
    });
  };
}
export function startSwipe(xStart, yStart) {
  return (dispatch, getState) => {
    dispatch({ type: UPDATE_SWIPE_COORDINATES, xStart, yStart });
  };
}

export function insertTextInAndroid(serverId, targetUniqueId, key) {
  return async (dispatch, getState) => {
    dispatch({ type: FAST_INPUT_ICON_INDICATION });
    if (client) {
      client.send('{"action" : "inputText", "text": "' + escapeSpecialCaseCharAndroid(key) + '"}');
    }
    return true;
  };
}

export function insertTextInIOS(serverId, targetUniqueId, key) {
  return async (dispatch, getState) => {
    dispatch({ type: FAST_INPUT_ICON_INDICATION });

    return async(
      "device/inputText",
      "POST",
      {
        serverId,
        targetUniqueId,
        textToInsert: key,
        logedInUser: getState().RecordingReducer.logedInUser,
      },
      getState().RecordingReducer.serverDetail.serverIp
    ).then((response) => {
      if (response.status === SUCCESS_STATUS) {
        return true;
      } else {
        dispatch({ type: SHOW_MESSAGE, response });
      }
    });
  };
}

export function recentAppTray(data) {
  let self = this;
  return async (dispatch, getState) => {
    return async(
      "device/recentAppTray",
      "POST",
      {
        targetUniqueId: data.targetUniqueId,
        serverId: data.serverId,
        logedInUser: getState().RecordingReducer.logedInUser,
      },
      getState().RecordingReducer.serverDetail.serverIp
    ).then((response) => {
      if (response.status === SUCCESS_STATUS) {
        dispatch({ type: OPEN_RECENT_APP_TRAY, response });
        return true;
      } else {
        dispatch({ type: SHOW_MESSAGE, response });
        return false;
      }
    });
  };
}

export function restartDevice(data, startProxy) {
  let self = this;
  return async (dispatch, getState) => {
    dispatch({ type: RESTART_DEVICE_REQUESTED });
    return async(
      "device/restart",
      "POST",
      {
        targetUniqueId: data.targetUniqueId,
        serverId: data.serverId,
        logedInUser: getState().RecordingReducer.logedInUser,
      },
      getState().RecordingReducer.serverDetail.serverIp
    ).then((response) => {
      if (response.status === SUCCESS_STATUS) {
        // if (data.platformName === "ios") {
        dispatch(
          startAppiumStreaming(
            data.platform,
            data.serverId,
            data.targetUniqueId,
            data.deviceFarmId,
            true,
            data.projectId
          )
        );
        // window.location.reload();
        // } else {
        //   if (client) {
        //     clientClosedStatus = true;
        //     client.close();
        //     client = undefined;
        //   }
        //   return async(
        //     "device/checkAndRestartAndroidNodeService",
        //     "POST",
        //     { serverId: data.serverId, targetUniqueId: data.targetUniqueId },
        //   ).then((response) => {
        //     response = updateResponse(response);
        //     if (response.status === Success) {
        //       if (client) {
        //         clientClosedStatus = true;
        //         client.close();
        //         client = undefined;
        //       }
        //       dispatch({ type: RESTART_DEVICE, response });
        //       dispatch(getDeviceScreenByWebsocket(data.serverId, data.targetUniqueId));
        //       dispatch({
        //         type: CHECK_AND_RESTART_ANDROID_NODE_SERVICE_REQUESTED,
        //       });
        //       dispatch({ type: RESTART_DEVICE, response });

        //       if (startProxy) {
        //         dispatch(startProxyWebSocket(data));
        //         dispatch({ type: PROXY_STARTED });
        //       }
        //     } else {
        //       dispatch({ type: SHOW_MESSAGE, response });
        //       notification["error"]({
        //         message: "",
        //         description: "Looks like this session has ended. Please refresh to continue.",
        //       });
        //     }
        //   });
        //   // dispatch(getDeviceScreenByWebsocket(data.serverId,data.targetUniqueId));
        // }
        return true;
      } else {
        dispatch({ type: SHOW_MESSAGE, response });
      }
    });
  };
}

export function forceStopDeviceLogs(data) {
  return async (dispatch, getState) => {
    return async(
      "device/forceStopDeviceLogs",
      "POST",
      { targetUniqueId: data.targetUniqueId, serverId: data.serverId },
      getState().RecordingReducer.serverDetail.serverIp
    ).then((response) => {
      if (response.status === SUCCESS_STATUS) {
        dispatch({ type: DEVICE_SESSION_LOGS_STREAM_STOPPED });
        return true;
      } else {
        dispatch({ type: SHOW_MESSAGE, response });
      }
    });
  };
}

export function updateUser(data) {
  return async (dispatch, getState) => {
    return dispatch(updateInvalidUser({ targetUniqueId: data.targetUniqueId }));
  };
}
export function passwordInput(value) {
  return async (dispatch) => {
    return dispatch({ type: PASSWORD_INPUT, value });
  };
}
export function showUserDetails() {
  return async (dispatch) => {
    return dispatch({ type: SHOW_USER_DETAILS });
  };
}
export function updateTakeStepScreenShot(event) {
  return async (dispatch) => {
    return dispatch({ type: UPDATE_TAKE_STEP_SCREENSHOT });
  };
}
export function getPreviewStepImage(previewOutputFolder, serverId) {
  return async (dispatch, getState) => {
    // dispatch({type:DOWNLOAD_PREVIEW_STEP_IMAGES_REQUEST});
    return async(
      "recording/getImages",
      "GET_FOR_FILE",
      { outputFolder: previewOutputFolder },
      getState().RecordingReducer.serverDetail.serverIp
    ).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = "download.zip";
        a.click();
      });
      return dispatch({ type: DOWNLOAD_PREVIEW_STEP_IMAGES_REQUESTED });
    });
  };
}

export function stopFastInputIndication() {
  return async (dispatch) => {
    return dispatch({ type: STOP_FAST_INPUT_INDICATION });
  };
}
export function pleaseSetScenerioName() {
  return async (dispatch) => {
    message.error("Please Enter The Scenerio Name");
  };
}
export function addNewScenerio() {
  return async (dispatch) => {
    return dispatch({ type: ADD_NEW_SCENARIO });
  };
}
export function backToSenerioList(from, id) {
  return async (dispatch) => {
    backToScenarioList(from, id);
    return dispatch({ type: BACK_TO_SCENARIO_LIST });
  };
}
export function editScenario() {
  return async (dispatch) => {
    return dispatch({ type: EDIT_SCENARIO });
  };
}
export function startExecuteScreen() {
  return async (dispatch) => {
    return dispatch({ type: START_EXECUTE_SCREEN });
  };
}

export function getScenarioByRecIdForExecution(recTestScenarioId, recTestScenarioName) {
  return async (dispatch, getState) => {
    dispatch({
      type: TABLE_EXECUTE_BUTTON_LOADER,
      loaderId: recTestScenarioId,
    });
    async("recTestStep/getByRecTestScenarioId", "GET", { recTestScenarioId: recTestScenarioId }).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        dispatch({
          type: LOAD_SELECTED_SCENARIO_FOR_EXECUTION,
          response,
          recTestScenarioId,
          recTestScenarioName,
        });

        if (history.location.pathname.match("/execute/") === null) {
          let executePathName = recTestScenarioId + "/execute/";
          if (!(history.location.pathname.slice(-1) === "/")) {
            executePathName = "/" + executePathName;
          }
          history.push(history.location.pathname + executePathName);
        } else {
          dispatch({ type: SET_CURRENT_EXECUTE_GROUP_FROM_URL, data: "" });
        }
        dispatch(startExecuteScreen());
      }
      dispatch({
        type: TABLE_EXECUTE_BUTTON_LOADER_STOP,
        loaderId: recTestScenarioId,
      });
    });
  };
}

export function clearExecutionData(runId, serverId) {
  return (dispatch) => {
    dispatch({ type: CLEAR_EXECUTION_DATA, response: null });
    async("recording/removeRunData", "POST", { runId: runId }).then((removeDataResponse) => {
      removeDataResponse = updateResponse(removeDataResponse);
      dispatch({ type: CLEAR_EXECUTION_DATA_DONE, response: null });
    });
  };
}

export function changeSelectedTabInExecutionPanel(selectedKey) {
  return (dispatch) => {
    dispatch({
      type: CHANGE_SELECTED_EXECUTION_PANEL_TAB,
      data: { selectedKey: selectedKey, changeModeToPrevious: false },
    });
  };
}

export function insertCustomTextVerificationRow(order) {
  return (dispatch) => {
    dispatch({ type: INSERT_CUSTOM_TEXT_VERIFICATION_ROW, order });
  };
}
export function removeCustomTextVerificationRow(order) {
  return (dispatch) => {
    dispatch({ type: REMOVE_CUSTOM_TEXT_VERIFICATION_ROW, order });
  };
}

//TYPE
export function updateCustomRefTypeValue(value, order) {
  return (dispatch) => {
    dispatch({ type: UPDATE_CUSTOM_REF_TYPE_VALUE, value, order });
  };
}
//TEXT
export function updateCustomRefTextValue(value, order) {
  return (dispatch) => {
    dispatch({ type: UPDATE_CUSTOM_REF_TEXT_VALUE, value, order });
  };
}
//OPTION VALUE
export function updateCustomRefOptionValue(value, order) {
  return (dispatch) => {
    dispatch({ type: UPDATE_CUSTOM_REF_OPTION_VALUE, value, order });
  };
}

//RESET
export function resetCustomRefList() {
  return (dispatch) => {
    dispatch({ type: RESET_CUSTOM_REF_LIST });
  };
}

export function showElementPropertiesModal(value) {
  return (dispatch) => {
    dispatch({ type: TOGGLE_ELEMENT_PROPERTIES_MODAL, value });
  };
}

export function flushTheDataOfRecording() {
  return (dispatch) => {
    dispatch({ type: FLUSH_THE_RECORDING_DATA });
  };
}

export function resetElement() {
  return (dispatch) => {
    dispatch({ type: RESET_ELEMENT_SELECTION });
  };
}
export function removeBlankStep() {
  return (dispatch) => {
    dispatch({ type: REMOVE_BLANK_STEP });
  };
}
export function showUnsavedStepModalVisible(id) {
  return (dispatch) => {
    dispatch({ type: SHOW_UNSAVED_STEP_MODAL_VISIBLE });
    dispatch(setSelectedStep(id));
  };
}
export function onCancelUnsavedModalVisible() {
  return (dispatch) => {
    dispatch({ type: ON_CANCEL_UNSAVED_STEP_MODAL_VISIBLE });
  };
}
export function getAllBuildDetails(
  reset,
  searchKeyword,
  projectID,
  filter,
  rights,
  stepType,
  testPlanId,
  isForRepeat,
  buildType = ""
) {
  return async (dispatch, getState) => {
    var searchText = searchKeyword;
    let { testJobDetail } = getState().TestJobReducer;
    var pageNumber = reset ? 1 : getState().RecordingReducer.appBuildListCurrentSelectedPage;
    var recordsPerPage = RECORD_10_PER_PAGE;
    var filterStatus = filter || "";
    var projectId = projectID || getState().ProjectsReducer.projectId;
    !isForRepeat && dispatch({ type: GET_APP_MANAGER_BUILD_DETAILS_REQUEST });
    return async("appBuild/getAllBuildDetails", "GET", {
      searchText: util.isNotNullAndNotEmptyAndNotUndefined(searchText) ? searchText : "",
      pageNumber: pageNumber,
      recordsPerPage: recordsPerPage,
      projectId: projectId,
      filter: filterStatus,
      rights: rights ? rights : "",
      testPlanId: testPlanId ? testPlanId : 0,
      executionFarm:
        stepType === EXECUTION_JOB
          ? testJobDetail.deviceServiceFarm
            ? testJobDetail.deviceServiceFarm
            : DEVICE_EXECUTION_FARM.XPRESS
          : "",
      buildType: buildType,
    }).then((response) => {
      if (response.status === SUCCESS_STATUS) {
        dispatch({ type: ALL_APP_BUILD_DETAILS, response, pageNumber });
        if (getState().RecordingReducer.appBuildListCurrentSelectedPage > response.data.totalCount) {
          dispatch(getAllBuildDetails());
        }
        return true;
      } else {
        dispatch({ type: ALL_APP_BUILD_DETAILS_FAILED, response });
        notification["error"]({
          message: "App Manager",
          description: "Failed to fetch the app builds.",
        });
      }
    });
  };
}
export function showAddAppBuildMenu() {
  return async (dispatch) => {
    return dispatch({ type: SHOW_ADD_APP_BUILD_MENU });
  };
}

export function handleSharedChangeInAddBuild(value) {
  return async (dispatch, getState) => {
    dispatch(getDeviceFarmList());
    return dispatch({ type: CHANGE_SHARED_IN_ADD_BUILD, value });
  };
}
export function farmSelectInAddBuild(value) {
  return (dispatch) => {
    return dispatch({ type: SELECT_APP_BUILD_FARM_LIST_OPTION, value });
  };
}
export function handlePlatformTypeInAddBuild(value) {
  return (dispatch) => {
    return dispatch({ type: CHANGE_PLATFORM_TYPE_IN_ADD_BUILD, value });
  };
}

export function getUploadBuildOption(projectId) {
  return async (dispatch) => {
    return async("appBuild/getUploadBuildOptions", "GET", { projectId }).then((response) => {
      response = updateResponse(response);
      dispatch({ type: GET_UPLOAD_BUILD_OPTIONS, response });
    });
  };
}

export function getAWSDeviceFarmProjects(projectId) {
  return async (dispatch) => {
    return async("awsDeviceFarmProject/getListByProjectId", "GET", { projectId }).then((response) => {
      response = updateResponse(response);
      dispatch({ type: GET_AWS_PROJECTS_LIST, response });
    });
  };
}

export function checkForSameBuildVersionExists(buildData) {
  return async (dispatch, getState) => {
    return async("appBuild/checkForSameBuildVersionExist", "GET", {
      buildVersion: buildData?.buildVersion,
      platformType: getState().ProjectsReducer.platform,
      buildType: buildData?.buildType,
      projectId: getState().ProjectsReducer.projectId,
    }).then((response) => {
      if (response.status === SUCCESS_STATUS) {
        if (response.data === null) {
          return response.status;
        } else if (response.data !== null) {
          message.error(response.message);
          dispatch({ type: UPLOAD_BUILD_REQUEST_FAILED });
          dispatch({ type: ADDING_BUILD_ALREADY_EXIST, response });
        }
      } else {
        message.error(response.message);
        dispatch({ type: UPLOAD_BUILD_REQUEST_FAILED });
      }
    });
  };
}

export function getCurlForBuildUpload(buildData) {
  return async (dispatch, getState) => {
    var data = {
      projectId: getState().ProjectsReducer.projectId,
      awsDeviceFarmProjectId: buildData?.awsDeviceFarmProject || 0,
    };
    dispatch({ type: CURL_FETCH_FOR_BUILD_LOADING });
    return async("buildDetails/getCurlForBuildUpload", "GET", data).then((response) => {
      if (response.status === SUCCESS_STATUS) {
        dispatch({ type: FETCHED_CURL_FOR_BUILD_UPLOAD, response });
      } else {
        util.showNotification(ERROR_TYPE, response.message);
      }
      dispatch({ type: CURL_FETCH_FOR_BUILD_LOADING_FALSE });
    });
  };
}

export function saveBuildDetails(buildData, deviceId, saveBuild, setAppBuildData,createBuild,setBuildVersionList) {
  let checkExistBuild = null;
  return async (dispatch, getState) => {
    let { allAppBuildDetails, pushBuildModalVisible } = getState().RecordingReducer;
    if (pushBuildModalVisible) {
      dispatch({ type: UPLOAD_AND_SAVE_BUILD_REQUEST });
    } else {
      dispatch({ type: UPLOAD_BUILD_REQUEST });
    }
    if (util.isNullOrUndefinedOrEmpty(buildData?.buildId)) {
      checkExistBuild = await dispatch(checkForSameBuildVersionExists(buildData));
    }
    if (checkExistBuild === SUCCESS_STATUS || buildData?.buildId) {
      var saveBuildDetailsBeforeUpload = new FormData();
      if (util.checkNotUndefined(buildData?.appFile)) {
        saveBuildDetailsBeforeUpload.append("appMultiPartFile", buildData?.appFile);
      }
      let formattedBuildDate = new Date(buildData?.buildDateAndTime);
      // Ensure that buildDate is properly formatted as ISO string
      formattedBuildDate = formattedBuildDate.toISOString();

      const appBuildRequest = {
        displayName: buildData?.displayName,
        buildTags: buildData?.tag,
        buildType: buildData?.buildType,
        buildVersion: buildData?.buildVersion,
        buildDate: formattedBuildDate,
        appActivity: buildData?.appActivity,
        enableX: buildData?.enableX,
        enableSL: buildData?.enableSL,
        enableLT: buildData?.enableLT,
        project: {
          id: getState().ProjectsReducer.projectId,
        },
      };
      saveBuildDetailsBeforeUpload.append("appBuildRequest", JSON.stringify(appBuildRequest));

      return async("appBuild/upload", "POST_WITH_FILE", saveBuildDetailsBeforeUpload).then((response) => {
        if (response.status === SUCCESS_STATUS) {
          dispatch({ type: UPLOAD_BUILD_REQUEST_COMPLETE });
          // dispatch(uploadingEvent(buildData?.buildId || 0));
          dispatch(changeAppManagerTab(ALL_BUILDS));
          dispatch(editAppBuild(false));
          if(createBuild){
            dispatch(visibleCreateBuildModal(false));
            dispatch(getBuildVersions(buildData?.buildType,setBuildVersionList));       
          }
          if (saveBuild) {
            dispatch(pushBuild([deviceId], allAppBuildDetails?.data[0]?.id, "", setAppBuildData));
          }
        } else {
          message.error(response.message);
          dispatch({ type: UPLOAD_BUILD_REQUEST_FAILED });
          dispatch(changeAppManagerTab(ALL_BUILDS));
        }
      });
    }
  };
}

//update Build details
export const updateBuildDetails = (buildData) => {
  return async (dispatch) => {
    let formattedBuildDate = new Date(buildData?.buildDateAndTime);
    // Ensure that buildDate is properly formatted as ISO string
    formattedBuildDate = formattedBuildDate.toISOString();

    const payload = {
      uuid: buildData?.buildId,
      displayName: buildData?.displayName,
      buildTags: buildData?.tag,
      buildType: buildData?.buildType,
      buildVersion: buildData?.buildVersion,
      buildDate: formattedBuildDate,
      appActivity: buildData?.appActivity,
      enableX: buildData?.enableX,
      enableSL: buildData?.enableSL,
      enableLT: buildData?.enableLT,
    };
    dispatch({ type: UPLOAD_BUILD_REQUEST });
    return async("appBuild/update", "POST", payload).then((response) => {
      if (response.status === SUCCESS_STATUS) {
        dispatch({ type: UPLOAD_BUILD_REQUEST_COMPLETE });
        // dispatch(uploadingEvent(buildData?.buildId || 0));
        dispatch(changeAppManagerTab(ALL_BUILDS));
        dispatch(editAppBuild(false));
      } else {
        message.error(response.message);
        dispatch({ type: UPLOAD_BUILD_REQUEST_FAILED });
        dispatch(changeAppManagerTab(ALL_BUILDS));
      }
    });
  };
};

export function backToAppManagement() {
  return (dispatch) => {
    return dispatch({ type: BACK_TO_APP_MANAGEMENT });
  };
}
export function setAddAppBuildError(field, errorStatus) {
  return (dispatch) => {
    return dispatch({
      type: SET_ADD_APP_BUILD_DETAILS_FORM_ERROR,
      field,
      errorStatus,
    });
  };
}

export function getAllBuildDetailsForFormAndForUserWithPlatform(farmId, platformType, reset) {
  let self = this;
  return async (dispatch, getState) => {
    var searchText = reset ? "" : getState().RecordingReducer.searchQueryTextInputInstallPage;
    var pageNumber = reset ? 1 : getState().RecordingReducer.installAppCurrentSelectedPage;
    var recordsPerPage = INSTALL_PAGE_APP_BUILD_RECORD_PER_PAGE;
    dispatch({ type: ALL_APP_BUILD_DETAILS_FOR_FARM_AND_DEVICE_REQUEST });
    return async("buildDetails/getAllBuildDetailsForFormAndForUserWithPlatform", "GET", {
      searchText: searchText,
      farmId: farmId,
      platformType: platformType,
      pageNumber: pageNumber,
      recordsPerPage: recordsPerPage,
    }).then((response) => {
      if (response.status === SUCCESS_STATUS) {
        dispatch({
          type: ALL_APP_BUILD_DETAILS_FOR_FARM_AND_DEVICE,
          response,
          pageNumber,
        });
        return true;
      } else {
        dispatch({
          type: ALL_APP_BUILD_DETAILS_FOR_FARM_AND_DEVICE_FAILED,
          response,
        });
        notification["error"]({
          message: "App Manager",
          description: "Failed to fetch the app builds.",
        });
      }
    });
  };
}
export function deleteAppBuild(selectedBuild, setSelectAllBuild, rights, searchKeyword, selectedBuildType) {
  return async (dispatch, getState) => {
    return async("appBuild/delete", "POST", selectedBuild).then((response) => {
      if (response.status === SUCCESS_STATUS) {
        setSelectAllBuild([]);
        dispatch(getAllBuildDetails(false, searchKeyword, "", "", rights, "", "", "", selectedBuildType));
        return true;
      } else {
        message.error(response.message);
        dispatch({ type: SHOW_MESSAGE, response });
        return false;
      }
    });
  };
}

export function saveBuildDetailsRights(permission, data, searchKeyword, projectId, filter, rights, selectBuildType) {
  return async (dispatch) => {
    return async(`appBuild/${data?.id}/visibility/${permission === PRIVATE ? true : false}`, "POST").then(
      (response) => {
        response = updateResponse(response);
        if (response.status === SUCCESS_STATUS) {
          util.showNotification(SUCCESS_TYPE, response.message);
          dispatch(
            getAllBuildDetails(false, searchKeyword, projectId, filter, rights?.id, "", "", "", selectBuildType)
          );
        } else {
          util.showNotification(ERROR_TYPE, response.message);
        }
      }
    );
  };
}

export function saveTestJobRights(data, pageNumber, projectId, searchKeyword, searchByFrequency, rights) {
  return async (dispatch) => {
    return async("testJobRights/save", "POST", data).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        util.showNotification(SUCCESS_TYPE, response.message);
        dispatch(getTestJob(pageNumber, projectId, searchKeyword, searchByFrequency, rights));
      } else {
        util.showNotification(ERROR_TYPE, response.message);
      }
    });
  };
}

export function saveTestPlanRights(data, projectId, rights) {
  return async (dispatch) => {
    return async("testPlanRight/save", "POST", data).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        util.showNotification(SUCCESS_TYPE, response.message);
        dispatch(
          getTestPlanDetailsList({
            pageNumber: 1,
            recordPerPage: RECORD_10_PER_PAGE,
            projectId: projectId,
            rights: rights,
          })
        );
      } else {
        util.showNotification(ERROR_TYPE, response.message);
      }
    });
  };
}

export function handleBuildTypeChangeInAddBuild(value) {
  return async (dispatch) => {
    return dispatch({ type: CHANGE_BUILD_TYPE_IN_ADD_BUILD, value });
  };
}
export function handleBuildVersionInAddBuild(value) {
  return (dispatch) => {
    return dispatch({ type: CHANGE_BUILD_VERSION_IN_ADD_BUILD, value });
  };
}
export function handleBuildDateTimeInAddBuild(value) {
  return (dispatch) => {
    return dispatch({ type: CHANGE_BUILD_DATE_TIME_IN_ADD_BUILD, value });
  };
}
export function handleAppSelectInInstallBuild(value) {
  return (dispatch) => {
    return dispatch({ type: SELECT_THE_BUILD_TO_INSTALL, value });
  };
}

export function installAppInDeviceFromSftp(serverId, targetUniqueId) {
  let self = this;
  return async (dispatch, getState) => {
    dispatch({ type: INSTALL_APP_IN_DEVICE_FROM_SFTP_REQUEST });
    return async(
      "device/downloadAndInstallAppFromSftp",
      "POST",
      {
        serverId: serverId,
        targetUniqueId: targetUniqueId,
        sftpFileUrl: getState().RecordingReducer.selectedAppBuild.fileUrl,
        logedInUser: getState().RecordingReducer.logedInUser,
      },
      getState().RecordingReducer.serverDetail.serverIp
    ).then((response) => {
      if (response.status === SUCCESS_STATUS) {
        dispatch(downloadingEvent(serverId, targetUniqueId));
        return true;
      } else {
        dispatch({ type: INSTALL_APP_IN_DEVICE_FROM_SFTP_REQUEST_FAILED });
        message.error(response.message);
      }
    });
  };
}

export function getSearchResultsForBuildDetails(searchQuery) {
  let self = this;
  return async (dispatch, getState) => {
    dispatch({ type: SEARCH_QUERY_APP_DETAILS_REQUEST });
    return async("buildDetails/getSearchResultsForBuildDetails", "GET", {
      search: getState().RecordingReducer.searchQueryTextInput,
    }).then((response) => {
      if (response.status === SUCCESS_STATUS) {
        dispatch({ type: SEARCH_QUERY_APP_DETAILS_COMPLETED });
        dispatch({ type: SEARCH_QUERY_RESULT_APP_DETAILS, response });
      } else {
        dispatch({ type: SEARCH_QUERY_APP_DETAILS_FAILED });
      }
    });
  };
}

export function handleInstallPageAppDetailsSearchQuery(value) {
  return (dispatch) => {
    return dispatch({
      type: CHANGE_SEARCH_TEXT_FOR_SEARCH_QUERY_INSTALL_APP,
      value,
    });
  };
}
export function showAppManagementBuildModal(deviceFarmId, platfromType) {
  return (dispatch) => {
    dispatch(getAllBuildDetailsForFormAndForUserWithPlatform(deviceFarmId, platfromType));
    dispatch({ type: SHOW_INSTALL_APP_BUILD_MODAL });
  };
}
export function hideAppManagementBuildModal() {
  return (dispatch) => {
    dispatch({ type: HIDE_INSTALL_APP_MODAL });
  };
}

export function cancelBuildUpload(data) {
  return async (dispatch, getState) => {
    return async("buildDetails/cancelUploadBuild", "POST", data).then((response) => {
      if (response.status === SUCCESS_STATUS) {
        dispatch(getAllBuildDetails(false));
      } else {
        message.error(response.message);
      }
    });
  };
}

// TODO: Remove after testing on staging.
// export function uploadingEvent(id) {
//   if (!moniterBuildUpload == undefined) {
//     moniterBuildUpload.close();
//   }
//   return async (dispatch, getState) => {
//     moniterBuildUpload = prepareEventSource("buildDetails/moniterTheBuildUpload", { id: id }, "");
//     moniterBuildUpload.onmessage = (event: any) => {
//       let data = event.data;
//       let eventParseResponse = JSON.parse(data);
//       if (eventParseResponse["status"] === APP_BUILD_UPLOAD_STATUS.EMPTY) {
//         dispatch(getAllBuildDetails(false));
//         moniterBuildUpload.close();
//       } else if (eventParseResponse["status"] === APP_BUILD_UPLOAD_STATUS.DONE) {
//         dispatch(getAllBuildDetails(false));
//         moniterBuildUpload.close();
//       } else if (eventParseResponse["status"] === APP_BUILD_UPLOAD_STATUS.IN_PROGRESS) {
//         var percentage = eventParseResponse["data"];
//         dispatch({ type: UPLOAD_BUILD_PROGRESS, percentage });
//       }
//     };
//   };
// }
export function downloadingEvent(serverId, targetUniqueId) {
  return async (dispatch, getState) => {
    moniterBuildDownload = prepareEventSource(
      "device/moniterInstallAppBuild",
      {
        targetUniqueId: targetUniqueId,
      },
      getState().RecordingReducer.serverDetail.serverIp
    );
    moniterBuildDownload.onmessage = (event: any) => {
      let data = event.data;
      let eventParseResponse = JSON.parse(data);
      if (eventParseResponse["status"] == APP_BUILD_DOWNLOAD_STATUS.DONE) {
        moniterBuildDownload.close();
        message.success("Successfully Installed App on Device");
        dispatch({ type: INSTALLED_APP_IN_DEVICE_FROM_SFTP });
      } else if (eventParseResponse["status"] == APP_BUILD_DOWNLOAD_STATUS.IN_PROGRESS) {
        var percentage = eventParseResponse["data"];
        dispatch({ type: DOWNLOAD_BUILD_PROGRESS, percentage });
      } else if (eventParseResponse["status"] == APP_BUILD_DOWNLOAD_STATUS.FAILED) {
        dispatch({ type: INSTALL_APP_IN_DEVICE_FROM_SFTP_REQUEST_FAILED });
        message.error("Failed To Install App on Device");
      }
    };
  };
}

var debug = false;
function setdebug() {
  debug = !debug;
  sendAndroidServiceEvent({ event: "debug", val: debug });
}
export function sendAndroidServiceEvent(obj) {
  return async (dispatch, getState) => {
    if (client.readyState === client.OPEN) {
      var json = JSON.stringify(obj);
      client.send(json);
    } else {
      console.log("websocket is closed");
      // window.location.reload();
    }
  };
}

export function startLogsForDeviceSession(serverId, targetUniqueId) {
  return async (dispatch, getState) => {
    dispatch({ type: DEVICE_SESSION_LOGS_STREAM_REQUEST });
    return async(
      "device/startDeviceLogsForSession",
      "POST",
      {
        targetUniqueId: targetUniqueId,
        logType: getState().RecordingReducer.deviceSessionLogType,
        appPackage: getState().RecordingReducer.selectedAppForDeviceLogs,
      },
      getState().RecordingReducer.serverDetail.serverIp
    ).then((response) => {
      if (response.status == SUCCESS_STATUS) {
        dispatch({ type: DEVICE_SESSION_LOGS_STREAM_STARTED });
        dispatch(streamLogsForDevice(serverId, targetUniqueId));
      } else {
        dispatch({ type: DEVICE_SESSION_LOGS_STREAM_FAILED });
      }
    });
  };
}

export function streamLogsForDevice(serverId, targetUniqueId) {
  if (streamLogsForDeviceSession) {
    streamLogsForDeviceSession.close();
  }
  return async (dispatch, getState) => {
    streamLogsForDeviceSession = prepareEventSource(
      "device/streamTheDeviceLogs",
      { targetUniqueId: targetUniqueId },
      getState().RecordingReducer.serverDetail.serverIp
    );
    streamLogsForDeviceSession.onmessage = (event: any) => {
      let eventParseResponse = JSON.parse(event.data);
      if (eventParseResponse.status === SUCCESS_STATUS) {
        if (eventParseResponse) {
          dispatch({ type: DEVICE_SESSION_LOGS_STREAM, eventParseResponse });
          return true;
        } else {
          message.error("Error In Starting The Logs");
          return false;
        }
      } else {
        streamLogsForDeviceSession.close();
        dispatch({ type: DEVICE_SESSION_LOGS_STREAM_STOPPED });
        message.error("Error In Starting The Logs");
        return false;
      }
    };
  };
}

export function stopLogsForDeviceSession(serverId, targetUniqueId) {
  return async (dispatch, getState) => {
    dispatch({ type: DEVICE_SESSION_LOGS_STREAM_STOPPING_REQUEST });
    return async(
      "device/stopDeviceLogsForSession",
      "POST",
      { targetUniqueId: targetUniqueId },
      getState().RecordingReducer.serverDetail.serverIp
    ).then((response) => {
      if (response.status == SUCCESS_STATUS) {
        if (streamLogsForDeviceSession) {
          streamLogsForDeviceSession.close();
        }
        dispatch({ type: DEVICE_SESSION_LOGS_STREAM_STOPPED });
        return true;
      } else {
        dispatch({ type: DEVICE_SESSION_LOGS_STREAM_STOPPING_FAILED });
        return false;
      }
    });
  };
}

export function changeSelectedLogTypeForDeviceSession(value) {
  return (dispatch) => {
    dispatch({ type: CHANGE_DEVICE_SESSION_LOGTYPE, value });
  };
}
export function clearDeviceSessionLogs() {
  return (dispatch) => {
    dispatch({ type: CLEAR_THE_DEVICE_SESSION_LOGS });
  };
}

export function changeSelectedAppForDeviceLogs(value) {
  return (dispatch) => {
    dispatch({ type: CHANGE_APP_FOR_DEVICE_LOGS, value });
  };
}

export function downloadDeviceLogs(data, fileName) {
  let self = this;
  // return async (dispatch,getState) => {
  //   return async('device/downloadLogsFile', 'GET_FOR_FILE',{targetUniqueId:targetUniqueId,serverId:serverId,logedInUser:getState().RecordingReducer.logedInUser},serverId==="Local",getState().RecordingReducer.serverDetail.serverIp)
  //   .then(response => {
  //     dispatch({type:STOP_LOGS_ON_DEVICE});
  //     response.blob().then(blob => {
  // 			let url = window.URL.createObjectURL(blob);
  // 			let a = document.createElement('a');
  // 			a.href = url;
  // 			a.download = targetUniqueId+'.txt';
  // 			a.click();
  // 		});
  //   });
  // };
  return async (dispatch, getState) => {
    var deviceLog = [];
    let filter = getState().RecordingReducer.deviceSessionLogType;
    if (filter !== DEVICE_SESSION_LOGTYPE.VERBOSE) {
      data.map((v, k) => {
        return (
          (v.includes(" " + filter.charAt(0) + " ") || (v.charAt(0) == filter.charAt(0) && v.charAt(1) == "/")) &&
          deviceLog.push(v + " \r\n")
        );
      });
    } else {
      data.map((v, k) => {
        return deviceLog.push(v + " \r\n");
      });
    }
    const element = document.createElement("a");
    const file = new Blob(deviceLog, { type: "text/plain", endings: "native" });
    element.href = URL.createObjectURL(file);
    element.download = fileName + ".txt";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };
}

export function flushTheDashBoardAttribute() {
  return (dispatch) => {
    dispatch({ type: FLUSH_THE_DASHBOARD_ATTRIBUTE });
  };
}

export function resumeTheDeviceLogsAfterRefresh(serverId, targetUniqueId) {
  return (dispatch) => {
    dispatch(streamLogsForDevice(serverId, targetUniqueId));
    dispatch({ type: DEVICE_SESSION_LOGS_STREAM_STARTED });
  };
}

export function getClipboardData(targetUniqueId, serverId) {
  return (dispatch, getState) => {
    if (getState().RecordingReducer.deviceDetail.platformName === "ANDROID") {
      dispatch(sendAndroidServiceEvent({ event: "getclip" }));
    }
    // else if(getState().RecordingReducer.deviceDetail.platformName==="ios"){
    //   return async('device/getClipboardData', 'GET',{targetUniqueId:targetUniqueId,serverId:serverId},serverId==="Local",getState().RecordingReducer.serverDetail.serverIp)
    //   .then(response => {
    //     dispatch({type:"UPDATE_CLIPBOARD_DATA",value:response.data});
    //   });
    // }
  };
}
export function changeUrlForDeviceBrowser(value) {
  return (dispatch) => {
    dispatch({ type: CHANGE_URL_FOR_DEVICE_BROWSER_NAVIGATION, value });
  };
}
export function getAllInstalledBrowsers(targetUniqueId, serverId) {
  return async (dispatch, getState) => {
    async(
      "device/getAllInstalledBrowser",
      "GET",
      { targetUniqueId: targetUniqueId, serverId: serverId },
      getState().RecordingReducer.serverDetail.serverIp
    ).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        dispatch({ type: ALL_BROWSER_INSTALLED_IN_DEVICE, response });
      } else {
        dispatch({ type: ERROR_ALL_BROWSER_INSTALLED_IN_DEVICE, response });
      }
    });
  };
}
export function launchBrowserWithLink(targetUniqueId, serverId, appPackageForLaunchApp) {
  return async (dispatch, getState) => {
    async(
      "device/launchBrowserWithLink",
      "POST",
      {
        targetUniqueId: targetUniqueId,
        serverId: serverId,
        urlNeedToBrowse: getState().RecordingReducer.urlNeedToBrowse,
        appPackage: appPackageForLaunchApp,
      },
      getState().RecordingReducer.serverDetail.serverIp
    ).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        message.success(response.message);
      } else {
        dispatch(getAllInstalledBrowsers(targetUniqueId, serverId));
        message.error(response.message);
      }
    });
  };
}

export function startProxyWebSocket(data) {
  return async (dispatch, getState) => {
    let serverIpAddress = getState().RecordingReducer.serverDetail.serverIpAddress;
    serverIpAddress.split("://");
    let websocketProtocol = "ws";
    if (serverIpAddress.split("://").length > 1) {
      serverIpAddress = serverIpAddress.split("://")[1];
      websocketProtocol = serverIpAddress.split("://")[0] == "https" ? "wss://" : "ws://";
    }
    let proxyWebPort = data.proxyWebPort;

    try {
      _socket = new WebSocket(websocketProtocol + serverIpAddress + proxyWebPort + "/updates");

      _socket.addEventListener("open", function (t) {
        dispatch({ type: PROXY_STARTED });
      });

      _socket.addEventListener("close", function (t) {});

      _socket.addEventListener("message", function (data) {
        let flowData = JSON.parse(data.data);
        if (flowData && flowData["resource"] == "flows") {
          dispatch({ type: GET_PROXY_STREAMING_DATA, flow: flowData });
        }
      });

      _socket.addEventListener("error", function (t) {});
    } catch (e) {
      console.log(e);
    }
  };
}

export function startProxy(data) {
  let self = this;
  return async (dispatch, getState) => {
    dispatch({ type: PROXY_START_REQUEST });
    return async(
      "device/startProxy",
      "POST",
      { targetUniqueId: data.targetUniqueId },
      getState().RecordingReducer.serverDetail.serverIp
    ).then((response) => {
      if (response.status === SUCCESS_STATUS) {
        if (data.platformName === "A") {
          //  dispatch(restartDevice(data, true));
          dispatch(startProxyWebSocket(data));
          dispatch({ type: PROXY_STARTED });
        }
      } else {
        dispatch({ type: SHOW_MESSAGE, response });
      }
    });
  };
}

export function stopProxy(data) {
  let self = this;
  return async (dispatch, getState) => {
    dispatch({ type: PROXY_STOP_REQUEST });
    if (_socket) {
      _socket.close();
      _socket = undefined;
    }
    return async(
      "device/stopProxy",
      "POST",
      { targetUniqueId: data.targetUniqueId },
      getState().RecordingReducer.serverDetail.serverIp
    ).then((response) => {
      if (response.status === SUCCESS_STATUS) {
        dispatch({ type: PROXY_STOPPED });
      } else {
        dispatch({ type: SHOW_MESSAGE, response });
      }
    });
  };
}
export function clearProxy() {
  return async (dispatch, getState) => {
    dispatch({ type: PROXY_CLEAR_DATA });
  };
}

export function startQuickLaunchApp(targetUniqueId, serverId, appName) {
  return async (dispatch, getState) => {
    async(
      "device/startQuickLaunchApp",
      "POST",
      { targetUniqueId: targetUniqueId, serverId: serverId, appName: appName },
      getState().RecordingReducer.serverDetail.serverIp
    ).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        // message.success(response.message)
      } else {
        message.error(response.message);
      }
    });
  };
}

export function splitterDragged() {
  var splitterSize =
    document.getElementsByClassName("layout-splitter").length > 0 &&
    document.getElementsByClassName("layout-splitter")[0].offsetLeft;
  if (splitterSize) {
    return (dispatch) => {
      dispatch({ type: SET_SPLITTER_DRAGGED, splitterSize });
    };
  }
}

export function disableKeyboard() {
  return (dispatch) => {
    dispatch({ type: DISABLE_KEYBOARD_IN_DEVICE });
  };
}
export function removeFocusFromInputText() {
  return (dispatch) => {
    if (document.getElementById("inputKeyboardTextBox")) {
      document.getElementById("inputKeyboardTextBox").blur();
    }
    const inputList = document.querySelectorAll("input, textarea");
    if (inputList.length !== 0) {
      for (let i = 0; i < inputList.length; i++) {
        inputList[i].blur();
      }
    }
  };
}

export function getDeviceUsageReport(keyword, pageNumber, recordsPerPage, startDate, endDate) {
  return async (dispatch, getState) => {
    dispatch({ type: DEVICE_USAGE_REPORT_DATA_REQUEST });
    async("xpressUserDeviceUsage/getDeviceUsageReportForAllFarms", "GET", {
      searchKeyword: keyword,
      pageNumber,
      recordsPerPage,
      startDate: moment(startDate).utc().format("YYYY-MM-DD HH:mm:ss"),
      endDate: moment(endDate).utc().format("YYYY-MM-DD HH:mm:ss"),
    }).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        dispatch({ type: DEVICE_USAGE_REPORT_DATA, response });
      } else {
        dispatch({ type: DEVICE_USAGE_REPORT_DATA_REQUEST_FAILED });
        message.error(response.message);
      }
    });
  };
}

export function fetchAppBuildData(appBuildDataByID) {
  return (dispatch) => {
    dispatch({ type: APP_BUILD_DETAILS_BY_ID, appBuildDataByID });
  };
}

export function showInstallBuildOnDevicesModal(projectId, buildData) {
  return (dispatch) => {
    new Promise((resolve) => resolve(dispatch(getDeviceFarmList({ projectId: projectId })))).then((response) => {
      dispatch({ type: SHOW_INSTALL_BUILD_ON_DEVICE, buildData });
    });
  };
}

export function hideInstallBuildOnDevicesModal(appBuildDataByID) {
  return (dispatch) => {
    dispatch({ type: HIDE_INSTALL_BUILD_ON_DEVICE, appBuildDataByID });
  };
}
export function installBuildOnMultipleDevice(deviceList) {
  console.log("API");
  return (dispatch, getState) => {
    dispatch({ type: REQUEST_INSTALL_BUILD_ON_DEVICE });
    const { installBuildDeviceDetails, selectedBuildDataToInstall } = getState().RecordingReducer;
    dispatch({
      type: SERVER_DEVICES,
      data: deviceList.filter((item) => item.miscDetails?.targetUniqueId === installBuildDeviceDetails),
      devices: deviceList,
      response: undefined,
    });
    const { selectedServerDevices } = getState().ServerListReducer;
    let selectedDeviceDetail = selectedServerDevices?.filter((item) =>
      installBuildDeviceDetails?.includes(item.device.targetUniqueId)
    );
    let groupDevicesByServer = _(selectedDeviceDetail)
      .groupBy((item) => item.serverDetail.serverIp)
      .value();
    let pushedCountOnServer = 0;
    Object.keys(groupDevicesByServer).map((key) => {
      let deviceIds = [];
      groupDevicesByServer[key].map((v, k) => deviceIds.push(v.device.targetUniqueId));
      async(
        "device/V2/pushBuild",
        "POST",
        {
          targetUniqueIds: deviceIds,
          appBuildId: selectedBuildDataToInstall?.id,
          logedInUser: getState().RecordingReducer.logedInUser,
          isRemoveExisting: getState().RecordingReducer.unInstallBUildBeforeInstall,
        },
        key
      ).then((response) => {
        response = updateResponse(response);
        if (response?.status === SUCCESS_STATUS) {
          pushedCountOnServer++;
          if (pushedCountOnServer === Object.keys(groupDevicesByServer).length) {
            util.showNotification("success", response.message, "");
          }
        } else {
          util.showNotification("error", response.message, "");
        }
        dispatch({ type: REQUESTED_INSTALL_BUILD_ON_DEVICE });
      });
    });
  };
}

export function changeDeviceSelectionForBuildInstallation(devices) {
  return (dispatch) => {
    dispatch({ type: CHANGE_DEVICE_SELECTION_FOR_BUILD_INSTALL, devices });
  };
}

export function resetAppBuildData() {
  return (dispatch) => {
    dispatch({ type: RESET_APP_BUILD_DETAILS_BY_ID });
  };
}

export function changeAppBUildListPageNumber(value) {
  return (dispatch) => {
    dispatch({ type: CHANGE_APP_BUILD_LIST_PAGE_NUMBER, value });
  };
}
export function changeInstallPageAppBUildListPageNumber(value) {
  return (dispatch) => {
    dispatch({ type: CHANGE_INSTALL_PAGE_APP_BUILD_LIST_PAGE_NUMBER, value });
  };
}

export function setSelectedGroup(data) {
  return (dispatch) => {
    dispatch({ type: SET_SELECTED_GROUP, data });
  };
}

export function setSelectedPreviewGroup(data) {
  return (dispatch) => {
    dispatch({ type: SET_SELECTED_PREVIEW_GROUP, data });
  };
}

export function setCurrentGroupOnBreadCumbClick(data, index) {
  return (dispatch) => {
    dispatch({
      type: SET_CURRENT_GROUP_ON_BREAD_CRUMB_CLICK,
      data: { data: data, index: index },
    });
  };
}

export function setCurrentPreviewGroupOnBreadCumbClick(data, index) {
  return (dispatch) => {
    dispatch({
      type: SET_CURRENT_PREVIEW_GROUP_ON_BREAD_CRUMB_CLICK,
      data: { data: data, index: index },
    });
  };
}

export function loadRecordingScreenStatus(value, recTestScenarioId, runId, serverId, targetUniqueId) {
  return (dispatch) => {
    let recordingScreenStatus = RECORDING_SCREEN.SHOW_SCENARIO_LIST;
    async("recTestScenario/getScenarioById", "GET", { scenarioId: recTestScenarioId }).then((response) => {
      if (value == "recording") {
        recordingScreenStatus = RECORDING_SCREEN.SHOW_RECORDING;
        dispatch(
          getRecTestStepListByRecTestScenarioId(
            recTestScenarioId,
            response.data ? response.data.name : undefined,
            runId,
            serverId,
            targetUniqueId
          )
        );
      } else if (value == "execute") {
        recordingScreenStatus = RECORDING_SCREEN.SHOW_EXECUTE_SCENARIO;
        dispatch(getScenarioByRecIdForExecution(recTestScenarioId, response.data ? response.data.name : undefined));
      }
      dispatch({ type: LOAD_RECORDING_SCREEN_STATUS, recordingScreenStatus });
    });
  };
}
export function checkIsRecordingOn(serverId, targetUniqueId, platform) {
  return (dispatch, getState) => {
    let recordingScreenStatus = RECORDING_SCREEN.SHOW_SCENARIO_LIST;
    async(
      "device/checkIsRecordingOn",
      "GET",
      { targetUniqueId },
      getState().RecordingReducer.serverDetail.serverIp
    ).then((response) => {
      if (response.status === SUCCESS_STATUS) {
        if (response.data && response.data.isRecordingOn === "true") {
          dispatch(recordingStart(serverId, targetUniqueId, platform));
          dispatch({
            type: LOAD_RECORDING_SCREEN_STATUS,
            recordingScreenStatus: RECORDING_SCREEN.SHOW_RECORDING,
          });
        }
      }
    });
  };
}

export function backToPreviousStep(childId) {
  return (dispatch) => {
    dispatch({ type: BACK_PREVIOUS_STEP, childId });
  };
}

export function backToPreviousGroupStep(childId) {
  return (dispatch) => {
    dispatch({ type: BACK_PREVIOUS_PREVIEW_STEP, childId });
  };
}
export function changeShellCommandForDevice(value) {
  return (dispatch) => {
    dispatch({ type: CHANGE_SHELL_COMMAND_FOR_DEVICE, value });
  };
}
export function sendShellCommandForExecution(serverId, targetUniqueId) {
  return (dispatch, getState) => {
    dispatch({ type: SAVE_SHELL_COMMAND_OUTPUT, data: [] });
    // dispatch(sendAndroidServiceEvent({ event: 'adbshellcmdexec', val: getState().RecordingReducer.shellCommandForDevice}))
    async(
      "device/executeShellCmdInDeviceShell",
      "GET",
      {
        serverId: serverId,
        targetUniqueId: targetUniqueId,
        adbCommand: getState().RecordingReducer.shellCommandForDevice,
      },
      getState().RecordingReducer.serverDetail.serverIp
    ).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        dispatch({ type: SAVE_SHELL_COMMAND_OUTPUT, data: response.data });
      } else {
        message.error(response.message);
      }
    });
  };
}
export function resetShellResultOutput() {
  return (dispatch, getState) => {
    dispatch({ type: RESET_SHELL_COMMAND_OUTPUT });
  };
}

export function updateCloseLaunchAppPreviousSession(value) {
  return (dispatch, getState) => {
    dispatch({ type: UPDATE_CLOSE_LAUNCH_APP_PREVIEW_SESSION });
  };
}
export function changeDeviceInteractionMode(value) {
  return (dispatch, getState) => {
    dispatch({ type: CHANGE_DEVICE_INTERACTION_MODE, value });
  };
}

export function changeDeviceTabActionKey(deviceTabActionKey, projectId) {
  if (deviceTabActionKey !== REPORT_MANAGER) {
    closeConnectionsOfResult();
  }
  return (dispatch, getState) => {
    if (deviceTabActionKey === "FARM_MANAGEMENT" || "RECORDING") {
      dispatch(getDeviceFarmList({ projectId: projectId }));
    }
    if (deviceTabActionKey === TEST_SCENARIOS) {
      dispatch(getRecTestScenarioList("", 1, projectId, "", "", ""));
    }
    if (deviceTabActionKey === TEST_PLAN) {
      dispatch(
        getTestPlanDetailsList({
          pageNumber: getState().TestPlanReducer.currentTestPlanPage,
          recordPerPage: 15,
          projectId: projectId,
        })
      );
    }
    if (deviceTabActionKey === REPORT_MANAGER) {
      dispatch(getTestPlanAndScenarioResultList(projectId));
    }
    if (deviceTabActionKey === TEST_STEP_BLOCKS) {
      dispatch(getListOfBlocksByProjectId(projectId, 1, ""));
    }

    dispatch({ type: CHANGE_DEVICE_TAB_ACTION_KEY, deviceTabActionKey });
  };
}

export function toggleOtherElementPopover(visible) {
  return (dispatch, getState) => {
    dispatch({ type: TOGGLE_OTHER_ELEMENT_POPOVER, visible });
  };
}

export function changeAppManagerTab(data) {
  return (dispatch, getState) => {
    dispatch({ type: CHANGE_APP_MANAGER_TAB, data });
  };
}

export function setSelectedAppBuildDetails(data) {
  return (dispatch, getState) => {
    dispatch({ type: SET_SELECTED_APP_BUILD, data });
  };
}

export function setUninstallBuildBeforeInstall(value) {
  return (dispatch, getState) => {
    dispatch({ type: UNINSTALL_BUILD_BEFORE_INSTALL, value });
  };
}

export function pushAnUploadBuild(appBuildData) {
  return (dispatch, getState) => {
    const { selectedDeviceByUdid } = getState().RecTestStepReducer;
    var saveBuildDetailsBeforeUpload = new FormData();
    saveBuildDetailsBeforeUpload.append(
      "appFile",
      (util.checkNotUndefined(appBuildData?.appFile) && appBuildData?.appFile) || null
    );
    saveBuildDetailsBeforeUpload.append("targetUniqueId", selectedDeviceByUdid?.targetUniqueId);
    saveBuildDetailsBeforeUpload.append("serverId", selectedDeviceByUdid?.autoServerDetail?.id);
    saveBuildDetailsBeforeUpload.append("launchApp", true);
    dispatch({ type: PUSH_BUILD_REQUEST });
    async("device/installAndLaunchApp", "POST_WITH_FILE", saveBuildDetailsBeforeUpload).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        dispatch({ type: PUSH_BUILD, response });
        message.success(response.message);
      } else {
        dispatch({ type: SHOW_MESSAGE, response });
        message.error(response.message);
      }
    });
  };
}

export function visibleRecordingSettingsModal(visible) {
  return (dispatch) => {
    dispatch({ type: VISIBLE_RECORDING_SETTINGS_MODAL, visible });
  };
}

export function editAppBuild(value) {
  return (dispatch) => {
    dispatch({ type: EDIT_APP_BUILD, value });
  };
}

export const pushUploadBuild = (id) => {
  return (dispatch) => {
    dispatch({ type: PUSH_UPLOAD_BUILD_ID, id });
  };
};
