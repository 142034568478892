import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox, Radio, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DEVICE_EXECUTION_FARM, PARALLEL_OS, PARALLEL_SPEED, SERIAL } from "../../../../../Constants";
import { showNotification, validateEmail } from "../../../../../Util";
import { changeTestJobData, getTestRailSuitesByTestPlanId } from "../../../../../actions/TestJobActions";
import styles from "./JobCriteriaOptionsV2.module.scss";

const { Option } = Select;

const ExecutionSettingsTabV2 = (props) => {
  const dispatch = useDispatch();
  const { projectId } = props;
  const { getTestRailSuitesByTestPlanID, testJobDetail } = useSelector((state) => state.TestJobReducer);
  const { getTestRailConfigData } = useSelector((state) => state.ProjectSettingsReducer);

  const [selectedTestSuiteId, setSelectedTestSuiteId] = useState([]);

  useEffect(() => {
    if (testJobDetail?.testRailTestSuiteIds) {
      let iD =
        getTestRailSuitesByTestPlanID &&
        getTestRailSuitesByTestPlanID
          ?.filter((i) => testJobDetail?.testRailTestSuiteIds?.includes(i?.id) && i)
          ?.map((i) => i?.id.toString());

      setSelectedTestSuiteId(iD);
    }
  }, [getTestRailSuitesByTestPlanID]);

  useEffect(() => {
    if (testJobDetail && testJobDetail?.testPlan?.id) {
      dispatch(getTestRailSuitesByTestPlanId(projectId, testJobDetail?.testPlan?.id));
    }
  }, [testJobDetail?.testPlan]);

  let testRailSuites = [];
  if (getTestRailSuitesByTestPlanID) {
    for (let i = 0; i < getTestRailSuitesByTestPlanID.length; i++) {
      testRailSuites.push(
        <Option key={getTestRailSuitesByTestPlanID[i].id} value={getTestRailSuitesByTestPlanID[i].id.toString()}>
          {getTestRailSuitesByTestPlanID[i].name}
        </Option>
      );
    }
  }

  const addTags = (event) => {
    if (event.key === "Enter" && event.target.value !== "") {
      if (validateEmail(event.target.value)) {
        dispatch(changeTestJobData({ ...testJobDetail, email: [...testJobDetail.email, event.target.value] }));
      } else {
        showNotification("error", "Please Enter Valid Email Id");
      }
      event.target.value = "";
    }
  };

  const removeTags = (index) => {
    dispatch(
      changeTestJobData({
        ...testJobDetail,
        email: [...testJobDetail.email.filter((email) => testJobDetail.email.indexOf(email) !== index)],
      })
    );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(changeTestJobData({ ...testJobDetail, [name]: value }));
  };

  const handleSelect = (e) => {
    const { name } = e.target;
    if (e.target.checked) {
      dispatch(changeTestJobData({ ...testJobDetail, [name]: 1 }));
    } else {
      dispatch(changeTestJobData({ ...testJobDetail, [name]: 0, testRailTestSuiteIds: [] }));
    }
  };

  const deviceFarmSLAndLT =
    testJobDetail.deviceServiceFarm === DEVICE_EXECUTION_FARM.SAUCELABS ||
    testJobDetail.deviceServiceFarm === DEVICE_EXECUTION_FARM.LAMBDA_TEST;

  return (
    <>
      <div className={styles["execution_Settings_Text"]}>Execution Settings</div>
      <div className={styles["label"]}>Execution Mode </div>
      <div className={styles["radio_Box_Div"]}>
        <input
          className={styles["radio_Input_Box"]}
          type="radio"
          defaultChecked={testJobDetail.executionType === SERIAL}
          onChange={handleChange}
          name="executionType"
          value={SERIAL}
          disabled={testJobDetail.devices.length > 1}
        />
        <span className={styles["radio_Or_Checkbox__Text"]}>
          Serial (Test Plan execution on one device at a time. Best to keep other devices free for other tests)
        </span>
      </div>
      <div className={styles["radio_Box_Div"]}>
        <input
          className={styles["radio_Input_Box"]}
          type="radio"
          defaultChecked={testJobDetail?.executionType === PARALLEL_SPEED && testJobDetail?.devices?.length > 1}
          onChange={handleChange}
          name="executionType"
          disabled={testJobDetail.devices.length === 1}
          value={PARALLEL_SPEED}
        />
        <span className={styles["radio_Or_Checkbox__Text"]}>
          Parallel Distribution (Split Test Plan execution across all devices. Best for faster test completion)
        </span>
      </div>
      <div className={styles["radio_Box_Div"]}>
        <input
          className={styles["radio_Input_Box"]}
          type="radio"
          defaultChecked={testJobDetail?.executionType === PARALLEL_OS && testJobDetail?.devices?.length > 1}
          onChange={handleChange}
          name="executionType"
          value={PARALLEL_OS}
          disabled={testJobDetail.devices.length === 1}
        />
        <span className={styles["radio_Or_Checkbox__Text"]}>
          Parallel Repetition (Repeat Test Plan execution across all devices. Best for more devices or local coverage)
        </span>
      </div>
      <div className={styles["label"]}>Other Settings</div>
      <div className="mt-5">
        <Checkbox
          defaultChecked={deviceFarmSLAndLT || testJobDetail.isLogs}
          onChange={handleSelect}
          name="isLogs"
          className="selection-checkBox"
          disabled={deviceFarmSLAndLT}
        >
          <span className={styles["radio_Or_Checkbox__Text"]}>Capture Logs</span>
        </Checkbox>
      </div>
      <div className="mt-5">
        <Checkbox
          defaultChecked={deviceFarmSLAndLT || testJobDetail.isCaptureVideo}
          onChange={handleSelect}
          name="isCaptureVideo"
          className="selection-checkBox"
          disabled={deviceFarmSLAndLT}
        >
          <span className={styles["radio_Or_Checkbox__Text"]}>Capture Videos</span>
        </Checkbox>
      </div>
      <div className="mt-5">
        <Checkbox
          defaultChecked={testJobDetail.accessibilityReport}
          onChange={handleSelect}
          name="accessibilityReport"
          className="selection-checkBox"
        >
          <span className={styles["radio_Or_Checkbox__Text"]}>Accessibility Report </span>
        </Checkbox>
      </div>
      {testJobDetail?.accessibilityReport !== undefined && testJobDetail?.accessibilityReport !== 0 && (
        <div className={styles["accessibility_And_Local_Report"]}>
          <Radio.Group
            onChange={(e) => dispatch(changeTestJobData({ ...testJobDetail, accessibilityReport: e.target.value }))}
            value={testJobDetail?.accessibilityReport}
          >
            <div>
              <Radio value={1}>
                <span className={styles["radio_Or_Checkbox__Text"]}>Run only when functional testing pass</span>
              </Radio>
            </div>
            <div>
              <Radio value={2}>
                <span className={styles["radio_Or_Checkbox__Text"]}>Always run</span>
              </Radio>
            </div>
          </Radio.Group>
        </div>
      )}
      <div className="mt-5">
        <Checkbox
          defaultChecked={testJobDetail.localeReport}
          onChange={handleSelect}
          name="localeReport"
          className="selection-checkBox"
        >
          <span className={styles["radio_Or_Checkbox__Text"]}> Localization Report </span>
        </Checkbox>
      </div>
      {testJobDetail?.localeReport !== undefined && testJobDetail?.localeReport !== 0 && (
        <div className={styles["accessibility_And_Local_Report"]}>
          <Radio.Group
            onChange={(e) => dispatch(changeTestJobData({ ...testJobDetail, localeReport: e.target.value }))}
            value={testJobDetail?.localeReport}
          >
            <div>
              <Radio value={1}>
                <span className={styles["radio_Or_Checkbox__Text"]}>Run only when functional testing pass</span>
              </Radio>
            </div>
            <div>
              <Radio value={2}>
                <span className={styles["radio_Or_Checkbox__Text"]}>Always run</span>
              </Radio>
            </div>
          </Radio.Group>
        </div>
      )}
      
      {/* <div className={styles["label"]} T>
        Email Run Results Report to:
      </div>
      <div className="width-50 mt-10">
        <div className="tags">
          {testJobDetail?.email?.map((tag, index) => (
            <li key={index} className="tag">
              <span>{tag}</span>
              <FontAwesomeIcon className="ml-10 cursor-pointer" onClick={() => removeTags(index)} icon={faTimes} />
            </li>
          ))}
        </div>
       <div className={styles["custom-input-wrapper"]}>
          <div className={`${styles["custom-input-label"]} `}>Email Addresses</div>
          <Input
            className={`width-full ${styles["modal-input"]}`}
            type="text"
            onKeyUp={(event) => addTags(event)}
            name="email"
            placeholder="Enter email addresses"
          />
        </div>
        <div className={styles["custom-input-wrapper"]}>
          <div className={`${styles["custom-input-label"]} `}>Notes</div>
          <TextArea
            className={`width-full ${styles["modal-input"]}`}
            rows={3}
            type="text"
            onChange={handleChange}
            placeholder="Enter notes for this Job"
            value={testJobDetail.scheduleNote}
            name="scheduleNote"
          />
        </div>
      </div> */}
    </>
  );
};

export default ExecutionSettingsTabV2;
