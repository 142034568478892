import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeTestJobData } from "../../../../../actions/TestJobActions";
import TestPlanDetailsV2 from "../../../../TestPlanV2/TestPlanDetailsV2/TestPlanDetailsV2";

const TestPlanTab = ({ projectId, testPlan, orgId }) => {
  const { scheduleTestPlan } = useSelector((state) => state.TestPlanReducer);
  const { createBuildJob, testJobDetail } = useSelector((state) => state.TestJobReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    if (scheduleTestPlan) {
      dispatch(
        changeTestJobData({
          ...testJobDetail,
          testPlan: scheduleTestPlan,
          testDataSet: { name: "Default" },
        })
      );
    }
  }, []);
  if (!testJobDetail?.id && !createBuildJob) {
    return null;
  }

  return (
    <TestPlanDetailsV2
      testPlan={testPlan}
      isForModal={true}
      projectId={projectId}
      isProjectModal={true}
      testPlanTab={true}
      testJobDetail={testJobDetail}
      orgId={orgId}
    />
  );
};

export default TestPlanTab;
