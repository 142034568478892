import _ from "lodash";
import {
  ADD_PREVIEW_REQUEST,
  ADD_PREVIEW_REQUESTED,
  ANDROID,
  CHANGE_DEVICE_INTERACTION_MODE,
  CHANGE_REC_TEST_STEP_PRE_CONDITION_PANEL,
  CHANGE_SELECTED_REC_TEST_STEP_OR_ACTION,
  CHANGE_SELECT_CHILD_ELEMENT,
  CHANGE_TEST_DATA_LIST_FILTER_VALUE,
  CLEAR_RE_RECORD_ELEMENT_STEP_TAG,
  CLEAR_UNSAVED_STEP,
  DEVICE_INTERACTION_MODE,
  DEVICE_LOADED,
  DEVICE_STATUS,
  ERROR_STATUS,
  FETCHED_TEST_STEP_LIST,
  FETCHING_TEST_STEP_LIST,
  FIND_DEVICE_BY_UDID,
  HIDE_ADD_TEST_DATA_FORM,
  HIDE_UNSAVED_REC_TEST_STEP_ACTION_MODAL,
  START_PREVIEW_LOADER,
  LOAD_REC_STEP_ACTION_DETAIL,
  LOAD_REC_STEP_DETAIL,
  MAKE_STEP_EDITABLE_REQUEST,
  MAKE_SUB_REC_TEST_STEP,
  PREVIEW_COMPLETED,
  RECORD_15_PER_PAGE,
  REC_TEST_SCENARIO_STEP_ACTIONS_LIST,
  REC_TEST_STEP_LIST,
  REQUESTED_CLEAR_PREVIEW,
  REQUESTED_DRAG_AND_DROP,
  REQUESTED_FETCHING_REC_TEST_SCENARIO_STEP_ACTIONS,
  REQUEST_DRAG_AND_DROP,
  REQUEST_FETCHING_REC_TEST_SCENARIO_STEP_ACTIONS,
  REQUEST_FOR_DEVICE_LOAD,
  RESET_REC_TEST_STEP_ACTION_SELECTED_ELEMENT,
  RESET_STATE,
  RESET_TEST_BLOCk_STEP_SCENARIO_NAME,
  RunKilled,
  SCENARIO_DETAIL_FOR_EDIT,
  SELECT_REC_TEST_STEPS,
  SELECT_REC_TEST_STEP_FOR_PLAY,
  SET_TEST_RAIL_CASE_ID,
  SET_TEST_RAIL_SUITE_ID,
  SHOW_UNSAVED_REC_TEST_STEP_ACTION_MODAL,
  STEP,
  STOP_PREVIEW_REQUEST,
  STOP_PREVIEW_REQUESTED,
  SUCCESS_STATUS,
  TAG_LIST_RECORD_SCENARIO,
  TEST_BLOCK,
  TEST_DATA_SCOPE,
  TEST_SCENARIOS,
  TEST_STEP_BLOCKS,
  UPDATED_TEST_BLOCK_NAME,
  UPDATE_DEVICE_DETAIL_FOR_REC_TEST_SCENARIO,
  UPDATE_DEVICE_STATE,
  UPDATE_PREVIEW_REQUEST,
  UPDATE_PREVIEW_STATUS,
  UPDATE_PREVIEW_STATUS_WITH_REC_TEST_STEP,
  UPDATE_TEST_BLOCK_STEP_NAME,
  SCENARIO_GENERATE_REQUEST_STOP,
} from "../Constants";
import {
  checkDropRecTestStepIsChildOfDragRecTestStep,
  checkForInValidUserActionPermissionDenied,
  checkNotUndefinedAndNotNullAndListNotEmpty,
  checkRecTestStepIsNotStep,
  checkRecTestStepIsStep,
  getUniqueId,
  handleShowDragError,
  isAndroid,
  isIOS,
  isStepHaveLoopStep,
  isStepHavePrecondition,
  platformAbbrevMapping,
  showNotification,
  updateResponse,
} from "../Util";
import { updateScenarioOrTagField } from "../actions/RecTestScenarioAction";
import { async, prepareEventSource } from "../api/automation";
import {
  findRecTestStepByAssocId,
  findRecTestStepById,
  findRecTestStepByRecTestScenarioRecTestStepAssocId,
  findRecTestStepByTag,
} from "../reducer/RecTestStepReducer";
import {
  resetEditRecTestStep,
  resetPreviewSettings,
} from "./RecTestStep/RecTestStepCommonAction";
import {
  clearExpandedElements,
  clearExpandedSteps,
  clearPreview,
  editRecTestStep,
  hideAIGenerateStepsFromRecTestStep,
  // getPreviewDetail,
  updateStepType,
} from "./RecTestStep/RecTestStepRedirectAction";
import { getStepBasicDetailByRecTestScenarioId } from "./RecTestStepMultiColumnViewAction";
import {
  changeDeviceInteractionMode,
  recordingStop,
  resetElement,
  startDeviceSession,
  stopAppiumStreaming,
} from "./Recording";
import { fetchTestData } from "./TestDataAction";
let previewResultEventSource = "";

export function getRecTestStepListByRecTestScenarioId(recTestScenarioId, recTestStep,stepGenerationComplete) {
  return async (dispatch,getState) => {
    dispatch({ type: FETCHING_TEST_STEP_LIST });
    dispatch({
      type: CHANGE_TEST_DATA_LIST_FILTER_VALUE,
      value: TEST_DATA_SCOPE.ALL,
    });
    async("recTestStep/getByRecTestScenarioId", "GET", {
      recTestScenarioId: recTestScenarioId,
    }).then((response) => {
      response = updateResponse(response);
      if (stepGenerationComplete) {
        dispatch(hideAIGenerateStepsFromRecTestStep(recTestStep?.id));
        dispatch({ type: SCENARIO_GENERATE_REQUEST_STOP });
      }
      dispatch(resetEditRecTestStep());
      dispatch({
        type: REC_TEST_STEP_LIST,
        response,
        stepType: TEST_SCENARIOS,
      });
    });
  };
}

export function initialStateForScenarioDesigner(
  projectId,
  recTestScenarioTags,
  recTestScenarioId,
  recTestScenarioName,
  testRailTestCaseId,
  testRailTestSuiteId,
  platform
) {
  return async (dispatch) => {
    dispatch({
      type: SCENARIO_DETAIL_FOR_EDIT,
      recTestScenarioId,
      recTestScenarioName,
      testRailTestCaseId,
      testRailTestSuiteId,
      platform,
    });
    dispatch(updateStepType(TEST_SCENARIOS));
    if (projectId) {
      dispatch(fetchTestData(projectId));
    }
    dispatch({ type: HIDE_ADD_TEST_DATA_FORM });
    dispatch({ type: TAG_LIST_RECORD_SCENARIO, val: recTestScenarioTags });
    dispatch(updateScenarioOrTagField(false));
    dispatch(clearPreview());
    dispatch(clearExpandedElements());
    dispatch(clearExpandedSteps());
    dispatch(selectRecTestSteps(undefined));
    dispatch(resetPreviewSettings());
  };
}
export function loadStepDetail(recTestStep) {
  return async (dispatch) => {
    dispatch({ type: LOAD_REC_STEP_DETAIL, selectedRecTestStep: recTestStep });
  };
}

export function loadRecTestStepActionDetail(stepActionData) {
  return async (dispatch) => {
    dispatch({ type: LOAD_REC_STEP_ACTION_DETAIL, stepActionData });
  };
}

export function resetRecTestStepActionElement(recTestStepId, recTestStepTag) {
  return (dispatch, getState) => {
    dispatch({
      type: RESET_REC_TEST_STEP_ACTION_SELECTED_ELEMENT,
      reRecordRecStepTag: recTestStepTag,
      recElementGuid:
        getState().RecTestStepCommonViewReducer.unSavedRecElementGuid,
    });
    dispatch(resetEditRecTestStep());
    dispatch(resetElement());
    dispatch(recordingStop());
  };
}

export function clearReRecordElementStepTag() {
  return (dispatch) => {
    dispatch(resetEditRecTestStep());
    dispatch({ type: CLEAR_RE_RECORD_ELEMENT_STEP_TAG });
    dispatch(recordingStop());
  };
}

export function updateDeviceDetailForRecTestScenario(
  targetUniqueId,
  serverId,
  platform
) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_DEVICE_DETAIL_FOR_REC_TEST_SCENARIO,
      targetUniqueId,
      serverId,
      platform,
    });
  };
}

export function updateStepName() {
  return (dispatch, getState) => {
    const {
      testBlockStep,
      changedTestBlockStepName,
      selectedRecTestScenarioName,
    } = getState().RecTestStepReducer;
    let data = {};
    if (testBlockStep && changedTestBlockStepName) {
      data = {
        name: selectedRecTestScenarioName,
        id: testBlockStep?.recTestStepProperty?.id,
      };
    } else {
      data = {
        name: getState().RecTestStepReducer.selectedRecTestStepDetail
          ?.recTestStepProperty?.stepName,
        id: getState().RecTestStepReducer.selectedRecTestStepDetail
          ?.recTestStepProperty?.id,
      };
    }
    dispatch({ type: UPDATED_TEST_BLOCK_NAME, response: { isLoading: true } });
    return async("recTestStep/updateStepName", "POST", data).then(
      (response) => {
        showNotification("success", "Successfully Update The Step Name.", "");
        dispatch({ type: "CHANGED_REC_TEST_STEP_NAME" });
        if (testBlockStep !== undefined) {
          dispatch({
            type: UPDATE_TEST_BLOCK_STEP_NAME,
            value: selectedRecTestScenarioName,
          });
        }
        dispatch({
          type: UPDATED_TEST_BLOCK_NAME,
          response: { isLoading: false },
        });
      }
    );
  };
}

export function convertToTestBlockChildIntoStep() {
  return (dispatch, getState) => {
    let newRecTestStep = {
      tag: getUniqueId(),
      orderId: 1,
      recTestStepProperty: {
        action: STEP,
        operator: "AND",
        stepName:
          "Step-" + (getState().RecTestStepReducer.recTestStepList.length + 1),
        recElements: [],
      },
      recTestSteps: [...getState().RecTestStepReducer.recTestStepList],
      parentRecTestStep: { id: getState().RecTestStepReducer.testBlockStep.id },
      requestForTestBlock: "true",
      project: { id: getState().ProjectsReducer.projectId },
      testBlockStepId: getState().RecTestStepReducer.testBlockStep.id,
      testBlockStep: { id: getState().RecTestStepReducer.testBlockStep.id },
    };
    dispatch({ type: FETCHING_TEST_STEP_LIST });
    async("recTestStep/makeCurrentStepSubStep", "POST", newRecTestStep).then(
      (response) => {
        response = updateResponse(response);
        if (response.status === SUCCESS_STATUS) {
          dispatch({
            type: MAKE_SUB_REC_TEST_STEP,
            response,
            existingRecTestStep: { ...newRecTestStep },
          });
          dispatch({ type: FETCHED_TEST_STEP_LIST });
        }
      }
    );
  };
}
export function startPreview(
  projectId,
  runStepType,
  runStepTag,
  recTestScenarioRecTestStepAssocId
) {
  return async (dispatch, getState) => {
    const {
      selectedServerIdForRecording,
      selectedDeviceIdForRecording,
      selectedDevicePlatformForRecording,
      selectedTestStepBlock,
      selectedDeviceByUdid
    } = getState().RecTestStepReducer;
    const { stepType } = getState().RecTestStepCommonViewReducer;
    const { recordingState } = getState().RecordingReducer;
    //Clear the previous result if exists:
    dispatch(clearPreview());
    // Stop Recording and Page source Streaming
    if (recordingState === DEVICE_STATUS.RECORDING) {
      dispatch(
        recordingStop(
          selectedServerIdForRecording,
          selectedDeviceByUdid?.targetUniqueId,
          selectedDevicePlatformForRecording
        )
      );
    }
    // Add preview Request
    dispatch({ type: ADD_PREVIEW_REQUEST, runStepType });
    dispatch({ type: REQUESTED_CLEAR_PREVIEW });
    let request = {};
    if (stepType === TEST_SCENARIOS) {
      request = {
        selectedDeviceIdForRecording: selectedDeviceByUdid?.targetUniqueId,
        projectId,
        runStepType,
        runStepTag,
        recTestScenarioRecTestStepAssocId,
        scenarioId: getState().RecTestStepReducer.selectedRecTestScenarioId,
        testDataSetId:
          getState().RecTestStepCommonViewReducer.previewTestDataSetName &&
          getState().RecTestStepCommonViewReducer.previewTestDataSetId ===
            undefined
            ? -1
            : getState().RecTestStepCommonViewReducer.previewTestDataSetId,
        appLocale: getState().RecTestStepCommonViewReducer.previewAppLocale,
        localeReport:getState().RecTestStepCommonViewReducer.localReportValue,
        accessibilityReport:getState().RecTestStepCommonViewReducer.accessibilityReportValue,
      };
    } else if (stepType === TEST_STEP_BLOCKS) {
      request = {
        selectedDeviceIdForRecording: selectedDeviceByUdid?.targetUniqueId,
        projectId,
        runStepType,
        runStepTag,
        scenarioId: 0,
        stepId: recTestScenarioRecTestStepAssocId
          ? recTestScenarioRecTestStepAssocId
          : getState().TestBlockReducer.testBlockStep?.id,
        testDataSetId:
          getState().RecTestStepCommonViewReducer.previewTestDataSetName &&
          getState().RecTestStepCommonViewReducer.previewTestDataSetId ===
            undefined
            ? -1
            : getState().RecTestStepCommonViewReducer.previewTestDataSetId,
        appLocale: getState().RecTestStepCommonViewReducer.previewAppLocale,
        localeReport:getState().RecTestStepCommonViewReducer.localReportValue,
        accessibilityReport:getState().RecTestStepCommonViewReducer.accessibilityReportValue,
      };
    }
    dispatch(changeDeviceInteractionMode(DEVICE_INTERACTION_MODE.INTERACTIVE));
    dispatch({ type:START_PREVIEW_LOADER , isPreviewRunning: true})
    return async("recording/addPreviewRequest", "POST", request).then(
      (response) => {
        if (response.status === SUCCESS_STATUS) {
          dispatch({ type: UPDATE_PREVIEW_REQUEST, response });

        let startPreviewData = {
          scenarioId: getState().RecTestStepReducer.selectedRecTestScenarioId,
          runId: response.data.runId,
          runNumber: response.data.runNumber,
          targetUniqueId: selectedDeviceByUdid?.targetUniqueId,
          platform: getState().RecordingReducer.deviceDetail && getState().RecordingReducer.deviceDetail.platformName,
          runFolderName: response.data.runFolderName,
          requestedUserName: response.data.userName,
          isVideo: 0,
          isLogs: 0,
          isCaptureFailureImageEnable: 0,
          saveAtTestStepLevel: 1,
          runStepType,
          runStepTag,
          testScenarioId: response.data.testScenarioId,
          isTestBlock: stepType === TEST_STEP_BLOCKS ? 1 : 0,
          stepId: stepType === TEST_STEP_BLOCKS ? selectedTestStepBlock.id : 0,
          appLocale: getState().RecTestStepCommonViewReducer.previewAppLocale,
          project: { id: projectId },
          localeReport:getState().RecTestStepCommonViewReducer.localReportValue,
          accessibilityReport:getState().RecTestStepCommonViewReducer.accessibilityReportValue,
        };
        if (stepType === TEST_STEP_BLOCKS) {
          startPreviewData = { ...startPreviewData, testStepBlockTag: selectedTestStepBlock?.tag };
        }
        // Start preview
        async(
          "recording/startPreview",
          "POST",
          startPreviewData,
          getState().RecordingReducer.serverDetail && getState().RecordingReducer.serverDetail.serverIp,
          undefined,
          dispatch
        ).then((resp) => {
          resp = updateResponse(resp);
          if (checkForInValidUserActionPermissionDenied(resp)) {
            dispatch(
              stopAppiumStreaming(
                getState().RecordingReducer.serverDetail.id,
                selectedDeviceByUdid?.targetUniqueId,
                true,
                projectId
              )
            );
            dispatch(clearPreview());
          }

            if (resp.status === SUCCESS_STATUS) {
              let data = {
                runId: response.data.runId,
                serverId: selectedServerIdForRecording,
                targetUniqueId: selectedDeviceByUdid?.targetUniqueId,
                platformName:
                  getState().RecordingReducer.deviceDetail.platformName,
              };
              dispatch(getPreviewDetail(data)); 
            } else {
              showNotification("error", "Failed To Start Preview", "");
              dispatch({ type: ADD_PREVIEW_REQUESTED });
              dispatch(changeDeviceInteractionMode(DEVICE_INTERACTION_MODE.INTERACTIVE));
            }
          });
        } else {
          showNotification(
            "error",
            showNotification("error", "Failed To Start Preview", ""),
            response.message
            );
            dispatch({ type: ADD_PREVIEW_REQUESTED });
            dispatch(changeDeviceInteractionMode(DEVICE_INTERACTION_MODE.INTERACTIVE));
          }
          dispatch({ type:START_PREVIEW_LOADER , isPreviewRunning: false})
        }
    );
  };
}

export function getPreviewDetail(data) {
  return async (dispatch, getState) => {
    const { stepType } = getState().RecTestStepCommonViewReducer;
    previewResultEventSource = prepareEventSource(
      "recording/getPreviewDetail",
      {
        runId: data.runId,
      },
      ""
    );
    dispatch({ type: UPDATE_DEVICE_STATE, state: DEVICE_STATUS.PREVIEW });
    previewResultEventSource.onmessage = (event: any) => {
      if (event.data != "") {
        let data = event.data;
        let eventParseResponse = JSON.parse(data);
        if (
          eventParseResponse.status === SUCCESS_STATUS &&
          eventParseResponse.data.length > 0 &&
          eventParseResponse.data[0]["runStatus"] === "DONE"
        ) {
          previewResultEventSource && previewResultEventSource.close();
          dispatch({ type: UPDATE_DEVICE_STATE, state: DEVICE_STATUS.INTERACTION });
          dispatch({ type: CHANGE_DEVICE_INTERACTION_MODE, value: DEVICE_INTERACTION_MODE.INTERACTIVE });
          dispatch({ type: PREVIEW_COMPLETED });
          dispatch({
            type: UPDATE_PREVIEW_STATUS,
            response: eventParseResponse,
            orderId: stepType === TEST_STEP_BLOCKS ? 1 : undefined,
          });
          dispatch({ type: UPDATE_PREVIEW_STATUS_WITH_REC_TEST_STEP, response: eventParseResponse });
        } else if (eventParseResponse.status === ERROR_STATUS && eventParseResponse.message === RunKilled) {
          previewResultEventSource && previewResultEventSource.close();
          dispatch({ type: UPDATE_DEVICE_STATE, state: DEVICE_STATUS.INTERACTION });
          dispatch({ type: CHANGE_DEVICE_INTERACTION_MODE, value: DEVICE_INTERACTION_MODE.INTERACTIVE });
          dispatch({ type: PREVIEW_COMPLETED });
          showNotification("error", "Preview Was Unexpectedly Killed.", "");
          dispatch(stopPreview(data.serverId, data.targetUniqueId));
          dispatch({
            type: UPDATE_PREVIEW_STATUS,
            response: eventParseResponse,
            orderId: stepType === TEST_STEP_BLOCKS ? 1 : undefined,
          });
          dispatch({ type: UPDATE_PREVIEW_STATUS_WITH_REC_TEST_STEP, response: eventParseResponse });
        } else {
          dispatch({
            type: UPDATE_PREVIEW_STATUS,
            response: eventParseResponse,
            orderId: stepType === TEST_STEP_BLOCKS ? 1 : undefined,
          });
          dispatch({ type: UPDATE_PREVIEW_STATUS_WITH_REC_TEST_STEP, response: eventParseResponse });
        }
      }
    };
  };
}

export function stopPreview() {
  return async (dispatch, getState) => {
    const {
      previewRunId,
      selectedDeviceIdForRecording,
      selectedServerIdForRecording,
      selectedDevicePlatformForRecording,
      runStepType,
    } = getState().RecTestStepReducer;
    dispatch({ type: STOP_PREVIEW_REQUEST, runStepType });
    dispatch({ type: REQUESTED_CLEAR_PREVIEW });
    return async("recording/stopPreviewRequest", "POST", {
      selectedDeviceIdForRecording,
      runId: previewRunId,
    }).then((response) => {
      if (
        response.status === SUCCESS_STATUS &&
        selectedDeviceIdForRecording &&
        selectedServerIdForRecording &&
        selectedDevicePlatformForRecording
      ) {
        previewResultEventSource && previewResultEventSource.close();
        dispatch(clearPreview());
      } else {
        showNotification("error", "Error In Aborting Preview.", "");
      }
      dispatch({ type: STOP_PREVIEW_REQUESTED });
      dispatch({ type: REQUESTED_CLEAR_PREVIEW });
      dispatch({ type: UPDATE_DEVICE_STATE, state: DEVICE_STATUS.INTERACTION });
      dispatch(changeDeviceInteractionMode(DEVICE_INTERACTION_MODE.INTERACTIVE));
    });
  };
}

export function onNodeDragAndDrop(dataObj) {
  return async (dispatch, getState) => {
    const {
      selectedServerIdForRecording,
      selectedRecTestScenarioId,
      selectedRecTestScenarioName,
    } = getState().RecTestStepReducer;
    let data = {};
    dispatch({ type: REQUEST_DRAG_AND_DROP });
    if (
      dataObj.dragNode.props.pos.split("-").length <
        dataObj.node.props.pos.split("-").length &&
      dataObj.dragNode.props.children.length > 0
    ) {
      showNotification("error", "Not Able To Move Step As It Has Sub Step", "");
    } else {
      //Get the element postion
      let dragRecTestStepPosition = dataObj.dragNode.props.pos.split("-");
      dragRecTestStepPosition =
        dragRecTestStepPosition[dragRecTestStepPosition.length - 1];

      //Get the element postion
      let dropOnRecTestStepPosition = dataObj.node.props.pos.split("-");
      dropOnRecTestStepPosition =
        dropOnRecTestStepPosition[dropOnRecTestStepPosition.length - 1];

      data = {
        dragParentRecTestStep: { id: dataObj.dragNode.props.parentId },
        dragRecTestStepId: dataObj.dragNode.props.data.id,
        dragRecTestStepPosition,
        dropOnParentRecTestStep: { id: dataObj.node.props.parentId },
        dropOnRecTestStepId: dataObj.node.props.data.id,
        dropOnRecTestStepPosition,
        droppedPosition: dataObj.dropPosition,
        recTestScenario: { id: selectedRecTestScenarioId },
      };
      async(
        "recTestScenarioRecTestStepAssoc/updateRecTestStepOrder",
        "POST",
        data
      ).then((response) => {
        if (response.status === SUCCESS_STATUS) {
          dispatch(resetEditRecTestStep());
          dispatch({
            type: REC_TEST_STEP_LIST,
            response: { data: response.data.data, status: response.status },
            recTestScenarioId: selectedRecTestScenarioId,
            recTestScenarioName: selectedRecTestScenarioName,
          });
          dispatch({ type: REQUESTED_DRAG_AND_DROP });
        } else {
          showNotification("error", response.message, "");
          dispatch({ type: REQUESTED_DRAG_AND_DROP });
        }
      });
    }
  };
}

export function onRecStepActionDragAndDrop(dataObj) {
  return async (dispatch, getState) => {
    // const { selectedServerIdForRecording, selectedRecTestScenarioId } = getState().RecTestStepReducer;
    // let data = {};
    // if (dataObj.destination === null) {
    //   showNotification("error", "Not able to move action", "");
    // } else {
    //   dispatch({ type: REQUEST_DRAG_AND_DROP });
    //   data = {
    //     parentRecTestStep: { id: dataObj.source.droppableId },
    //     newOrderId: dataObj.destination.index,
    //     recTestStepId: dataObj.draggableId,
    //     recTestScenario: { id: selectedRecTestScenarioId },
    //   };
    //   async("recTestStep/updateRecTestStepActionOrder", "POST", data).then((resp) => {
    //     if (resp.status === SUCCESS_STATUS) {
    //       dispatch({ type: UPDATE_REC_TEST_STEP_ACTIONS, data: resp.data, parentId: dataObj.source.droppableId });
    //       dispatch({ type: REQUESTED_DRAG_AND_DROP });
    //     } else {
    //       showNotification("error", resp.message, "");
    //       dispatch({ type: REQUESTED_DRAG_AND_DROP });
    //     }
    //   });
    // }
  };
}

export function searchChildElement(recTestStepTag) {
  return (dispatch, getState) => {
    const {
      selectedDeviceIdForRecording,
      selectedServerIdForRecording,
      recTestStepList,
      testBlockStep,
    } = getState().RecTestStepReducer;
    let recTestStep = findRecTestStepByTag(
      recTestStepList,
      recTestStepTag,
      testBlockStep
    );
    // return async(
    //   "device/findElements",
    //   "POST",
    //   {
    //     targetUniqueId: selectedDeviceIdForRecording,
    //     serverId: selectedServerIdForRecording,
    //     recStepElementXpaths,
    //     refRecStepElementXpaths: [],
    //   },
    //   // { targetUniqueId, serverId, strategy, value: selector },
    //   getState().RecordingReducer.serverDetail.serverIp
    // ).then((response) => {
    //   response = updateResponse(response);
    // });
  };
}

// TODO: NOT IN USE
export function changeSelectRecTestStepOrAction(
  checkedKeys,
  nodeData,
  importType = undefined
) {
  return (dispatch, getState) => {
    let data = [];
    const { recTestStepList, selectedRecTestStepDetail } =
      getState().RecTestStepReducer;
    const {
      disableRecTestStepActionsForImport,
      checkedTestBlockImportStep,
      selectedRecTestStepOrActionForImport,
    } = getState().RecTestStepCommonViewReducer;
    let recTestStepDetail = findRecTestStepByAssocId(
      recTestStepList ? recTestStepList : [],
      selectedRecTestStepDetail
    );
    const { projectId } = getState().ProjectsReducer;
    let requestData = [];
    if (importType === TEST_STEP_BLOCKS) {
      recTestStepDetail = undefined;
      checkedKeys = [];
      checkedKeys = checkedTestBlockImportStep;
      requestData = _.cloneDeep(selectedRecTestStepOrActionForImport);
      // Need to add condition if parent selected need to remove the child entry from the list
      if (!nodeData.checked && checkedKeys.includes(nodeData.node.key)) {
        checkedKeys = checkedKeys.filter((item) => item !== nodeData.node.key);
        requestData = requestData.filter(
          (item) => item.recTestStep.id !== nodeData.node.key
        );
      } else {
        checkedKeys.push(nodeData.node.key);
        requestData.push({
          recTestStep: {
            id: nodeData.node.key,
            action: nodeData.node.data.action,
          },
          project: { id: projectId },
        });
      }
    } else {
      if (!disableRecTestStepActionsForImport) {
        //Fetch Selected Actions
        checkedKeys
          .filter(
            (item) =>
              // item.includes("-step#") &&
              item.includes("-action#") &&
              data.filter((dataItem) => item.includes(dataItem)).length === 0
          )
          .map((v) => {
            data.push(v);
          });
      } else {
        //Fetch Selected Scenario
        checkedKeys
          .filter((item) => item.includes("scenario#") && !item.includes("-"))
          .map((v) => {
            data.push(v);
          });

        //Fetch Selected Steps
        checkedKeys
          .filter(
            (item) =>
              item.includes("-step#") &&
              !item.includes("-subStep#") &&
              !item.includes("-action#") &&
              data.filter((dataItem) => item.includes(dataItem)).length === 0
          )
          .map((v) => {
            data.push(v);
          });
        if (recTestStepDetail) {
          //Fetch Selected Sub Steps
          let subStepsItem = checkedKeys.filter(
            (item) =>
              item.includes("-step#") &&
              item.includes("-subStep#") &&
              data.filter(
                (dataItem) =>
                  dataItem.includes("-step#") &&
                  dataItem.includes("-subStep#") &&
                  item.includes(dataItem)
              ).length === 0
          );
          data = subStepsItem.length > 0 ? [] : data;
          subStepsItem.map((v) => {
            data.push(v);
          });
        } else {
          //Fetch Selected Sub Steps
          checkedKeys
            .filter(
              (item) =>
                item.includes("-step#") &&
                item.includes("-subStep#") &&
                data.filter((dataItem) => item.includes(dataItem)).length === 0
            )
            .map((v) => {
              data.push(v);
            });
        }
        if (data.length === 0) {
          //Fetch Selected Actions
          checkedKeys
            .filter(
              (item) =>
                item.includes("-step#") &&
                item.includes("-action#") &&
                data.filter((dataItem) => item.includes(dataItem)).length === 0
            )
            .map((v) => {
              data.push(v);
            });
        }
      }
      data.map((v) => {
        let request = {};
        if (importType === TEST_STEP_BLOCKS) {
          if (v.includes("step#")) {
            let id = v.split("-")[1].split("#")[1];
            request = {
              ...request,
              recTestStep: { id: id },
              action: "step",
            };
          }
          if (v.includes("subStep#") || v.includes("action#")) {
            let parentId = v.split("-")[1].split("#")[1];
            let childId = v.split("-")[2].split("#")[1];
            request = {
              ...request,
              recTestStep: { id: childId },
              parentRecTestStep: { id: parentId },
              action: v.includes("subStep#") ? "step" : "action",
            };
          }
        } else {
          if (v.includes("scenario#")) {
            request = {
              recTestScenario: { id: v.split("-")[0].split("#")[1] },
            };
          }
          if (v.includes("step#")) {
            let id = v.split("-")[1].split("#")[1];
            request = {
              ...request,
              recTestScenarioRecTestStepsAssoc: { id: id },
            };
          }
          if (v.includes("subStep#") || v.includes("action#")) {
            let parentId = v.split("-")[1].split("#")[1];
            let childId = v.split("-")[2].split("#")[1];
            request = {
              ...request,
              recTestScenarioRecTestStepsAssoc: { id: childId },
              recTestScenarioRecTestStepsAssocParent: { id: parentId },
            };
          }
        }
        requestData.push(request);
      });
    }
    dispatch({
      type: CHANGE_SELECTED_REC_TEST_STEP_OR_ACTION,
      requestData,
      checkedKeys,
    });
  };
}

export function fetchScenariosAndRecTestStepsByPlatform(
  projectId,
  keyword = "",
  pageNumber = 1
) {
  return (dispatch, getState) => {
    const { selectedServerIdForRecording, selectedDevicePlatformForRecording } =
      getState().RecTestStepReducer;
    dispatch({ type: REQUEST_FETCHING_REC_TEST_SCENARIO_STEP_ACTIONS });
    async("recTestScenario/getScenariosAndRecTestStepsByPlatform", "GET", {
      platform: selectedDevicePlatformForRecording
        ? platformAbbrevMapping(selectedDevicePlatformForRecording)
        : ANDROID,
      projectId: projectId,
      pageNumber: pageNumber || 1,
      recordPerPage: RECORD_15_PER_PAGE,
      searchKeyword: keyword,
    }).then((response) => {
      dispatch({ type: REC_TEST_SCENARIO_STEP_ACTIONS_LIST, response });
      dispatch({ type: REQUESTED_FETCHING_REC_TEST_SCENARIO_STEP_ACTIONS });
    });
  };
}

export function showUnsavedRecTestStepActionModal() {
  return (dispatch, getState) => {
    dispatch({ type: SHOW_UNSAVED_REC_TEST_STEP_ACTION_MODAL });
  };
}

export function hideUnsavedRecTestStepActionModal() {
  return (dispatch, getState) => {
    dispatch({ type: HIDE_UNSAVED_REC_TEST_STEP_ACTION_MODAL });
  };
}

export function deleteUnSavedRecTestStepActionAndStopRecording() {
  return (dispatch, getState) => {
    const {
      selectedDeviceIdForRecording,
      selectedServerIdForRecording,
      selectedDevicePlatformForRecording,
    } = getState().RecTestStepReducer;
    new Promise((resolve) =>
      resolve(dispatch({ type: CLEAR_UNSAVED_STEP }))
    ).then(() => {
      dispatch(
        recordingStop(
          selectedServerIdForRecording,
          selectedDeviceIdForRecording,
          selectedDevicePlatformForRecording
        )
      );
      dispatch({ type: HIDE_UNSAVED_REC_TEST_STEP_ACTION_MODAL });
    });
  };
}

export function resetState() {
  return (dispatch) => {
    dispatch({ type: RESET_STATE });
  };
}

export function resetTestBlockName() {
  return (dispatch) => {
    dispatch({ type: RESET_TEST_BLOCk_STEP_SCENARIO_NAME });
    dispatch(recordingStop());
  };
}

export function changeRecTestStepPreConditionPanel(tag, value) {
  return (dispatch) => {
    dispatch({ type: CHANGE_REC_TEST_STEP_PRE_CONDITION_PANEL, tag });
  };
}

/****
 * Private Methods
 */

function prepareBounds(recTestStepActionSelectedElement) {
  let bounds = "";
  if (recTestStepActionSelectedElement) {
    if (isAndroid(recTestStepActionSelectedElement)) {
      bounds = recTestStepActionSelectedElement.attributes
        ? recTestStepActionSelectedElement.attributes.bounds
        : "";
    }
    if (isIOS(recTestStepActionSelectedElement)) {
      bounds = recTestStepActionSelectedElement.attributes
        ? "[" +
          recTestStepActionSelectedElement.attributes.x +
          "," +
          recTestStepActionSelectedElement.attributes.y +
          "]"
        : "";
    }
  }
  return bounds;
}

export function findByUdId(udId, deviceFarmId) {
  return async (dispatch, getState) => {
    return async(`device/findByUdId?udId=${udId}`, "GET").then((response) => {
      response = updateResponse(response);
      dispatch({ type: FIND_DEVICE_BY_UDID, response, deviceFarmId });
    });
  };
}

export function manageStartSession(
  targetUniqueId,
  deviceFarmId,
  projectId,
  orgId
) {
  return async (dispatch, getState) => {
    dispatch({ type: DEVICE_LOADED, isLoaded: false });
    dispatch({ type: REQUEST_FOR_DEVICE_LOAD });
    localStorage.setItem(
      "isDeviceLoaded",
      JSON.stringify({
        targetUniqueId
      })
    );
    new Promise((resolve) =>
      resolve(dispatch(findByUdId(targetUniqueId, deviceFarmId)))
    ).then(() => {
      const { selectedDeviceByUdid } = getState().RecTestStepReducer;
      if (selectedDeviceByUdid) {
        dispatch(
          startDeviceSession(
            selectedDeviceByUdid["platformType"].name,
            selectedDeviceByUdid["autoServerDetail"].id,
            targetUniqueId,
            selectedDeviceByUdid["deviceFarm"].id,
            false,
            projectId,
            orgId,
            DEVICE_STATUS.RECORDING
          )
        );
      }
    });
  };
}

export function onRecTestStepDragOver(event) {
  return async (dispatch, getState) => {
    event.preventDefault();
  };
}

export function onRecTestStepDrop(
  event,
  recTestStep,
  parentRecTestStep,
  index,
  innerRecTestStep,
  multiColumnView,
  eventData
) {
  return async (dispatch, getState) => {
    dispatch({ type: REQUEST_DRAG_AND_DROP });
    let dropBeforeRecTestStep =
      event.clientY <
      event.target.getBoundingClientRect().top +
        event.target.getBoundingClientRect().height / 2;
    event.stopPropagation();
    const {
      recTestStepList,
      selectedRecTestScenarioId,
      selectedRecTestScenarioName,
      testBlockStep,
    } = getState().RecTestStepReducer;
    const { stepType } = getState().RecTestStepCommonViewReducer;
    const { selectedScenario } = getState().RecTestScenarioReducer;
    let dragRecTestStep = undefined;
    let dragParentRecTestStep = undefined;
    let droppingRecTestStep = recTestStep;
    let droppingParentRecTestStep = parentRecTestStep;
    let requestUrl = undefined;
    let data = {};

    switch (stepType) {
      case TEST_SCENARIOS:
        requestUrl = "recTestScenarioRecTestStepAssoc/updateRecTestStepOrder";
        if (multiColumnView) {
          data = {
            dragParentRecTestScenarioRecTestStepsAssoc: {
              id: eventData.dragNode?.data
                ?.parentRecTestScenarioRecTestStepsAssocId,
            },
            dragRecTestScenarioRecTestStepsAssocId:
              eventData.dragNode?.data.recTestScenarioRecTestStepAssocId,
            dragRecTestScenarioRecTestStepsAssocPosition:
              eventData.dragNode?.data.orderId,
            dropOnParentRecTestScenarioRecTestStepsAssoc: {
              id: eventData?.node?.data
                ?.parentRecTestScenarioRecTestStepsAssocId,
            },
            dropOnRecTestScenarioRecTestStepsAssocId:
              eventData.node.data.recTestScenarioRecTestStepAssocId,
            dropOnRecTestScenarioRecTestStepsAssocPosition:
              eventData.dropPosition,
            droppedPosition: eventData.dropPosition,
            recTestScenario: { id: selectedScenario.id },
          };
        } else {
          dragRecTestStep =
            event.dataTransfer.getData(
              "dragRecTestScenarioRecTestStepAssocId"
            ) !== undefined
              ? findRecTestStepByRecTestScenarioRecTestStepAssocId(
                  recTestStepList,
                  parseInt(
                    event.dataTransfer.getData(
                      "dragRecTestScenarioRecTestStepAssocId"
                    )
                  )
                )
              : undefined;

          dragParentRecTestStep =
            event.dataTransfer.getData(
              "dragParentRecTestScenarioRecTestStepAssocId"
            ) !== undefined
              ? findRecTestStepByRecTestScenarioRecTestStepAssocId(
                  recTestStepList,
                  parseInt(
                    event.dataTransfer.getData(
                      "dragParentRecTestScenarioRecTestStepAssocId"
                    )
                  )
                )
              : undefined;

          data = prepareObjectInScenarioScreen(
            dragRecTestStep,
            dragParentRecTestStep,
            droppingRecTestStep,
            droppingParentRecTestStep,
            dropBeforeRecTestStep,
            index,
            selectedRecTestScenarioId,
            innerRecTestStep
          );
        }
        break;
      case TEST_STEP_BLOCKS:
        requestUrl = "recTestStepBlock/updateBlockOrder";
        dragRecTestStep =
          event.dataTransfer.getData("dragRecTestStepId") !== undefined
            ? findRecTestStepById(
                recTestStepList,
                parseInt(event.dataTransfer.getData("dragRecTestStepId"))
              )
            : undefined;

        dragParentRecTestStep =
          event.dataTransfer.getData("dragParentRecTestStepId") !== undefined
            ? findRecTestStepById(
                recTestStepList,
                parseInt(event.dataTransfer.getData("dragParentRecTestStepId"))
              )
            : undefined;

        // Parent should be same in case of a TB with actions only.
        const isParentOnlyHasActions =
          recTestStepList &&
          recTestStepList[0].recTestStepProperty?.action !== STEP;
        // Reset parent or make it undefined.
        if (isParentOnlyHasActions) {
          dragParentRecTestStep = {
            id: recTestStepList[0].testBlockRecTestStepId,
          };
          droppingParentRecTestStep = {
            id: recTestStepList[0].testBlockRecTestStepId,
          };
        }

        data = prepareObjectInTestBlocks(
          dragRecTestStep,
          dragParentRecTestStep,
          droppingRecTestStep,
          droppingParentRecTestStep,
          dropBeforeRecTestStep,
          index,
          testBlockStep.id,
          innerRecTestStep
        );
        break;

      default:
        break;
    }
    if (dragRecTestStep !== undefined || multiColumnView) {
      if (dragRecTestStep?.id === droppingRecTestStep?.id) {
        dispatch({ type: REQUEST_DRAG_AND_DROP });
      }else if (
        !validateDragInDesignerScreen(
          dragRecTestStep,
          dragParentRecTestStep,
          droppingRecTestStep,
          droppingParentRecTestStep,
          dropBeforeRecTestStep,
          innerRecTestStep
        )
      ) {
          handleShowDragError(dispatch,dragRecTestStep,droppingRecTestStep,droppingParentRecTestStep,stepType)     
      } else {
        async(requestUrl, "POST", data).then((response) => {
          if (response.status === SUCCESS_STATUS) {
            dispatch(resetEditRecTestStep());
            if (multiColumnView) {
              dispatch(
                getStepBasicDetailByRecTestScenarioId({
                  recTestScenarioId: selectedScenario?.id,
                })
              );
            } else {
              dispatch({
                type: REC_TEST_STEP_LIST,
                response: { data: response.data, status: response.status },
                recTestScenarioId: selectedRecTestScenarioId,
                recTestScenarioName: selectedRecTestScenarioName,
              });
            }
            dispatch({ type: REQUESTED_DRAG_AND_DROP });
          } else {
            showNotification("error", response.message, "");
            dispatch({ type: REQUESTED_DRAG_AND_DROP });
          }
        });
      }
    }
  };
}

export function validateDragInDesignerScreen(
  dragRecTestStep,
  dragParentRecTestStep,
  droppingRecTestStep,
  droppingParentRecTestStep,
  dropBeforeRecTestStep,
  innerRecTestStep,
  stepType
) {
  // if Main Step with sub step moves into other sub-step of main step [Blocked]
  // if (
  //   checkRecTestStepIsStep(dragRecTestStep) &&
  //   !checkListIsEmpty(dragRecTestStep.recTestSteps) &&
  //   checkRecTestStepIsStep(dragRecTestStep.recTestSteps[0]) &&
  //   checkRecTestStepIsStep(droppingRecTestStep) &&
  //   checkRecTestStepIsStep(droppingParentRecTestStep)
  // ) {
  //   return false;
  // }
  // if Main Step with sub step moves into other main step with sub step [Blocked]
  // else
  // if (
  //   checkRecTestStepIsStep(dragRecTestStep) &&
  //   checkNotUndefinedAndNotNullAndListNotEmpty(dragRecTestStep.recTestSteps) &&
  //   checkRecTestStepIsStep(dragRecTestStep.recTestSteps[0]) &&
  //   checkRecTestStepIsStep(droppingRecTestStep) &&
  //   checkNotUndefinedAndNotNullAndListNotEmpty(droppingRecTestStep.recTestSteps) &&
  //   checkRecTestStepIsStep(droppingRecTestStep.recTestSteps[0]) &&
  //   checkRecTestStepIsStep(droppingParentRecTestStep)
  // ) {
  //   return false;
  // }
  // if Actions moves into root level [Blocked]
  // else
  if (
    dragRecTestStep.isTestBlock === 0 &&
    droppingRecTestStep.isTestBlock === 1 &&
    innerRecTestStep
  ) {
    return false;
  } else if (
    stepType === TEST_SCENARIOS &&
    dragRecTestStep.isTestBlock === 1 &&
    (droppingRecTestStep?.isTestBlock === 1 || droppingParentRecTestStep?.isTestBlock === 1)  &&
    innerRecTestStep
  ) {
    return false;
  } else if (
    stepType === TEST_SCENARIOS &&
    dragRecTestStep.isTestBlock === 0 &&
    (droppingParentRecTestStep?.isTestBlock === 1) 
  ) {
    return false;
  } 
  
  else if (
    checkRecTestStepIsNotStep(dragRecTestStep) &&
    droppingRecTestStep === undefined
  ) {
    return false;
  }
  // if Actions moves into other main step with sub step [Blocked]
  else if (
    checkDropRecTestStepIsChildOfDragRecTestStep(
      droppingRecTestStep,
      dragRecTestStep
    )
  ) {
    return false;
  }
  // If parent move into child Step
  // else if (
  //   checkRecTestStepIsNotStep(dragRecTestStep) &&
  //   checkRecTestStepIsStep(droppingRecTestStep) &&
  //   checkNotUndefinedAndNotNullAndListNotEmpty(droppingRecTestStep.recTestSteps)
  // ) {
  //   return false;
  // }
  // if Sub-Step moves into other main step with sub step on Action [Blocked]
  else if (checkRecTestStepIsStep(dragRecTestStep) && checkRecTestStepIsNotStep(droppingRecTestStep)) {
    return false;
  } else if (
    checkRecTestStepIsStep(dragRecTestStep) &&
    droppingParentRecTestStep !== undefined &&
    checkRecTestStepIsStep(droppingRecTestStep) &&
    checkRecTestStepIsNotStep(droppingParentRecTestStep?.recTestSteps && droppingParentRecTestStep?.recTestSteps[0])
  ) {
    return false;
  } else if (checkRecTestStepIsNotStep(droppingRecTestStep) && checkRecTestStepIsStep(dragRecTestStep)) {
    return false;
  } else if (
    checkRecTestStepIsNotStep(droppingRecTestStep) &&
    checkRecTestStepIsNotStep(dragRecTestStep) &&
    droppingParentRecTestStep === undefined
  ) {
    return false;
  } else if (
    checkRecTestStepIsNotStep(dragRecTestStep) &&
    checkRecTestStepIsStep(droppingRecTestStep) &&
    droppingParentRecTestStep !== undefined && checkRecTestStepIsStep(droppingParentRecTestStep?.recTestSteps && droppingParentRecTestStep?.recTestSteps[0]) 
  ) {
    return false;
  } else if (
    checkRecTestStepIsNotStep(dragRecTestStep) &&
    droppingParentRecTestStep?.recTestSteps === undefined 
  ) {
    return false;
  }

  // else if (
  //   checkRecTestStepIsStep(dragRecTestStep) &&
  //   dragParentRecTestStep === undefined &&
  //   !checkListIsEmpty(dragRecTestStep.recTestSteps) &&
  //   checkRecTestStepIsStep(dragRecTestStep.recTestSteps[0]) &&
  //   checkRecTestStepIsStep(droppingRecTestStep) &&
  //   droppingParentRecTestStep === undefined &&
  //   innerRecTestStep
  // ) {
  //   return false;
  // }
  return true;
}

export function prepareObjectInScenarioScreen(
  dragRecTestStep,
  dragParentRecTestStep,
  droppingRecTestStep,
  droppingParentRecTestStep,
  dropBeforeRecTestStep,
  index,
  selectedRecTestScenarioId,
  innerRecTestStep,
  stepType
) {
  /**
   * Possibility of Drag and Drop
   * 1. Move Action inside Empty Step
   *       - dragRecTestStep, dropOnParentRecTestStep
   * 2. Move Action inside Empty Sub-Step
   *       - dragRecTestStep, dropOnParentRecTestStep
   * 3. Move Action inside Sub-Step with Actions
   *        - dragRecTestStep, dropOnRecTestStep
   * 3. Move Sub-Step inside Empty Step
   *        -dragRecTestStep, dropOnParentRecTestStep
   * 4. Move Sub-Step inside Step with Sub-Steps
   *        - dragRecTestStep, dropOnRecTestStep
   * 5. Move Step inside Empty Step
   *        - dragRecTestStep, dropOnParentRecTestStep
   * 6. Move Step(Contains Actions) inside Step with Sub-Steps
   *        - dragRecTestStep, dropOnRecTestStep
   * 7. Move Same Level Steps
   *        - dragRecTestStep, dropOnRecTestStep
   * 8. Move Same Level Sub-Steps
   *        - dragRecTestStep, dropOnRecTestStep
   * 9. Move Same Level Actions
   *        - dragRecTestStep, dropOnRecTestStep
   */
  let data = {
    dragParentRecTestScenarioRecTestStepsAssoc: dragParentRecTestStep
      ? {
          id: dragParentRecTestStep.recTestScenarioRecTestStepAssocId,
        }
      : {},
    dragRecTestScenarioRecTestStepsAssocId:
      dragRecTestStep.recTestScenarioRecTestStepAssocId,
    dragRecTestScenarioRecTestStepsAssocPosition: dragRecTestStep.orderId,

    dropOnParentRecTestScenarioRecTestStepsAssoc: droppingParentRecTestStep
      ? {
          id: droppingParentRecTestStep?.recTestScenarioRecTestStepAssocId,
        }
      : {},
    dropOnRecTestScenarioRecTestStepsAssocId: droppingRecTestStep.recTestScenarioRecTestStepAssocId,
    dropOnRecTestScenarioRecTestStepsAssocPosition: index,
    droppedPosition: index,
    recTestScenario: { id: selectedRecTestScenarioId },
  };
  if (
    ((stepType === TEST_SCENARIOS && droppingRecTestStep.isTestBlock == 0) ||
      (stepType === TEST_BLOCK && droppingRecTestStep.isTestBlock == 1)) &&
    checkRecTestStepIsStep(droppingRecTestStep) &&
    droppingRecTestStep?.recTestSteps?.length === 0 &&
    (innerRecTestStep !== undefined || checkRecTestStepIsNotStep(dragRecTestStep))
    //     ||
    // isStepHavePrecondition(droppingRecTestStep) ||
    // isStepHaveLoopStep(droppingRecTestStep)
  ) {
    data = {
      dragParentRecTestScenarioRecTestStepsAssoc: dragParentRecTestStep
        ? {
            id: dragParentRecTestStep.recTestScenarioRecTestStepAssocId,
          }
        : {},
      dragRecTestScenarioRecTestStepsAssocId:
        dragRecTestStep.recTestScenarioRecTestStepAssocId,
      dragRecTestScenarioRecTestStepsAssocPosition: dragRecTestStep.orderId,
      dropOnParentRecTestScenarioRecTestStepsAssoc: {
        id: droppingRecTestStep.recTestScenarioRecTestStepAssocId,
      },
      dropOnRecTestScenarioRecTestStepsAssocId:
        droppingRecTestStep.recTestScenarioRecTestStepAssocId,
      dropOnRecTestScenarioRecTestStepsAssocPosition: 0,
      droppedPosition: 0,
      recTestScenario: { id: selectedRecTestScenarioId },
    };
  }
  //
  else if (
    (dragParentRecTestStep === undefined &&
      droppingParentRecTestStep === undefined) ||
    (droppingParentRecTestStep === undefined &&
      checkRecTestStepIsStep(droppingRecTestStep) &&
      checkRecTestStepIsStep(dragRecTestStep) &&
      !dropBeforeRecTestStep &&
      droppingRecTestStep?.recTestSteps.length === 0)
  ) {
    data = {
      ...data,
      dropOnParentRecTestScenarioRecTestStepsAssoc: {},
      dropOnRecTestScenarioRecTestStepsAssocId:
        droppingRecTestStep.recTestScenarioRecTestStepAssocId,
    };
  }
  // Move Action from one step to another Step
  else if (
    checkRecTestStepIsNotStep(dragRecTestStep) &&
    checkRecTestStepIsStep(droppingRecTestStep)
  ) {
    data = {
      ...data,
      dropOnParentRecTestScenarioRecTestStepsAssoc: {
        id: droppingRecTestStep.recTestScenarioRecTestStepAssocId,
      },
    };
    if (droppingRecTestStep?.recTestSteps.length === 0) {
      data = {
        ...data,
        dropOnRecTestScenarioRecTestStepsAssocPosition: 0,
        droppedPosition: 0,
      };
    }
  }
  return data;
}

export function prepareObjectInTestBlocks(
  dragRecTestStep,
  dragParentRecTestStep,
  droppingRecTestStep,
  droppingParentRecTestStep,
  dropBeforeRecTestStep,
  index,
  testBlockStepId,
  innerRecTestStep
) {
  let data = {
    dragParentRecTestStep: dragParentRecTestStep
      ? { id: dragParentRecTestStep.id }
      : {},
    dragRecTestStepId: dragRecTestStep.id,
    dragRecTestStepPosition: dragRecTestStep.orderId,

    dropOnParentRecTestStep: droppingParentRecTestStep
      ? {
          id: droppingParentRecTestStep.id,
        }
      : {},
    dropOnRecTestStepId: droppingRecTestStep.id,
    dropOnRecTestStepPosition: index,
    droppedPosition: index,
    testBlockStep: { id: testBlockStepId },
  };
  if (
    (checkRecTestStepIsStep(droppingRecTestStep) &&
      droppingRecTestStep.recTestSteps.length === 0 &&
      (innerRecTestStep !== undefined ||
        checkRecTestStepIsNotStep(dragRecTestStep))) 
    //     ||
    // isStepHavePrecondition(droppingRecTestStep) ||
    // isStepHaveLoopStep(droppingRecTestStep)
  ) {
    data = {
      dragParentRecTestStep: dragParentRecTestStep
        ? {
            id: dragParentRecTestStep.id,
          }
        : {},
      dragRecTestStepId: dragRecTestStep.id,
      dragRecTestStepPosition: dragRecTestStep.orderId,
      dropOnParentRecTestStep: { id: droppingRecTestStep.id },
      dropOnRecTestStepId: droppingRecTestStep.id,
      dropOnRecTestStepPosition: 0,
      droppedPosition: 0,
      testBlockStep: { id: testBlockStepId },
    };
  }
  //
  else if (
    (dragParentRecTestStep === undefined &&
      droppingParentRecTestStep === undefined) ||
    (droppingParentRecTestStep === undefined &&
      checkRecTestStepIsStep(droppingRecTestStep) &&
      !dropBeforeRecTestStep &&
      droppingRecTestStep.recTestSteps.length === 0)
  ) {
    data = {
      ...data,
      dropOnParentRecTestStep: {},
      dropOnRecTestStepId: droppingRecTestStep.id,
    };
  }
  // Move Action from one step to another Step
  else if (
    checkRecTestStepIsNotStep(dragRecTestStep) &&
    checkRecTestStepIsStep(droppingRecTestStep)
  ) {
    data = {
      ...data,
      dropOnParentRecTestStep: { id: droppingRecTestStep.id },
    };
  }
  return data;
}

export function showDragError(errorMessage, dispatch) {
  showNotification("error", errorMessage);
  dispatch({ type: REQUESTED_DRAG_AND_DROP });
}

export function onRecTestStepDragStart(
  event,
  recTestStep,
  stepIndex,
  parentRecTestStep,
  multiColumnView
) {
  return async (dispatch, getState) => {
    event.dataTransfer.setData(
      "dragRecTestScenarioRecTestStepAssocId",
      recTestStep?.recTestScenarioRecTestStepAssocId
    );
    event.dataTransfer.setData("dragRecTestStepIndex", stepIndex);
    event.dataTransfer.setData(
      "dragParentRecTestScenarioRecTestStepAssocId",
      parentRecTestStep?.recTestScenarioRecTestStepAssocId
    );
    if (multiColumnView) {
      event.dataTransfer.setData(
        "dragRecTestStepId",
        recTestStep.recTestStepId
      );
    } else {
      event.dataTransfer.setData("dragRecTestStepId", recTestStep.id);
    }
    event.dataTransfer.setData(
      "dragParentRecTestStepId",
      parentRecTestStep?.id
    );
    event.stopPropagation();
  };
}

export function setTestRailTestCaseId(data) {
  return async (dispatch) => {
    dispatch({ type: SET_TEST_RAIL_CASE_ID, testCaseID: data });
  };
}

export function setTestRailTestSuiteId(data) {
  return async (dispatch) => {
    dispatch({ type: SET_TEST_RAIL_SUITE_ID, testSuiteId: data });
  };
}

export function changeSelectChildElement(checked, recTestStepTag) {
  return async (dispatch) => {
    dispatch(editRecTestStep(recTestStepTag));
    dispatch({ type: CHANGE_SELECT_CHILD_ELEMENT, checked, recTestStepTag });
  };
}

export function makeStepEditable(assocId) {
  return async (dispatch, getState) => {
    const { selectedRecTestScenarioId, selectedRecTestScenarioName } =
      getState().RecTestStepReducer;
    dispatch({ type: MAKE_STEP_EDITABLE_REQUEST, isEnable: true });
    return async(
      "recTestScenarioRecTestStepAssoc/makeRecTestStepEditable",
      "POST",
      {
        id: assocId,
      }
    ).then((response) => {
      if (response.status === SUCCESS_STATUS) {
        dispatch(resetEditRecTestStep());
        dispatch({
          type: REC_TEST_STEP_LIST,
          response: { data: response.data.data, status: response.status },
          recTestScenarioId: selectedRecTestScenarioId,
          recTestScenarioName: selectedRecTestScenarioName,
        });
        showNotification("success", "Successfully Crated Copy of Step.", "");
      } else {
        showNotification("error", response.message, "");
      }
      dispatch({ type: MAKE_STEP_EDITABLE_REQUEST, isEnable: false });
    });
  };
}

// export function captureDeviceSelection(startPositionX, startPositionY, width, height) {
//   return async (dispatch, getState) => {
//     const { selectedDeviceIdForRecording } = getState().RecTestStepReducer;
//     const { unSavedRecStepTag } = getState().RecTestStepCommonViewReducer;
//     let data = {
//       startPositionX,
//       startPositionY,
//       width,
//       height,
//       targetUniqueId: selectedDeviceIdForRecording,
//     };
//     async("device/captureDeviceSelection", "GET", data, getState().RecordingReducer.serverDetail.serverIp).then(
//       (response) => {
//         response = updateResponse(response);
//         dispatch({
//           type: UPDATE_DEVICE_SELECTION_DATA,
//           selectedImageCoordinates: { x: startPositionX, y: startPositionY, width, height },
//           response,
//           recTestStepTag: unSavedRecStepTag,
//         });
//       }
//     );
//   };
// }

export const selectRecTestSteps = (recTestStep) => {
  return async (dispatch, getState) => {
    const { unSavedRecStepTag } = getState().RecTestStepCommonViewReducer;
    if (unSavedRecStepTag === undefined) {
      dispatch({ type: SELECT_REC_TEST_STEPS, recTestStep });
    }
  };
};

export const selectRecTestStepForPlay = (selectedRecTestStep) => {
  return async (dispatch) => {
    dispatch({ type: SELECT_REC_TEST_STEP_FOR_PLAY, selectedRecTestStep });
  };
};
