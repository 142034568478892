import { default as React } from "react";
import { Row } from "antd";
import { capitalizeWords, getDeviceName, lowerCaseWords, showPlatformIcon } from "../../../../Util";
import { ANDROID } from "../../../../Constants";
import styles from "./TestPlanResultDeviceDetailsV2.module.scss";

const TestPlanResultDeviceDetailsV2 = (props) => {
  const { deviceResultDetails, selectedDeviceDetail = false, deviceServiceFarm } = props;

  return (
    <div className={styles["device_Details_Container"]}>
      {!selectedDeviceDetail && (
        <Row>
          {showPlatformIcon(deviceResultDetails?.platform)}
          <span className={styles["device_BrandName"]}>
            {deviceResultDetails?.brandName || deviceResultDetails?.deviceBrandName}
          </span>
        </Row>
      )}
      <div className={styles["label"]}>
        <span className={styles["device_Os_And_Locale"]}>
          {getDeviceName(deviceResultDetails || "N/A", deviceServiceFarm)} <span className={styles["divider"]}>|</span>
          {deviceResultDetails?.platform === ANDROID
            ? capitalizeWords(deviceResultDetails?.platform)
            : lowerCaseWords(deviceResultDetails?.platform)}
          <span className={styles["device_OsVer"]}> {deviceResultDetails?.osVer || "N/A"}</span>
          {deviceResultDetails?.locale && (
            <>
              <span className={styles["divider"]}>|</span>{" "}
              <span> {deviceResultDetails?.locale?.replaceAll(",", ",  ") || "Unknown"}</span>
            </>
          )}
        </span>
      </div>
      <div className={styles["label"]}>
        <span>Device Id:</span>
        <span className={styles["deviceId_And_Usages"]}>{deviceResultDetails?.targetUniqueId || "N/A"}</span>
      </div>

      {deviceResultDetails?.autoServerDetail && (
        <div className={styles["label"]}>
          <span> Server IP:</span>
          <span className={styles["deviceId_And_Usages"]}>
            {deviceResultDetails?.autoServerDetail?.serverIp || "N/A"}
          </span>
        </div>
      )}
      {deviceResultDetails?.devicesLength > 1 &&
        deviceResultDetails?.currentIndex !== deviceResultDetails?.devicesLength - 1 && (
          <div className="border-bottom-1"></div>
        )}
    </div>
  );
};

export default TestPlanResultDeviceDetailsV2;
