import { Col, Select, Spin } from "antd";
import { isEmpty } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  APP_MANAGER,
  DEFAULT_PAGE,
  DEVICE_EXECUTION_FARM,
  DEVICE_LOADING,
  LAMBDA_TEST,
  NONE,
  RECORD_10_PER_PAGE,
  SAUCELABS,
  SERIAL,
} from "../../../../../Constants";
import { checkNotUndefined, showDeviceTabCheckBox } from "../../../../../Util";
import { getUploadBuildOption } from "../../../../../actions/Recording";
import {
  changeExternalFarmDeviceListPageNumber,
  changeTestJobData,
  getExternalFarmDevice,
  handleSearchExternalFarmDeviceList,
} from "../../../../../actions/TestJobActions";
import EmptyRecords from "../../../../CommonComponents/EmptyRecords/EmptyRecords";
import PaginationComponentV2 from "../../../../CoreComponents/PaginationComponent/PaginationComponentV2";
import DeviceDetailsCardV2 from "../../../../FarmManagementV2/DeviceDetailsCardV2/DeviceDetailsCardV2";
import PoolManagementV2 from "../../../../FarmManagementV2/PoolManagementV2/PoolManagementV2";
import SelectAwsDevicePoolsV2 from "../AwsDeviceFarmV2/SelectAwsDevicePoolsV2";
import SelectAwsDeviceProjectV2 from "../AwsDeviceFarmV2/SelectAwsDeviceProjectV2";
import CommonSearchV2 from "../../../../CoreComponents/SearchComponent/CommonSearchV2";
import styles from "./JobCriteriaOptionsV2.module.scss";

const { Option } = Select;

const DeviceTabV2 = (props) => {
  const dispatch = useDispatch();
  const { projectId, orgId, showDeviceView} = props;
  const { getSearchDeviceList } = useSelector((state) => state.ManageFarmReducer);
  const {
    totalExternalFarmDeviceItems,
    totalExternalFarmDevicePage,
    lambdaTestDeviceLoading,
    externalFarmDeviceCurrentPageNumber,
    externalFarmDeviceLoading,
    externalFarmDeviceList,
    testJobDetail,
    previousTestJobDetail,
  } = useSelector((state) => state.TestJobReducer);

  const [lambdaTestDevicePageItems, setLambdaTestDevicePageItems] = useState("");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [showSearchBox, setShowSearchBox] = useState(false);

  //destructure of object
  const { deviceServiceFarm, devices, buildDetail, id } = testJobDetail;

  useEffect(() => {
    dispatch(getUploadBuildOption(projectId));
    if (
      deviceServiceFarm === DEVICE_EXECUTION_FARM.SAUCELABS ||
      deviceServiceFarm === DEVICE_EXECUTION_FARM.LAMBDA_TEST
    ) {
      dispatch(changeExternalFarmDeviceListPageNumber(DEFAULT_PAGE));
      dispatch(
        getExternalFarmDevice(
          deviceServiceFarm === DEVICE_EXECUTION_FARM.SAUCELABS
            ? DEVICE_EXECUTION_FARM.SAUCELABS
            : DEVICE_EXECUTION_FARM.LAMBDA_TEST
        )
      );
    }
  }, []);

  useEffect(() => {
    setShowSearchBox(false);
  }, [deviceServiceFarm]);

  const currentExternalFarmTableData = useMemo(() => {
    const firstPageIndex = (externalFarmDeviceCurrentPageNumber - 1) * RECORD_10_PER_PAGE;
    const lastPageIndex = firstPageIndex + RECORD_10_PER_PAGE;

    return externalFarmDeviceList?.slice(firstPageIndex, lastPageIndex);
  }, [externalFarmDeviceCurrentPageNumber, externalFarmDeviceList]);

  const getExternalFarmTableMeta = () => {
    const lengthOfTable = currentExternalFarmTableData?.length;
    let from = (externalFarmDeviceCurrentPageNumber - 1) * RECORD_10_PER_PAGE + 1;
    let to = (externalFarmDeviceCurrentPageNumber - 1) * RECORD_10_PER_PAGE + lengthOfTable;
    setLambdaTestDevicePageItems(`${from}- ${to}`);
  };

  useEffect(() => {
    getExternalFarmTableMeta();
  }, [externalFarmDeviceCurrentPageNumber, currentExternalFarmTableData]);

  const onChangeDeviceSelection = (devices) => {
    const getDeviceList =
      {
        [DEVICE_EXECUTION_FARM.LAMBDA_TEST]: externalFarmDeviceList,
        [DEVICE_EXECUTION_FARM.SAUCELABS]: externalFarmDeviceList,
      }[deviceServiceFarm] || getSearchDeviceList;
    const updatedDevices =
      getDeviceList
        ?.filter((deviceData) => {
          const deviceIdentifier = deviceData?.device?.targetUniqueId || deviceData?.id;

          return devices?.includes(deviceIdentifier) && deviceIdentifier;
        })
        ?.map((filteredData) => filteredData?.device?.targetUniqueId || filteredData?.id) || [];

    if (updatedDevices?.length < 2) {
      dispatch(changeTestJobData({ ...testJobDetail, devices: updatedDevices, executionType: SERIAL }));
    } else {
      dispatch(changeTestJobData({ ...testJobDetail, devices: updatedDevices }));
    }
  };

  const handleSelectDeviceFarm = (e) => {
    setSearchKeyword("");
    setShowSearchBox(true);
    const isExternalFarm = e === DEVICE_EXECUTION_FARM.SAUCELABS || e === DEVICE_EXECUTION_FARM.LAMBDA_TEST;
    const isXpress = e === DEVICE_EXECUTION_FARM.XPRESS;
    const selectedFarm = isExternalFarm ? e : undefined;

    if (isExternalFarm) {
      dispatch(getExternalFarmDevice(selectedFarm));
    }
    const selectedBuild = isXpress ? NONE : APP_MANAGER;

    if (isXpress) {
      dispatch({ type: DEVICE_LOADING, response: { isLoading: true } });
    }

    const buildDetailId = !isXpress && buildDetail && buildDetail?.id !== 0 ? 0 : buildDetail?.id || 0;

    dispatch(
      changeTestJobData({
        ...testJobDetail,
        deviceServiceFarm: e,
        devices: [],
        selectedBuild,
        deviceFarmIds: undefined,
        buildDetail: {
          id: buildDetailId,
        },
      })
    );
  };

  return (
    <>
      <div className={styles["job_Details_wrap"]}>
        <div className={styles["execution_Farm_Wrapper"]}>
          <div className={styles["build_And_Farm_Title"]}>Execution Farm</div>
          <div>
            <Select
              className={styles["modal-select-box"]}
              key="ExecutionFarm"
              placeholder="Please Select Execution Farm"
              value={deviceServiceFarm}
              onChange={(e) => {
                handleSelectDeviceFarm(e);
              }}
            >
              {DEVICE_EXECUTION_FARM &&
                Object.keys(DEVICE_EXECUTION_FARM).map((key, index) => {
                  return (
                    <Option key={index + 1} value={DEVICE_EXECUTION_FARM[key]}>
                      {DEVICE_EXECUTION_FARM[key]}
                    </Option>
                  );
                })}
            </Select>
          </div>
        </div>
        {deviceServiceFarm === DEVICE_EXECUTION_FARM.AWS && (
          <div className="width-50">
            <div className={styles["aws_Settings_Title"]}>AWS Settings</div>
            <div>
              <SelectAwsDeviceProjectV2 required={true} testJobDetail={testJobDetail} onChange={changeTestJobData} />
            </div>
            <SelectAwsDevicePoolsV2 required={true} testJobDetail={testJobDetail} onChange={changeTestJobData} />
          </div>
        )}
      </div>
      <Col span={24} className="margin-10">
        {deviceServiceFarm === DEVICE_EXECUTION_FARM.XPRESS && (
          <PoolManagementV2
            projectId={projectId}
            deviceTab={true}
            testJobDetail={testJobDetail}
            orgId={orgId}
            selectedDevices={devices}
            previousSelectedDevices={previousTestJobDetail?.devices}
            disableDeviceSelection={checkNotUndefined(id)}
            onChangeDeviceSelection={onChangeDeviceSelection}
            showDeviceView={showDeviceView}
          />
        )}
      </Col>
      {(deviceServiceFarm === DEVICE_EXECUTION_FARM.LAMBDA_TEST ||
        deviceServiceFarm === DEVICE_EXECUTION_FARM.SAUCELABS) && (
        <div className={styles["lambdaTest_And_SauceLabs_DeviceFarm"]}>
          <Spin className={styles["spinner_Center"]} spinning={externalFarmDeviceLoading}>
            <div className={styles["deviceFarm_Header"]}>
              <div className={styles["deviceFarm_Label"]}>
                {" "}
                {deviceServiceFarm === DEVICE_EXECUTION_FARM.LAMBDA_TEST
                  ? "LambdaTest Device"
                  : "SauceLabs Device"}{" "}
              </div>
              {!showSearchBox && (
                <CommonSearchV2
                  placeHolder="Search by Device or OS version"
                  searchDetails={(searchText) => dispatch(handleSearchExternalFarmDeviceList(searchText))}
                  searchKeyword={searchKeyword}
                  setSearchKeyword={setSearchKeyword}
                />
              )}
            </div>
            <div className={styles["device_Farm_Details"]}>
              <div
                className={
                  isEmpty(currentExternalFarmTableData) ? styles["empty_Data"] : styles["external_Farm_Table_Data"]
                }
              >
                {!isEmpty(currentExternalFarmTableData)
                  ? currentExternalFarmTableData?.map((item) => {
                      return (
                        <div>
                          <DeviceDetailsCardV2
                            deviceData={item}
                            deviceTab={true}
                            showDeviceTabCheckBox={() => showDeviceTabCheckBox(item, devices)}
                            selectedDevices={devices}
                            previousSelectedDevices={previousTestJobDetail?.devices}
                            disableDeviceSelection={checkNotUndefined(id)}
                            onChangeDeviceSelection={onChangeDeviceSelection}
                            typeForDeviceModals={
                              deviceServiceFarm === DEVICE_EXECUTION_FARM.LAMBDA_TEST ? LAMBDA_TEST : SAUCELABS
                            }
                            deviceServiceFarm={deviceServiceFarm}
                          />
                        </div>
                      );
                    })
                  : !lambdaTestDeviceLoading && <EmptyRecords description="No Device available" />}
              </div>
              {!isEmpty(currentExternalFarmTableData) && (
                <PaginationComponentV2
                  currentPageItems={lambdaTestDevicePageItems}
                  totalItems={totalExternalFarmDeviceItems}
                  currentPage={externalFarmDeviceCurrentPageNumber}
                  totalPage={totalExternalFarmDevicePage}
                  callPaginationAPI={(pageNumber) => {
                    dispatch(changeExternalFarmDeviceListPageNumber(pageNumber));
                  }}
                />
              )}
            </div>
          </Spin>
        </div>
      )}
    </>
  );
};

export default DeviceTabV2;
