export const PROJECT_DOWN_ARROW = "PROJECT_DOWN_ARROW";
export const LOGO = "LOGO";
export const HELP_ICON = "HELP_ICON";

export const ICON_ADD_ACTION = "ICON_ADD_ACTION";
export const ICON_ADD_VERIFICATION = "ICON_ADD_VERIFICATION";
export const ICON_DUPLICATE = "ICON_DUPLICATE";
export const ICON_CONDITIONAL_STEP = "ICON_CONDITIONAL_STEP";
export const ICON_ROBOT_STEP = "ICON_ROBOT_STEP";
export const ICON_AI = "ICON_AI";
export const ICON_ADD_SUB_STEP = "ICON_ADD_SUB_STEP";
export const ICON_IMPORT_STEP = "ICON_IMPORT_STEP";
export const ICON_STOP = "ICON_STOP";
export const ICON_PLAY = "ICON_PLAY";
export const DOWN_ARROW_ICON = "DOWN_ARROW_ICON";
export const ICON_PLAY_FOR_SUB_HEADER = "ICON_PLAY_FOR_SUB_HEADER";
export const ICON_PLAY_FROM_HERE = "ICON_PLAY_FROM_HERE";
export const ICON_PLAY_TILL_HERE = "ICON_PLAY_TILL_HERE";
export const STEP_DELETE_ICON = "STEP_DELETE_ICON";
export const ICON_GENERAL_ACTION = "ICON_GENERAL_ACTION";
export const ICON_IMPORT_ACTION = "ICON_IMPORT_ACTION ";
export const ADD_ICON = "ADD_ICON";
export const IMPORT_STEP_ICON = "IMPORT_STEP_ICON";
export const BUILD_DATA_SET_ICON = "BUILD_DATA_SET_ICON";
export const PREVIEW_RUN_ICON = "PREVIEW_RUN_ICON";
export const STOP_ICON = "STOP_ICON";
export const REC_TEST_PLAY_ICON = "REC_TEST_PLAY_ICON";
export const TEST_PLAN_RESULT_ICON = "TEST_PLAN_RESULT_ICON";
export const CLEAR_RESULT_ICON = "CLEAR_RESULT_ICON";
export const SETTING_ICON = "SETTING_ICON";
export const ICON_TAP_ACTION = "ICON_TAP_ACTION";
export const ICON_TAP_BY_INDEX_ACTION = "ICON_TAP_BY_INDEX_ACTION";
export const ICON_LONG_PRESS_ACTION = "ICON_LONG_PRESS_ACTION";
export const ICON_ENTER_TEXT_ACTION = "ICON_ENTER_TEXT_ACTION";
export const ICON_CLEAR_TEXT_ACTION = "ICON_CLEAR_TEXT_ACTION";
export const ICON_SWIPE_ELEMENT_ACTION = "ICON_SWIPE_ELEMENT_ACTION";
export const ICON_SCREENSHOT_ELEMENT_ACTION = "ICON_SCREENSHOT_ELEMENT_ACTION";
export const ICON_LAUNCH_APP_ACTION = "ICON_LAUNCH_APP_ACTION";
export const ICON_ADD_DELAY_ACTION = "ICON_ADD_DELAY_ACTION";
export const ICON_SCROLL_PAGE_ACTION = "ICON_SCROLL_PAGE_ACTION";
export const ICON_SCREENSHOT_PAGE_ACTION = "ICON_SCREENSHOT_PAGE_ACTION";
export const ICON_NAVIGATE_HOME_ACTION = "ICON_NAVIGATE_HOME_ACTION";
export const ICON_NAVIGATE_BACK_ACTION = "ICON_NAVIGATE_BACK_ACTION";
export const ICON_KEYPAD_ENTER_ACTION = "ICON_KEYPAD_ENTER_ACTION";
export const ICON_ASSIGN_VALUE_TO_TEST_DATA_ACTION = "ICON_ASSIGN_VALUE_TO_TEST_DATA_ACTION";
export const MORE_ACTION_ICON_ON_HOVER = "MORE_ACTION_ICON_ON_HOVER";
export const ADD_ACTION_ICON_ON_HOVER = "ADD_ACTION_ICON_ON_HOVER";
export const ADD_SUB_STEP_ICON_ON_HOVER = "ADD_SUB_STEP_ICON_ON_HOVER";
export const EMPTY_RECORDS_IMAGE = "EMPTY_RECORDS_IMAGE";
export const EMPTY_REC_TEST_STEP_IMG = "EMPTY_REC_TEST_STEP_IMG";
export const DRAG_AND_DROP_ICON = "DRAG_AND_DROP_ICON";
export const OPEN_COLLAPSE_ICON_FOR_SUB_STEP = "OPEN_COLLAPSE_ICON_FOR_SUB_STEP";
export const CLOSE_COLLAPSE_ICON_FOR_SUB_STEP = "CLOSE_COLLAPSE_ICON_FOR_SUB_STEP";
export const OPEN_COLLAPSE_ICON_FOR_PARENT_STEP = "OPEN_COLLAPSE_ICON_FOR_PARENT_STEP";
export const CLOSE_COLLAPSE_ICON_FOR_PARENT_STEP = "CLOSE_COLLAPSE_ICON_FOR_PARENT_STEP";
export const ICON_SAVE = "ICON_SAVE";
export const ICON_RESET = "ICON_RESET";
export const ICON_PERFORM_AND_SAVE = "ICON_PERFORM_AND_SAVE";
export const ICON_CLOSE = "ICON_CLOSE";
export const BUILD_TEST_DATA_SET_ICON = "BUILD_TEST_DATA_SET_ICON";
export const ICON_DOWN_ARROW = "ICON_DOWN_ARROW";
export const DISABLE_ICON = "DISABLE_ICON";
export const MENU_BUTTON = "MENU_BUTTON";
export const USER_ICON = "USER_ICON";
export const HIDE_DEVICE_VIEW_ICON = "HIDE_DEVICE_VIEW_ICON";
export const SHOW_DEVICE_VIEW_ICON = "SHOW_DEVICE_VIEW_ICON";
export const ANDROID_LOGO = "ANDROID_LOGO";
export const IOS_LOGO = "IOS_LOGO";
export const CLOCK_ICON = "CLOCK_ICON";
export const CLOSE_SESSION_ICON = "CLOSE_SESSION_ICON";
export const ACCESSIBILITY_ICON = "ACCESSIBILITY_ICON";
export const LOCAL_REPORT_ICON = "LOCAL_REPORT_ICON";
export const IMPORT_TB_IN_TS_ICON="IMPORT_TB_IN_TS_ICON";

export const PRESS_HOME_ICON = "PRESS_HOME_ICON";
export const PRESS_BACK_ICON = "PRESS_BACK_ICON";
export const PRESS_LOCK_OPEN_ICON = "PRESS_LOCK_OPEN_ICON";
export const PRESS_LOCK_ICON = "PRESS_LOCK_ICON";
export const PRESS_VOLUME_UP_ICON = "PRESS_VOLUME_UP_ICON";
export const PRESS_NOTIFICATION_ICON = "PRESS_NOTIFICATION_ICON";
export const PRESS_CLOUD_UPLOAD = "PRESS_CLOUD_UPLOAD";
export const PRESS_MENU_OPEN = "PRESS_MENU_OPEN";
export const ANDROID_LOGO_IMAGE = "ANDROID_LOGO_IMAGE";
export const PRESS_VOLUME_DOWN_ICON = "PRESS_VOLUME_DOWN_ICON";
export const DEVICE_ORIENTATION_ICON = "DEVICE_ORIENTATION_ICON";
export const IGNORE_FAILURE_AND_CONTINUE_EXECUTION_ICON = "IGNORE_FAILURE_AND_CONTINUE_EXECUTION_ICON";
export const PRESS_KEYBOARD_ICON = "PRESS_KEYBOARD_ICON";
export const INFO_ICON = "INFO_ICON";
export const SAVE_ICON = "SAVE_ICON";
export const ICON_PERFORM_SAVE = "ICON_PERFORM_SAVE";
export const RESET_ICON = "RESET_ICON";
export const APPLICATION_STRING_ICON = "APPLICATION_STRING_ICON";
export const DEVICE_STRING_ICON = "DEVICE_STRING_ICON";
export const DELETE_ICON = "DELETE_ICON";
export const ICON_EDIT = "ICON_EDIT";
export const ICON_DELETE_WITH_BG = "ICON_DELETE_WITH_BG";
export const DELETE_ELEMENT_ICON = "DELETE_ELEMENT_ICON";
export const MORE_PROPERTIES_ICON = "MORE_PROPERTIES_ICON";
export const REPLACE_ELEMENT_ICON = "REPLACE_ELEMENT_ICON";
export const GENERAL_SETTINGS_ICON = "GENERAL_SETTINGS_ICON";
export const SELECT_TEST_DATA_ICON = "SELECT_TEST_DATA_ICON";
export const ICON_ARROW_RIGHT = "ICON_ARROW_RIGHT";
export const ICON_ARROW_LEFT = "ICON_ARROW_LEFT";
export const ICON_ARROW_DOWN = "ICON_ARROW_DOWN";
export const ICON_ARROW_UP = "ICON_ARROW_UP";
export const EDIT_RULES_ICON = "EDIT_RULES_ICON";
export const SEARCH_ICON = "SEARCH_ICON";
export const ICON_FILTER = "ICON_FILTER";
export const DEVICE_IMG = "DEVICE_IMG";
export const ANDROID_ICON = "ANDROID_ICON";
export const IOS_ICON = "IOS_ICON";
export const USE_DEVICE_ICON = "USE_DEVICE_ICON";
export const RELEASE_DEVICE_ICON = "RELEASE_DEVICE_ICON";
export const DEVICE_INFORMATION_ICON = "DEVICE_INFORMATION_ICON";
export const MODAL_CLOSE_ICON = "MODAL_CLOSE_ICON";
export const DISABLE_ELEMENT_ICON = "DISABLE_ELEMENT_ICON";
export const USE_DEVICE_FOR_RUN_JOB = "USE_DEVICE_FOR_RUN_JOB";
export const ICON_LOCK = "ICON_LOCK";
export const PARENT_ELEMENT_ARROW_ICON = "PARENT_ELEMENT_ARROW_ICON";
export const IMPORT_ICON = "IMPORT_ICON";
export const EMPTY_ELEMENT_IMAGE = "EMPTY_ELEMENT_IMAGE";
export const INSPECTOR_ELEMENT_IMAGE = "INSPECTOR_ELEMENT_IMAGE";
export const OPEN_COLLAPSE_ICON_IN_MODAL = "OPEN_COLLAPSE_ICON_IN_MODAL";
export const OPEN_CHILD_COLLAPSE_ICON_IN_MODAL = "OPEN_CHILD_COLLAPSE_ICON_IN_MODAL";
export const CLOSE_CHILD_COLLAPSE_ICON_IN_MODAL = "CLOSE_CHILD_COLLAPSE_ICON_IN_MODAL";
export const APPLICATION_STRING_BLACK_ICON = "APPLICATION_STRING_BLACK_ICON";
export const TIMER_OUT_IMAGE = "TIMER_OUT_IMAGE";

//result
export const RESULT_SUCCESS_ICON = "RESULT_SUCCESS_ICON";
export const RESULT_FAIL_ICON = "RESULT_FAIL_ICON";
export const RESULT_SKIPPED_ICON = "RESULT_SKIPPED_ICON";
export const RESULT_CONTINUE_AFTER_FAIL_ICON = "RESULT_CONTINUE_AFTER_FAIL_ICON";
export const RESULT_PRE_CONDITION_FAIL_ICON = "RESULT_PRE_CONDITION_FAIL_ICON";
export const WARNING_NO_ELEMENT_DATA_FOUND = "WARNING_NO_ELEMENT_DATA_FOUND";

export const ACTION_ICON_COLOR = "#3F5065";
export const DISABLE_ICON_COLOR = "#D3D3D3";
export const BLUE_ICON_COLOR = "#338fcc";
export const DISABLE_COLOR = "#6e7282";
export const BLACK_COLOR = "#000000";
export const YELLOW_COLOR = "yellow";
export const PINK_COLOR = "#FF188F";
export const BLUE_COLOR = "#01A3E3";
export const WHITE_COLOR = "#FFF";
export const GRAY_COLOR = "#848484";
export const LIGHT_GRAY_COLOR = "#7d7e82";

//Update UI
export const PROJECT_AND_TEAM_ICON = "PROJECT_AND_TEAM_ICON";
export const INVITE_MEMBER_ICON = "INVITE_MEMBER_ICON";
export const SEND_ICON = "SEND_ICON";
export const VIEW_INVITATION_ICON = "VIEW_INVITATION_ICON";
export const CREATE_ICON = "CREATE_ICON";
export const ICON_SEARCH = "ICON_SEARCH";
export const LOGO_XPRESS = "LOGO_XPRESS";
export const CLOSE_ICON_RED = "CLOSE_ICON_RED";
export const ICON_EDIT_NEW = "ICON_EDIT_NEW";
export const ICON_BUILD_TEST_DATA_SET_NEW = "ICON_BUILD_TEST_DATA_SET_NEW";
export const ICON_APPLICATION_STRING_NEW = "ICON_APPLICATION_STRING_NEW";
export const ICON_MORE_NEW = "ICON_MORE_NEW";
export const PRIVATE_ICON = "PRIVATE_ICON";
export const PUBLIC_ICON = "PUBLIC_ICON";
export const CUSTOM_ICON = "CUSTOM_ICON";
export const APPLICATION_STRING_ICON_NEW = "APPLICATION_STRING_ICON_NEW";
export const GENERAL_SETTING_NEW_ICON = "GENERAL_SETTING_NEW_ICON";
export const LOAD_DEVICE_ICON = "LOAD_DEVICE_ICON";
export const EDIT_RULES_ICON_NEW = "EDIT_RULES_ICON_NEW";
export const ADD_CIRCLE_ICON = "ADD_CIRCLE_ICON";
export const REMOVE_ICON = "REMOVE_ICON";
export const DELETE_ICON_NEW = "DELETE_ICON_NEW";
export const CLONE_ICON = "CLONE_ICON";
export const TRENDS_ICON_NEW = "TRENDS_ICON_NEW";
export const CROSS_REMOVE_ICON = "CROSS_REMOVE_ICON";
export const SELECT_TEST_DATA_ICON_NEW = "SELECT_TEST_DATA_ICON_NEW";
export const SELECT_AREA_IMAGE = "SELECT_AREA_IMAGE";
export const CONDITION_DELETE_IMAGE = "CONDITION_DELETE_IMAGE";
export const NEW_COLLAPSE_OPEN_ICON = "NEW_COLLAPSE_OPEN_ICON";
export const NEW_COLLAPSE_CLOSE_ICON = "NEW_COLLAPSE_CLOSE_ICON";
export const SEARCH_DEVICE_ICON = "SEARCH_DEVICE_ICON";
export const HIDE_DEVICE_VIEW_ICON_NEW = "HIDE_DEVICE_VIEW_ICON_NEW";
export const SHOW_DEVICE_VIEW_ICON_NEW = "SHOW_DEVICE_VIEW_ICON_NEW";
export const INFO_ICON_NEW = "INFO_ICON_NEW";
export const RELEASE_ICON_NEW = "RELEASE_ICON_NEW";
export const EMPTY_DEVICE_LIST_IMG = "EMPTY_DEVICE_LIST_IMG";
export const IPHONE_IMG = "IPHONE_IMG";
export const ANDROID_PHONE_IMG = "ANDROID_PHONE_IMG";
export const ADD_OR_REMOVE_DEVICE_ICON = "ADD_OR_REMOVE_DEVICE_ICON";
export const ICON_SCHEDULED_NEW = "ICON_SCHEDULED_NEW";
export const ICON_DELETE_NEW = "ICON_DELETE_NEW";
export const FILTER_ICON_V2 = "FILTER_ICON_V2";
export const DELETE_ICON_RED_BORDER = "DELETE_ICON_RED_BORDER";
export const SELECT_ICON = "SELECT_ICON";
export const BLUE_CLOSE_COLLAPSE_ICON = "BLUE_CLOSE_COLLAPSE_ICON";
export const BLUE_OPEN_COLLAPSE_ICON = "BLUE_OPEN_COLLAPSE_ICON";
export const REPORT_DETAILS_ICON = "REPORT_DETAILS_ICON";
export const TRASH_ICON = "TRASH_ICON";
export const UPLOAD_BUILD_ICON = "UPLOAD_BUILD_ICON";
export const UNDO_ICON = "UNDO_ICON";
export const DRAG_SCENARIO_IMG = "DRAG_SCENARIO_IMG";
export const ABORT_ICON = "ABORT_ICON";
export const ICON_MORE_OPTIONS = "ICON_MORE_OPTIONS";
export const ACCEPT_INVITATION_ICON="ACCEPT_INVITATION_ICON";
export const RESEND_INVITATION_ICON="RESEND_INVITATION_ICON";
export const STEPS_DRAG_AND_DROP_ICON="STEPS_DRAG_AND_DROP_ICON";
export const ICON_TEAM="ICON_TEAM";
export const CLEAR_STRINGS_ICON="CLEAR_STRINGS_ICON";
export const ICON_COPY="ICON_COPY";
export const ICON_VIEW="ICON_VIEW";

//deviceImg
export const GOOGLE_PIXEL_4A_IMG = "GOOGLE_PIXEL_4A_IMG";
export const SAMSUNG_A02_IMG = "SAMSUNG_A02_IMG";
export const ONE_PLUS_6T_IMG = "ONE_PLUS_6T_IMG";
export const GOOGLE_PIXEL_6_PRO_IMG = "GOOGLE_PIXEL_6_PRO_IMG";
export const SAMSUNG_A52_IMG = "SAMSUNG_A52_IMG";
export const MOTOROLA_G04_IMG = "MOTOROLA_G04_IMG";
export const SAMSUNG_M04_IMG = "SAMSUNG_M04_IMG";
export const SAMSUNG_A05M_IMG = "SAMSUNG_A05M_IMG";
export const VIVO_IMG = "VIVO_IMG";
export const IPHONE_SE_IMG = "IPHONE_SE_IMG";
export const IPHONE_11_IMG = "IPHONE_11_IMG";

//Login
export const AUTH_BANNER = "AUTH_BANNER";
export const SUCCESS_SIGN_UP_IMAGE = "SUCCESS_SIGN_UP_IMAGE";
export const VERIFY_EMAIL_IMAGE = "VERIFY_EMAIL_IMAGE";
export const ORGANIZATION_STEP_1_IMG = "ORGANIZATION_STEP_1_IMG";
export const ORGANIZATION_STEP_2_IMG = "ORGANIZATION_STEP_2_IMG";
export const ORGANIZATION_STEP_3_IMG = "ORGANIZATION_STEP_3_IMG";
export const AI_ICON = "AI_ICON";


//menu
export const PROJECT_MENU_ICON = "PROJECT_MENU_ICON";
export const TEAM_MENU_ICON = "TEAM_MENU_ICON";
export const DEVICE_LIST_MENU_ICON = "DEVICE_LIST_MENU_ICON";
export const EXECUTION_REPORT_MENU_ICON = "EXECUTION_REPORT_MENU_ICON";
export const SERVER_MANAGEMENT_MENU_ICON = "SERVER_MANAGEMENT_MENU_ICON";
export const LINK_ICON="LINK_ICON";

//device-list
export const ANDROID_DEVICE_ICON="ANDROID_DEVICE_ICON";
export const IOS_DEVICE_ICON="IOS_DEVICE_ICON";

//device-server
export const OPEN_COLLAPSE_ICON_FOR_TABLE="OPEN_COLLAPSE_ICON_FOR_TABLE";
export const CLOSE_COLLAPSE_ICON_FOR_TABLE="CLOSE_COLLAPSE_ICON_FOR_TABLE";
export const DEVICE_SERVER_ICON="DEVICE_SERVER_ICON";

//AI-STep
export const AI_STEP_ICON="AI_STEP_ICON";
export const GENERAL_SETTINGS_ICON_V2="GENERAL_SETTINGS_ICON_V2";
export const PAGE_SOURCE_ICON_V2="PAGE_SOURCE_ICON_V2";
export const AI_JSON_DATA_ICON_V2="AI_JSON_DATA_ICON_V2"

//color
export const DARK_GRAY_COLOR = "#a5a5a5";
export const DEEP_BLUE_COLOR = "#3772FF";
export const SHADOW_GRAY_COLOR = "#4A4D53";
export const RED_COLOR = "#EE0B0B";
export const DARK_GREEN = "#00ab55";
export const NAVY_BLUE_COLOR = "#002858";
export const DARK_BLUE_COLOR = "#3772ff";
export const SILVER_GRAY = "#7a7a7a";
export const SHADOW_BLUE_COLOR="#3f5065"
