import React from "react";
import { useDispatch } from "react-redux";
import { Select } from "antd";
import PropTypes from "prop-types";
import {
  ACTIONS,
  ACTIONS_OPTIONS,
  ELEMENT_ACTION,
  STEP,
  SWIPE_OVER_ELEMENT,
  VERIFICATIONS,
} from "../../../../../Constants";
import { updateActionValue } from "../../../../../actions/RecTestStep/RecTestStepRedirectAction";
import VerifyTestDataV2 from "../../VerificationsV2/VerifyTestDataV2/VerifyTestDataV2";
import TapByIndexV2 from "../TapByIndexV2/TapByIndexV2";
import styles from "./ActionsVerificationItemsV2.module.scss";
import AppendRandomTextV2 from "../EnterTextV2/AppendRandomTextV2";

const ActionsVerificationItemsV2 = (props) => {
  const dispatch = useDispatch();
  const { data, isShowPerformActionsForNLP, isShowPerformActionsForSpecificElements } = props;

  const { action, isStepHasVerifyTestData, isLoopStep, recStepVerifyElementProperties, isStepHasPrecondition } =
    data.recTestStepProperty;

  const isDisabled = isShowPerformActionsForNLP || isShowPerformActionsForSpecificElements;

  return (
    <React.Fragment>
      <div>
        {getActionType(action) === ACTIONS && (
          <div className={styles["actionsSelection"]}>
            <div className={styles["contentInCenter"]}>
              <div className={styles["selectActionsOrText"]}>
                <span className={styles["operation_Text"]}>Operation :</span>
                <div className={styles["selectBoxAndLabel"]}>
                  <div className={styles["actionTitle"]}>Action</div>
                  <Select
                    className={`${styles["selectActions"]} ${isDisabled && styles["selectBoxBgColor"]}`}
                    value={action === "actions" ? ACTIONS_OPTIONS : action}
                    disabled={isDisabled}
                    defaultValue={ELEMENT_ACTION.TAP}
                    dropdownMatchSelectWidth
                    onChange={(value) => {
                      dispatch(updateActionValue(value, data.tag));
                    }}
                  >
                    <Select.Option value={ELEMENT_ACTION.TAP}>Tap</Select.Option>
                    {/* <Select.Option value={ELEMENT_ACTION.TAP_BY_INDEX}>Tap By Index</Select.Option> */}
                    <Select.Option value={ELEMENT_ACTION.LONG_PRESS}>Long Press</Select.Option>
                    <Select.Option value={ELEMENT_ACTION.SEND_KEYS}>Enter Text</Select.Option>
                    <Select.Option value={ELEMENT_ACTION.CLEAR_TEXT}>Clear Text</Select.Option>
                    <Select.Option value={SWIPE_OVER_ELEMENT}>Swipe Element</Select.Option>
                  </Select>
                </div>
              </div>
              <div className={styles["contentInCenter"]}>
                {action === ELEMENT_ACTION.TAP_BY_INDEX && (
                  <div className={styles["enterTextOrTapIndex"]}>
                    <TapByIndexV2 {...props} />
                  </div>
                )}
              </div>
              {action === ELEMENT_ACTION.SEND_KEYS && 
              <div className={styles["appendText"]}>
                <AppendRandomTextV2
                  data={data}
                  disabled={props.isSkipStep || isDisabled}
                />
              </div>
              }
            </div>
          </div>
        )}
        <div
          className={
            action !== VERIFICATIONS &&
            (action === ELEMENT_ACTION.SEND_KEYS ||
              action === SWIPE_OVER_ELEMENT ||
              !(isLoopStep === 1 && isStepHasPrecondition === 0)) &&
            styles["action_Details"]
          }
        >
          {action === VERIFICATIONS && (
            <div className={styles["verification_Text_Select_Box"]}>
              <span className={styles["label"]}>Operation:</span>
              <div className={styles["select_Box_And_Label"]}>
                <div className={styles["verify_Text_Label"]}>Verify Element</div>
              </div>
            </div>
          )}
          {action === STEP &&
            isStepHasVerifyTestData === 1 &&
            recStepVerifyElementProperties?.some((i) => i?.type === "VerifyTestData") && (
              <VerifyTestDataV2 {...props} />
            )}
        </div>
      </div>
    </React.Fragment>
  );
};

ActionsVerificationItemsV2.propTypes = {
  data: PropTypes.object.isRequired,
};

const getActionType = (action) => {
  switch (action) {
    case ELEMENT_ACTION.SWIPE:
    case ELEMENT_ACTION.TAP:
    case ELEMENT_ACTION.LONG_PRESS:
    case ELEMENT_ACTION.TAP_BY_INDEX:
    case ELEMENT_ACTION.TAP_BY_COORDINATES:
    case ELEMENT_ACTION.SEND_KEYS:
    case ELEMENT_ACTION.CLEAR_TEXT:
    case ELEMENT_ACTION.CLEAR:
    case ELEMENT_ACTION.SWIPE_OVER_ELEMENT:
      return ACTIONS;
    case VERIFICATIONS:
    case STEP:
      return VERIFICATIONS;
    default:
      return ACTIONS;
  }
};
export default ActionsVerificationItemsV2;
