import { default as React, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Collapse, Dropdown, Input, Menu, Row, Spin, Tooltip } from "antd";
import isEmpty from "lodash/isEmpty";
import {
  REC_TEST_STEP_PREVIEW_STATUS_TYPE,
  STEP,
  STEP_DISABLED,
  TEST_SCENARIOS,
  TEST_STEP_BLOCKS,
  TOOLTIP_BG_COLOR,
  splitValue,
} from "../../../../Constants";
import {
  BLUE_ICON_COLOR,
  BUILD_TEST_DATA_SET_ICON,
  CLOSE_COLLAPSE_ICON_FOR_PARENT_STEP,
  DISABLE_ICON_COLOR,
  IMPORT_TB_IN_TS_ICON,
  OPEN_COLLAPSE_ICON_FOR_PARENT_STEP,
  STEPS_DRAG_AND_DROP_ICON,
} from "../../../../Constants/SvgConstants";
import {
  blockCollapseAction,
  disableStep,
  disableStepsDragging,
  dragHoverOnStepOrAction,
  getRecTestStepStatusWithImage,
  hasPreviousAiKeys,
  isArrayNotUndefinedNotNullNotEmpty,
  isCurrentRecTestStepDisable,
  isStepHaveLoopStep,
  isStepHavePrecondition,
  isSubStep,
  showUnSavedNotification,
} from "../../../../Util";
import SvgLoader from "../../../../Util/SvgLoader";
import { resetAllRecScenarioStepIds, selectAllRecScenarioStepIds } from "../../../../actions/RecTestScenarioAction";
import { resetEditRecTestStep } from "../../../../actions/RecTestStep/RecTestStepCommonAction";
import {
  captureExpandedSteps,
  changeRecTestStepActionName,
  hideAIGenerateStepsFromRecTestStep,
  onRecTestStepDrop,
  resetRecTestStepAction,
  updateRecTestStepAction,
} from "../../../../actions/RecTestStep/RecTestStepRedirectAction";
import {
  onRecTestStepDragOver,
  onRecTestStepDragStart,
  selectRecTestSteps,
} from "../../../../actions/RecTestStepAction";
import { recordingStop, resetElement } from "../../../../actions/Recording";
import { setTestDataSetAssigneeDetail } from "../../../../actions/TestDataSet/TestDataSetCommonAction";
import {
  getSelectedTestBlock,
  getTestDataSetByTestPlanId,
  showTestDataSetSelectionModal,
} from "../../../../actions/TestDataSetAction";
import { findRecTestStepByTag } from "../../../../reducer/RecTestStepReducer";
import EmptyRecords from "../../../CommonComponents/EmptyRecords/EmptyRecords";
import VerticalLine from "../../../CommonComponents/VerticalLine";
import AssignTestDataSetModal from "../../../ExecutionManager/JobDesigner/BuildJob/JobCriteriaOptions/AssignTestDataSetModal/AssignTestDataSetModal";
import GenerateScenarioStepsHeaderV2 from "../../../RecTestStep/ScenarioHeader/ScenarioHeaderOptions/GenerateScenarioStepsModal/GenerateScenarioStepsHeaderV2";
import { getConditionalIcon } from "../../../RecTestStep/SingleColumnView/RecTestStepCondition/RecTestStepCondition";
import HoverActionsOnRecTestStepV2 from "../HoverActionsOnRecTestStepV2/HoverActionsOnRecTestStepV2";
import RecTestStepActionV2 from "../RecTestStepActionV2";
import RecTestStepConditionV2 from "../RecTestStepConditionV2/RecTestStepConditionV2";
import styles from "./RecTestStepV2.module.scss";

const { Panel } = Collapse;

const RecTestStepV2 = (props) => {
  const {
    recTestStep,
    parentIndex,
    projectId,
    parentRecTestStep,
    disableAction,
    isDeviceLoaded,
    index,
    parentSkipStep,
    recTestStepList,
    stepId,
    isChildRecTestStep,
  } = props;
  const dispatch = useDispatch();

  const {
    isPreviewRunning,
    selectedRectTestScenarioStepId,
    selectedRecStepForOperation,
    requestingUpdateRecTestStepAction,
    selectedRecTestScenarioId,
  } = useSelector((state) => state.RecTestStepReducer);
  const {
    unSavedRecStepTag,
    expandedStepTags,
    expandedStepAssocIds,
    isLoadingForAIStep,
    isLoadingForAllStepsIds,
    stepType,
    visibleAIGenerateRecTestStep,
    unSavedRecElementGuid,
  } = useSelector((state) => state.RecTestStepCommonViewReducer);
  const { selectTestBlockStep, testBlockStep } = useSelector((state) => state.RecTestStepReducer);
  const [recTestStepId, setRectTestStepId] = useState(null);
  const [menuVisible, setMenuVisible] = useState(false);
  const [actionMenuVisible, setActionMenuVisible] = useState(false);
  const [generalActionMenuVisible, setGeneralActionMenuVisible] = useState(false);
  const [isDragOver, setIsDragOver] = useState(false);
  const [isChildDragOver, setIsChildDragOver] = useState(false);
  const [isEmptyStepDragOver, setIsEmptyStepDragOver] = useState(false);
  const [dragOverOnCollapse, setDragOverOnCollapse] = useState(false);
  const [isChildStepsDragEnter, setIsChildStepsDragEnter] = useState(false);

  const unSavedRecTestStep = findRecTestStepByTag(recTestStepList, unSavedRecStepTag, testBlockStep);

  useEffect(() => {
    dispatch(resetAllRecScenarioStepIds());
  }, []);

  useEffect(() => {
    // add and remove class on hoverEffect
    const hoverMenuVisible = document.getElementsByClassName("actionsMenuVisible");
    if (hoverMenuVisible[0]) {
      const collapseHeader = hoverMenuVisible[0].getElementsByClassName("ant-collapse-header");
      if (collapseHeader[0]) {
        collapseHeader[0].classList.add("visibleActionsMenu");
        const actionsWrapper = collapseHeader[0].getElementsByClassName("showRecTestStepActionsIconOnHover");
        if (actionsWrapper[0]) {
          actionsWrapper[0].classList.add("showActionsIconsOnHover");
        }
        return () => {
          collapseHeader[0] && collapseHeader[0].classList.remove("visibleActionsMenu");
          actionsWrapper[0] && actionsWrapper[0].classList.remove("showActionsIconsOnHover");
        };
      }
    }
  }, [menuVisible, actionMenuVisible, generalActionMenuVisible]);

  function CollapseBorder(element, addClass) {
    const parentClassName = element.parentNode.parentNode.parentNode;
    if (parentClassName) {
      const stepOrderNameBorder = parentClassName.getElementsByClassName("recTestStepCollapseBorder");
      if (stepOrderNameBorder[0]) {
        if (addClass) {
          stepOrderNameBorder[0].classList.add("hover_Border_Dark_Blue");
        } else {
          stepOrderNameBorder[0].classList.remove("hover_Border_Dark_Blue");
        }
      }
    }
  }

  function collapseBorderOnHover() {
    CollapseBorder(this, true);
  }

  function collapseBorderOnHoverOut() {
    CollapseBorder(this, false);
  }

  useEffect(() => {
    //add or remove collapseBorder color
    const collapseHeader = document.querySelectorAll(".stepCollapseBorder > div > div.ant-collapse-header");
    for (let i = 0; i < collapseHeader.length; i++) {
      collapseHeader[i].addEventListener("mouseenter", collapseBorderOnHover);
      collapseHeader[i].addEventListener("mouseleave", collapseBorderOnHoverOut);
    }
    return () => {
      for (let i = 0; i < collapseHeader.length; i++) {
        collapseHeader[i].removeEventListener("mouseenter", collapseBorderOnHover);
        collapseHeader[i].removeEventListener("mouseleave", collapseBorderOnHoverOut);
      }
    };
  }, []);

  //Destructure of recTestStep and selectedRecStepForOperation object
  const {
    tag,
    isTestBlock: testBlockOfRecTestStep,
    isSkipStep: skipStepOfRecTestStep,
    recTestScenarioRecTestStepAssocId,
    testBlockRecTestScenarioRecTestStepAssocId,
    parentRecTestStep: parentStep,
    parentRecTestStepId,
    id: idOfRecTestStep,
    testBlockRecTestStepId,
    orderId: orderIdOfRecTestStep,
    recTestSteps: childStepsOfRecTestSteps,
    selectedTestDataSet,
    previewStatus,
    preconditionResult,
  } = recTestStep ? recTestStep : {};

  const {
    isStepHasPrecondition,
    isLoopStep,
    isSkipStep: disabledStep,
    action,
    stepName: stepNameOfRecTestStep,
  } = recTestStep && recTestStep.recTestStepProperty ? recTestStep.recTestStepProperty : {};

  const {
    testBlockRecTestScenarioRecTestStepAssocId: testBlockAssocIdOfSelectedStep,
    recTestScenarioRecTestStepAssocId: scenarioAssocIdOfSelectedStep,
  } = selectedRecStepForOperation ? selectedRecStepForOperation : {};

  const memoizedValues = useMemo(() => {
    const stepNo = stepId?.split(".").length;
    const disabled =
      isCurrentRecTestStepDisable(unSavedRecStepTag, recTestStep, disableAction) ||
      isPreviewRunning ||
      isLoadingForAIStep !== null;
    const isTestBlock = stepType === TEST_SCENARIOS ? testBlockOfRecTestStep === 1 || props.isTestBlock : false;
    const isSkipStep = parentSkipStep || skipStepOfRecTestStep === 1 || isTestBlock;
    const isChildSkipStep = parentSkipStep || skipStepOfRecTestStep === 1;
    const testDataSetOptions = generateTestDataSetOptions(dispatch, recTestStepList, recTestStep);
    const hideDragAndDropIcon = isChildSkipStep || (isTestBlock && parentRecTestStepId);
    const selectRecTestStep = stepType === TEST_SCENARIOS ? [...expandedStepAssocIds] : [...expandedStepTags];
    const activeCollapse = selectRecTestStep?.includes(
      stepType === TEST_SCENARIOS
        ? recTestScenarioRecTestStepAssocId
          ? recTestScenarioRecTestStepAssocId
          : testBlockRecTestScenarioRecTestStepAssocId + splitValue + tag
        : tag
    );
    const subTestBlockStep = stepType === TEST_SCENARIOS && testBlockOfRecTestStep === 1 && parentRecTestStepId;
    const showToggle =
      (stepType === TEST_SCENARIOS && testBlockOfRecTestStep === 0) ||
      (stepType === TEST_SCENARIOS && testBlockOfRecTestStep === 1 && !parentRecTestStepId) ||
      stepType === TEST_STEP_BLOCKS;

    const selectedStep =
      selectedRecStepForOperation?.id + "," + (scenarioAssocIdOfSelectedStep || testBlockAssocIdOfSelectedStep) ===
      idOfRecTestStep + "," + (recTestScenarioRecTestStepAssocId || testBlockRecTestScenarioRecTestStepAssocId);

    const childStepDisabled =
      (isChildSkipStep && skipStepOfRecTestStep === 0) ||
      (isChildSkipStep && !skipStepOfRecTestStep && parentStep !== null);

    const isSelected = selectedRectTestScenarioStepId?.includes(
      stepType === TEST_SCENARIOS ? recTestScenarioRecTestStepAssocId : idOfRecTestStep
    );
    const selectedRectTestStep = !isSelected && selectedStep;

    const isDisableStep = skipStepOfRecTestStep === 1 || parentSkipStep;
    const isRecTestStepChild = isChildRecTestStep;
    const stylesRecTestStepOrderName = isRecTestStepChild
      ? isDisableStep
        ? styles["childDisableStepOrder"]
        : styles["childStyledStepOrder"]
      : isDisableStep
      ? styles["disableStepOrderName"]
      : styles["styledStepOrder"];

    const isDisableDragAndDropIcon =
      disabled || unSavedRecElementGuid !== undefined || hasPreviousAiKeys(recTestStep?.recTestStepProperty);

    return {
      stepNo,
      disabled,
      isTestBlock,
      isSkipStep,
      isChildSkipStep,
      testDataSetOptions,
      hideDragAndDropIcon,
      selectRecTestStep,
      activeCollapse,
      subTestBlockStep,
      showToggle,
      selectedStep,
      childStepDisabled,
      isSelected,
      selectedRectTestStep,
      isDisableStep,
      isRecTestStepChild,
      stylesRecTestStepOrderName,
      isDisableDragAndDropIcon,
    };
  }, [
    unSavedRecElementGuid,
    isLoadingForAIStep,
    testBlockOfRecTestStep,
    tag,
    stepId,
    unSavedRecStepTag,
    recTestStep,
    disableAction,
    isPreviewRunning,
    stepType,
    props.isTestBlock,
    parentSkipStep,
    dispatch,
    recTestStepList,
    expandedStepAssocIds,
    expandedStepTags,
    selectedRecStepForOperation,
    selectedRectTestScenarioStepId,
    isChildRecTestStep,
    testBlockRecTestScenarioRecTestStepAssocId,
    recTestScenarioRecTestStepAssocId,
    skipStepOfRecTestStep,
    parentStep,
    parentRecTestStepId,
    idOfRecTestStep,
    scenarioAssocIdOfSelectedStep,
    testBlockAssocIdOfSelectedStep,
  ]);

  const {
    stepNo,
    disabled,
    isTestBlock,
    isSkipStep,
    isChildSkipStep,
    testDataSetOptions,
    hideDragAndDropIcon,
    activeCollapse,
    subTestBlockStep,
    showToggle,
    selectedStep,
    childStepDisabled,
    isSelected,
    selectedRectTestStep,
    isDisableStep,
    stylesRecTestStepOrderName,
    isDisableDragAndDropIcon,
  } = memoizedValues;

  const isUnsaved = showUnSavedNotification(recTestStep, unSavedRecStepTag);

  let recTestStepCollapseClassNames = `rec_Test_Step_Collapse ${
    dragOverOnCollapse && !disabled && !isChildSkipStep && "border_onDragOver"
  } ${
    !isDisableStep && !selectedStep && "recTestStepCollapseBorder"
  } collapseStepOrderBorder stepCollapseBorder recTestStepSteps `;
  if (isUnsaved) {
    recTestStepCollapseClassNames += "unsavedStepBorder ";
  } else if (isSelected) {
    recTestStepCollapseClassNames += "multiple_select_steps ";
  } else if (selectedStep) {
    recTestStepCollapseClassNames += "border_Blue ";
  }

  const handleMultiSelectStepId = useCallback(
    (e, id) => {
      if (
        ((!disabled && !recTestStepId && !isSkipStep && isLoadingForAIStep === null) || isTestBlock) &&
        (e.ctrlKey || e.metaKey) &&
        !subTestBlockStep
      ) {
        dispatch(selectAllRecScenarioStepIds(id));
      } else {
        if (
          !selectedRectTestScenarioStepId?.includes(
            stepType === TEST_SCENARIOS ? recTestScenarioRecTestStepAssocId : idOfRecTestStep
          ) &&
          isLoadingForAIStep === null
        ) {
          dispatch(selectRecTestSteps({ ...recTestStep, parentSkipStep }));
        }
      }
    },
    [
      disabled,
      recTestStepId,
      isSkipStep,
      isTestBlock,
      subTestBlockStep,
      dispatch,
      idOfRecTestStep,
      recTestScenarioRecTestStepAssocId,
      parentSkipStep,
      recTestStep,
      selectedRectTestScenarioStepId,
      stepType,
      isLoadingForAIStep,
    ]
  );
  const childSteps = () => {
    return (
      <div
        key={index}
        onDragStart={(event) =>
          !disabled || (isTestBlock && testBlockRecTestStepId === undefined)
            ? dispatch(
                onRecTestStepDragStart(
                  event,
                  recTestStep,
                  orderIdOfRecTestStep,
                  parentRecTestStep ? parentRecTestStep : undefined
                )
              )
            : undefined
        }
        onDragOver={(event) =>
          !disabled || (isTestBlock && testBlockRecTestStepId === undefined)
            ? (dispatch(onRecTestStepDragOver(event)),
              setIsChildStepsDragEnter(true),
              isChildStepsDragEnter && setDragOverOnCollapse(false))
            : undefined
        }
        onDrop={(event) => {
          !isChildSkipStep && dispatch(onRecTestStepDrop(event, recTestStep, parentRecTestStep, index, recTestStep));
          setDragOverOnCollapse(false);
        }}
        onDragLeave={() => setDragOverOnCollapse(false)}
        id={idOfRecTestStep}
      >
        {isArrayNotUndefinedNotNullNotEmpty(childStepsOfRecTestSteps) ? (
          isSubStep(childStepsOfRecTestSteps) ? (
            childStepsOfRecTestSteps?.map((childRecTestStep, key) => {
              return (
                <RecTestStepV2
                  key={key}
                  index={key}
                  parentRecTestStep={recTestStep}
                  recTestStep={childRecTestStep}
                  parentIndex={orderIdOfRecTestStep}
                  isDeviceLoaded={isDeviceLoaded}
                  disableAction={disableAction}
                  projectId={projectId}
                  parentSkipStep={isChildSkipStep}
                  stepType={stepType}
                  stepId={stepId + "." + childRecTestStep?.orderId}
                  isTestBlock={isTestBlock}
                  isChildRecTestStep
                />
              );
            })
          ) : (
            <RecTestStepActionV2
              recTestSteps={recTestStepList}
              key={index}
              recTestStep={recTestStep}
              parentIndex={recTestStep.orderId}
              isDeviceLoaded={isDeviceLoaded}
              projectId={projectId}
              disableAction={disableAction || isPreviewRunning || isTestBlock}
              parentRecTestStep={parentRecTestStep}
              isSkipStep={isChildSkipStep}
              isTestBlock={isTestBlock}
              stepType={stepType}
              showToggle={showToggle}
              stepId={stepId}
              stepNo={stepNo}
            />
          )
        ) : (
          <>
            {!isChildSkipStep &&
              dragHoverOnStepOrAction(
                isChildDragOver,
                setIsChildDragOver,
                dispatch,
                disabled,
                recTestStep,
                recTestStep,
                0
              )}
            <div>
              {isEmptyStepDragOver
                ? dragHoverOnStepOrAction(
                    isEmptyStepDragOver,
                    setIsEmptyStepDragOver,
                    dispatch,
                    disabled,
                    recTestStep,
                    recTestStep,
                    0
                  )
                : !isChildDragOver &&
                  isStepHasPrecondition === 0 &&
                  isLoopStep === 0 &&
                  !visibleAIGenerateRecTestStep?.includes(recTestStep.id) && (
                    <div
                      onDragEnter={() => {
                        setIsEmptyStepDragOver(true);
                      }}
                      onDragLeave={() => {
                        setIsEmptyStepDragOver(false);
                      }}
                      onDrop={() => {
                        setIsEmptyStepDragOver(false);
                      }}
                    >
                      <EmptyRecords
                        description="Add a new sub-step or an Action/Verification step!"
                        ignoreMargin={true}
                      />
                    </div>
                  )}
            </div>
          </>
        )}
        {!isChildDragOver &&
          !isChildSkipStep &&
          !isEmpty(childStepsOfRecTestSteps) &&
          isSubStep(childStepsOfRecTestSteps) &&
          dragHoverOnStepOrAction(
            isChildDragOver,
            setIsChildDragOver,
            dispatch,
            disabled,
            recTestStep,
            recTestStep,
            childStepsOfRecTestSteps?.length
          )}
      </div>
    );
  };

  const handleDragEnter = useCallback(() => {
    setDragOverOnCollapse(true);
  }, [setDragOverOnCollapse]);

  const handleDragLeave = useCallback(
    (event) => {
      const newTarget = event.relatedTarget;
      const id = document.getElementById(idOfRecTestStep);
      if (id) {
        if (!id.contains(newTarget)) {
          setDragOverOnCollapse(false);
        }
      }
      if (!id || !id.contains(newTarget)) {
        setIsChildStepsDragEnter(false);
        setDragOverOnCollapse(false);
      }
    },
    [idOfRecTestStep, setIsChildStepsDragEnter, setDragOverOnCollapse]
  );

  const handleSaveRecStepName = () => {
    if (unSavedRecStepTag === recTestStep.tag) {
      if (recTestStep?.recTestStepProperty?.stepName.length >= 1) {
        dispatch(updateRecTestStepAction(recTestStep));
      } else {
        dispatch(changeRecTestStepActionName(unSavedRecTestStep["recTestStepProperty"].previous_stepName, tag));
        dispatch(resetEditRecTestStep());
      }
    }
  };

  const handleCloseAIGenerateHeader = () => {
    dispatch(resetEditRecTestStep());
    dispatch(resetElement());
    dispatch(recordingStop());
    dispatch(hideAIGenerateStepsFromRecTestStep(recTestStep?.id));
  };

  return (
    <>
      <Tooltip
        color={TOOLTIP_BG_COLOR}
        key={idOfRecTestStep}
        title={skipStepOfRecTestStep === 1 ? STEP_DISABLED : ""}
        zIndex="100"
      >
        {childStepDisabled ? (
          <div className="hoverDragAndDrop dragOverClass"></div>
        ) : (
          dragHoverOnStepOrAction(isDragOver, setIsDragOver, dispatch, disabled, recTestStep, parentRecTestStep, index)
        )}
        <div
          className={`${styles["styledStepHeader"]} ${
            (menuVisible || actionMenuVisible || generalActionMenuVisible) && !isDisableStep && "actionsMenuVisible"
          }`}
          name="recTestStepHeader"
          key={idOfRecTestStep}
          id={idOfRecTestStep}
          onDragStart={(event) => {
            if (!disabled) {
              dispatch(
                onRecTestStepDragStart(
                  event,
                  recTestStep,
                  orderIdOfRecTestStep,
                  parentRecTestStep ? parentRecTestStep : undefined
                )
              );
            }
          }}
          onDragOver={(event) =>
            !disabled
              ? (!isChildStepsDragEnter && setDragOverOnCollapse(true), dispatch(onRecTestStepDragOver(event)))
              : undefined
          }
          onDragEnd={() => setDragOverOnCollapse(false)}
          onDrop={(event) =>
            disabledStep === 0 && dragOverOnCollapse && !childStepDisabled
              ? (() => {
                  dispatch(
                    captureExpandedSteps(
                      tag,
                      recTestScenarioRecTestStepAssocId
                        ? recTestScenarioRecTestStepAssocId
                        : testBlockRecTestScenarioRecTestStepAssocId + splitValue + tag,
                      true
                    )
                  );

                  dispatch(onRecTestStepDrop(event, recTestStep, recTestStep, childStepsOfRecTestSteps?.length));
                  setDragOverOnCollapse(false);
                })()
              : undefined
          }
          onDragLeave={handleDragLeave}
        >
          <div className={styles["styledStepName"]}>
            <Col span={24}>
              <Collapse
                className={(recTestStepCollapseClassNames += isDisableStep ? " disable_bg_color" : " bg__white")}
                key={idOfRecTestStep}
                activeKey={stepType === TEST_SCENARIOS ? [...expandedStepAssocIds] : [...expandedStepTags]}
                expandIcon={() => null}
                isSkipStep={skipStepOfRecTestStep === 1 || parentSkipStep}
                isTestBlock={isTestBlock}
                onChange={(values) => {
                  if (isLoadingForAIStep === null) {
                    dispatch(
                      captureExpandedSteps(
                        tag,
                        recTestScenarioRecTestStepAssocId
                          ? recTestScenarioRecTestStepAssocId
                          : testBlockRecTestScenarioRecTestStepAssocId + splitValue + tag,
                        values.some(
                          (value) =>
                            value ==
                            (stepType === TEST_SCENARIOS
                              ? recTestScenarioRecTestStepAssocId
                                ? recTestScenarioRecTestStepAssocId
                                : testBlockRecTestScenarioRecTestStepAssocId + splitValue + tag
                              : tag)
                        )
                      )
                    );
                    dispatch(selectRecTestSteps(recTestStep));
                  }
                }}
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                onDragOver={() => {
                  setDragOverOnCollapse(true);
                }}
              >
                <Panel
                  key={
                    stepType === TEST_SCENARIOS
                      ? recTestScenarioRecTestStepAssocId
                        ? recTestScenarioRecTestStepAssocId
                        : testBlockRecTestScenarioRecTestStepAssocId + splitValue + tag
                      : tag
                  }
                  showArrow={true}
                  header={
                    <Row justify="space-between" align="middle">
                      <Col className={styles["recTestStep"]}>
                        <div className={styles["recTestStepTextInput"]}>
                          <Tooltip color={TOOLTIP_BG_COLOR} title={stepId.length > 8 && stepId}>
                            <div
                              className={`${stylesRecTestStepOrderName} ${
                                !isDisableStep ? "onHoverStepOrderNameBorder" : ""
                              } ${
                                (selectedRectTestScenarioStepId?.includes(
                                  stepType === TEST_SCENARIOS ? recTestScenarioRecTestStepAssocId : idOfRecTestStep
                                )
                                  ? "order_path_blue"
                                  : "") || (selectedStep && !isDisableStep ? "selected-step-or-action" : "")
                              } ${activeCollapse ? "border-bottom-of-order-path" : ""} `}
                              onClick={(e) => {
                                e.stopPropagation();
                                !unSavedRecStepTag &&
                                  handleMultiSelectStepId(
                                    e,
                                    stepType === TEST_SCENARIOS ? recTestScenarioRecTestStepAssocId : idOfRecTestStep
                                  );
                              }}
                              increaseWidth={parentIndex && action === STEP}
                            >
                              <span
                                className={`recTestStepName ${
                                  selectedRectTestStep ? "color__white" : "color__darkGrayColor"
                                }`}
                              >
                                {stepId?.length >= 10 ? orderIdOfRecTestStep : stepId}
                              </span>
                            </div>
                          </Tooltip>
                          <div>
                            <div
                              className={`collapseIconOnHover ${styles["collapseIcon"]} ${
                                isDisableStep ? "collapseIconBgColor" : ""
                              } ${isLoadingForAIStep !== null && "cursor__not_allowed"}`}
                            >
                              <SvgLoader
                                iconName={
                                  activeCollapse
                                    ? OPEN_COLLAPSE_ICON_FOR_PARENT_STEP
                                    : CLOSE_COLLAPSE_ICON_FOR_PARENT_STEP
                                }
                                disabled={isLoadingForAIStep !== null}
                              />
                            </div>
                          </div>
                          {isTestBlock && !parentRecTestStepId && (
                            <Tooltip
                              title="Reusable Test Blocks are not editable!"
                              overlayClassName="testBlock_In_TestScenario_Tooltip"
                              color="black"
                            >
                              <div className={styles["testBlock_In_TestScenario_Icon"]}>
                                <SvgLoader iconName={IMPORT_TB_IN_TS_ICON} />
                              </div>
                            </Tooltip>
                          )}
                          {(isStepHavePrecondition(recTestStep) || isStepHaveLoopStep(recTestStep)) && (
                            <span className={styles["conditionalIcon"]}>{getConditionalIcon(recTestStep)}</span>
                          )}
                          <Input
                            collapsible={"false"}
                            className={styles["inputStyle"]}
                            type="text"
                            placeholder="Provide a title/description"
                            value={stepNameOfRecTestStep}
                            disabled={disabled || isSkipStep}
                            onChange={(e) => {
                              dispatch(resetAllRecScenarioStepIds());
                              setRectTestStepId(idOfRecTestStep);
                              dispatch(changeRecTestStepActionName(e.target.value, tag));
                            }}
                            onClick={(e) => {
                              blockCollapseAction(e);
                              isLoadingForAIStep === null && dispatch(selectRecTestSteps(recTestStep));
                            }}
                            onDragOver={() => {
                              setDragOverOnCollapse(true);
                              isChildStepsDragEnter && setIsChildStepsDragEnter(true);
                            }}
                            onDragLeave={handleDragLeave}
                            onBlur={() => {
                              handleSaveRecStepName();
                            }}
                            onKeyDown={(event) => {
                              if (event.key === "Enter") {
                                handleSaveRecStepName();
                              }
                            }}
                          />
                          {unSavedRecStepTag === recTestStep?.tag &&
                            unSavedRecTestStep &&
                            unSavedRecTestStep["recTestStepProperty"].previous_stepName && (
                              <Spin spinning={requestingUpdateRecTestStepAction}></Spin>
                            )}
                        </div>

                        {!isDisableStep && unSavedRecStepTag === undefined && !isPreviewRunning && (
                          <div
                            onClick={(e) => {
                              e.stopPropagation();
                              isLoadingForAIStep === null && dispatch(selectRecTestSteps(recTestStep));
                            }}
                            className={!selectedStep ? "showRecTestStepActionsIconOnHover" : ""}
                          >
                            <HoverActionsOnRecTestStepV2
                              recTestStep={recTestStep}
                              showToggle={showToggle}
                              childStepDisabled={childStepDisabled}
                              parentSkipStep={parentSkipStep}
                              disabled={disabled}
                              projectId={projectId}
                              stepId={stepId}
                              stepNo={stepNo}
                              menuVisible={menuVisible}
                              setMenuVisible={setMenuVisible}
                              actionMenuVisible={actionMenuVisible}
                              setActionMenuVisible={setActionMenuVisible}
                              generalActionMenuVisible={generalActionMenuVisible}
                              setGeneralActionMenuVisible={setGeneralActionMenuVisible}
                            />
                          </div>
                        )}
                        {isLoadingForAllStepsIds?.includes(recTestStep?.id) && (
                          <Spin spinning={true} className="ai_Generate_spinner"></Spin>
                        )}
                        {(previewStatus || isDisableStep) && (
                          <div className={styles["resultIcon"]}>
                            {getRecTestStepStatusWithImage(
                              previewStatus === REC_TEST_STEP_PREVIEW_STATUS_TYPE.CONTINUE_AFTER_FAILED &&
                                preconditionResult === REC_TEST_STEP_PREVIEW_STATUS_TYPE.FAILED
                                ? REC_TEST_STEP_PREVIEW_STATUS_TYPE.PRECONDITION_FAILED
                                : previewStatus
                            )}
                            {previewStatus && <VerticalLine className={styles["borderRight"]} />}
                            {isDisableStep && (
                              <div className={styles["disabledTextOrSwitch"]}>
                                <Tooltip
                                  color={TOOLTIP_BG_COLOR}
                                  title={!childStepDisabled && (skipStepOfRecTestStep === 1 ? "Enable" : "Disable")}
                                >
                                  {disableStep(
                                    isTestBlock,
                                    unSavedRecStepTag,
                                    childStepDisabled,
                                    parentSkipStep,
                                    recTestStep,
                                    dispatch,
                                    isPreviewRunning,
                                    stepType,
                                    "disable_Switch",
                                    isLoadingForAIStep
                                  )}
                                </Tooltip>
                              </div>
                            )}
                            {stepType === TEST_SCENARIOS &&
                              isTestBlock === 1 &&
                              testBlockRecTestStepId === undefined &&
                              skipStepOfRecTestStep === 0 && (
                                <Col span={1} className={styles["buildTestDataSet"]}>
                                  <Dropdown
                                    className={`${styles["buildTestDataSetDropdown"]} ${
                                      disabled ? "cursor__not_allowed" : ""
                                    }`}
                                    overlay={testDataSetOptions}
                                    trigger={["click"]}
                                    onClick={(e) => {
                                      dispatch(resetRecTestStepAction(tag, recTestScenarioRecTestStepAssocId));
                                      blockCollapseAction(e);
                                    }}
                                  >
                                    <Tooltip title="Build & Assign Test Data Profile" color={TOOLTIP_BG_COLOR}>
                                      <SvgLoader
                                        iconName={BUILD_TEST_DATA_SET_ICON}
                                        iconColor={selectedTestDataSet === 1 ? BLUE_ICON_COLOR : DISABLE_ICON_COLOR}
                                      />
                                    </Tooltip>
                                  </Dropdown>
                                </Col>
                              )}
                          </div>
                        )}
                      </Col>
                    </Row>
                  }
                  extra={
                    !hideDragAndDropIcon && (
                      <div
                        className={`${styles["dragAndDrop"]} ${disabled ? "cursor__not_allowed" : ""}`}
                        onDragStart={(event) => {
                          if (!disabled) {
                            dispatch(
                              onRecTestStepDragStart(
                                event,
                                recTestStep,
                                orderIdOfRecTestStep,
                                parentRecTestStep ? parentRecTestStep : undefined
                              )
                            );
                          }
                        }}
                        onDragOver={(event) => {
                          if (!disabled) {
                            dispatch(onRecTestStepDragOver(event));
                          }
                        }}
                        draggable={!disabled && !disableStepsDragging(recTestStep, unSavedRecStepTag, stepType)}
                      >
                        <SvgLoader
                          iconName={STEPS_DRAG_AND_DROP_ICON}
                          width="12px"
                          height="8px"
                          cursorGrab={!isDisableDragAndDropIcon && "grab"}
                          disabled={isDisableDragAndDropIcon}
                          iconColor={isDisableDragAndDropIcon ? DISABLE_ICON_COLOR : "#4A4D53"}
                        />
                      </div>
                    )
                  }
                >
                  <div>
                    {visibleAIGenerateRecTestStep?.includes(recTestStep.id) ? (
                      <GenerateScenarioStepsHeaderV2
                        recTestStep={recTestStep}
                        handleClose={() => handleCloseAIGenerateHeader()}
                      />
                    ) : (
                      ""
                    )}
                    <div
                      className={
                        visibleAIGenerateRecTestStep?.includes(recTestStep.id) &&
                        (isStepHasPrecondition === 1 || isLoopStep === 1)
                          ? "mt-15"
                          : ""
                      }
                    >
                      <RecTestStepConditionV2
                        recTestSteps={recTestStepList}
                        isDeviceLoaded={isDeviceLoaded}
                        recTestStep={recTestStep}
                        disableAction={disableAction}
                        isSkipStep={isSkipStep}
                        isTestBlock={isTestBlock}
                        stepType={stepType}
                        projectId={projectId}
                        setDragOverOnCollapse={setDragOverOnCollapse}
                      />
                    </div>
                    {childSteps()}
                  </div>
                  <AssignTestDataSetModal
                    testDataSetAssigneeDetail={selectTestBlockStep}
                    projectId={projectId}
                    stepType={stepType}
                  />
                </Panel>
              </Collapse>
            </Col>
          </div>
        </div>
      </Tooltip>
    </>
  );
};

const generateTestDataSetOptions = (dispatch, recTestSteps, recTestStep) => {
  let menuItems = [];
  menuItems.push(
    <Menu.Item
      key={menuItems.length + 1}
      onClick={() => {
        dispatch(showTestDataSetSelectionModal(recTestStep));
        dispatch(getSelectedTestBlock(recTestSteps?.find((i) => i?.id === recTestStep?.id)));
        dispatch(
          getTestDataSetByTestPlanId(
            recTestStep?.id,
            "",
            TEST_SCENARIOS,
            recTestStep?.recTestScenarioRecTestStepAssocId
          )
        );
      }}
    >
      Assign Test Data Set
    </Menu.Item>
  );
  menuItems.push(
    <Menu.Item
      key={menuItems.length + 1}
      onClick={(e) => {
        dispatch(setTestDataSetAssigneeDetail(recTestStep));
      }}
    >
      Edit Test Data Set
    </Menu.Item>
  );
  return <Menu>{menuItems}</Menu>;
};

export default RecTestStepV2;
