import { default as React, useMemo } from "react";
import ReactJson from "react-json-view";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox, Col, Divider, Input, Popover, Row, Spin, Tooltip } from "antd";
import { ELEMENT_ACTION, STEP, SWIPE_OVER_ELEMENT, TOOLTIP_BG_COLOR, VERIFICATIONS } from "../../../../Constants";
import {
  AI_JSON_DATA_ICON_V2,
  DISABLE_ICON_COLOR,
  GENERAL_SETTINGS_ICON_V2,
  ICON_PERFORM_SAVE,
  PAGE_SOURCE_ICON_V2,
  RESET_ICON,
  SAVE_ICON,
} from "../../../../Constants/SvgConstants";
import {
  AIInputPlaceholder,
  getRecElement,
  isActionNeedToSelectElement,
  isNeedManualSelection,
  isNotNullAndNotEmptyAndNotUndefined,
  showNotification,
  validForNLP,
} from "../../../../Util";
import SvgLoader from "../../../../Util/SvgLoader";
import {
  changeExecuteBeforeAiStep,
  changeIsAIStep,
  changeUseManualElementSelection,
  updateAiStepObjective,
  visibleGeneralSettingsDrawer,
} from "../../../../actions/RecTestStep/RecTestStepRedirectAction";
import { findRecTestStepByTag } from "../../../../reducer/RecTestStepReducer";
import RecStepGeneralSetting from "../../../RecTestStep/StepInnerComponents/RecStepGeneralSetting/RecStepGeneralSetting";
import { disableStepSaveButton } from "../../../RecTestStep/ValidateStepMandatoryProperty";
import PerformRecTestStepActionV2 from "../../StepDetailsV2/PerformRecTestStepActionV2/PerformRecTestStepActionV2";
import ActionsVerificationItemsV2 from "../ActionsV2/ActionsVerificationItemsV2/ActionsVerificationItemsV2";
import EnterTextV2 from "../ActionsV2/EnterTextV2/EnterTextV2";
import TestDataOptionV2 from "../ActionsV2/EnterTextV2/TestDataOptionV2";
import LaunchAppListV2 from "../ActionsV2/LaunchAppV2/LaunchAppListV2";
import QuickActionsV2 from "../ActionsV2/QuickActionsV2/QuickActionsV2";
import SwipeElementV2 from "../ActionsV2/SwipeElementV2/SwipeElementV2";
import RecTestStepPostActionSettingsV2 from "../RecTestStepPostActionSettingsV2/RecTestStepPostActionSettingsV2";
import VerifyExistenceV2 from "../VerificationsV2/VerifyExistenceV2/VerifyExistenceV2";
import RecTestStepAIDetail from "./RecTestStepAIDetail/RecTestStepAIDetail";
import RecTestStepElementListV2 from "./RecTestStepElementListV2/RecTestStepElementListV2";
import styles from "./RecTestStepDetailV2.module.scss";

const getActionMapping = (recTestStepProperty) => {
  const mapping = {
    actions: ActionsVerificationItemsV2,
    tap: ActionsVerificationItemsV2,
    longPress: ActionsVerificationItemsV2,
    tapByIndex: ActionsVerificationItemsV2,
    tapByCoordinates: ActionsVerificationItemsV2,
    sendKeys: ActionsVerificationItemsV2,
    clearText: ActionsVerificationItemsV2,
    clear: ActionsVerificationItemsV2,
    swipeOverElement: ActionsVerificationItemsV2,
    launchApp: LaunchAppListV2,
    verifications: ActionsVerificationItemsV2,
    step: ActionsVerificationItemsV2,
    Swipe: QuickActionsV2,
    BACK: QuickActionsV2,
    HOME: QuickActionsV2,
    ENTER: QuickActionsV2,
    deviceInteraction: QuickActionsV2,
    pause: QuickActionsV2,
    insertValueToOutputTestData: QuickActionsV2,
    setAppLocale: QuickActionsV2,
    captureScreenshot: QuickActionsV2,
  };
  return mapping[recTestStepProperty?.action];
};

const RecTestStepDetailV2 = (props) => {
  const dispatch = useDispatch();
  const {
    data,
    disabled,
    projectId,
    isDeviceLoaded,
    isSkipStep,
    recTestSteps,
    isTestBlock,
    selectedRecTestStepDisabled,
    isShowPerformActionsForSpecificElements,
    isShowPerformActionsForNLP,
    stepId,
    isDisabledSpecificElements,
    isDisabledNLP,
  } = props;

  const { findElementProcessing } = useSelector((state) => state.RecordingReducer);
  const { isPreviewRunning, selectedScenarioPlatform, recTestStepList, testBlockStep } = useSelector(
    (state) => state.RecTestStepReducer
  );
  const { unSavedRecStepTag, stepType, unSavedRecElementGuid, showTestDataVisibility } = useSelector(
    (state) => state.RecTestStepCommonViewReducer
  );

  //Destructure of data object
  const { action, isStepHasPrecondition, recStepVerifyElementProperties, isLoopStep, recElements } =
    data && data?.recTestStepProperty ? data?.recTestStepProperty : {};

  const { recTestStepProperty, id, tag } = data ? data : {};

  const unSavedRecTestStep = useMemo(
    () => findRecTestStepByTag(recTestStepList, unSavedRecStepTag, testBlockStep),
    [recTestStepList, unSavedRecStepTag, testBlockStep]
  );
  const unSavedRecElement = useMemo(
    () => getRecElement(recElements, unSavedRecElementGuid),
    [recElements, unSavedRecElementGuid]
  );
  let isSameStepForEdit = useMemo(() => unSavedRecStepTag === tag, [unSavedRecStepTag, tag]);
  const saveButtonDisable = useMemo(
    () => !isSameStepForEdit || disableStepSaveButton(unSavedRecTestStep, unSavedRecElement),
    [isSameStepForEdit, unSavedRecTestStep, unSavedRecElement]
  );
  const ActionItem = useMemo(
    () => (data ? getActionMapping(recTestStepProperty) : undefined),
    [data, recTestStepProperty]
  );

  const showPostActionOrSettings = ![ELEMENT_ACTION?.INSERT_VALUE_TO_OUTPUT_TEST_DATA, ELEMENT_ACTION?.PAUSE].includes(
    action
  );

  const showTestDataContent =
    (showTestDataVisibility[data.tag] !== undefined && showTestDataVisibility[data.tag]) ||
    (data?.recTestStepProperty?.testData && data?.recTestStepProperty?.testData.id ? true : false);

  let testDataChecked =
    (showTestDataVisibility[data.tag] !== undefined && showTestDataVisibility[data.tag]) ||
    (data?.recTestStepProperty?.testData && data?.recTestStepProperty?.testData.id)
      ? true
      : false;

  const renderSvgLoader = (iconName, tooltipTitle, handleClick = null, disabled = false) => (
    <Tooltip title={tooltipTitle} color={TOOLTIP_BG_COLOR}>
      <div>
        <SvgLoader
          iconName={iconName}
          iconColor={disabled ? DISABLE_ICON_COLOR : "#4E4E4E"}
          handleClick={!disabled && handleClick}
          disabled={disabled}
        />
      </div>
    </Tooltip>
  );

  const showPerformActions = () => {
    return (
      <PerformRecTestStepActionV2
        action={action}
        data={data}
        recTestStepId={data?.id}
        recTestStepTag={data?.tag}
        disabled={
          disabled || (isNotNullAndNotEmptyAndNotUndefined(unSavedRecStepTag) && unSavedRecStepTag !== data?.tag)
        }
        isDeviceLoaded={isDeviceLoaded}
        projectId={projectId}
        isPreviewRunning={isPreviewRunning}
        saveIcon={SAVE_ICON}
        performSaveIcon={ICON_PERFORM_SAVE}
        resetIcon={RESET_ICON}
      />
    );
  };

  const showWarningMessage = () => {
    return showNotification(
      "warning",
      "At least 1 method (either NLP or Element Selection) is required for performing the Step!",
      true
    );
  };

  return (
    <>
      <div>
        <div className={styles["actionSelection"]}>
          <Col span={18}>
            <Spin spinning={id === undefined && findElementProcessing}>
              {ActionItem && (
                <ActionItem
                  recTestSteps={recTestSteps}
                  data={data}
                  disabled={
                    disabled ||
                    isSkipStep ||
                    (isNotNullAndNotEmptyAndNotUndefined(unSavedRecStepTag) && unSavedRecStepTag !== tag) ||
                    selectedRecTestStepDisabled
                  }
                  projectId={projectId}
                  isDeviceLoaded={isDeviceLoaded}
                  isSkipStep={isSkipStep}
                  isPreviewRunning={isPreviewRunning}
                  isTestBlock={isTestBlock}
                  stepType={stepType}
                  platform={selectedScenarioPlatform}
                  showPostActionOrSettings={showPostActionOrSettings}
                  stepId={stepId}
                  isShowPerformActionsForNLP={isShowPerformActionsForNLP}
                  isShowPerformActionsForSpecificElements={isShowPerformActionsForSpecificElements}
                />
              )}
            </Spin>
          </Col>
        </div>
        {validForNLP(data.recTestStepProperty.action) && (
          <>
            <div className={styles["sub_Header"]}>
              {(isStepHasPrecondition === 1 &&
                recStepVerifyElementProperties?.some((i) => i?.type === "VerifyExistence")) ||
              isLoopStep === 1
                ? "Check Condition Using:"
                : "Perform Using:"}
            </div>

            <div
              className={`${styles["AI_Step_Container"]} ${
                action === SWIPE_OVER_ELEMENT &&
                data.recTestStepProperty.useManualElementSelection === 1 &&
                styles["AI_Margin_Top"]
              }`}
            >
              <div>
                <Row justify="space-between" className={styles["AI_Header_Padding"]}>
                  <div>
                    <Checkbox
                      disabled={isDisabledNLP}
                      checked={data?.recTestStepProperty?.isAIStep}
                      onChange={(e) => {
                        if (data.recTestStepProperty.useManualElementSelection) {
                          dispatch(changeIsAIStep(e.target.checked, data));
                        } else {
                          showWarningMessage();
                        }
                      }}
                      className="selection-checkBox"
                    >
                      <span className={styles["checkbox-Text"]}>NLP</span>
                    </Checkbox>
                  </div>
                  <Col className={styles["AI_Generated_Step_Icons"]}>
                    {isShowPerformActionsForNLP && showPerformActions()}
                    {data.recTestStepProperty.isAIStep === 1 && data.recTestStepProperty.aiGeneratedStep && (
                      <div className={styles["actionIcon"]}>
                        <Popover
                          overlayStyle={{ width: "60%" }}
                          content={
                            <div className={styles["json_Data"]}>
                              <ReactJson src={JSON.parse(data?.recTestStepProperty?.aiGeneratedStep)} />
                            </div>
                          }
                          title="AI Generated Step"
                          placement="bottom"
                          showArrow={false}
                          trigger="click"
                        >
                          {renderSvgLoader(AI_JSON_DATA_ICON_V2, "JSON Data", null)}
                        </Popover>
                      </div>
                    )}

                    {data?.recTestStepProperty?.aiGeneratedPagesource &&
                      renderSvgLoader(
                        PAGE_SOURCE_ICON_V2,
                        "Page Source",
                        () => window.open(data?.recTestStepProperty?.aiGeneratedPagesource, "_blank"),
                        isDisabledNLP
                      )}

                    {renderSvgLoader(
                      GENERAL_SETTINGS_ICON_V2,
                      "Settings",
                      () => dispatch(visibleGeneralSettingsDrawer(true, data.recTestStepProperty?.action, data?.tag)),
                      isDisabledNLP
                    )}
                  </Col>
                </Row>
                {data.recTestStepProperty.isAIStep == 1 && <Divider className={styles["AI_Steps_Divider"]} />}
                <div className={styles["AI_Step_Details"]}>
                  {data.recTestStepProperty.isAIStep == 1 ? (
                    <Row className={styles["AI_Step_Objective_And_Image"]}>
                      <Col span={15}>
                        <span className={styles["checkbox-Text"]}>Step Objective:</span>
                        <Input
                          className={styles["AI_Objective_Input"]}
                          type="text"
                          autoFocus
                          disabled={isDisabledNLP}
                          value={data.recTestStepProperty?.aiStepObjective}
                          onChange={(e) => {
                            dispatch(updateAiStepObjective(e.target.value, data?.tag));
                          }}
                          placeholder={AIInputPlaceholder(action)}
                        />
                      </Col>
                      <Col>
                        {data.recTestStepProperty.aiGeneratedScreenshot && (
                          <Popover
                            content={
                              <img
                                src={data.recTestStepProperty.aiGeneratedScreenshot}
                                height={450}
                                weight={225}
                                alt="Screenshot"
                              />
                            }
                            placement="left"
                            title="Element Image"
                            trigger="hover"
                          >
                            <img
                              className={styles["AI_Element_Image"]}
                              src={data.recTestStepProperty.aiGeneratedScreenshot}
                              height={50}
                              weight={50}
                              alt="Screenshot"
                            />
                          </Popover>
                        )}
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                  <Row>
                    <Col span="15">
                      {data.recTestStepProperty.isAIStep == 1 ? (
                        <>
                          <RecTestStepAIDetail
                            {...props}
                            saveButtonDisable={saveButtonDisable}
                            isDisabledNLP={isDisabledNLP}
                          />
                        </>
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </>
        )}
        <>
          {isNeedManualSelection(data?.recTestStepProperty) && (
            <div className={styles["manual_Element_Selection_Container"]}>
              <Row justify="space-between">
                <Col>
                  <Checkbox
                    disabled={isDisabledSpecificElements}
                    checked={data.recTestStepProperty.useManualElementSelection}
                    onChange={(e) => {
                      if (data.recTestStepProperty.isAIStep) {
                        dispatch(changeUseManualElementSelection(e.target.checked, data?.tag));
                      } else {
                        showWarningMessage();
                      }
                    }}
                    className="selection-checkBox"
                  >
                    <span className={styles["checkbox-Text"]}>Specific Elements</span>
                  </Checkbox>
                  {data.recTestStepProperty.useManualElementSelection == 1 && (
                    <>
                      <Checkbox
                        disabled={isDisabledSpecificElements || data.recTestStepProperty.isAIStep == 0}
                        checked={data.recTestStepProperty.executeBeforeAiStep}
                        onChange={(e) => {
                          dispatch(changeExecuteBeforeAiStep(e.target.checked, data?.tag));
                        }}
                        className="selection-checkBox"
                      >
                        <span className={styles["checkbox-Text"]}>Execute before AI</span>
                      </Checkbox>
                    </>
                  )}
                </Col>
                <Col>{isShowPerformActionsForSpecificElements && showPerformActions()}</Col>
              </Row>
              {(isActionNeedToSelectElement(action) ||
                (action === STEP &&
                  ((isStepHasPrecondition === 1 &&
                    recStepVerifyElementProperties?.some((i) => i?.type === "VerifyExistence")) ||
                    isLoopStep === 1))) && (
                <>
                  {action === ELEMENT_ACTION.SEND_KEYS && data.recTestStepProperty.useManualElementSelection == 1 && (
                    <>
                      <div className={styles["action_Details"]}>
                        <EnterTextV2
                          {...props}
                          showTestDataContent={showTestDataContent}
                          testDataChecked={testDataChecked}
                          disabled={isDisabledSpecificElements}
                        />
                        <TestDataOptionV2
                          data={data}
                          projectId={projectId}
                          showTestDataContent={showTestDataContent}
                          testDataChecked={testDataChecked}
                          disabled={isDisabledSpecificElements}
                        />
                      </div>
                    </>
                  )}
                  {action === SWIPE_OVER_ELEMENT && data.recTestStepProperty.useManualElementSelection === 1 ? (
                    <div className={styles["swipe_Element"]}>
                      <SwipeElementV2 {...props} disabled={disabled || isDisabledSpecificElements} />
                    </div>
                  ) : (
                    ""
                  )}
                  {data.recTestStepProperty.useManualElementSelection == 1 && (
                    <>
                      <div className={styles["selectorOrSettings"]}>Element Selector</div>
                      {(action === VERIFICATIONS ||
                        (action === STEP &&
                          ((isStepHasPrecondition === 1 &&
                            recStepVerifyElementProperties?.some((i) => i?.type === "VerifyExistence")) ||
                            isLoopStep === 1))) && (
                        <VerifyExistenceV2
                          disabled={isDisabledSpecificElements}
                          recTestStep={data}
                          recTestSteps={props.recTestSteps}
                          showPostActionOrSettings={showPostActionOrSettings}
                        />
                      )}

                      <RecTestStepElementListV2
                        {...props}
                        saveButtonDisable={saveButtonDisable}
                        isDisabledSpecificElements={isDisabledSpecificElements}
                      />
                    </>
                  )}
                </>
              )}
              {action === ELEMENT_ACTION.SWIPE && data.recTestStepProperty.useManualElementSelection == 1 && (
                <>
                  <div className={styles["selectorOrSettings"]}>Scroll Settings</div>
                  <SwipeElementV2 {...props} />
                </>
              )}
            </div>
          )}
        </>

        {action !== ELEMENT_ACTION.PAUSE && (
          <RecTestStepPostActionSettingsV2
            data={props.data}
            disabled={
              disabled ||
              isPreviewRunning ||
              isSkipStep ||
              isShowPerformActionsForSpecificElements ||
              isShowPerformActionsForNLP
            }
            projectId={projectId}
            isSkipStep={isSkipStep}
          />
        )}
      </div>
      <RecStepGeneralSetting
        data={data}
        disabled={disabled}
        disableMaxNoOfSwipes={disabled || isSkipStep || isPreviewRunning}
        isPreviewRunning={isPreviewRunning}
        isSkipStep={isSkipStep}
        saveButtonDisable={saveButtonDisable}
      />
    </>
  );
};

export default RecTestStepDetailV2;
