import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "antd";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faStop } from "@fortawesome/free-solid-svg-icons";
import { CONFIRM_MODAL_CONTENT, GENERATE_AI_STEPS_CONFIRM_MODEL_TEXT, TOOLTIP_BG_COLOR } from "../../../Constants";
import * as actions from "../../../actions/HomeAction";
import { resetEditRecTestStep } from "../../../actions/RecTestStep/RecTestStepCommonAction";
import {
  deleteRecTestStep,
  resetRecTestStepAction,
  showDeleteRecTestStepConfirmationModal,
  removeLoopConditionFromRecTestStep,
  stopGenerateStepsFromAIModal,
} from "../../../actions/RecTestStep/RecTestStepRedirectAction";
import * as recordingActions from "../../../actions/Recording";
import history from "../../../history";
import SvgLoader from "../../../Util/SvgLoader";
import { CLOSE_SESSION_ICON } from "../../../Constants/SvgConstants";
import CommonConfirmationModalV2 from "../../CommonComponents/CommonConfirmationModalV2/CommonConfirmationModalV2";
library.add(faStop);

const CloseSession = (props) => {
  const dispatch = useDispatch();
  const {
    isCloseDevice,
    isPreviewRunning,
    requestingForPreview,
    selectedRecStepForOperation,
    selectedDeviceByUdid,
    selectedRecTestScenarioId,
  } = useSelector((state) => state.RecTestStepReducer);
  const { unSavedRecStepTag, isLoadingForAIStep, scenarioGenerateInstructions, selectedModelType } = useSelector(
    (state) => state.RecTestStepCommonViewReducer
  );

  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  useEffect(() => {
    if (isCloseDevice) {
      props.handleReleaseDevice();
    }
  }, [props.projectId]);

  //WillMount
  useEffect(() => {
    let _isMounted = true;
    window.onpopstate = (event) => {
      if (_isMounted) {
        if (props.tipmessage == "Back To Home") {
          var isConfirm = dispatch(actions.forceStopLogs(props.serverId, props.targetUniqueId));
          if (!isConfirm) {
            history.push("/farms/" + (props.deviceFarmId ? props.deviceFarmId : ""));
          }
        }
      }
    };
  }, []);

  const handleConfirm = () => {
    props.handleReleaseDevice();
    if (unSavedRecStepTag) {
      dispatch(recordingActions.resetElement());
      if (selectedRecStepForOperation?.recTestStepProperty?.isLoopStep === 1) {
        dispatch(removeLoopConditionFromRecTestStep());
      }
      dispatch(resetRecTestStepAction(unSavedRecStepTag));
      dispatch(resetEditRecTestStep());
      if (selectedRecStepForOperation?.project) {
        dispatch(showDeleteRecTestStepConfirmationModal(undefined, unSavedRecStepTag));
        dispatch(deleteRecTestStep());
      }
    }
    if (isLoadingForAIStep !== null) {
      dispatch(
        stopGenerateStepsFromAIModal({
          objective: "",
          rules: scenarioGenerateInstructions,
          deviceId: selectedDeviceByUdid?.targetUniqueId,
          recTestScenarioId: selectedRecTestScenarioId,
          modelType: selectedModelType,
        })
      );
    }
  };
  const closeSession = () => {
    if (unSavedRecStepTag || isPreviewRunning || isLoadingForAIStep !== null) {
      setOpenConfirmModal(true);
    } else {
      props.handleReleaseDevice();
    }
  };

  const isDisableCloseSessionIcon = requestingForPreview.length > 0 && !isPreviewRunning;

  return (
    <React.Fragment>
      <Tooltip title="Release Device" color={TOOLTIP_BG_COLOR}>
        <div>
          <SvgLoader
            iconName={CLOSE_SESSION_ICON}
            iconColor={isDisableCloseSessionIcon ? "#D3D3D3" : "#FF188F"}
            handleClick={!isDisableCloseSessionIcon && closeSession}
            width="2.875rem"
            height="4rem"
            disabled={isDisableCloseSessionIcon}
          />
        </div>
      </Tooltip>
      <CommonConfirmationModalV2
        handleOk={() => {
          handleConfirm();
          setOpenConfirmModal(false);
        }}
        handleCancel={() => setOpenConfirmModal(false)}
        modalOpen={openConfirmModal}
        modalContent={
          isPreviewRunning
            ? "Are you sure you want to release the Device? Preview will be stopped!"
            : isLoadingForAIStep !== null
            ? GENERATE_AI_STEPS_CONFIRM_MODEL_TEXT
            : CONFIRM_MODAL_CONTENT
        }
        modalTitle={
          isPreviewRunning ? "Stop Preview" : isLoadingForAIStep !== null ? "Stop AI Step Generation" : "Discard Changes"
        }
        okText={"Confirm"}
        isDeleteModal={true}
      />
    </React.Fragment>
  );
};
export default CloseSession;
