import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Checkbox, Input, Spin, Tooltip } from "antd";
import { recordingStart, recordingStop, resetElement } from "../../../../../actions/Recording";
import { editRecTestStep, resetEditRecTestStep } from "../../../../../actions/RecTestStep/RecTestStepCommonAction";
import {
  changeScenarioGenerateStepType,
  changeScenarioObjective,
  deviceInteractionSelectMode,
  fetchGenerateStepStatus,
  generateScenarioSteps,
  showGenerateStepsFromAIHeader,
  stopGenerateStepsFromAIModal,
} from "../../../../../actions/RecTestStep/RecTestStepRedirectAction";
import { selectRecTestSteps } from "../../../../../actions/RecTestStepAction";
import { CLOSE_ICON_GRAY } from "../../../../../CdnImagePath";
import {
  DEVICE_INTERACTION_MODE,
  GENERATE_AI_STEPS_CONFIRM_MODEL_TEXT,
  IOS,
  STEP,
  TOOLTIP_BG_COLOR,
} from "../../../../../Constants";
import { checkNotUndefinedAndNullAndBlank } from "../../../../../Util";
import CommonConfirmationModalV2 from "../../../../CommonComponents/CommonConfirmationModalV2/CommonConfirmationModalV2";
import styles from "./GenerateScenarioStepsModal.module.scss";

const GenerateScenarioStepsHeaderV2 = ({ recTestStep, handleClose }) => {
  const dispatch = useDispatch();
  const {
    scenarioGenerateInstructions,
    selectedModelType,
    isLoadingForAIStep,
    unSavedRecStepTag,
    isDeviceInteractionSelectMode,
  } = useSelector((state) => state.RecTestStepCommonViewReducer);
  const { selectedDeviceByUdid, isPreviewRunning, selectedRecTestScenarioId } = useSelector(
    (state) => state.RecTestStepReducer
  );
  const { platform } = useSelector((state) => state.ProjectsReducer);
  const { selectedElement, hoveredElement, deviceInteractionMode, startDeviceStreamingStatus } = useSelector(
    (state) => state.RecordingReducer
  );

  const [inputValue, setInputValue] = useState("");
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [checked, setChecked] = useState(false);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    dispatch(changeScenarioObjective(value, recTestStep?.id));
    dispatch(recordingStop());
  };

  useEffect(() => {
    if (isLoadingForAIStep === recTestStep?.id) {
      const intervalId = setInterval(() => {
        dispatch(
          fetchGenerateStepStatus(
            {
              objective: inputValue,
              rules: scenarioGenerateInstructions,
              deviceId: selectedDeviceByUdid?.targetUniqueId,
              modelType: selectedModelType,
            },
            recTestStep
          )
        );
      }, 4000);
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [isLoadingForAIStep, recTestStep]);

  const handleStop = () => {
    dispatch(
      stopGenerateStepsFromAIModal({
        objective: inputValue,
        rules: scenarioGenerateInstructions,
        deviceId: selectedDeviceByUdid?.targetUniqueId,
        recTestScenarioId: selectedRecTestScenarioId,
        modelType: selectedModelType,
      })
    );
    setInputValue("");
    setOpenConfirmModal(false);
  };

  const handleClick = () => {
    if (!isPreviewRunning) {
      if (isLoadingForAIStep === recTestStep?.id) {
        setOpenConfirmModal(true);
      } else {
        handleClose();
      }
    }
  };

  const handleStart = () => {
    dispatch(selectRecTestSteps(recTestStep));
    dispatch(recordingStop());
    dispatch(showGenerateStepsFromAIHeader(STEP, recTestStep));
    dispatch(
      generateScenarioSteps(
        {
          objective: inputValue,
          rules: scenarioGenerateInstructions,
          deviceId: selectedDeviceByUdid?.targetUniqueId,
          modelType: selectedModelType,
        },
        recTestStep
      )
    );
  };

  const disableInspectorButton =
    !selectedDeviceByUdid ||
    !startDeviceStreamingStatus ||
    isPreviewRunning ||
    isLoadingForAIStep !== null ||
    (unSavedRecStepTag && unSavedRecStepTag !== recTestStep?.tag);

  const isDisabledInput =
    isLoadingForAIStep !== null || (unSavedRecStepTag && unSavedRecStepTag !== recTestStep?.tag) || isPreviewRunning;

  const getHoveredOrSelectedElementText = (hoveredOrSelectedElement) => {
    let text = "";
    if (hoveredOrSelectedElement?.attributes) {
      if (checkNotUndefinedAndNullAndBlank(hoveredOrSelectedElement.attributes.text)) {
        text = hoveredOrSelectedElement.attributes.text;
      } else if (checkNotUndefinedAndNullAndBlank(hoveredOrSelectedElement.attributes["content-desc"])) {
        text = hoveredOrSelectedElement.attributes["content-desc"];
      } else if (platform === IOS && checkNotUndefinedAndNullAndBlank(hoveredOrSelectedElement.attributes["name"])) {
        text = hoveredOrSelectedElement.attributes["name"];
      }
    }
    return text;
  };

  const onHoverElementAttribute = getHoveredOrSelectedElementText(hoveredElement);
  const onSelectedElementAttribute = getHoveredOrSelectedElementText(selectedElement);

  const handleChecked = (e) => {
    setChecked(e.target.checked);
    dispatch(changeScenarioGenerateStepType(e.target.checked, recTestStep?.id));
  };

  return (
    <div className={styles["AI_Generate_Header"]}>
      <div className={styles["padding"]}>
        <div className={styles["AI_Generate_Header_Details"]}>
          <div>
            <div>Generate New Steps with AI</div>
          </div>
          <div className={styles["close_Icon"]}>
            <img
              src={CLOSE_ICON_GRAY}
              alt="close"
              className={isPreviewRunning ? "cursor__not_allowed" : "cursor__pointer"}
              onClick={handleClick}
            />
          </div>
        </div>
        <div className={styles["input_Or_CheckBox"]}>
          <Input
            className={styles["AI_Objective_Input"]}
            placeholder="Test Objective:"
            onChange={handleInputChange}
            value={inputValue}
            autoFocus
            disabled={isDisabledInput}
            onFocus={() => dispatch(selectRecTestSteps(recTestStep))}
          />
          {isLoadingForAIStep === recTestStep?.id ? (
            <Button
              type="primary"
              size="large"
              className={styles["generate_Button"]}
              onClick={() => setOpenConfirmModal(true)}
            >
              <div className={styles["stop_Generate_Step"]}>
                <Spin className="stop_Ai_Generate_spinner" />
                <span>Stop Generating</span>
              </div>
            </Button>
          ) : (
            <Button
              className={styles["generate_Button"]}
              disabled={!selectedDeviceByUdid || !inputValue || isPreviewRunning || !startDeviceStreamingStatus}
              onClick={handleStart}
            >
              Generate
            </Button>
          )}
        </div>
        <div className={styles["input_Or_CheckBox"]}>
          <Checkbox
            disabled={isLoadingForAIStep}
            className="selection-checkBox AI_Selection_Checkbox"
            checked={checked}
            onChange={(e) => handleChecked(e)}
          >
            <span className={styles["radio_Or_Checkbox__Text"]}> Remove existing steps</span>
          </Checkbox>
          {deviceInteractionMode === DEVICE_INTERACTION_MODE.SELECT && isDeviceInteractionSelectMode ? (
            <div>
              <Button
                type="primary"
                size="large"
                className={styles["inspector_Button"]}
                disabled={disableInspectorButton}
                onClick={() => {
                  dispatch(resetEditRecTestStep());
                  dispatch(resetElement());
                  dispatch(recordingStop());
                  dispatch(deviceInteractionSelectMode());
                }}
              >
                Stop Inspecting
              </Button>
            </div>
          ) : (
            <Tooltip
              title="Hover over the Elements on the Device to view their suggested text. Using this text in the Test Objective helps improve accuracy of the Step generation."
              color={TOOLTIP_BG_COLOR}
            >
              <div>
                <Button
                  type="primary"
                  size="large"
                  className={styles["inspector_Button"]}
                  disabled={disableInspectorButton}
                  onClick={() => {
                    dispatch(recordingStart());
                    dispatch(editRecTestStep(recTestStep?.tag));
                    dispatch(selectRecTestSteps(recTestStep));
                    dispatch(deviceInteractionSelectMode());
                  }}
                >
                  Inspector
                </Button>
              </div>
            </Tooltip>
          )}
        </div>
        {(onSelectedElementAttribute || onHoverElementAttribute) && unSavedRecStepTag === recTestStep?.tag && (
          <div>
            Suggested: <span className={"ml-5"}></span>
            {onSelectedElementAttribute ? onSelectedElementAttribute : onHoverElementAttribute}
          </div>
        )}
        <CommonConfirmationModalV2
          handleOk={handleStop}
          handleCancel={() => setOpenConfirmModal(false)}
          modalOpen={openConfirmModal}
          modalContent={GENERATE_AI_STEPS_CONFIRM_MODEL_TEXT}
          modalTitle="Stop AI Step Generation"
          okText="Abort"
          isDeleteModal
        />
      </div>
    </div>
  );
};

export default GenerateScenarioStepsHeaderV2;
