import moment from "moment";
import { async } from "../api/automation";
import { BuildJobModal } from "../Components/ExecutionManager/JobDesigner/BuildJobModal";
import { ScheduleJobModal } from "../Components/ExecutionManager/JobDesigner/ScheduleJobModal";
import {
  ALL_JOBS,
  ANDROID,
  APP_MANAGER,
  BUILD_URL,
  BUILD_VERSION_LIST,
  CHANGE_BUILD_JOB_LIST_FILTER,
  CHANGE_EXECUTION_MANAGER_TAB,
  CHANGE_EXTERNAL_FARM_DEVICE_LIST_PAGE_NUMBER,
  CHANGE_SELECTED_TEST_JOB_CRITERIA,
  CHANGE_TEST_JOB_DATA,
  CHANGE_TEST_JOB_SCHEDULE_DATA,
  CLOSE_TEST_JOB_SCHEDULE_DRAWER,
  CREATE_BUILD_JOB,
  CURL_FETCH_FOR_JOB_LOADING,
  dateHourMinuteFormat,
  DEFAULT_PAGE,
  DEVICE_EXECUTION_FARM,
  EDIT_BUILD_JOB,
  ERROR_TYPE,
  EXTERNAL_FARM_DEVICE_LOADING,
  FETCHED_CURL_FOR_JOB,
  GET_BUILD_VERSIONS_LOADER,
  GET_BUILDS_VERSIONS,
  GET_EXTERNAL_FARM_DEVICE,
  GET_TEST_JOB,
  GET_TEST_JOB_QUEUE_LIST,
  GET_TEST_JOB_SCHEDULE,
  GET_TIMEZONE,
  JOB_DESIGNER,
  LAMBDA_TEST,
  LOADING_JOB,
  ONETIME,
  OPEN_TEST_JOB_SCHEDULE_DRAWER,
  RECORD_10_PER_PAGE,
  RECURRING,
  REMOVE_TEST_JOB,
  REPORT_MANAGER,
  RESET_GET_TEST_JOB_BY_ID_DATA,
  RESET_GET_TEST_JOB_SCHEDULE_DATA,
  RUN_RESULT_LOADING,
  SAUCELABS,
  SAVE_TEST_JOB,
  SCHEDULE_TEST_JOB,
  SEARCH_EXTERNAL_FARM_DEVICE_LIST,
  SUCCESS_STATUS,
  SUCCESS_TYPE,
  TEST_RAIL_SUITES_BY_TEST_PLAN_ID,
  UPDATE_TEST_JOB_ERROR_DETAIL,
  VISIBLE_CREATE_BUILD_MODAL,
} from "../Constants";
import {
  checkListIsEmpty,
  checkNotUndefined,
  checkNotUndefinedAndNull,
  checkNull,
  checkUndefined,
  getDateTimeInUTCFormat,
  isTrim,
  showNotification,
  updateResponse,
} from "../Util";
import { setScheduleTestPlan } from "./TestPlanAction";

export function getTestJob(pageNumber, projectId, searchKeyword, searchByFrequency, rights) {
  return async (dispatch, getState) => {
    let { testJobListFilter } = getState().TestJobReducer;
    dispatch({ type: LOADING_JOB, response: { isLoading: true } });
    return async("testJob/V2/getByProjectId", "GET", {
      projectId: projectId,
      pageNumber: pageNumber || 1,
      recordPerPage: RECORD_10_PER_PAGE,
      searchKeywords: searchKeyword,
      filter: searchByFrequency,
      status: "",
      rights: rights ? rights?.id : testJobListFilter?.id,
    }).then((response) => {
      response = updateResponse(response);
      dispatch({ type: GET_TEST_JOB, response, pageNumber });
      dispatch({ type: LOADING_JOB, response: { isLoading: false } });
    });
  };
}

export function fetchExecutionTestJobDetail(data) {
  return async (dispatch, getState) => {
    dispatch({ type: LOADING_JOB, response: { isLoading: true } });
    return async("testJob/getByStatus", "GET", {
      projectId: data.projectId,
      pageNumber: data.pageNumber || 1,
      recordPerPage: data.recordPerPage,
      // searchKeywords: data.searchKeywords,
      status: data.status,
    }).then((response) => {
      response = updateResponse(response);
      dispatch({ type: GET_TEST_JOB_QUEUE_LIST, response, data });
      dispatch({ type: LOADING_JOB, response: { isLoading: false } });
    });
  };
}

export function getTestJobSchedule(data) {
  return async (dispatch, getState) => {
    dispatch({ type: LOADING_JOB, response: { isLoading: true } });
    return async("testJobScheduler/getAll", "GET", {
      projectId: data.projectId,
      pageNumber: data.pageNumber || 1,
      recordPerPage: data.recordPerPage,
      searchKeywords: data.searchKeywords,
      filter: data.filter,
      status: data.status,
    }).then((response) => {
      response = updateResponse(response);
      dispatch({ type: GET_TEST_JOB_SCHEDULE, response, data });
      dispatch({ type: LOADING_JOB, response: { isLoading: false } });
    });
  };
}

export function cloneTestJob(testJobId, projectId, rights) {
  return async (dispatch) => {
    dispatch({ type: LOADING_JOB, response: { isLoading: true } });
    return async("testJob/clone", "POST", { id: testJobId }).then((response) => {
      response = updateResponse(response);
      dispatch(getTestJob(1, projectId, "", "", rights));
      dispatch({ type: LOADING_JOB, response: { isLoading: false } });
    });
  };
}
export function getTestJobByJobId(jobData, isForEditSchedule) {
  return async (dispatch, getState) => {
    return async("testJob/V2/getById", "GET", jobData).then((response) => {
      response = updateResponse(response);
      if (isForEditSchedule) {
        dispatch({ type: CHANGE_TEST_JOB_SCHEDULE_DATA, data: new ScheduleJobModal(response.data) });
      } else {
        let buildJobData = new BuildJobModal(response.data);
        dispatch({
          type: CHANGE_TEST_JOB_DATA,
          data: buildJobData,
        });
      }
    });
  };
}
export function closeTestJobScheduleDrawer() {
  return async (dispatch, getState) => {
    dispatch({
      type: CLOSE_TEST_JOB_SCHEDULE_DRAWER,
    });
    dispatch(resetTestScheduleJobData());
  };
}

export function openTestJobScheduleDrawer(testJobId) {
  return async (dispatch, getState) => {
    dispatch({
      type: OPEN_TEST_JOB_SCHEDULE_DRAWER,
    });
    dispatch(getTestJobByJobId({ testJobId }, true));
  };
}

export function changeBuildJobListFilter(value, projectId, searchKeyword) {
  return async (dispatch, getState) => {
    dispatch({ type: CHANGE_BUILD_JOB_LIST_FILTER, value });
    dispatch(getTestJob(1, projectId, searchKeyword, "", value));
  };
}
export function saveTestJob(projectId, rights, allDeviceIncludesInDeviceList, buildVersionList) {
  return async (dispatch, getState) => {
    dispatch({ type: LOADING_JOB, response: { isLoading: true } });
    let { testJobDetail, externalFarmDeviceList } = getState().TestJobReducer;
    let { getSearchDeviceList } = getState().ManageFarmReducer;
    const getDeviceList =
      {
        [DEVICE_EXECUTION_FARM.LAMBDA_TEST]: externalFarmDeviceList,
        [DEVICE_EXECUTION_FARM.SAUCELABS]: externalFarmDeviceList,
      }[testJobDetail.deviceServiceFarm] || getSearchDeviceList;
    const deviceList =
      getDeviceList
        ?.filter((deviceData) => {
          const deviceIdentifier = deviceData?.device?.targetUniqueId || deviceData?.id;

          return testJobDetail?.devices?.includes(deviceIdentifier) && deviceIdentifier;
        })
        ?.map((filteredData) =>
          testJobDetail.deviceServiceFarm === DEVICE_EXECUTION_FARM.XPRESS
            ? filteredData?.miscDetails?.deviceDetail
            : filteredData?.device || filteredData
        ) || [];
    const lambdaTestDevice = testJobDetail?.devices.map((targetUniqueId) => {
      const lambdaTestDeviceName = deviceList.find((device) => device.targetUniqueId === targetUniqueId);
      return lambdaTestDeviceName ? lambdaTestDeviceName.deviceName : null;
    });
    const localeFromDevice = deviceList?.map((i) => i?.locale)?.filter((item) => item);
    testJobDetail = {
      ...testJobDetail,
      devices:
        testJobDetail.deviceServiceFarm === DEVICE_EXECUTION_FARM.LAMBDA_TEST
          ? lambdaTestDevice?.join()
          : allDeviceIncludesInDeviceList
          ? testJobDetail.devices.join()
          : deviceList
              ?.filter(
                (id) => !getSearchDeviceList?.some((item) => item?.miscDetails?.deviceDetail?.targetUniqueId === id)
              )
              ?.map((i) => i?.targetUniqueId)
              ?.join(","),
      deviceOs: deviceList?.map((i) => i?.osVer || i?.platformVersion)?.join(),
      deviceBrands: deviceList?.map((i) => i?.brandName)?.join(),
      deviceNames: deviceList
        ?.map((i) => (i?.deviceName ? i?.deviceName : i?.displayName ? i?.displayName : i?.name))
        ?.join(),
      locale: (localeFromDevice?.length && localeFromDevice?.join()) || "Unknown",
      testRailTestSuiteIds: testJobDetail.testRailTestSuiteIds !== null ? testJobDetail.testRailTestSuiteIds : [],
      project: { id: projectId },
      email: testJobDetail.email.join(),
      testDataSet: checkUndefined(testJobDetail.testDataSet.id) ? null : testJobDetail.testDataSet,
      testPlan: testJobDetail.testPlan && testJobDetail.testPlan.id !== 0 ? testJobDetail.testPlan : null,
      isRemoveExisting: testJobDetail?.unInstallBUildBeforeInstall,
      isUseLatestBuild: testJobDetail?.isUseLatestBuild,
    };
    if (testJobDetail.fileUrl) {
      testJobDetail.fileUrl = testJobDetail.fileUrl === "https://" ? null : testJobDetail.fileUrl;
    }
    if (testJobDetail?.selectedBuild === APP_MANAGER) {
      testJobDetail = {
        ...testJobDetail,
        fileUrl: undefined,
        appActivity: null,
        appBuild:
          testJobDetail?.buildVersion === "Latest" || testJobDetail?.buildDetail?.buildVersion === "Latest"
            ? {
                buildType: testJobDetail?.buildDetail?.buildType
                  ? testJobDetail?.buildDetail?.buildType
                  : testJobDetail?.buildType,
              }
            : {
                uuid: buildVersionList?.find((i) => i?.buildVersion === testJobDetail?.buildDetail?.buildVersion)?.uuid,
              },
        isUseLatestBuild:
          testJobDetail?.buildVersion === "Latest" || testJobDetail?.buildDetail?.buildVersion === "Latest" ? 1 : 0,
        buildDetail: undefined,
        buildType: null,
        buildVersion: undefined,
      };
    } else {
      testJobDetail = {
        ...testJobDetail,
        buildDetail: undefined,
        buildVersion: undefined,
        isUseLatestBuild: 0,
        buildType: null,
      };
    }
    return async("testJob/V2/customSave", "POST", testJobDetail).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        showNotification(SUCCESS_TYPE, response.message);
        dispatch({ type: SAVE_TEST_JOB, response });
        dispatch(changeBuildJobListFilter(rights, projectId, ""));
        dispatch({ type: RESET_GET_TEST_JOB_BY_ID_DATA });
        dispatch(setScheduleTestPlan(null));
        dispatch(ediBuildJob(false));
      } else {
        showNotification(ERROR_TYPE, response.message);
      }
      dispatch({ type: LOADING_JOB, response: { isLoading: false } });
      dispatch(changeExecutionManagerTabAndSubTab(ALL_JOBS));
    });
  };
}
export function getTimeZone() {
  return async (dispatch, getState) => {
    return async("testJob/getAllTimeZones", "GET").then((response) => {
      response = updateResponse(response);
      dispatch({ type: GET_TIMEZONE, response });
    });
  };
}

export function scheduleNow(data, changeMenuOptionCallBack) {
  return async (dispatch, getState) => {
    data = {
      ...data,
    };
    dispatch({ type: RUN_RESULT_LOADING, response: { isLoading: true } });
    dispatch({ type: LOADING_JOB, response: { isLoading: true } });
    return async("testJob/V2/schedule", "POST", data).then((response) => {
      response = updateResponse(response);
      dispatch({ type: LOADING_JOB, response: { isLoading: false } });
      if (response.status === SUCCESS_STATUS) {
        showNotification(SUCCESS_TYPE, response.message);
        //TODO: change Redirect to Report Manager
        changeMenuOptionCallBack(REPORT_MANAGER);
        // dispatch(changeExecutionManagerTabAndSubTab(IN_QUEUE_JOB));
        dispatch(getTestJobByJobId({ testJobId: data.testJobId }, true));
        let testJobScheduleDetail = new ScheduleJobModal({ testJobId: data.testJobId, isScheduledJob: 1 });
        dispatch(changeTestJobScheduleData(testJobScheduleDetail));
      } else {
        showNotification(ERROR_TYPE, response.message);
      }
      dispatch({ type: RUN_RESULT_LOADING, response: { isLoading: false } });
    });
  };
}

export function getScheduleJobCurl(data, changeMenuOptionCallBack) {
  return async (dispatch, getState) => {
    dispatch({ type: CURL_FETCH_FOR_JOB_LOADING, response: { isLoading: true } });
    return async("testJob/getCurlForScheduleNow", "GET", data).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        dispatch({ type: FETCHED_CURL_FOR_JOB, response });
      } else {
        showNotification(ERROR_TYPE, response.message);
      }
      dispatch({ type: CURL_FETCH_FOR_JOB_LOADING, response: { isLoading: false } });
    });
  };
}

export function removeJob(data, projectId, setSelectAllTestJob, rights, searchKeyword) {
  return async (dispatch, getState) => {
    return async("testJob/delete", "POST", data).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        setSelectAllTestJob([]);
        showNotification(SUCCESS_TYPE, response.message);
        dispatch({ type: REMOVE_TEST_JOB, response });
        dispatch(getTestJob(1, projectId, searchKeyword, "", rights));
      } else {
        showNotification(ERROR_TYPE, response.message);
      }
    });
  };
}

export function buildVersionsByProjectId(projectId, cloudProvider) {
  return async (dispatch, getState) => {
    return async("buildDetails/getVersionByProjectId", "GET", {
      projectId,
      cloudProvider: cloudProvider ? cloudProvider : DEVICE_EXECUTION_FARM.XPRESS,
    }).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        dispatch({ type: BUILD_VERSION_LIST, response });
      }
    });
  };
}

export function getTestRailSuitesByTestPlanId(projectId, testPlanId) {
  return async (dispatch, getState) => {
    return async("recTestScenario/getSuitesByTestPlanId", "GET", { projectId, testPlanId }).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        dispatch({ type: TEST_RAIL_SUITES_BY_TEST_PLAN_ID, response });
      }
    });
  };
}
export function changeSelectedTestJobCriteria(selectedTestJobCriteria) {
  return async (dispatch) => {
    dispatch({ type: CHANGE_SELECTED_TEST_JOB_CRITERIA, selectedTestJobCriteria });
  };
}
export function changeTestJobData(testJobData) {
  return async (dispatch) => {
    dispatch({ type: CHANGE_TEST_JOB_DATA, data: testJobData });
  };
}

export function resetTestJobData(clearData = false) {
  return async (dispatch) => {
    dispatch({ type: RESET_GET_TEST_JOB_BY_ID_DATA, clearData });
    dispatch({ type: UPDATE_TEST_JOB_ERROR_DETAIL, testJobErrorDetail: {} });
  };
}

export function resetTestScheduleJobData(clearData = false) {
  return async (dispatch) => {
    dispatch({ type: RESET_GET_TEST_JOB_SCHEDULE_DATA, clearData });
  };
}
export function disableScheduleJob(projectId, rights) {
  return async (dispatch, getState) => {
    let { testJobScheduleDetail, testJobListFilter } = getState().TestJobReducer;
    testJobScheduleDetail = {
      ...testJobScheduleDetail,
      isJobScheduled: testJobScheduleDetail.isJobScheduled === 1 ? 0 : 1,
      scheduleTime: undefined,
      scheduleDate: undefined,
      scheduleDateTime: undefined,
      timeZoneName: undefined,
      executionFrequency: undefined,
      days: [],
      hours: [],
      minutes: [],
    };
    dispatch(saveScheduleJob(projectId, testJobScheduleDetail, testJobListFilter));
  };
}

export function changeTestJobScheduleData(testJobScheduleDetail) {
  return async (dispatch, getState) => {
    //validation
    const { previousTestJobScheduleDetail } = getState().TestJobReducer;
    let errorInScheduleJob = false;
    if (checkUndefined(previousTestJobScheduleDetail) && testJobScheduleDetail.isJobScheduled === 0) {
      errorInScheduleJob = true;
    } else {
      if (testJobScheduleDetail.isJobScheduled === 1 && checkNotUndefined(testJobScheduleDetail.id)) {
        switch (testJobScheduleDetail.executionFrequency) {
          case ONETIME:
            if (
              !checkNotUndefinedAndNull(testJobScheduleDetail.scheduleDate) ||
              !checkNotUndefinedAndNull(testJobScheduleDetail.scheduleTime) ||
              !checkNotUndefinedAndNull(testJobScheduleDetail.timeZoneName)
            ) {
              errorInScheduleJob = true;
            }
            break;
          case RECURRING:
            if (
              checkUndefined(testJobScheduleDetail.days) ||
              checkListIsEmpty(testJobScheduleDetail.days) ||
              checkUndefined(testJobScheduleDetail.hours) ||
              checkListIsEmpty(testJobScheduleDetail.hours) ||
              checkUndefined(testJobScheduleDetail.minutes) ||
              checkListIsEmpty(testJobScheduleDetail.minutes) ||
              checkUndefined(testJobScheduleDetail.timeZoneName) ||
              checkNull(testJobScheduleDetail.timeZoneName)
            ) {
              errorInScheduleJob = true;
            }
            break;
          default:
            errorInScheduleJob = true;
        }
      } else if (JSON.stringify(previousTestJobScheduleDetail) === JSON.stringify(testJobScheduleDetail)) {
        errorInScheduleJob = true;
      } else {
        errorInScheduleJob = false;
      }
    }
    dispatch({ type: CHANGE_TEST_JOB_SCHEDULE_DATA, data: testJobScheduleDetail, errorInScheduleJob });
  };
}

export function saveScheduleJob(projectId, _testJobScheduleDetail = undefined) {
  return async (dispatch, getState) => {
    dispatch({ type: LOADING_JOB, response: { isLoading: true } });
    let { testJobScheduleDetail, getTimeZoneList, testJobListFilter } = getState().TestJobReducer;
    let timeZone =
      getTimeZoneList && getTimeZoneList?.timeZones?.find((i) => i?.name === testJobScheduleDetail["timeZoneName"]);
    if (_testJobScheduleDetail) {
      testJobScheduleDetail = { ..._testJobScheduleDetail };
    }
    // Reset value of other Execution type updateTestJobErrorDetail
    if (testJobScheduleDetail.executionFrequency === ONETIME) {
      let date = moment(testJobScheduleDetail?.scheduleDate).format("YYYY-MM-DD");
      let time = moment(testJobScheduleDetail?.scheduleTime).format("HH:mm:ss");
      let scheduleTime = getDateTimeInUTCFormat(date + " " + time, dateHourMinuteFormat);
      // let scheduleTime = date + " " + time;
      delete testJobScheduleDetail["scheduleDateTime"];
      delete testJobScheduleDetail["scheduleDate"];
      delete testJobScheduleDetail["scheduleTime"];
      testJobScheduleDetail = {
        ...testJobScheduleDetail,
        scheduleTime: scheduleTime,
        days: [],
        hours: [],
        minutes: [],
      };
    } else {
      testJobScheduleDetail = {
        ...testJobScheduleDetail,
        scheduleTime: null,
        scheduleDate: null,
        scheduleDateTime: null,
      };
    }
    testJobScheduleDetail = {
      ...testJobScheduleDetail,
      timeZone: timeZone?.offset,
      id: testJobScheduleDetail.testJobId,
      days: testJobScheduleDetail.days.toString(),
      hours: testJobScheduleDetail.hours.toString(),
      minutes: testJobScheduleDetail.minutes.toString(),
    };
    return async("testJob/saveScheduleDetails", "POST", testJobScheduleDetail).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        showNotification(SUCCESS_TYPE, response.message);
        dispatch({ type: RESET_GET_TEST_JOB_SCHEDULE_DATA, response });
        dispatch(getTestJob(1, projectId, "", "", testJobListFilter));
        dispatch(closeTestJobScheduleDrawer());
        dispatch(changeExecutionManagerTabAndSubTab(ALL_JOBS));
        dispatch({ type: LOADING_JOB, response: { isLoading: false } });
      } else {
        showNotification(ERROR_TYPE, response.message);
        dispatch({ type: LOADING_JOB, response: { isLoading: false } });
      }
    });
  };
}

export function changeExecutionManagerTabAndSubTab(executionManagerSubTab, createBuildJob) {
  return async (dispatch, getState) => {
    if (checkUndefined(executionManagerSubTab)) {
      executionManagerSubTab = ALL_JOBS;
    } else {
      switch (executionManagerSubTab) {
        case ALL_JOBS:
          dispatch(resetTestJobData(true));
          dispatch(resetTestScheduleJobData(true));
          break;
        case JOB_DESIGNER:
          createBuildJob !== undefined && dispatch({ type: CREATE_BUILD_JOB, createBuildJob });
          dispatch(resetTestScheduleJobData(true));
          break;
        // TODO :Remove this Code
        // case JOB_SCHEDULER:
        //   dispatch(resetTestScheduleJobData(true));
        //   break;
        default:
          break;
      }
    }

    dispatch({ type: CHANGE_EXECUTION_MANAGER_TAB, executionManagerSubTab });
  };
}

export function updateTestJobErrorDetail() {
  return async (dispatch, getState) => {
    const { testJobDetail, selectedTestJobCriteria, testJobErrorDetail } = getState().TestJobReducer;
    const { platform } = getState().ProjectsReducer;
    let _errorInStep = testJobErrorDetail;
    if (JSON.stringify(testJobDetail) === JSON.stringify(new BuildJobModal({}))) {
      return;
    }
    _errorInStep["name"] =
      checkUndefined(testJobDetail.name) || testJobDetail.name === "" || isTrim(testJobDetail?.name);
    // To validate TestPlan and Test Data
    if (selectedTestJobCriteria > SCHEDULE_TEST_JOB.STEP1 || checkNotUndefined(testJobDetail.id)) {
      _errorInStep[SCHEDULE_TEST_JOB.STEP1] = validateBuildJobStep1ErrorDetail(testJobDetail);
    }
    // To validate Test Device
    if (selectedTestJobCriteria > SCHEDULE_TEST_JOB.STEP2 || checkNotUndefined(testJobDetail.id)) {
      _errorInStep[SCHEDULE_TEST_JOB.STEP2] = validateBuildJobStep2ErrorDetail(testJobDetail);
    }
    // To validate Test Build
    if (selectedTestJobCriteria > SCHEDULE_TEST_JOB.STEP3 || checkNotUndefined(testJobDetail.id)) {
      _errorInStep[SCHEDULE_TEST_JOB.STEP3] = validateBuildJobStep3ErrorDetail(testJobDetail, platform);
    }
    // To validate Execution Setting
    if (validateBuildJobStep4ErrorDetail(testJobDetail)) {
      _errorInStep[SCHEDULE_TEST_JOB.STEP4] = true;
    } else {
      _errorInStep[SCHEDULE_TEST_JOB.STEP4] = false;
    }
    dispatch({ type: UPDATE_TEST_JOB_ERROR_DETAIL, testJobErrorDetail: _errorInStep });
  };
}

export function validateBuildJobStep1ErrorDetail(testJobDetail) {
  return checkUndefined(testJobDetail.testPlan.id) || checkUndefined(testJobDetail.testDataSet.name);
}

export function validateBuildJobStep2ErrorDetail(testJobDetail) {
  const { deviceServiceFarm, devices } = testJobDetail;

  switch (deviceServiceFarm) {
    case DEVICE_EXECUTION_FARM.XPRESS:
    case DEVICE_EXECUTION_FARM.SAUCELABS:
    case DEVICE_EXECUTION_FARM.LAMBDA_TEST:
    case DEVICE_EXECUTION_FARM.AWS:
      return checkUndefined(deviceServiceFarm) || checkListIsEmpty(devices);
    default:
      return checkUndefined(deviceServiceFarm);
  }
}

export function validateBuildJobStep3ErrorDetail(testJobDetail, platform) {
  return (
    checkUndefined(testJobDetail.selectedBuild) ||
    (testJobDetail.selectedBuild === BUILD_URL &&
      (checkUndefined(testJobDetail.fileUrl) ||
        (testJobDetail?.deviceServiceFarm !== DEVICE_EXECUTION_FARM.XPRESS &&
          platform === ANDROID &&
          checkNull(testJobDetail.appActivity)))) ||
    (testJobDetail.selectedBuild === APP_MANAGER &&
      testJobDetail.buildDetail &&
      testJobDetail.buildDetail === undefined)
  );
}

export function validateBuildJobStep4ErrorDetail(testJobDetail) {
  return testJobDetail.updateResultToTestRail === 1 && checkUndefined(testJobDetail.testRailTestSuiteIds);
}

export function changeExternalFarmDeviceListPageNumber(value) {
  return (dispatch) => {
    dispatch({ type: CHANGE_EXTERNAL_FARM_DEVICE_LIST_PAGE_NUMBER, value });
  };
}

export const handleSearchExternalFarmDeviceList = (searchText) => {
  return (dispatch) => {
    dispatch({ type: SEARCH_EXTERNAL_FARM_DEVICE_LIST, searchText });
  };
};

export function getExternalFarmDevice(farmName) {
  return async (dispatch, getState) => {
    const { projectId, platform } = getState().ProjectsReducer;
    const data = { projectId, platform, farmName };

    dispatch({ type: EXTERNAL_FARM_DEVICE_LOADING, response: { isLoading: true } });

    try {
      let response = await async("externalFarmConfig", "GET", { projectId, farmName });
      response = updateResponse(response);

      if (response.status === SUCCESS_STATUS && response?.data === "") {
        showNotification(
          ERROR_TYPE,
          `You do not have the necessary credentials for ${
            farmName === DEVICE_EXECUTION_FARM.LAMBDA_TEST ? LAMBDA_TEST : SAUCELABS
          }.`
        );
        dispatch({ type: GET_EXTERNAL_FARM_DEVICE, response: [], farmName });
        return;
      }

      response = await async("externalFarm/getAvailableDevices", "GET", data);
      response = updateResponse(response);

      if (response.status !== SUCCESS_STATUS && response.message) {
        showNotification(ERROR_TYPE, response.message);
      } else {
        dispatch(changeExternalFarmDeviceListPageNumber(DEFAULT_PAGE));
      }
      dispatch({ type: GET_EXTERNAL_FARM_DEVICE, response, farmName });
    } catch (error) {
      showNotification(ERROR_TYPE, "An error occurred while fetching the external farm device.");
    } finally {
      dispatch({ type: EXTERNAL_FARM_DEVICE_LOADING, response: { isLoading: false } });
    }
  };
}

export function ediBuildJob(value) {
  return (dispatch) => {
    dispatch({ type: EDIT_BUILD_JOB, value });
  };
}

export const getBuildVersions = (buildType, setBuildVersionList) => {
  return (dispatch, getState) => {
    const { projectId } = getState().ProjectsReducer;
    const payload = {
      buildType,
      projectId,
    };
    dispatch({ type: GET_BUILD_VERSIONS_LOADER, isLoading: true });
    async("appBuild/versions", "GET", payload).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        showNotification(SUCCESS_TYPE, response.message);
        dispatch({ type: GET_BUILDS_VERSIONS, response });
        setBuildVersionList && setBuildVersionList(response?.data);
      } else {
        showNotification(ERROR_TYPE, response.message);
        dispatch({ type: GET_BUILD_VERSIONS_LOADER, isLoading: false });
      }
      dispatch({ type: GET_BUILD_VERSIONS_LOADER, isLoading: false });
    });
  };
};

export const visibleCreateBuildModal = (visible) => {
  return (dispatch) => {
    dispatch({ type: VISIBLE_CREATE_BUILD_MODAL, visible });
  };
};
