import { default as React } from "react";
import { useDispatch, useSelector } from "react-redux";
import { faTasks } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Checkbox, Select, Tooltip } from "antd";
import isEmpty from "lodash/isEmpty";
import { AI_TARGET_ELEMENT, TOOLTIP_BG_COLOR } from "../../../../../Constants";
import {
  changeAIStepUseTestData,
  selectAIStepTestData,
} from "../../../../../actions/RecTestStep/RecTestStepRedirectAction";
import { selectRecTestSteps } from "../../../../../actions/RecTestStepAction";
import { visibleTestDataDrawer } from "../../../../../actions/TestDataAction";
import ApplicationStringDrawer from "../../../../RecTestStep/SingleColumnView/ApplicationStringDrawer/ApplicationStringDrawer";
import ManageTestDataV2 from "../../ManageTestDataV2/ManageTestDataV2";
import styles from "./RecTestStepAIDetail.module.scss";

const RecTestStepAIDetail = (props) => {
  const dispatch = useDispatch();
  const { data, disabled, projectId, isSkipStep, isDisabledNLP } = props;

  const { aiUseTestData, aiUseApplicationString } = data.recTestStepProperty;
  const { testDataList } = useSelector((state) => state.RecTestStepCommonViewReducer);
  const { openTestDataSetDrawerByTabName, tagForOpenDrawer } = useSelector((props) => props.TestDataReducer);
  //select use test data and application string details
  const testDataAndStringsCheckBox = [
    {
      label: "Use Test Data",
      checked: aiUseTestData === 1,
      onChange: (e) => dispatch(changeAIStepUseTestData(e.target.checked, data.tag)),
      disableCondition: isDisabledNLP || aiUseApplicationString === 1,
    },
    // {
    //   label: "Use Application String",
    //   checked: aiUseApplicationString === 1,
    //   onChange: (e) =>
    //     dispatch(
    //       changeAIStepUseApplicationString(
    //         e.target.checked,
    //         data.tag
    //       )
    //     ),
    //   disableCondition: disabled || aiUseTestData === 1,
    // },
  ];

  const renderCustomDetails = () => {
    if (aiUseTestData === 1) {
      return (
        <>
          <div className={styles["text-And-Button"]}>
            <Select
              disabled={isDisabledNLP}
              className={styles["selectMode"]}
              placeholder={
                <span>
                  <span className={styles["required_Field"]}>*</span> Please Select Test Data
                </span>
              }
              value={
                data?.recTestStepProperty?.aiTargetElementTestDataId
                  ? data?.recTestStepProperty?.aiTargetElementTestDataId
                  : data?.recTestStepProperty?.aiTargetElementTestData
                  ? data?.recTestStepProperty?.aiTargetElementTestData?.id
                  : undefined
              }
              onChange={(value) => {
                let selectedTestData = testDataList.find((item) => item.id == value);
                dispatch(selectAIStepTestData(data.tag, selectedTestData.id, selectedTestData.value));
              }}
            >
              {!isEmpty(testDataList) &&
                testDataList?.map((v, k) => {
                  return (
                    <Select.Option key={v.id} value={v.id} title={v.name + (v.value ? " (" + v.value + ")" : "")}>
                      <span>
                        <span>{v.name + (v.value ? " (" + v.value + ")" : "")}</span>
                      </span>
                    </Select.Option>
                  );
                })}
            </Select>
            <div>
              <Tooltip title="Select Test Data" color={TOOLTIP_BG_COLOR}>
                <Button
                  className={`${styles.button} ${!isDisabledNLP && styles.blue}`}
                  disabled={isDisabledNLP}
                  onClick={() => {
                    dispatch(visibleTestDataDrawer(AI_TARGET_ELEMENT, data?.tag));
                    dispatch(selectRecTestSteps(data));
                  }}
                >
                  <FontAwesomeIcon icon={faTasks}></FontAwesomeIcon>
                </Button>
              </Tooltip>
            </div>
          </div>
        </>
      );
    }
    // else if (aiUseApplicationString === 1) {
    //   return (
    //     <>
    //       <div className={styles["label"]}>Application String</div>
    //       <div className={styles["text-And-Button"]}>
    //         <div
    //           className={`${styles["label"]} ${styles["application-String-key"]}`}
    //         >
    //           {data.recTestStepProperty?.aiApplicationStringKey ||
    //             data.recTestStepProperty?.aiApplicationStrings?.key ||
    //             "Please Select the application String"}
    //         </div>
    //         <div>
    //           <Tooltip
    //             title="Select Application String"
    //             color={TOOLTIP_BG_COLOR}
    //           >
    //             <Button
    //               className={`${styles.button} ${!disabled && styles.blue}`}
    //               disabled={disabled}
    //               onClick={() => {
    //                 dispatch(applicationDrawerVisible(true, data.tag));
    //                 dispatch(
    //                   getApplicationStrings(
    //                     projectId,
    //                     "",
    //                     "",
    //                     "",
    //                     "",
    //                     data.tag
    //                   )
    //                 );
    //                 isEmpty(getListOfLocales) &&
    //                   dispatch(getListOfLocalesForApplicationStrings());
    //               }}
    //             >
    //               <FontAwesomeIcon icon={faTasks}></FontAwesomeIcon>
    //             </Button>
    //           </Tooltip>
    //         </div>
    //       </div>
    //     </>
    //   );
    // } else {
    //   return (
    //     <>
    //       <Input
    //         placeholder="Enter the target element text"
    //         disabled={disabled}
    //         value={data.recTestStepProperty.aiTargetElementText}
    //         onChange={(event) =>
    //           dispatch(changeAITargetElementValue(
    //               event.target.value,
    //               data.tag
    //             )
    //           )
    //         }
    //         className={styles["input-box"]}
    //       />
    //     </>
    //   );
    // }
  };
  return (
    <div>
      <div>
        <div className={styles["select-use-data-or-string"]}>
          {testDataAndStringsCheckBox?.map((checkbox, index) => (
            <div className={styles.checkbox} key={index}>
              <Checkbox
                disabled={checkbox.disableCondition}
                checked={checkbox.checked}
                onChange={checkbox.onChange}
                className="selection-checkBox"
              >
                <span className={styles["checkbox-Text"]}>{checkbox.label}</span>
              </Checkbox>
            </div>
          ))}
          {renderCustomDetails()}
        </div>
      </div>
      {openTestDataSetDrawerByTabName === AI_TARGET_ELEMENT && data?.tag === tagForOpenDrawer && (
        <ManageTestDataV2 {...props} tabName={AI_TARGET_ELEMENT} />
      )}
      <ApplicationStringDrawer recTestStep={data} projectId={projectId} disabled={disabled || isSkipStep} />
    </div>
  );
};

export default RecTestStepAIDetail;
