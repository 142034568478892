import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DEVICE_EXECUTION_FARM, PARALLEL_OS, PARALLEL_SPEED, SCHEDULE_TEST_JOB } from "../../../../../Constants";
import { changeTestJobData } from "../../../../../actions/TestJobActions";
import TestPlanTab from "../../../../ExecutionManager/JobDesigner/BuildJob/JobCriteriaOptions/TestPlanTab";
import DeviceTabV2 from "./DeviceTabV2";
import ExecutionSettingsTabV2 from "./ExecutionSettingsTabV2";
import SelectBuildTabV2 from "./SelectBuildTabV2";
import styles from "./JobCriteriaOptionsV2.module.scss";

export const RenderJobCriteriaOptions = (props) => {
  const { orgId, projectId, selectedCriteria, showDeviceView, buildVersionList, setBuildVersionList } = props;
  switch (selectedCriteria) {
    case SCHEDULE_TEST_JOB.STEP1:
      return <TestPlanTab projectId={projectId} orgId={orgId}/>;
    case SCHEDULE_TEST_JOB.STEP2:
      return <DeviceTabV2 projectId={projectId} orgId={orgId} showDeviceView={showDeviceView} />;
    case SCHEDULE_TEST_JOB.STEP3:
      return (
        <SelectBuildTabV2
          projectId={projectId}
          orgId={orgId}
          showDeviceView={showDeviceView}
          buildVersionList={buildVersionList}
          setBuildVersionList={setBuildVersionList}
        />
      );
    case SCHEDULE_TEST_JOB.STEP4:
      return <ExecutionSettingsTabV2 projectId={projectId} />;
    default:
      break;
  }
};

const JobCriteriaOptionsV2 = (props) => {
  const dispatch = useDispatch();
  const { orgId, projectId, showDeviceView, buildVersionList, setBuildVersionList } = props;
  const { testJobDetail, selectedTestJobCriteria } = useSelector((state) => state.TestJobReducer);

  useEffect(() => {
    if (testJobDetail?.devices?.length > 1) {
      dispatch(
        changeTestJobData({
          ...testJobDetail,
          executionType: testJobDetail.executionType === PARALLEL_SPEED ? PARALLEL_SPEED : PARALLEL_OS,
        })
      );
    }
  }, [testJobDetail?.devices]);

  return (
    <div
      className={`${styles["job_Container"]} ${
        testJobDetail.deviceServiceFarm !== DEVICE_EXECUTION_FARM.AWS && styles["min_Height"]
      }`}
    >
      <RenderJobCriteriaOptions
        orgId={orgId}
        projectId={projectId}
        selectedCriteria={selectedTestJobCriteria}
        showDeviceView={showDeviceView}
        buildVersionList={buildVersionList}
        setBuildVersionList={setBuildVersionList}
      />
    </div>
  );
};

export default JobCriteriaOptionsV2;
