import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Upload } from "antd";
import { isNotNullAndNotEmptyAndNotUndefined } from "../../../Util";
import styles from "./UploadBuildV2.module.scss";

const UploadBuildV2 = (props) => {
  const {
    addNewBuild,
    existingBuildName,
    labelName,
    placeholder,
    setUploadBuildFile,
    acceptFileTypes,
    index,
    selectStringsIndex,
    showInModal,
  } = props;


  const [dragOver, setDragOver] = useState(false);
  const sanitizeFileName = (fileName) => {
    return fileName.replace(/\s+/g, "_").replace(/[^a-zA-Z0-9_\-.]/g, "");
  };
  const uploadProps = {
    onRemove: (file) => {},
    beforeUpload: (file) => {
      const sanitizedFile = new File([file], sanitizeFileName(file.name), { type: file.type });
      changeBuildFileName(sanitizedFile);
      return false;
    },
    accept: acceptFileTypes,
    multiple: false,
  };

  const changeBuildFileName = (file) => {
    if (file) {
      setUploadBuildFile && setUploadBuildFile(file, file.name);
    }
  };

  const handleDrop = (e) => {
    setDragOver(false);
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    changeBuildFileName(droppedFile);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragOver(true);
  };

  const addFile =
    addNewBuild || selectStringsIndex?.includes(index) || !isNotNullAndNotEmptyAndNotUndefined(existingBuildName);

  return (
    <>
      <div className={`${addFile && styles["custom_Input_Wrapper"]}`}>
        {labelName && (
          <div
            className={`${addFile ? styles["custom_Input_Label"] : styles["upload_Label"]} ${
              showInModal ? styles["input_Label_Modal"] : ""
            }`}
          >
            <div>
              {labelName}
              <span className={styles["field_Required"]}>*</span>
            </div>
          </div>
        )}

        {!addFile && (
          <div className="display-flex mb-5">
            <span className="font-size-14 font-weight-500 font-color-darkBlue">{existingBuildName}</span>
          </div>
        )}

        {addFile && (
          <div
            className={`${styles["upload_Box"]} ${dragOver ? styles["drag_Over_Text"] : ""}`}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
          >
            <Upload {...uploadProps} maxCount={1} className="upload_Builds" onDrop={handleDrop}>
              <div className={styles["drag_And_Drop_Text"]}>Drag and Drop Build Here</div>
              {!dragOver && (
                <>
                  <div className={styles["OR_Label"]}>OR</div>
                  <div className={styles["upload_Button_Detail"]}>
                    <Button className={styles["upload_Button"]} type="primary">
                      <FontAwesomeIcon icon="cloud-upload-alt" className="mr-5" />
                      {placeholder}
                    </Button>
                  </div>
                </>
              )}
            </Upload>
          </div>
        )}
      </div>
    </>
  );
};

export default UploadBuildV2;
