import React from "react";
import { useDispatch } from "react-redux";
import { Button, Select } from "antd";
import {
  VERIFICATIONS,
  splitValue,
  verificationExistenceDisplayName,
  verificationExistenceValues,
} from "../../../../../Constants";
import { getAllowedElementsForReference } from "../../../../../Util";
import {
  updateReferenceStepValue,
  updateVerificationKey,
} from "../../../../../actions/RecTestStep/RecTestStepRedirectAction";
import { getEmptyVerificationRow } from "../../../../../reducer/RecTestStepReducer";
import styles from "./VerifyExistenceV2.module.scss";

const VerifyExistenceV2 = (props) => {
  const { disabled, recTestStep, recTestSteps } = props;
  let recStepVerifyProperty =
    recTestStep?.recTestStepProperty?.recStepVerifyElementProperties &&
    recTestStep?.recTestStepProperty?.recStepVerifyElementProperties.length === 1
      ? recTestStep?.recTestStepProperty?.recStepVerifyElementProperties[0]
      : [getEmptyVerificationRow("VerifyExistence", verificationExistenceValues.exist)];
  const dispatch = useDispatch();
  let filteredElementList = getAllowedElementsForReference(recTestSteps, [], recTestStep);
  recStepVerifyProperty.uuid = recStepVerifyProperty?.uuid ? recStepVerifyProperty.uuid : recStepVerifyProperty?.id;

  return (
    <>
      <div className={styles["verification_Text_Select_Box"]}>
        {recTestStep?.recTestStepProperty?.action !== VERIFICATIONS && (
          <span className={styles["condition_Text"]}>Condition:</span>
        )}
        <div className={styles["select_Box_And_Label"]}>
          <div className={styles["verify_Text_Label"]}>Verify Element</div>
          <Select
            disabled={disabled}
            className={styles["select_Verify_ELement"]}
            defaultActiveFirstOption={true}
            value={recStepVerifyProperty?.key}
            onChange={(value) => dispatch(updateVerificationKey(value, recStepVerifyProperty.uuid, recTestStep.tag))}
          >
            <Select.Option key={verificationExistenceValues.exist} value={verificationExistenceValues.exist}>
              {verificationExistenceDisplayName[verificationExistenceValues.exist]}
            </Select.Option>
            <Select.Option
              key={verificationExistenceValues.does_not_exist}
              value={verificationExistenceValues.does_not_exist}
            >
              {verificationExistenceDisplayName[verificationExistenceValues.does_not_exist]}
            </Select.Option>
          </Select>
        </div>
        {recStepVerifyProperty?.key === verificationExistenceValues.does_not_exist &&
          recStepVerifyProperty.referenceStep && (
            <>
              <Select
                disabled={props.disabled}
                className={styles["select_Verify_ELement"]}
                placeholder={"Select previous step"}
                value={
                  recStepVerifyProperty.referenceStep
                    ? recStepVerifyProperty.referenceStep.tag + splitValue + recStepVerifyProperty.referenceStep.id
                    : ""
                }
                onChange={(value) =>
                  dispatch(updateReferenceStepValue(value, recStepVerifyProperty.uuid, recTestStep.tag))
                }
              >
                {filteredElementList.map((v, k) => {
                  return (
                    <Select.Option key={k} value={v.tag + splitValue + (v.id ? v.id : 0)}>
                      <span>
                        <span className="font-weight-500 text-transform-capital">{v.action}:</span>
                        {v.name}
                      </span>
                    </Select.Option>
                  );
                })}
              </Select>
              <Button
                type="primary"
                onClick={() =>
                  dispatch(updateReferenceStepValue(undefined, recStepVerifyProperty.uuid, recTestStep.tag))
                }
              >
                Clear Reference Step
              </Button>
            </>
          )}
      </div>
    </>
  );
};
export default VerifyExistenceV2;
