import React from "react";
import { useDispatch } from "react-redux";
import { Select } from "antd";
import {
  ACTIONS_OPTIONS,
  ANDROID,
  DEVICE_INTERACTION,
  ELEMENT_ACTION,
  INSERT_VALUE_TO_OUTPUT_TEST_DATA_TXT,
  SET_APP_LOCALE,
  SET_APP_LOCALE_TXT,
} from "../../../../../Constants";
import { updateActionValue } from "../../../../../actions/RecTestStep/RecTestStepRedirectAction";
import SwipeElementV2 from "../SwipeElementV2/SwipeElementV2";
import CaptureScreenshotV2 from "./CaptureScreenshotV2";
import InsertValueToOutputTestDataV2 from "./InsertValueToOutputTestDataV2";
import PauseV2 from "./PauseV2";
import SetAppLocaleVariableV2 from "./SetAppLocaleVariableV2";
import styles from "./QuickActionsV2.module.scss";

const QuickActionsV2 = (props) => {
  const { data, isSkipStep, isPreviewRunning, platform } = props;
  let { disabled } = props;
  const { action } = data.recTestStepProperty;
  const dispatch = useDispatch();

  disabled = disabled || isPreviewRunning || isSkipStep;
  return (
    <React.Fragment>
      <div>
        <div className={styles["select_Actions_And_Label"]}>
          <div className={styles["operation_Text"]}>Operation :</div>
          <div className={styles["selectBoxAndLabel"]}>
            <Select
              value={action === "actions" ? ACTIONS_OPTIONS : action}
              disabled={disabled}
              defaultValue={ELEMENT_ACTION.TAP}
              dropdownMatchSelectWidth
              onChange={(value) => {
                dispatch(updateActionValue(value, data.tag));
              }}
              className={`${styles["selectActions"]} ${disabled && styles["selectBoxBgColor"]}`}
            >
              <Select.Option value={DEVICE_INTERACTION.HOME}>Navigate to Home</Select.Option>
              {platform === ANDROID && <Select.Option value={DEVICE_INTERACTION.BACK}>Navigate Back</Select.Option>}
              {platform === ANDROID && <Select.Option value={DEVICE_INTERACTION.ENTER}>Keypad Enter</Select.Option>}
              <Select.Option value={ELEMENT_ACTION.PAUSE}>Add Delay</Select.Option>
              <Select.Option value={ELEMENT_ACTION.CAPTURE_SCREENSHOT}>Screenshot Page</Select.Option>
              <Select.Option value={ELEMENT_ACTION.SWIPE}>Scroll Page</Select.Option>
              <Select.Option value={ELEMENT_ACTION.INSERT_VALUE_TO_OUTPUT_TEST_DATA}>
                {INSERT_VALUE_TO_OUTPUT_TEST_DATA_TXT}
              </Select.Option>
              <Select.Option value={SET_APP_LOCALE}>{SET_APP_LOCALE_TXT}</Select.Option>
            </Select>
          </div>
          {action === ELEMENT_ACTION.CAPTURE_SCREENSHOT ? <CaptureScreenshotV2 {...props} /> : ""}
        </div>
        <div>
          {action === ELEMENT_ACTION.PAUSE ? <PauseV2 {...props} /> : ""}
          {action === ELEMENT_ACTION.INSERT_VALUE_TO_OUTPUT_TEST_DATA ? (
            <InsertValueToOutputTestDataV2 {...props} />
          ) : (
            ""
          )}
          {action === SET_APP_LOCALE ? <SetAppLocaleVariableV2 {...props} /> : ""}
        </div>
      </div>
    </React.Fragment>
  );
};

export default QuickActionsV2;
