import { APP_MANAGER, BUILD_URL, DEVICE_EXECUTION_FARM, NONE, SERIAL, USE_LATEST_BUILD } from "../../../Constants";

export class BuildJobModal {
  constructor(responseData) {
    //For Get API
    if (responseData["id"]) {
      this.id = responseData["id"];
      this.testPlan = { id: responseData["testPlan"]["id"], name: responseData["testPlan"]["name"] };
      this.testDataSet = responseData["testDataSet"]
        ? { id: responseData["testDataSet"]["id"], name: responseData["testDataSet"]["name"] }
        : { name: "Default" };
      this.name = responseData["name"];
      this.deviceFarmIds = responseData["deviceFarmIds"];
      this.devices = responseData["devices"];
      this.deviceOs = responseData["deviceOs"];
      this.executionType = responseData["executionType"];
      this.retryIndex = responseData["retryIndex"];
      this.isCaptureVideo = responseData["isVideo"];
      this.accessibilityReport = responseData["accessibilityReport"];
      this.localeReport = responseData["localeReport"];
      this.isLogs = responseData["isLogs"];
      this.locale = responseData["locale"];
      this.updateResultToTestRail = responseData["updateResultToTestRail"];
      this.email = responseData["emailList"] ? responseData["emailList"].split(",") : [];
      this.scheduleNote = responseData["scheduleNote"];
      this.testRailTestSuiteIds = responseData["testRailTestSuiteIds"];
      this.deviceServiceFarm = responseData["deviceServiceFarm"];
      //For Get Api using this field, This is logical field
      this.selectedBuild =
        (responseData["buildDetail"] && responseData["buildDetail"]["uuid"] !== undefined) ||
        responseData["isUseLatestBuild"] === 1
          ? APP_MANAGER
          : responseData["fileUrl"]
          ? BUILD_URL
          : NONE;
      //For Get Api using this field
      this.isUseLatestBuild = responseData["isUseLatestBuild"];
      this.buildDetailId = responseData["buildDetailId"];
      this.fileUrl = responseData["fileUrl"];
      this.buildDetail = responseData["buildDetail"];
      this.lastUpdatedOn = responseData["lastUpdatedOn"];
      this.lastUpdatedBy = responseData["lastUpdatedBy"];
      this.createdBy = responseData["createdBy"];
      this.createdOn = responseData["createdOn"];
      this.scheduleNote = responseData["scheduleNote"];
      this.isJobScheduled = 0;
      this.appActivity = responseData["appActivity"];
      this.buildType = responseData["buildType"];
      this.buildVersion = responseData["isUseLatestBuild"] === 1 ? "Latest" : "";
    } else {
      this.id = undefined;
      this.testPlan = { id: 0 };
      this.testDataSet = { name: "Default" };
      this.name = undefined;
      this.deviceFarmIds = undefined;
      this.devices = [];
      this.executionType = SERIAL;
      this.retryIndex = 0;
      this.isCaptureVideo = 1;
      this.isLogs = 1;
      this.accessibilityReport = 0;
      this.localeReport = 0;
      this.updateResultToTestRail = 0;
      this.email = [];
      this.scheduleNote = undefined;
      this.testRailTestSuiteIds = [];
      this.deviceServiceFarm = DEVICE_EXECUTION_FARM.XPRESS;
      this.buildType = null;
      this.buildDetailId = undefined;
      this.fileUrl = "https://";
      this.buildDetail = { buildVersion: "Latest" };
      this.lastUpdatedOn = undefined;
      this.lastUpdatedBy = undefined;
      this.createdBy = undefined;
      this.createdOn = undefined;
      this.isJobScheduled = 0;
      this.locale = "en-US";
      this.unInstallBUildBeforeInstall = 0;
      this.appActivity = null;
      this.appBuild = undefined;
      this.buildVersion = "Latest";
      this.selectedBuild = NONE;
    }
  }

  // let id;
  //     let testPlan;
  //     let testDataSet;
  //     let name;
  //     let deviceFarmIds;
  //     let devices;
  //     let executionType;
  //     let retryIndex;
  //     let isVideo;
  //     let isLogs;
  //     let updateResultToTestRail;
  //     let emailList;
  //     let scheduleNote;
  //     let testRailTestSuiteIds;
  //     let fileUrl;
  //     let deviceServiceFarm = DEVICE_FARM_INTEGRATION_SERVICE;
  //     let buildDetail;
}
