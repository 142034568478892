import _ from "lodash";
import {
  BuildAWSDeviceFarmModal,
  BuildGeneralTabModal,
  BuildS3ConfigTabModal,
} from "../Components/ProjectSettings/BuildProjectSettingModal";
import {
  AWS_DEVICE_LOADING,
  CHANGE_AWS_DEVICE_FARM_TAB_DATA,
  CHANGE_GENERAL_TAB_DATA,
  CHANGE_S3CONFIG_TAB_DATA,
  EXTERNAL_FARM_LOADING,
  GENERATE_API_KEY,
  GENERATE_API_KEY_LOADER,
  GET_API_KEY,
  GET_AWS_DEVICE_FARMS,
  GET_AWS_POOLS,
  GET_AWS_PROJECTS,
  GET_EXTERNAL_FARM_DATA_CENTER_LIST,
  GET_EXTERNAL_FARM_DETAILS,
  GET_PROJECT_DETAIL_SETTING,
  GET_TEST_RAIL_CONFIG_DATA,
  GET_TEST_RAIL_CONFIG_PROJECT_LIST,
  HANDLE_DISABLE_BUTTON,
  PROJECT_LOADING,
  REMOVE_AWS_POOLS,
  RESET_STATE,
  TEST_RAIL_LOADING,
  UPDATE_PREVIOUS_AWS_DEVICE_FARM_TAB_DETAILS,
  VALIDATE_EXTERNAL_FARM_CREDENTIALS,
} from "../Constants";
let defaultState = {
  getProjectDetailsById: null,
  awsProjects: null,
  awsPools: null,
  getAWSDeviceFarms: [],
  projectLoading: false,
  getTestRailConfigData: null,
  getTestRailConfigProjectList: [],
  fetchProjectError: null,
  testRailLoading: false,
  awsDeviceLoading: false,
  generalTabDetails: new BuildGeneralTabModal({}),
  S3ConfigTabDetails: new BuildS3ConfigTabModal({}),
  awsDeviceFarmTabDetails: new BuildAWSDeviceFarmModal({}),
  previous_awsDeviceFarmTabDetails: undefined,
  getSauceLabsDetails: undefined,
  updateSaucelabsDetails: undefined,
  getSauceLabsDataCenter: undefined,
  isValidateSauceLabsCredentials: false,
  sauceLabsLoading: false,
  isDisabled: true,
  getLambdaTestDataCenter: undefined,
  isValidateLambdaTestCredentials: false,
  lambdaTestLoading: false,
  getLambdaTestDetails: undefined,
  externalFarmDataCenterList: undefined,
  externalFarmDetails: undefined,
  externalFarmLoading: false,
  isValidateExternalFarmCredentials: false,
  isGenerateAPIKeyLoading:false,
  keyOfGenerateAPI:undefined,
  keyOfAPI:undefined,
};

export default function ProjectSettingsReducer(state = defaultState, action) {
  let response = undefined;
  switch (action.type) {
    case GET_PROJECT_DETAIL_SETTING:
      response = action.response.data;
      return {
        ...state,
        getProjectDetailsById: response,
      };

    case GET_AWS_PROJECTS:
      response = action.response.data;
      return {
        ...state,
        awsProjects: response,
      };

    case GET_AWS_POOLS:
      response = action.response.data;
      return {
        ...state,
        awsPools: response,
      };

    case REMOVE_AWS_POOLS:
      response = action.removeAwsPool;
      return {
        ...state,
        awsPools: response,
      };

    case UPDATE_PREVIOUS_AWS_DEVICE_FARM_TAB_DETAILS:
      response = action.removeAwsPool;
      return {
        ...state,
        previous_awsDeviceFarmTabDetails: undefined,
      };

    case GET_AWS_DEVICE_FARMS:
      response = action.response.data;
      return {
        ...state,
        getAWSDeviceFarms: response,
      };

    case GET_TEST_RAIL_CONFIG_DATA:
      response = action.response.data;
      return {
        ...state,
        getTestRailConfigData: response,
      };

    case GET_TEST_RAIL_CONFIG_PROJECT_LIST:
      return {
        ...state,
        getTestRailConfigProjectList: action.response.data,
        fetchProjectError: action.response,
      };

    case PROJECT_LOADING:
      response = action.response;
      return {
        ...state,
        projectLoading: response.isLoading,
      };

    case TEST_RAIL_LOADING:
      response = action.response;
      return {
        ...state,
        testRailLoading: response.isLoading,
      };

    case AWS_DEVICE_LOADING:
      response = action.response;
      return {
        ...state,
        awsDeviceLoading: response.isLoading,
      };

    case RESET_STATE:
      return {
        ...state,
        getTestRailConfigData: null,
        getTestRailConfigProjectList: [],
      };

    case CHANGE_GENERAL_TAB_DATA:
      if (
        state.generalTabDetails["generalTabDetails"] === undefined &&
        JSON.stringify(state.generalTabDetails) !== JSON.stringify(action.data)
      ) {
        let generalTabDetails = undefined;
        generalTabDetails = { ...action.data };
        generalTabDetails["previous_generalTabDetails"] = { ...state.generalTabDetails };
        return {
          ...state,
          generalTabDetails,
        };
      } else {
        return {
          ...state,
          generalTabDetails: action.data,
        };
      }

    case CHANGE_S3CONFIG_TAB_DATA:
      if (
        state.generalTabDetails["S3ConfigTabDetails"] === undefined &&
        JSON.stringify(state.S3ConfigTabDetails) !== JSON.stringify(action.data)
      ) {
        let S3ConfigTabDetails = undefined;
        S3ConfigTabDetails = { ...action.data };
        S3ConfigTabDetails["previous_S3ConfigTabDetails"] = { ...state.S3ConfigTabDetails };
        return {
          ...state,
          S3ConfigTabDetails,
        };
      } else {
        return {
          ...state,
          S3ConfigTabDetails: action.data,
        };
      }

    case CHANGE_AWS_DEVICE_FARM_TAB_DATA:
      if (
        state.previous_awsDeviceFarmTabDetails === undefined &&
        ((action.data && action.data.awsDeviceFarmProjects && action.data.awsDeviceFarmProjects.length === 0) ||
          (action.data &&
            action.data.awsDeviceFarmProjects.length !== 0 &&
            JSON.stringify(action.data) !== JSON.stringify(new BuildAWSDeviceFarmModal({}))))
      ) {
        let awsDeviceFarmTabDetails = _.cloneDeep(action.data);
        let previous_awsDeviceFarmTabDetails = _.cloneDeep(awsDeviceFarmTabDetails);
        return {
          ...state,
          awsDeviceFarmTabDetails: awsDeviceFarmTabDetails,
          previous_awsDeviceFarmTabDetails: previous_awsDeviceFarmTabDetails,
        };
      } else {
        return {
          ...state,
          awsDeviceFarmTabDetails: action.data,
        };
      }

    case HANDLE_DISABLE_BUTTON:
      return {
        ...state,
        isDisabled: action.flag,
      };

    case GET_EXTERNAL_FARM_DATA_CENTER_LIST:
      response = action.data;
      return {
        ...state,
        externalFarmDataCenterList: response,
      };

    case GET_EXTERNAL_FARM_DETAILS:
      response = action.data;
      return {
        ...state,
        externalFarmDetails: response,
        externalFarmName: action?.farmName,
      };

    case EXTERNAL_FARM_LOADING:
      response = action.flag;
      return {
        ...state,
        externalFarmLoading: response,
      };
    case VALIDATE_EXTERNAL_FARM_CREDENTIALS:
      response = action?.flag;
      return {
        ...state,
        isValidateExternalFarmCredentials: response,
      };

    case GENERATE_API_KEY_LOADER:
      return {
        ...state,
        isGenerateAPIKeyLoading: action?.isLoading,
      };

    case GENERATE_API_KEY:
      return {
        ...state,
        keyOfGenerateAPI: action?.response?.data,
      };

    case GET_API_KEY:
      return {
        ...state,
        keyOfAPI: action?.response?.data,
      };

    default:
      return state;
  }
}
