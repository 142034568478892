import { async, prepareEventSource } from "../../api/automation";
import {
  ADD_REC_TEST_STEP,
  ADD_REC_TEST_STEP_ACTION_SINGLE_COLUMN_VIEW,
  AI_SCENARIO_GENERATE_STEP_TYPE,
  CHANGE_DEVICE_INTERACTION_MODE,
  CHANGE_DISABLE_REC_STEP,
  CHANGE_PARENT_REC_TEST_STEP_FOR_AI_GENERATED_STEP,
  CLEAR_PREVIEW_IN_SINGLE_COLUMN_VIEW,
  DELETE_REC_TEST_STEP,
  DEVICE_INTERACTION_MODE,
  DEVICE_STATUS,
  ERROR_STATUS,
  FETCHED_TEST_STEP_LIST,
  FETCHING_TEST_STEP_LIST,
  HIDE_IMPORT_REC_TEST_STEP_MODAL,
  MAKE_SUB_REC_TEST_STEP,
  PREVIEW_COMPLETED,
  REC_TEST_STEP_LIST,
  REMOVE_SELECTED_REC_TEST_STEP_OR_ACTION,
  REQUEST_DELETE_REC_TEST_STEP,
  REQUEST_DRAG_AND_DROP,
  REQUEST_IMPORT_REC_TEST_STEP_ACTION,
  REQUEST_PERFORM_REC_TEST_STEP_ACTION,
  REQUESTED_DELETE_REC_TEST_STEP,
  REQUESTED_DRAG_AND_DROP,
  REQUESTED_IMPORT_REC_TEST_STEP_ACTION,
  REQUESTED_PERFORM_REC_TEST_STEP_ACTION,
  REQUESTED_UPDATE_REC_TEST_STEP_ACTION_STEP_NAME_CONTINUE_ON_FAILURE,
  RESET_EDIT_STEP,
  RunKilled,
  SAVE_REC_TEST_STEP_ACTION,
  STEP,
  STEP_ITEMS,
  SUCCESS_STATUS,
  TEST_STEP_BLOCKS,
  UPDATE_DEVICE_STATE,
  UPDATE_PREVIEW_STATUS,
  UPDATE_PREVIEW_STATUS_WITH_REC_TEST_STEP,
  UPDATE_REC_TEST_STEP_ACTION_STEP_NAME_CONTINUE_ON_FAILURE,
  UPDATE_REC_TEST_STEP_SINGLE_COLUMN_VIEW
} from "../../Constants";
import { findParentRecTestStep, findRecTestStepById } from "../../reducer/RecTestStepReducer";
import {
  getRecElement,
  getUniqueId,
  getUnSavedRecElement,
  handleShowDragError,
  isNotNullAndNotEmptyAndNotUndefined,
  removeSpaceFromVerifications,
  showNotification,
  updateRecElement,
  updateResponse,
  updateUnSavedRecElement,
} from "../../Util";
import _ from "lodash";
import { performDeviceInteraction, recordingStart, recordingStop, resetElement } from "../Recording";
import { resetAllRecScenarioStepIds } from "../RecTestScenarioAction";
import {
  prepareObjectInTestBlocks,
  resetRecTestStepActionElement,
  selectRecTestSteps,
  stopPreview,
  validateDragInDesignerScreen
} from "../RecTestStepAction";
import { fetchRecTestStepDetail } from "../RecTestStepMultiColumnViewAction";
import { getTestBlockStepListByTestBlockId } from "../TestBlocksAction";
import { fetchTestData } from "../TestDataAction";
import * as RecTestStepCommonAction from "./RecTestStepCommonAction";
import {
  clearPreview,
  hideDeleteRecElementConfirmationModal,
  hideDeleteRecTestStepConfirmationModal,
  hideGenerateStepsFromAIModal,
} from "./RecTestStepRedirectAction";
import { getRecTestStepObjForSingleColumn } from "./RecTestScenarioRecTestStepSingleColumn";
let previewResultEventSource = "";
export function addRecTestStep(recParentStep, stepItemType) {
  return (dispatch, getState) => {
    const { recTestStepList, testBlockStep } = getState().RecTestStepReducer;
    const { stepType } = getState().RecTestStepCommonViewReducer;
    const projectId = getState().ProjectsReducer.projectId;
    let recTestStep = getRecTestStepObjForSingleColumn(
      recTestStepList,
      recParentStep,
      projectId,

      stepItemType
    );
    recTestStep = {
      ...recTestStep,
      recTestScenario: null,
      project: { id: projectId },
      testBlockStep: { id: testBlockStep?.id },
      isTestBlock: 1,
    };
    dispatch({ type: REQUEST_PERFORM_REC_TEST_STEP_ACTION });
    RecTestStepCommonAction.callAddRecTestStep(recTestStep).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        response.data = RecTestStepCommonAction.addUnsavedValueAfterRecTestStepSave(response.data, stepItemType);
        dispatch(clearPreview());
        if (
          [STEP_ITEMS.WHEN_TO_RUN_BY_VERIFYING_ELEMENT, STEP_ITEMS.WHEN_TO_RUN_BY_VERIFYING_TEST_DATA].includes(
            stepItemType
          )
        ) {
          dispatch(RecTestStepCommonAction.editRecTestStep(response.data.tag, getUniqueId()));
        }
        dispatch({
          type: ADD_REC_TEST_STEP,
          response,
          stepItemType,
          stepType,
        });
        if (stepItemType === STEP_ITEMS.WHEN_TO_RUN_BY_VERIFYING_ELEMENT) {
          dispatch(recordingStart());
        }
        dispatch(
          RecTestStepCommonAction._captureExpandedSteps(
            recParentStep?.tag,
            recParentStep?.recTestScenarioRecTestStepAssocId,
            true
          )
        );
        dispatch(
          RecTestStepCommonAction._captureExpandedSteps(
            response.data.tag,
            response.data.recTestScenarioRecTestStepAssocId,
            true
          )
        );
      }
      dispatch({ type: REQUESTED_PERFORM_REC_TEST_STEP_ACTION });
    });
  };
}

export function deleteRecTestStep() {
  return (dispatch, getState) => {
    dispatch({ type: REQUEST_DELETE_REC_TEST_STEP });
    const { selectedRectTestScenarioStepId, testBlockStep } = getState().RecTestStepReducer;
    const { selectedRecTestStepIdForDelete, selectedRecTestStepTagForDelete, selectedRecTestStepAssocForDelete } =
      getState().RecTestStepCommonViewReducer;
    if (selectedRecTestStepIdForDelete === undefined && selectedRecTestStepTagForDelete !== undefined) {
      dispatch({
        type: DELETE_REC_TEST_STEP,
        deleteFromUnSavedStep: true,
        selectedRecTestStepIdForDelete,
        selectedRecTestStepTagForDelete,
        selectedRecTestStepAssocForDelete,
        selectedRectTestScenarioStepId,
        stepType: getState().RecTestStepCommonViewReducer.stepType,
      });
      dispatch({ type: REQUESTED_DELETE_REC_TEST_STEP });
      dispatch(hideDeleteRecTestStepConfirmationModal());
    } else if (
      (selectedRecTestStepIdForDelete !== undefined && selectedRecTestStepTagForDelete !== undefined) ||
      selectedRecTestStepAssocForDelete ||
      selectedRecTestStepAssocForDelete !== undefined ||
      selectedRectTestScenarioStepId !== undefined
    ) {
      let recTestSteps = {
        recTestSteps: selectedRecTestStepIdForDelete
          ? [{ id: selectedRecTestStepIdForDelete }]
          : selectedRectTestScenarioStepId && selectedRectTestScenarioStepId?.map((i) => ({ id: i })),
        projectId: getState().ProjectsReducer.projectId,
        isDeleteReference: getState().RecTestStepReducer.isDelateRecTestStepRef,
        testBlockStepId: testBlockStep?.id,
      };
      RecTestStepCommonAction.callDeleteRecTestBlockStep(recTestSteps).then((response) => {
        response = updateResponse(response);
        if (response.status === SUCCESS_STATUS) {
          dispatch({
            type: DELETE_REC_TEST_STEP,
            deleteFromUnSavedStep: false,
            selectedRecTestStepIdForDelete,
            selectedRecTestStepTagForDelete,
            response,
            selectedRectTestScenarioStepId,
            stepType: getState().RecTestStepCommonViewReducer.stepType,
          });
          showNotification("success", "Successfully Deleted Step.", "");
        } else {
          showNotification("error", response.message, "");
        }
        dispatch({ type: REQUESTED_DELETE_REC_TEST_STEP });
        dispatch(hideDeleteRecTestStepConfirmationModal());
        dispatch(hideDeleteRecElementConfirmationModal());
        dispatch(resetAllRecScenarioStepIds());
      });
    } else {
      dispatch({ type: REQUESTED_DELETE_REC_TEST_STEP });
      showNotification("error", "Error In Deleting The Step.", "");
    }
    dispatch(RecTestStepCommonAction.resetEditRecTestStep());
    dispatch(resetElement());
    dispatch(recordingStop());
  };
}

export function duplicateRecTestStep(duplicateRecTestStep) {
  return (dispatch, getState) => {
    const { recTestStepList, selectedTestStepBlock } = getState().RecTestStepReducer;
    let recTestStep = RecTestStepCommonAction.getDuplicateRecTestStepObj(recTestStepList, duplicateRecTestStep);
    recTestStep = {
      ...recTestStep,
      recTestStepId: recTestStep["id"],
      recTestScenarioRecTestStepsAssoc: undefined,
      parentRecTestScenarioRecTestStepsAssoc: undefined,
      isTestBlock: 1,
      testBlockStep: { id: selectedTestStepBlock.id },
      testBlockStepId: selectedTestStepBlock.id,
      project: { id: getState().ProjectsReducer.projectId },
    };
    dispatch({ type: FETCHING_TEST_STEP_LIST });
    RecTestStepCommonAction.callDuplicateRecTestBlockStep(recTestStep).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        dispatch(RecTestStepCommonAction.resetEditRecTestStep());
        dispatch({
          type: REC_TEST_STEP_LIST,
          response: { data: response.data, status: response.status },
          recTestScenarioId: selectedTestStepBlock?.id,
          recTestScenarioName: selectedTestStepBlock?.name,
          duplicateRecTestStep,
        });
      }
      dispatch({ type: FETCHED_TEST_STEP_LIST });
    });
  };
}

export function makeSubStep(recTestStep) {
  return (dispatch, getState) => {
    let recTestStepList = getState().RecTestStepReducer.recTestStepList;
    let newRecTestStep = {
      tag: getUniqueId(),
      project: { id: getState().ProjectsReducer.projectId },
      orderId: recTestStep.orderId,
      recTestStepProperty: {
        action: STEP,
        operator: "AND",
        stepName: "Parent Step-" + (recTestStepList.length + 1),
        recElements: [],
      },
      testBlockStepId: getState().RecTestStepReducer.selectedTestStepBlock?.id,
      requestForTestBlock: 1,
      recTestSteps: recTestStep?.id
        ? [{ id: recTestStep?.id }]
        : recTestStepList?.map((i) => {
            return { id: i?.id };
          }),
      parentRecTestStep: recTestStep?.parentRecTestStepId
        ? { id: recTestStep?.parentRecTestStepId }
        : { id: getState().RecTestStepReducer.selectedTestStepBlock?.id },
    };

    dispatch({ type: FETCHING_TEST_STEP_LIST });
    async("recTestStepBlock/makeCurrentStepSubStep", "POST", newRecTestStep).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        dispatch({ type: MAKE_SUB_REC_TEST_STEP, response, existingRecTestStep: { ...recTestStep } });
        dispatch({ type: FETCHED_TEST_STEP_LIST });
      }
    });
  };
}

export function _importStepFromJson(jsonData,recTestStep,stepGenerationComplete){
  return (dispatch,getState) =>{
    const { testBlockStep,selectedDeviceByUdid } = getState().RecTestStepReducer;
    const { selectedParentStepforAIGenerateStep } = getState().RecTestStepCommonViewReducer;
    const { projectId } = getState().ProjectsReducer;
    console.log("Previous",jsonData)
    jsonData = jsonData.map(item => {
      let _aiGeneratedStep = _.cloneDeep(item)
      delete _aiGeneratedStep["screenshot"]
      delete _aiGeneratedStep["pagesource"]
      item = {
        ...item,
        aiGeneratedStep:JSON.stringify(_aiGeneratedStep),
        otherXpathGenerated:RecTestStepCommonAction.generateOtherXpathFromAIStep(item,selectedDeviceByUdid?.platformType?.name)
      }
      return item;
    })
    console.log("After",jsonData)
    let data={
      steps:jsonData,
      testBlockStepId:testBlockStep?.id,
      parentRecTestStepId:selectedParentStepforAIGenerateStep?.id

    }
    async("recTestStepBlock/importFromJson", "POST", data).then((response) => {
      if (response.status === SUCCESS_STATUS) {
        showNotification("success", response.message, "");
        dispatch(
          getTestBlockStepListByTestBlockId(
            testBlockStep.id,
            testBlockStep,
            projectId,
            testBlockStep.platform,
            recTestStep,stepGenerationComplete
          )
        )
        dispatch(fetchTestData(projectId));
      }else{
        showNotification("error", response.message, "");
      }
      dispatch({ type: HIDE_IMPORT_REC_TEST_STEP_MODAL });
    })
  }
}

export function importRecTestStepOrAction(isImportOriginal, requestData) {
  return (dispatch, getState) => {
    const { disableRecTestStepActionsForImport, selectRecTestStep } = getState().RecTestStepCommonViewReducer;
    const { projectId } = getState().ProjectsReducer;
    const { selectedTestStepBlocks } = getState().TestBlockReducer;
    dispatch({ type: REQUEST_IMPORT_REC_TEST_STEP_ACTION });
    let data = {
      importSteps: requestData,
      isKeepOriginal: isImportOriginal ? 1 : 0,
      isMakeStepEditable: isImportOriginal ? 1 : 0,
      project: { id: projectId },
    };
    data.parentRecTestStep = { id: selectRecTestStep?.id || selectedTestStepBlocks?.id };
    async("recTestStepBlock/importBlock", "POST", data).then((response) => {
      if (response.status === SUCCESS_STATUS) {
        dispatch(fetchTestData(projectId));
        dispatch({
          type: REC_TEST_STEP_LIST,
          response: { data: response.data, status: response.status },
          recTestScenarioId: selectedTestStepBlocks?.id,
          recTestScenarioName: selectedTestStepBlocks?.name,
          updateSelectedRecTestStepDetail: !disableRecTestStepActionsForImport,
        });
        dispatch(
          getTestBlockStepListByTestBlockId(
            selectedTestStepBlocks.id,
            selectedTestStepBlocks,
            projectId,
            selectedTestStepBlocks.platform,
            selectedTestStepBlocks.tags,
            selectedTestStepBlocks.testRailTestCaseId,
            selectedTestStepBlocks.testRailTestSuiteId
          )
        );
      } else {
        showNotification("error", response.message, "");
      }
      dispatch({ type: RESET_EDIT_STEP });
      dispatch({ type: REMOVE_SELECTED_REC_TEST_STEP_OR_ACTION });
      dispatch({ type: REQUESTED_IMPORT_REC_TEST_STEP_ACTION });
      dispatch({ type: HIDE_IMPORT_REC_TEST_STEP_MODAL });
    });
  };
}

export function onRecTestStepDrop(event, recTestStep, parentRecTestStep, index, innerRecTestStep) {
  return async (dispatch, getState) => {
    dispatch({ type: REQUEST_DRAG_AND_DROP });
    let dropBeforeRecTestStep =
      event.clientY < event.target.getBoundingClientRect().top + event.target.getBoundingClientRect().height / 2;
    event.stopPropagation();

    const { recTestStepList, selectedRecTestScenarioId, selectedRecTestScenarioName, testBlockStep } =
      getState().RecTestStepReducer;
    let dragRecTestStep = undefined;
    let dragParentRecTestStep = undefined;
    let droppingParentRecTestStep = parentRecTestStep;
    let droppingRecTestStep = recTestStep;
    if(recTestStep === undefined && parentRecTestStep===undefined && recTestStepList.length>0){
      droppingRecTestStep= recTestStepList[recTestStepList.length-1]
    }
    let data = {};

    dragRecTestStep =
      event.dataTransfer.getData("dragRecTestStepId") !== undefined
        ? findRecTestStepById(recTestStepList, parseInt(event.dataTransfer.getData("dragRecTestStepId")))
        : undefined;

    dragParentRecTestStep =
      event.dataTransfer.getData("dragParentRecTestStepId") !== undefined
        ? findRecTestStepById(recTestStepList, parseInt(event.dataTransfer.getData("dragParentRecTestStepId")))
        : undefined;

    // Parent should be same in case of a TB with actions only.
    const isParentOnlyHasActions = recTestStepList && recTestStepList[0].recTestStepProperty?.action !== STEP;
    // Reset parent or make it undefined.
    if (isParentOnlyHasActions) {
      dragParentRecTestStep = { id: recTestStepList[0].testBlockRecTestStepId };
      droppingParentRecTestStep = { id: recTestStepList[0].testBlockRecTestStepId };
    }

    data = prepareObjectInTestBlocks(
      dragRecTestStep,
      dragParentRecTestStep,
      droppingRecTestStep,
      droppingParentRecTestStep,
      dropBeforeRecTestStep,
      index,
      testBlockStep.id,
      innerRecTestStep
    );

    if (dragRecTestStep !== undefined) {
      if (dragRecTestStep?.id === droppingRecTestStep?.id) {
        dispatch({ type: REQUESTED_DRAG_AND_DROP });
      } else if (
        !validateDragInDesignerScreen(
          dragRecTestStep,
          dragParentRecTestStep,
          droppingRecTestStep,
          droppingParentRecTestStep,
          dropBeforeRecTestStep,
          innerRecTestStep,
          TEST_STEP_BLOCKS
        )
      ) {
        handleShowDragError(dispatch,dragRecTestStep,droppingRecTestStep,droppingParentRecTestStep)     
      } else {
        RecTestStepCommonAction.callDragAndDropRecTestStep(data, "recTestStepBlock/updateBlockOrder").then(
          (response) => {
            if (response.status === SUCCESS_STATUS) {
              new Promise((resolve) =>
                resolve(
                  dispatch({
                    type: REC_TEST_STEP_LIST,
                    response: { data: response.data, status: response.status },
                    recTestScenarioId: selectedRecTestScenarioId,
                    recTestScenarioName: selectedRecTestScenarioName,
                  })
                )
              ).then(() => {
                dispatch(selectRecTestSteps(dragRecTestStep));
              });
              dispatch({ type: REQUESTED_DRAG_AND_DROP });
            } else {
              showNotification("error", response.message, "");
              dispatch({ type: REQUESTED_DRAG_AND_DROP });
            }
          }
        );
      }
    }
    return;
  };
}

export function performAndSaveRecTestStepAction(stepAction, parentRecTestStep) {
  return (dispatch, getState) => {
    const { selectedDeviceIdForRecording, selectedServerIdForRecording } = getState().RecTestStepReducer;
    const projectId = getState().ProjectsReducer.projectId;
    new Promise((resolve) => resolve(dispatch(_addRecTestStepAction(stepAction, parentRecTestStep)))).then(
      (recTestStep) => {
        dispatch(
          performDeviceInteraction(
            {
              deviceInteractionAction: stepAction,
              serverId: selectedServerIdForRecording,
              targetUniqueId: selectedDeviceIdForRecording,
            },
            recTestStep.tag,
            projectId
          )
        );
      }
    );
  };
}

export function _addRecTestStepAction(stepAction, parentRecTestStep) {
  return (dispatch, getState) => {
    const { recTestStepList, testBlockStep } = getState().RecTestStepReducer;
    const projectId = getState().ProjectsReducer.projectId;
    // Prepare the Rec Test Step Object
    let recTestStep = RecTestStepCommonAction.getNewRecTestStepActionObj(stepAction, parentRecTestStep);
    let unSavedRecTestStep = findRecTestStepById([...recTestStepList], parentRecTestStep.id, undefined);
    let orderId = 1;
    if (unSavedRecTestStep?.recTestSteps) {
      orderId = unSavedRecTestStep.recTestSteps.length + 1;
    }
    recTestStep = {
      ...recTestStep,
      orderId,
      recTestScenario: null,
      project: { id: projectId },
      testBlockStep: { id: testBlockStep?.id },
      isTestBlock: 1,
    };
    dispatch(_updateUnSavedRecTestStep(recTestStep));
    dispatch(clearPreview());
    let unSavedRecElement = getUnSavedRecElement(recTestStep.recTestStepProperty?.recElements);
    if (parentRecTestStep) {
      dispatch(
        RecTestStepCommonAction._captureExpandedSteps(
          parentRecTestStep.tag,
          parentRecTestStep?.recTestScenarioRecTestStepAssocId,
          true
        )
      );
    }
    dispatch(
      RecTestStepCommonAction._captureExpandedSteps(
        recTestStep.tag,
        recTestStep.recTestScenarioRecTestStepAssocId,
        true
      )
    );
    if (unSavedRecElement) {
      dispatch(
        RecTestStepCommonAction._captureExpandedElements(
          unSavedRecElement.guid,
          recTestStep.tag,
          recTestStep.recTestScenarioRecTestStepAssocId,
          true
        )
      );
    }

    dispatch({ type: ADD_REC_TEST_STEP_ACTION_SINGLE_COLUMN_VIEW, stepAction, parentRecTestStep, recTestStep });
    return recTestStep;
  };
}

export function findRecTestStepByTag(state) {
  const { recTestStepList, testBlockStep } = state.RecTestStepReducer;
  const { unSavedRecStepTag } = state.RecTestStepCommonViewReducer;
  return _findRecTestStepByTag(recTestStepList, testBlockStep, unSavedRecStepTag);
}

function _findRecTestStepByTag(recTestStepList, testBlockStep, tag) {
  let selectedRecTestStep = undefined;
  if (testBlockStep?.tag === tag) {
    selectedRecTestStep = testBlockStep;
  } else {
    for (let i = 0; i < recTestStepList.length; i++) {
      if (recTestStepList[i].tag === tag) {
        selectedRecTestStep = recTestStepList[i];
        break;
      } else {
        if (
          recTestStepList[i].recTestStepProperty?.action === STEP &&
          recTestStepList[i].recTestSteps &&
          recTestStepList[i].recTestSteps.length > 0
        ) {
          selectedRecTestStep = _findRecTestStepByTag(recTestStepList[i]?.recTestSteps, testBlockStep, tag);
          if (selectedRecTestStep) {
            break;
          }
        }
      }
    }
  }
  return selectedRecTestStep;
}

export function updateRecTestStep(updatedRecTestStep) {
  return (dispatch, getState) => {
    if (updatedRecTestStep === undefined) {
      const { recTestStepList } = getState().RecTestStepReducer;
      const { unSavedRecStepTag } = getState().RecTestStepCommonViewReducer;
      updatedRecTestStep = _findRecTestStepByTag(recTestStepList, unSavedRecStepTag);
    }
    dispatch(RecTestStepCommonAction._updateUnSavedRecTestStep(updatedRecTestStep));
    dispatch({ type: UPDATE_REC_TEST_STEP_SINGLE_COLUMN_VIEW, updatedRecTestStep });
  };
}

export function getRecTestStepFromList(recTestStepTag) {
  return (dispatch, getState) => {
    const { recTestStepList, testBlockStep } = getState().RecTestStepReducer;
    return _findRecTestStepByTag(recTestStepList, testBlockStep, recTestStepTag);
  };
}

export function _updateUnSavedRecTestStep(updatedRecTestStep) {
  return (dispatch, getState) => {
    if (updatedRecTestStep === undefined) {
      const { recTestStepList } = getState().RecTestStepReducer;
      const { unSavedRecStepTag, unSavedRecElementGuid } = getState().RecTestStepCommonViewReducer;
      updatedRecTestStep = _findRecTestStepByTag(recTestStepList, unSavedRecStepTag);
    }
    dispatch(RecTestStepCommonAction._updateUnSavedRecTestStep(updatedRecTestStep));
    dispatch({ type: UPDATE_REC_TEST_STEP_SINGLE_COLUMN_VIEW, updatedRecTestStep });
  };
}

export function _updateRecTestStepAction(recTestStep, updateConditionMethod, updateStepActionName, unSavedRecTestStep) {
  return (dispatch, getState) => {
    const { recTestStepList } = getState().RecTestStepReducer;
    const { unSavedRecStepTag } = getState().RecTestStepCommonViewReducer;
    let updateStepName = recTestStep.recTestStepProperty?.stepName;
    let recTestStepActionTag = recTestStep?.tag;
    let condition =
      unSavedRecTestStep.recTestStepProperty?.isStepHasPrecondition === 1
        ? STEP_ITEMS.WHEN_TO_RUN_BY_VERIFYING_ELEMENT
        : unSavedRecTestStep.recTestStepProperty?.isLoopStep === 1
        ? STEP_ITEMS.REPEAT_TILL
        : "";
    let data = RecTestStepCommonAction.prepareUpdateRecTestStepActionObj(
      recTestStep,
      updateConditionMethod,
      condition,
      updateStepName
    );
    new Promise((resolve) =>
      resolve(
        dispatch(RecTestStepCommonAction._updateRecTestStepAction(data, updateConditionMethod, condition, recTestStep))
      )
    ).then((response) => {
      dispatch({
        type: UPDATE_REC_TEST_STEP_ACTION_STEP_NAME_CONTINUE_ON_FAILURE,
        response,
        data,
        recTestStepId: unSavedRecTestStep.id,
        recTestStepTag: unSavedRecTestStep.tag,
        updateStepName,
        recTestStep,
      });
      dispatch({
        type: REQUESTED_UPDATE_REC_TEST_STEP_ACTION_STEP_NAME_CONTINUE_ON_FAILURE,
        recTestStepActionId: unSavedRecTestStep.id,
        recTestStepActionTag,
      });
      if (response.status === SUCCESS_STATUS && updateConditionMethod) {
        dispatch(updateConditionMethod(recTestStepActionTag, condition));
      }
      dispatch(recordingStop());
    });
  };
}

export function performRecTestStepAction(unSavedRecTestStep) {
  return (dispatch, getState) => {
    const { unSavedRecStepTag } = getState().RecTestStepCommonViewReducer;
    const { selectedDeviceByUdid} = getState().RecTestStepReducer;
    dispatch({ type: REQUEST_PERFORM_REC_TEST_STEP_ACTION });
    new Promise((resolve) =>
      resolve(dispatch(RecTestStepCommonAction._performRecTestStepAction(unSavedRecTestStep)))
    ).then((performResp) => {
      if (performResp) {
        performResp = updateResponse(performResp);
        if (performResp.status === SUCCESS_STATUS) {
          if(performResp?.data?.recElements==undefined && unSavedRecTestStep["recTestStepProperty"]["isAIStep"]==1){
            let jsonData = performResp.data;
            jsonData = jsonData.map(item => {
              item = {
                ...item,
                otherXpathGenerated:RecTestStepCommonAction.generateOtherXpathFromAIStep(item,selectedDeviceByUdid?.platformType?.name)
              }
              return item;
            })
            jsonData = {
              steps:[...jsonData],
              recTestStepPropertyId:unSavedRecTestStep?.recTestStepProperty?.id,
              recTestStepId:unSavedRecTestStep?.id,
              isForPrecondition:unSavedRecTestStep?.recTestStepProperty?.isStepHasPrecondition,
              isForLoopStep:unSavedRecTestStep?.recTestStepProperty?.isLoopStep,
              loopCount:unSavedRecTestStep?.recTestStepProperty?.loopCount,
              recTestScenarioRecTestStepAssocId:unSavedRecTestStep?.recTestScenarioRecTestStepAssocId
            }
            console.log("jsonData",jsonData)
            new Promise(resolve => resolve(dispatch(saveStepAsNLP(jsonData,unSavedRecTestStep)))).then(resp => resp)
            dispatch(recordingStop());
            dispatch({ type: REQUESTED_PERFORM_REC_TEST_STEP_ACTION });
          }else{
            new Promise((resolve) =>
              resolve(
                dispatch(
                  RecTestStepCommonAction._saveRecTestStepAction(
                    unSavedRecTestStep,
                    performResp.data,
                    unSavedRecTestStep?.id,
                    unSavedRecStepTag
                  )
                )
              )
            ).then((response) => {
              if (response.status === SUCCESS_STATUS) {
                dispatch({ type: SAVE_REC_TEST_STEP_ACTION, response });
                dispatch(resetRecTestStepActionElement(response.data.id, response.data.tag));
                dispatch(clearPreview());
              } else {
                showNotification("error", response.message, "");
              }
              dispatch(recordingStop());
              dispatch({ type: REQUESTED_PERFORM_REC_TEST_STEP_ACTION });
            });
          }
        } else {
          dispatch({ type: REQUESTED_PERFORM_REC_TEST_STEP_ACTION });
          showNotification("error", performResp.message, "");
        }
      }
    });
  };
}

export function saveStepAsNLP(jsonData,unSavedRecTestStep){
  return (dispatch,getState) =>{
    const { testBlockStep,recTestStepList} = getState().RecTestStepReducer;
    const { unSavedRecStepTag } = getState().RecTestStepCommonViewReducer;
    const { projectId } = getState().ProjectsReducer;
    let parentRecTestStepDetail = findParentRecTestStep(recTestStepList, undefined ,unSavedRecStepTag);
      let _aiGeneratedStep = _.cloneDeep(jsonData)
      _aiGeneratedStep.steps.map(item =>{
        delete item["screenshot"]
        delete item["pagesource"]
      })
      jsonData = {
        ...jsonData,
        stepTitle:unSavedRecTestStep?.recTestStepProperty?.isStepHasPrecondition==1 || unSavedRecTestStep?.recTestStepProperty?.isLoopStep==1 ? unSavedRecTestStep?.recTestStepProperty?.preConditionTitle:unSavedRecTestStep?.recTestStepProperty?.stepName,
        appendRandomText:unSavedRecTestStep?.recTestStepProperty?.appendRandomText,
        aiGeneratedStep:JSON.stringify(_aiGeneratedStep.steps),
        expectedAction : unSavedRecTestStep?.recTestStepProperty?.action,
        testBlockStepId:testBlockStep?.id,
        parentRecTestStepId:parentRecTestStepDetail?.id,
      }
    console.log("After",jsonData)
    async("recTestStepBlock/saveStepAsNLP", "POST", jsonData).then((response) => {
      if (response.status === SUCCESS_STATUS) {
        showNotification("success", response.message, "");
        dispatch(
          getTestBlockStepListByTestBlockId(
            testBlockStep.id,
            testBlockStep,
            projectId,
            testBlockStep.platform,
            testBlockStep.tags,
            testBlockStep.testRailTestCaseId,
            testBlockStep.testRailTestSuiteId
          )
        )
      }else{
        showNotification("error", response.message, "");
      }
      dispatch({ type: HIDE_IMPORT_REC_TEST_STEP_MODAL });
    })
  }
}

export function saveRecTestStepAction(responseData, recTestStepId, recTestStepTag, recElementGuid) {
  return (dispatch, getState) => {
    let { recTestStepList, selectedRecTestScenarioId, testBlockStep } = getState().RecTestStepReducer;
    let { unSavedRecStepTag, stepType } = getState().RecTestStepCommonViewReducer;
    let unSavedRecTestStep = findRecTestStepByTag(getState());
    // let unSavedRecTestStep = _findRecTestStepByTag(recTestStepList, unSavedRecStepTag);
    let parentRecTestStepDetail = findParentRecTestStep(recTestStepList, recTestStepId, unSavedRecStepTag);
    if (unSavedRecTestStep) {
      if (
        unSavedRecStepTag &&
        isNotNullAndNotEmptyAndNotUndefined(unSavedRecStepTag) &&
        parentRecTestStepDetail &&
        unSavedRecTestStep.id !== parentRecTestStepDetail.id
      ) {
        unSavedRecTestStep["parentRecTestStep"] = {
          id: parentRecTestStepDetail.id,
        };
        unSavedRecTestStep["parentRecTestScenarioRecTestStepsAssoc"] = {
          id: parentRecTestStepDetail.recTestScenarioRecTestStepAssocId,
        };
      }
      if (
        unSavedRecTestStep.recTestStepProperty?.isStepHasPrecondition === 1 &&
        testBlockStep &&
        testBlockStep.tag === unSavedRecTestStep.tag
      ) {
        unSavedRecTestStep["recTestSteps"] = [...recTestStepList];
      } else {
        //Add Rec Test Scenario reference
        unSavedRecTestStep["recTestScenario"] = {
          id: selectedRecTestScenarioId,
        };
      }
      if (unSavedRecTestStep["recTestStepProperty"]["recStepVerifyElementProperties"]) {
        unSavedRecTestStep["recTestStepProperty"]["recStepVerifyElementProperties"] = removeSpaceFromVerifications(
          unSavedRecTestStep["recTestStepProperty"]["recStepVerifyElementProperties"]
        );
      }
      if (responseData && responseData["recElements"]) {
        unSavedRecTestStep["recTestStepProperty"]["recElements"] = updateUnSavedRecElement(
          unSavedRecTestStep["recTestStepProperty"]["recElements"],
          responseData["recElements"][0]
        );
      } else if (responseData && responseData["imageData"] && recElementGuid) {
        let recElement = getRecElement(unSavedRecTestStep["recTestStepProperty"]["recElements"], recElementGuid);
        recElement.elementImage = responseData["imageData"];
        unSavedRecTestStep["recTestStepProperty"]["recElements"] = updateRecElement(
          unSavedRecTestStep["recTestStepProperty"]["recElements"],
          recElement
        );
      }
      if (stepType === TEST_STEP_BLOCKS) {
        const projectId = getState().ProjectsReducer.projectId;
        unSavedRecTestStep = {
          ...unSavedRecTestStep,
          recTestScenario: null,
          project: { id: projectId },
          testBlockStep: unSavedRecTestStep?.id !== testBlockStep?.id ? { id: testBlockStep?.id } : undefined,
          isTestBlock: 1,
        };
      }
      dispatch({ type: REQUEST_PERFORM_REC_TEST_STEP_ACTION });
      return async("recTestStep/customSave", "POST", unSavedRecTestStep).then((response) => {
        if (response.status === SUCCESS_STATUS) {
          dispatch({ type: SAVE_REC_TEST_STEP_ACTION, response });
          dispatch(clearPreview());
          dispatch(recordingStop());
          dispatch(resetRecTestStepActionElement(recTestStepId, recTestStepTag));
        } else {
          showNotification("error", response.message, "");
        }
        dispatch({ type: REQUESTED_PERFORM_REC_TEST_STEP_ACTION });
        return true;
      });
    }
  };
}

export function _changeDisableStep(checked, recTestStep) {
  return async (dispatch, getState) => {
    dispatch({ type: REQUEST_PERFORM_REC_TEST_STEP_ACTION });
    const { selectedRecTestStepDetail } = getState().RecTestStepMultiColumnViewReducer;
    const { stepType } = getState().RecTestStepCommonViewReducer;
    const data = RecTestStepCommonAction.getDisabledRecTestStepOrActionObj(
      recTestStep?.recTestStepProperty?.id,
      checked
    );
    return RecTestStepCommonAction.callDisabledRecTestStep(data).then((response) => {
      if (selectedRecTestStepDetail !== undefined) {
        dispatch(fetchRecTestStepDetail(selectedRecTestStepDetail));
      }
      if (response.status === SUCCESS_STATUS) {
        dispatch({ type: CHANGE_DISABLE_REC_STEP, checked, recTestStep, stepType });
      } else {
        showNotification("error", response.message, "");
      }
      dispatch({ type: REQUESTED_PERFORM_REC_TEST_STEP_ACTION });
    });
  };
}

export function getPreviewDetail(data) {
  return async (dispatch, getState) => {
    previewResultEventSource = prepareEventSource(
      "recording/getPreviewDetail",
      {
        runId: data.runId,
      },
      ""
    );
    dispatch({ type: UPDATE_DEVICE_STATE, state: DEVICE_STATUS.PREVIEW });
    previewResultEventSource.onmessage = (event: any) => {
      if (event.data != "") {
        let data = event.data;
        let eventParseResponse = JSON.parse(data);
        if (
          eventParseResponse.status === SUCCESS_STATUS &&
          eventParseResponse.data.length > 0 &&
          eventParseResponse.data[0]["runStatus"] === "DONE"
        ) {
          previewResultEventSource && previewResultEventSource.close();
          dispatch({ type: UPDATE_DEVICE_STATE, state: DEVICE_STATUS.INTERACTION });
          dispatch({ type: CHANGE_DEVICE_INTERACTION_MODE, value: DEVICE_INTERACTION_MODE.INTERACTIVE });
          dispatch({ type: PREVIEW_COMPLETED });
          dispatch({ type: UPDATE_PREVIEW_STATUS, response: eventParseResponse, orderId: 1 });
          dispatch({ type: UPDATE_PREVIEW_STATUS_WITH_REC_TEST_STEP, response: eventParseResponse });
        } else if (eventParseResponse.status === ERROR_STATUS && eventParseResponse.message === RunKilled) {
          previewResultEventSource && previewResultEventSource.close();
          dispatch({ type: UPDATE_DEVICE_STATE, state: DEVICE_STATUS.INTERACTION });
          dispatch({ type: CHANGE_DEVICE_INTERACTION_MODE, value: DEVICE_INTERACTION_MODE.INTERACTIVE });
          dispatch({ type: PREVIEW_COMPLETED });
          showNotification("error", "Preview Was Unexpectedly Killed.", "");
          dispatch(stopPreview(data.serverId, data.targetUniqueId));
          dispatch({ type: UPDATE_PREVIEW_STATUS, response: eventParseResponse, orderId: 1 });
          dispatch({ type: UPDATE_PREVIEW_STATUS_WITH_REC_TEST_STEP, response: eventParseResponse });
        } else {
          dispatch({ type: UPDATE_PREVIEW_STATUS, response: eventParseResponse, orderId: 1 });
          dispatch({ type: UPDATE_PREVIEW_STATUS_WITH_REC_TEST_STEP, response: eventParseResponse });
        }
      }
    };
  };
}

export function _addConditionToStep(condition, unSavedRecTestStep, newRecElementGuid) {
  return (dispatch, getState) => {
    new Promise((resolve) =>
      resolve(dispatch(RecTestStepCommonAction._addConditionToStep(condition, unSavedRecTestStep)))
    ).then(() => {
      dispatch({
        type: UPDATE_REC_TEST_STEP_SINGLE_COLUMN_VIEW,
        updatedRecTestStep: unSavedRecTestStep,
        stepType: getState().RecTestStepCommonViewReducer.stepType,
      });
    });
  };
}

export function _removeLoopConditionFromRecTestStep(selectedRecTestStepForLoopCondition) {
  return async (dispatch, getState) => {
    new Promise((resolve) =>
      resolve(dispatch(RecTestStepCommonAction._removeLoopConditionFromRecTestStep(selectedRecTestStepForLoopCondition)))
    ).then((response) => {
      dispatch({
        type: UPDATE_REC_TEST_STEP_SINGLE_COLUMN_VIEW,
        updateRecTestStep: response,
      });
      dispatch(RecTestStepCommonAction.resetEditRecTestStep());
    });
  };
}

export function _clearPreview(runId, serverId) {
  return (dispatch, getState) => {
    if (runId) {
      new Promise((resolve) => resolve(dispatch(RecTestStepCommonAction._removeRunData(runId, serverId)))).then(
        (response) => {
          dispatch({ type: CLEAR_PREVIEW_IN_SINGLE_COLUMN_VIEW });
        }
      );
    } else {
      dispatch({ type: CLEAR_PREVIEW_IN_SINGLE_COLUMN_VIEW });
    }
  };
}

export function _generateScenarioSteps(data,selectedRecTestStep) {
  return async (dispatch, getState) => {
    const { stepType,selectedParentStepforAIGenerateStep,scenarioGenerateStepType } = getState().RecTestStepCommonViewReducer;
    let { recTestStepList, testBlockStep } = getState().RecTestStepReducer;
    const projectId = getState().ProjectsReducer.projectId;
    data={
      ...data,
      testBlockStepId:testBlockStep?.id
    }
    if(scenarioGenerateStepType[selectedRecTestStep?.id]){
      let recTestSteps = recTestStepList.map(item => {return {id:item.id}});
      let requestData = {
        isDeleteReference:0,
        projectId,
        testBlockStepId:testBlockStep?.id,
        recTestSteps,
      };
      if(selectedParentStepforAIGenerateStep){
        recTestSteps = selectedParentStepforAIGenerateStep.recTestSteps.map(item => {return {id:item.id}})
        requestData = {
          isDeleteReference:0,
          projectId,
          testBlockStepId:testBlockStep?.id,
          recTestSteps,
        };
      }
      let response = await RecTestStepCommonAction.callDeleteRecTestBlockStep(requestData)
      response = updateResponse(response);
        if (response.status === SUCCESS_STATUS) {
          recTestStepList = response.data
          dispatch({
            type: DELETE_REC_TEST_STEP,
            deleteFromUnSavedStep: false,
            undefined,
            undefined,
            response,
            undefined,
            stepType: getState().RecTestStepCommonViewReducer.stepType,
          });
        }
    }
    if(selectedParentStepforAIGenerateStep && (selectedParentStepforAIGenerateStep.recTestSteps ==undefined || selectedParentStepforAIGenerateStep.recTestSteps.length==0 || 
      (selectedParentStepforAIGenerateStep.recTestSteps && selectedParentStepforAIGenerateStep.recTestSteps.length>0 && selectedParentStepforAIGenerateStep.recTestSteps[0].recTestStepProperty.action!=STEP))){
          dispatch(RecTestStepCommonAction._generateScenarioSteps(data,selectedRecTestStep));
    }else{
        let recTestStep = getRecTestStepObjForSingleColumn(recTestStepList, selectedParentStepforAIGenerateStep, projectId);
        recTestStep["recTestStepProperty"]["stepName"]=data["objective"]
        recTestStep = {
          ...recTestStep,
          testBlockStep: { id: testBlockStep?.id },
          isTestBlock: 1,
        };
        RecTestStepCommonAction.callAddRecTestStep(recTestStep).then(response => {
          dispatch({type: ADD_REC_TEST_STEP,response,stepType});
          dispatch({type:CHANGE_PARENT_REC_TEST_STEP_FOR_AI_GENERATED_STEP,recTestStep:response.data});
          dispatch(RecTestStepCommonAction._generateScenarioSteps(data));
        });
    }
  };
}

  export function _stopGenerateStepsFromAIModal(data) {
    return async (dispatch,getState) => {
      const { testBlockStep } = getState().RecTestStepReducer;
      data = {
        ...data,
        testBlockStepId:testBlockStep?.id
      }
      dispatch(RecTestStepCommonAction._stopGenerateStepsFromAIModal(data));
    };
  }
  
  export function _fetchGenerateStepStatus(data,recTestStep) {
    return async (dispatch,getState) => {
      const { testBlockStep } = getState().RecTestStepReducer;
      data = {
        ...data,
        testBlockStepId:testBlockStep?.id
      }
      dispatch(RecTestStepCommonAction._fetchGenerateStepStatus(data,recTestStep));
    };
  }