import React from "react";
import { useDispatch } from "react-redux";
import { Spin, Tooltip } from "antd";
import { TOOLTIP_BG_COLOR } from "../../../../Constants";
import { ICON_EDIT_NEW, SILVER_GRAY, TRASH_ICON, UPLOAD_BUILD_ICON } from "../../../../Constants/SvgConstants";
import SvgLoader from "../../../../Util/SvgLoader";
import { showInstallBuildOnDevicesModal } from "../../../../actions/Recording";
import ActionComponentV2 from "../../../CoreComponents/ActionComponentV2/ActionComponentV2";
import styles from "./AppBuildListColumnsV2.module.scss";

const AppBuildListActionColumnV2 = (props) => {
  const dispatch = useDispatch();
  const { row, projectId, fetchAppBuildDataById, deleteConfirm, handleActionChange } = props;

  const handleAction = (row) => {
    const actions = [];
    if (row?.isPrivate === true) {
      actions.push({
        title: "Make Public",
        name: "PUBLIC",
      });
    } else {
      actions.push({
        title: "Make Private",
        name: "PRIVATE",
      });
    }
    return actions;
  };

  return (
    <div className={` ${row?.uploadStatus === "In-Progress" ? styles["in_Progress_Loader"] : styles["action-icon"]}`}>
      {row?.uploadStatus === "In-Progress" ? (
        <Spin spinning={true}></Spin>
      ) : (
        <>
          <Tooltip color={TOOLTIP_BG_COLOR} title="Install Build" mouseEnterDelay={0} mouseLeaveDelay={0}>
            <div className={styles["app_Build__Buttons"]}>
              <SvgLoader
                iconName={UPLOAD_BUILD_ICON}
                handleClick={() =>
                  row?.uploadStatus === "DONE" && dispatch(showInstallBuildOnDevicesModal(projectId, row))
                }
              />
            </div>
          </Tooltip>{" "}
          <Tooltip color={TOOLTIP_BG_COLOR} title="Edit" mouseEnterDelay={0} mouseLeaveDelay={0}>
            <div className={styles["app_Build__Buttons"]}>
              <SvgLoader
                iconName={ICON_EDIT_NEW}
                handleClick={() => row?.uploadStatus === "DONE" && fetchAppBuildDataById(row?.id)}
              />
            </div>
          </Tooltip>
          <Tooltip color={TOOLTIP_BG_COLOR} title="Delete" mouseEnterDelay={0} mouseLeaveDelay={0}>
            <div className={styles["app_Build__Buttons"]}>
              <SvgLoader
                iconName={TRASH_ICON}
                iconColor={SILVER_GRAY}
                handleClick={(e) => row?.uploadStatus === "DONE" && deleteConfirm(row?.displayName, [row?.id])}
              />
            </div>
          </Tooltip>
          <ActionComponentV2
            actions={handleAction(row)}
            data={row}
            handleActionChange={handleActionChange}
            disabled={row?.uploadStatus !== "DONE"}
          />
        </>
      )}
    </div>
  );
};

export default AppBuildListActionColumnV2;
